import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Card,
  Autocomplete,
  CardContent,
  Divider,
  Backdrop,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  textClasses,
  CircularProgress,
  Tooltip,
  CardHeader,
  Alert,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FadeLoader from "react-spinners/FadeLoader";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";
import errorMsg from "../util/errorMessage.json";
import ExcelValidation from "../../Template/Excel/ExcelValidation";
/*------------api call-----------------*/
import {
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
  Equipmentsitedropdown,
  postEquipmentSave,
  putEquipmentconfig,
  fetchequipmentTableById,
  primaryDrop,
  putEquipReplace,
  postEquipmentexceldata,
  Equipmentcategorysite,
  fetchSiteEquipmentFilterTable,
} from "../../Api/EquipmentApi";
import { fetchSiteDetails } from "../../Api/ReportApi";
import AdditionalFields from "../util/components/AdditionalFields";
import TableTemplate from "../../Template/TableTemplate";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Download from "../../Template/Excel/Download";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import * as XLSX from "xlsx";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  equipmentConfigEquipmentSchema,
  equipmentReplaceSchema,
} from "../util/ValidateSchema";

import { EquipmentConfiguration } from "../util/TextField";
import { EquipmentList } from "../util/TextField";
import {
  Statusdropdown,
  fetchInstalltionCapacity,
  fetchInverterCapacityUnits,
} from "../../Api/CommonApi";
import { statusMasterDrop } from "../../Api/EquipmentApi";
import replaceObjectById from "../util/StateUpdater";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import * as yup from "yup";
import { set } from "date-fns";
import { CheckForLight } from "../util/common/CommanFunction";
import { CapacityCategoryMaps } from "../util/common/MapContants";

export default function Sectorhome({ equipmentConfiguration, ...props }) {
  const { searchInput } = props;
  /*------------state-----------------*/
  const [SiteSector, setSiteSector] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [excelOpen, setExcelOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [loading, setloading] = useState(true);
  const [equipment, setEquipment] = useState("");
  const [statusvalue, setStatusvalue] = useState();
  const [sitestatus, setSitestatus] = useState("");
  const [dailyGeneration, setdailyGeneration] = useState("");
  const [primaryEquipment, setPrimaryEquipment] = useState("");
  const [primarymapequip, setprimaryMapEquip] = useState("");
  const [productionFlag, setProductionFlag] = useState("");
  const [mastervalue, setMastervalue] = useState(null);
  const [dataloggervalue, setDataLoggervalue] = useState("");
  const [statusMasterId, setStatusMaster] = useState();
  const [genvalue, setGenvalue] = useState();
  const [activeFlag, setactiveFlag] = useState("");
  const [replace, setReplace] = useState(null);
  const [Valuedata, setValuedata] = useState();
  const [acUnits, setAcUnits] = useState("");
  const [dcUnits, setDcUnits] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  console.log(selectedCategory);
  const [selectedEquipmentCategory, setSelectedEquipmentCategory] =
    useState(selectedCategory);
  //eslint-disable-next-line
  const [activeButton, setActiveButton] = useState("Equipment");
  const [activeTab, setActiveTab] = useState("Equipment");
  const [DataStatusvalue, setDataStatusvalue] = useState();
  const [Flog, setFlog] = useState();
  const [category, setCategory] = useState("");
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [siteName, setSiteNameList] = useState([]);
  // const [statusMastervalue, setStatusMastervalue] = useState([]);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [descriptionvalue, SetDescriptionvalue] = useState([]);
  const [text, settext] = useState([]);
  const [statusDrop, setStatusDrop] = useState([]);
  const [statusMasterDroplist, setStatusMasterDroplist] = useState([]);
  const [primaryEquipValue, setPrimaryEquipValue] = useState([]);
  const [sitedropvalue, setSitedropvalue] = useState([]);
  const [addHeadCells, setAddHeadCells] = useState([]);
  const [errorVal, setErrorVal] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const [errorIndex, setErrorIndex] = useState(null);
  console.log(Valuedata);

  const initialState = {
    siteName: Valuedata,
    acCapacity: "",
    dcCapacity: "",
    remarks: "",
    status: "",
    displayName: "",
    equipmentType: "",
    equipmentCategory: "",
    equipmentCategoryId: "",
    equipmentTypeId: "",
    isPrimaryEquipment: "",
    primaryMapEquip: "",
    latitude: "",
    longitude: "",
    noOfComponents: "",
    dcUnits: "",
    acUnits: "",
    installationDate: "",
    warrantyDate: "",
    // lastUpdatedBy: '',
    siteId: "",
    secondaryEquipmentId: "",
    serialNo: "",
    isReplaced: "",
    equipmentadditionalfield: [],
    deviceSn: "",
  };
  const [stdField, setstdField] = useState(initialState);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [siteTab, setSiteTab] = useState("");
  const [unitsIC, setUnitsIC] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatee, setSelectedDatee] = useState(null);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  console.log(selectedDate);
  console.log(unitsIC);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const styleTable = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1400,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };
  const handleChangeTxt = (e) => {
    console.log(e.target.name, "namesss", e.target.value);
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (event, value) => {
    const selectedValue = value;
    console.log(selectedValue, "selectvalue");
    setSelectedCategory(selectedValue);
    console.log("handleCategoryChange");
    if (selectedValue !== selectedEquipmentCategory) {
      setSelectedEquipmentCategory(selectedValue);
      setAcUnits("");
      setDcUnits("");
    }
  };

  const fetchUnits = async () => {
    try {
      let data = await fetchInverterCapacityUnits();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };
  const { categoryMaps, unitCategoryMaps } = CapacityCategoryMaps();

  const { acCategories, dcCategories, bothCategories } = categoryMaps;

  function isCategoryAndNameValid(selectedCategory, dataName, excelName) {
    // Preprocess categories for easier comparison
    const AcCategories = acCategories.map((category) =>
      category.replace(/\s+/g, "").toLowerCase()
    );

    const DcCategories = dcCategories.map((category) =>
      category.replace(/\s+/g, "").toLowerCase()
    );

    const BothCategories = bothCategories.map((category) =>
      category.replace(/\s+/g, "").toLowerCase()
    );

    // Preprocess the selected category
    const normalizedCategory = selectedCategory
      ?.replace(/\s+/g, "")
      .toLowerCase();

    // Check conditions
    // console.log(acCategories.includes(normalizedCategory), 'acCategories.includes(normalizedCategory)');
    if (
      AcCategories.includes(normalizedCategory) &&
      (dataName === "acCapacity" ||
        excelName === "AC Capacity" ||
        excelName === "AcUnits")
    ) {
      // console.log(acCategories.includes(normalizedCategory), 'Invalid colIndex: ');
      return true;
    }
    if (
      DcCategories.includes(normalizedCategory) &&
      (dataName === "dcCapacity" ||
        excelName === "DC Capacity" ||
        excelName === "DcUnits")
    ) {
      return true;
    }
    if (
      BothCategories.includes(normalizedCategory) &&
      (dataName === "acCapacity" ||
        dataName === "dcCapacity" ||
        excelName === "AC Capacity" ||
        excelName === "AcUnits" ||
        excelName === "DC Capacity" ||
        excelName === "DcUnits")
    ) {
      return true;
    }

    return false;
  }

  const { acKwCategories, acKvaCategories, dcKwpCategories, dcVahCategories } =
    unitCategoryMaps;

  function isUnitsMatch(category, unitName) {
    category = category?.replace(/\s+/g, "").toLowerCase();
    const overallCondition = {
      acUnits: [
        {
          categories: acKwCategories,
          unit: "kW",
        },
        {
          categories: acKvaCategories,
          unit: "kVA",
        },
        {
          categories: ["powertransformer"],
          unit: "MVA",
        },
        {
          categories: ["waterpumping"],
          unit: "hp",
        },
      ],
      dcUnits: [
        {
          categories: dcKwpCategories,
          unit: "kWp",
        },
        {
          categories: dcVahCategories,
          unit: "v/Ah",
        },
        {
          categories: ["pvmodules"],
          unit: "Wp",
        },
      ],
    };
    const selectedUnit = overallCondition[unitName];
    if (!selectedUnit) {
      return [];
    }
    for (const { categories, unit } of selectedUnit) {
      if (categories.includes(category)) {
        return unitsIC
          .filter((data) => data.units === unit)
          .map((data) => data.units);
      }
    }
  }

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "acUnits":
      case "dcUnits":
        return isUnitsMatch(selectedCategory, propName);
      case "siteName":
        return (option = siteName.map((option) => option.siteName));
      case "equipmentType":
        return (option = filteredTypeOptions);
      case "equipmentCategory":
        return (option = filteredCatOption);
      case "status":
        return (option = statusDrop);
      case "primaryMapEquip":
        return (option = primaryEquipValue.map((option) => option.displayName));
      case "isPrimaryEquipment":
        return (option = isPrimaryEquipmentOptions.map(
          (option) => option.label
        ));
      default:
    }
    return option;
  }
  console.log(dataloggervalue, "dataloggervalue");

  useEffect(() => {
    fetchUnits();
  }, []);

  const dailyGenerationOptions = [
    { value: 1, label: "Today Energy" },
    { value: 2, label: "Total Energy" },
  ];
  const isPrimaryEquipmentOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  console.log(isPrimaryEquipmentOptions, "isPrimaryEquipmentOptions");

  useEffect(() => {
    let data = EquipmentList();
    settext(data);
    console.log(data, "equipmentlist");
    if (!edit) {
      setstdField((prevState) => ({
        ...prevState,
        status: "Active",
        siteName: Valuedata,
      }));
    }
  }, [edit, Valuedata]);
  console.log(siteName);

  useEffect(() => {
    fetchEquipmenttype();
    // fetchEquipmentcategory();
    fetchsitedrop();
    fetchStatusMatser();
    fetchSitenameList();
    fetchStatus();
    fetchUnits();
  }, []);
  useEffect(() => {
    let SiteTypeIdFilter = siteName?.filter((data) => {
      console.log(sitestatus, "filterSite");
      return data.siteName === sitestatus;
    });
    if (edit) {
      fetchPrimaryEquip(editcall.siteId);
    }
    if (sitestatus !== "") {
      fetchPrimaryEquip(SiteTypeIdFilter[0]?.siteId);
    }
  }, [sitestatus, edit]);
  /*------------------------- handle save --------------------------------------------*/
  function isValidExcelDate(serial) {
    if (!Number.isInteger(serial)) {
      return false;
    }
    return serial >= 1 && serial <= 2958465;
  }
  function excelDateToFormattedDate(serial) {
    if (!isValidExcelDate(serial)) {
      return null;
    }

    const excelEpoch = new Date(1900, 0, 1);
    const jsDate = new Date(
      excelEpoch.getTime() + (serial - 1) * 24 * 60 * 60 * 1000
    );
    return jsDate.toISOString().split("T")[0];
  }
  const handleSave = async (e) => {
    try {
      let SiteTypeIdFilter = sitedropvalue?.filter(
        (data) => data.siteName === stdField.siteName
      );
      let EquipmentTypeIdFilter = equipmetvalue?.filter(
        (data) => data.equipmentType === equipment
      );
      let CategoryTypeIdFilter = categoryvalue?.filter(
        (data) => data.equipmentCategory === category
      );
      // let StatusFilter = statusDrop?.filter(
      //   (data) => data.label === activeFlag
      // );
      // let PrimaryMapEquipFilter = primaryEquipValue?.filter(
      //   (data) => data.displayName === primarymapequip
      // );
      let primaryValueFilter = isPrimaryEquipmentOptions?.filter(
        (data) => data.label === primaryEquipment
      );
      // let secondaryEquipId = ;

      const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      };

      const installationDate = selectedDate
        ? addDays(selectedDate, 1).toISOString().substring(0, 10)
        : "";
      let warrantyDate = selectedDatee
        ? addDays(selectedDatee, 1).toISOString().substring(0, 10)
        : "";
      let userAddField = [];
      stdField.equipmentadditionalfield.forEach((obj) => {
        if (obj.fieldName && obj.value) {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem("id")),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      });

      let lati = stdField?.latitude.toString();
      let longi = stdField?.longitude.toString();

      let acCapacityValue = stdField?.acCapacity
        ? Number(stdField?.acCapacity)
        : null;

      let dcCapacityValue = stdField?.dcCapacity
        ? Number(stdField?.dcCapacity)
        : null;

      let data = [
        {
          siteId: Number(SiteTypeIdFilter[0]?.siteId),
          acCapacity: acCapacityValue,
          dcCapacity: dcCapacityValue,
          createdBy: Number(sessionStorage.getItem("id")),
          remarks: stdField?.remarks,
          status: 1,
          displayName: stdField?.displayName,
          equipmentCategoryId: Number(CategoryTypeIdFilter[0]?.categoryId),
          equipmentTypeId: Number(EquipmentTypeIdFilter[0]?.equipmentTypeId),
          isPrimaryEquipment: Number(primaryValueFilter[0]?.value),
          installationDate: installationDate,
          warrantyDate: warrantyDate,
          latitude: lati,
          longitude: longi,
          deviceSn: stdField?.deviceSn,
          noOfComponents: Number(stdField?.noOfComponents),
          dcUnits: stdField?.dcUnits,
          acUnits: stdField?.acUnits,
          serialNo: stdField?.serialNo,
          primaryMapEquip: stdField?.primaryMapEquip || null,
          equipmentadditionalfield: userAddField,
        },
      ];
      console.log(data, "data");

      setToBackend(true);
      const responseData = await postEquipmentSave(data);
      console.log(responseData, "responseData");
      fetchData(SiteTypeIdFilter[0]?.siteId);
      setValuedata(SiteTypeIdFilter[0]?.siteName);
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: responseData.statusDescription,
      });
      setToBackend(false);
      handleClose();
    } catch (error) {
      if (error.response.status === 406) {
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          message: error.response.data.statusDescription,
        });
      } else {
        setSnack(errorMsg.failure);
      }

      setToBackend(false);
      console.error("Error::", error.response.data.statusDescription);
    }
  };

  /*-------------------------handle replace --------------------------------------------*/
  const replaceCall = async (e) => {
    let EquipmentTypeIdFilter = equipmetvalue?.filter((data) => {
      return data.equipmentType === equipment;
    });
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === category;
    });
    let primaryValueFilter = isPrimaryEquipmentOptions?.filter((data) => {
      return data.label === primaryEquipment;
    });
    console.log(primaryValueFilter, "primaryValueFilter");
    let userAddField = [];
    stdField.equipmentadditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        userAddField.push({
          createdBy: Number(sessionStorage.getItem("id")),
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        });
      }
    });
    console.log(
      EquipmentTypeIdFilter[0]?.equipmentTypeId,
      "stdField?.equipmentTypeId !== null"
    );
    let lati = stdField?.latitude.toString();
    let longi = stdField?.longitude.toString();
    let acCapacityValue = stdField?.acCapacity
      ? Number(stdField.acCapacity)
      : null;
    let dcCapacityValue = stdField?.dcCapacity
      ? Number(stdField.dcCapacity)
      : null;
    let data = {
      siteId: Number(stdField?.siteId),
      acCapacity: acCapacityValue,
      dcCapacity: dcCapacityValue,
      lastUpdatedBy: Number(sessionStorage.getItem("id")),
      remarks: stdField?.remarks,
      status: 1,
      displayName: stdField?.displayName,
      equipmentCategoryId: Number(CategoryTypeIdFilter[0]?.categoryId),
      equipmentTypeId:
        EquipmentTypeIdFilter[0]?.equipmentTypeId === undefined
          ? Number(stdField?.equipmentTypeId)
          : EquipmentTypeIdFilter[0]?.equipmentTypeId,
      isPrimaryEquipment: Number(stdField?.isPrimaryEquipment),
      latitude: lati,
      longitude: longi,
      noOfComponents: Number(stdField?.noOfComponents),
      dcUnits: stdField?.dcUnits,
      acUnits: stdField?.acUnits,
      serialNo: stdField?.serialNo,
      equipmentadditionalfield: userAddField,
    };
    console.log(data, "datsa");
    console.log(replace);
    try {
      setToBackend(true);
      const response = await putEquipReplace(data, replace.equipmentId);
      fetchData(stdField?.siteId);
      setSnack(errorMsg.success);
      setToBackend(false);
      handleClose();
    } catch (error) {
      setSnack(errorMsg.failure);
      setToBackend(false);
      console.error("Error:", error.response.status);
    }
  };
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    let id = editcall?.equipmentId;
    console.log(values, "values");
    console.log(editcall?.warrantyDate);
    let SiteTypeIdFilter = sitedropvalue?.filter((data) => {
      return data.siteName === sitestatus;
    });
    let EquipmentTypeIdFilter = equipmetvalue?.filter((data) => {
      return data.equipmentType === equipment;
    });
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === category;
    });
    let StatusFilter = statusDrop?.filter((data) => {
      return data.label === activeFlag;
    });
    let primaryValueFilter = isPrimaryEquipmentOptions?.filter((data) => {
      return data.label === primaryEquipment;
    });
    let installationDate = selectedDate
      ? selectedDate.toISOString().substring(0, 10)
      : "";
    let warrantyDate = selectedDatee
      ? selectedDatee.toISOString().substring(0, 10)
      : "";
    const userAddField = [];
    stdField.equipmentadditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem("id"),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem("id")),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });
    let lati = stdField?.latitude.toString();
    let longi = stdField?.longitude.toString();
    console.log(typeof stdField?.isReplaced, "stdField?.isReplaced");
    let acCapacityValue = stdField?.acCapacity
      ? Number(stdField.acCapacity)
      : null;
    let dcCapacityValue = stdField?.dcCapacity
      ? Number(stdField.dcCapacity)
      : null;
    let obj = {
      acCapacity: acCapacityValue,
      dcCapacity: dcCapacityValue,
      remarks: stdField?.remarks,
      status: edit && activeFlag === "" ? values.status : StatusFilter[0].value,
      displayName: stdField?.displayName,
      equipmentCategoryId:
        edit && category === ""
          ? values.equipmentCategoryId
          : Number(CategoryTypeIdFilter[0]?.categoryId),
      equipmentTypeId:
        edit && equipment === ""
          ? values.equipmentTypeId
          : Number(EquipmentTypeIdFilter[0]?.equipmentTypeId),
      isPrimaryEquipment: Number(primaryValueFilter[0]?.value),
      latitude: lati,
      longitude: longi,
      deviceSn: stdField?.deviceSn,
      noOfComponents: Number(stdField?.noOfComponents),
      acUnits: stdField.acUnits === null ? "kWh" : stdField?.acUnits,
      warrantyDate: warrantyDate,
      installationDate: installationDate,

      dcUnits: stdField.dcUnits === null ? "kWh" : stdField?.dcUnits,
      lastUpdatedBy: Number(sessionStorage.getItem("id")),
      siteId:
        edit && sitestatus === ""
          ? values.siteId
          : Number(SiteTypeIdFilter[0]?.siteId),
      serialNo: stdField?.serialNo,
      isReplaced: Number(stdField?.isReplaced),
      equipmentadditionalfield: userAddField,
    };

    console.log(userAddField, "userF");
    console.log(obj, "obj");
    console.log(stdField, "namzz");
    try {
      console.log(obj, "obj");
      // setToBackend(true); // Set loading state
      const responsedata = await putEquipmentconfig(obj, id);
      console.log(responsedata, "responsedata");
      setSnack(errorMsg.success);

      window.location.reload()
      // fetchData(
      //   edit && sitestatus === ''
      //     ? values.siteId
      //     : Number(SiteTypeIdFilter[0]?.siteId)
      // );
      // setValuedata(
      //   edit && sitestatus === ''
      //     ? values.siteName
      //     : Number(SiteTypeIdFilter[0]?.siteName)
      // );

      // Update pannina site name ah find panni, site ID ah eduthukkarom
      // Update state with the new value
      const selectedSite = sitedropvalue.find(
        (data) => data.siteName === sitestatus
      );
      if (selectedSite) {
        setValuedata(selectedSite.siteName);
        setSiteSector((prevSector) =>
          prevSector.map((item) =>
            item.equipmentId === id
              ? { ...item, siteName: selectedSite.siteName }
              : item
          )
        );
      }

      setSnack({
        open: true,
        severity: "success",
        message: "Update successful!",
      });

      // Close the modal after successful update
      handleClose();
    } catch (e) {
      if (e.response.data.statusCode === 406) {
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          message: e.response.data.statusDescription,
        });
      }
      // setSnack(errorMsg.failure);
      console.error("Error:", e);
    }
  };

  /*-------------------------Table get call --------------------------------------------*/

  const fetchData = async (siteId) => {
    try {
      setloading(true);
      let data;

      data = await fetchSiteEquipmentFilterTable(siteId);

      console.log(data);

      if (data) {
        data.forEach((obj) => {
          if (Array.isArray(obj?.equipmentadditionalfield)) {
            obj.equipmentadditionalfield.forEach((element) => {
              const fieldExists = data.some((item) =>
                item.hasOwnProperty(element.fieldName)
              );
              if (!fieldExists) {
                data.forEach((item) => (item[element.fieldName] = "-"));
              }
              obj[element.fieldName] = element.value || "-";
            });
          }

          obj.withAcUnits = obj.withAcUnits || "-";
          obj.withDcUnits = obj.withDcUnits || "-";
        });
        setSiteSector(Array.isArray(data) ? data : [data]);
      } else {
        setSiteSector([]);
      }
    } catch (e) {
      console.error(e);
      setSiteSector([]);
    } finally {
      setloading(false);
    }
  };

  console.log(SiteSector, "datadata");
  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      setEquipmevalue(data);
    } else if (typeof data === "object" && data !== null) {
      setEquipmevalue([data]);
    }
    console.log(data, '475"');
  };

  const fetchsitedrop = async () => {
    const data = await Equipmentsitedropdown();
    if (Array.isArray(data)) {
      setSitedropvalue(data);
    } else if (typeof data === "object" && data !== null) {
      setSitedropvalue([data]);
    }
  };
  const getEquipmentcategory = async (Id) => {
    console.log(Id);
    console.log(siteName);

    let NameID = siteName?.filter((data) => {
      return data.siteName === Id;
    });
    let Idd = NameID[0]?.siteId;
    const data = await Equipmentcategorysite(Idd);
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === "object" && data !== null) {
      setCategoryvalue([data]);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/

  const allowedKeys = ["fieldName", "value", "status"];
  const addAdditionalField = () => {
    const newField = {
      fieldName: "",
      status: 1,
      value: "",
    };

    setstdField((prevState) => ({
      ...prevState,
      equipmentadditionalfield: [
        ...prevState.equipmentadditionalfield,
        newField,
      ],
    }));
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...stdField.equipmentadditionalfield];
    updatedFields.splice(index, 1);
    setstdField((prevState) => ({
      ...prevState,
      equipmentadditionalfield: updatedFields,
    }));
  };

  /*---------------------------------- New Sector Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    // setEdit(false);
    seteditcall("");
  };
  const handleClose = () => {
    setSelectedFileData(null);
    setstdField(initialState);
    setActiveTab("Equipment");
    seteditcall("");
    setdailyGeneration("");
    setEquipment("");
    setCategory("");
    setOpen(false);
    setModalvalue(true);
    setEdit(false);
    setactiveFlag("");
    setReplace(null);
    setErrorVal(null);
    setIndexVal(null);
    setAcUnits(null);
    setDcUnits(null);
    setCategory(null);
    setSelectedCategory(null);
  };
  const style = {
    position: "absolute",
    top: "60%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  const handleEditOpen = async (sectorTypeId) => {
    seteditcall(sectorTypeId);
    setstdField(sectorTypeId);
    setCategory(sectorTypeId.equipmentCategory);
    setSelectedCategory(sectorTypeId.equipmentCategory);
    let val = dailyGenerationOptions.filter((data) => {
      return data.value === sectorTypeId?.dailyGenBasedOn;
    });
    console.log(val, "val");
    setdailyGeneration(val[0]?.label);

    let value = isPrimaryEquipmentOptions.filter((data) => {
      return Number(data.value) === sectorTypeId?.isPrimaryEquipment;
    });
    setPrimaryEquipment(value[0]?.label);

    let getPrimaryVal = await primaryDrop(sectorTypeId?.siteId);
    let value1 = getPrimaryVal.filter((data) => {
      return data.equipmentId === sectorTypeId?.primaryMapEquip;
    });
    console.log(value1, ">>>>>>>>>>>>>>>>>>>>>>>>>>");
    setprimaryMapEquip(value1.length > 0 ? value1[0]?.displayName : "");

    let value2 = statusMasterDroplist.filter((data) => {
      return Number(data.statusMasterId) === sectorTypeId?.statusMasterId;
    });
    setMastervalue(value2[0]?.statusMasterName);
    console.log(statusMasterDroplist, "statusMasterDroplist");
    setEdit(true);
    setOpen(true);
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "Equipment Details");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    console.log(data, "data");
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };

  const fetchSitenameList = async () => {
    const data = await fetchSiteDetails();
    await fetchData(data[0]?.siteId);
    setValuedata(data[0]?.siteName);
    const datas = await Equipmentcategorysite(data[0]?.siteId);

    setCategoryvalue(datas);
    if (Array.isArray(data)) {
      setSiteNameList(data);
    } else if (typeof data === "object" && data !== null) {
      setSiteNameList([data]);
    }
  };
  const fetchStatusMatser = async (customerId) => {
    const data = await statusMasterDrop();
    console.log(data, "data");
    if (Array.isArray(data)) {
      setStatusMasterDroplist(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusMasterDroplist([data]);
    }
  };

  const fetchPrimaryEquip = async (id) => {
    const data = await primaryDrop(id);
    console.log(data, "data");
    if (Array.isArray(data)) {
      setPrimaryEquipValue(data);
    } else if (typeof data === "object" && data !== null) {
      setPrimaryEquipValue([data]);
    }
  };

  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },
    {
      label: "Equipment Category",
      id: "equipmentCategory",
      view: true,
    },
    {
      label: "Equipment Type",
      id: "equipmentType",
      view: true,
    },
    {
      label: "Serial No",
      id: "serialNo",
      view: false,
    },
    {
      label: "AC Capacity",
      id: "withAcUnits",
      view: true,
    },
    {
      label: "DC Capacity",
      id: "withDcUnits",
      view: true,
    },
    {
      label: "Latitude",
      id: "latitude",
      view: false,
    },
    {
      label: "Longitude",
      id: "longitude",
      view: false,
    },
    {
      label: "No of Components",
      id: "noOfComponents",
      view: false,
    },
    {
      label: "Remarks",
      id: "remarks",
      view: false,
    },
    {
      label: "Created Date",
      id: "createdDate",
      view: false,
    },
    {
      label: "Status",
      id: "status",
      view: true,
      default: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  useEffect(() => {
    // setloading(true);
    let viewArr = [...headCells];
    SiteSector?.forEach((obj) => {
      const addField = obj.equipmentadditionalfield;
      if (addField?.length > 0) {
        addField.forEach((field) => {
          let key = field.fieldName;
          if (!viewArr.some((item) => item.id === key)) {
            let lastIndex = viewArr.length - 1;
            viewArr.splice(lastIndex, 0, { id: key, label: key });
          }
        });
      }
      // setloading(false);
    });
    setAddHeadCells(viewArr);
  }, [headCells.length, addHeadCells.length, SiteSector]);

  const tableValues = SiteSector?.map((row) => {
    let newRow = { ...row };

    if (row.acCapacity) {
      newRow.withAcUnits = row.acCapacity + " " + row.acUnits;
    }

    if (row.dcCapacity) {
      newRow.withDcUnits = row.dcCapacity + " " + row.dcUnits;
    }

    return newRow;
  }).filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });

  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = SiteSector?.length;

  const Active = SiteSector?.filter((site) => site?.status == 1).length;

  const InActive = SiteSector?.filter((site) => site?.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: "All Equipment", value: "all", badgeCount: Total },
    { name: "Active Equipment", value: "Active", badgeCount: Active },
    {
      name: "Inactive Equipment",
      value: "Inactive",
      badgeCount: InActive,
    },
  ];

  const requiredHeader = [
    "Site Name",
    "Display Name",
    "Equipment Category",
    "Equipment Type",
    "AC Capacity",
    "DC Capacity",
    "AcUnits",
    "DcUnits",
    "Serial No",
    "Latitude",
    "Longitude",
    "Primary Equipment",
    "No Of Components",
    "Remark",
    "Installation Date",
    "Warranty Date",
  ];
  const nonValidatedHeader = ["Installation Date", "Warranty Date"];

  console.log(Valuedata);

  const handlesitevalue = (val) => {
    console.log(val);
    const siteIdFilter = siteName?.filter((data) => data.siteName === val);
    setValuedata(val);
    fetchData(siteIdFilter[0]?.siteId);
  };

  const handleExpect = (value, event) => {
    if (value === null) {
      setSitestatus("");
    } else {
      setstdField((prevState) => ({
        ...prevState,
        siteName: value,
      }));
      setSitestatus(value);
    }
  };

  const handleSiteFilterValue = async (val) => {
    console.log("Selected value:", val);
    if (val) {
      const siteIdFilter = siteName?.find((data) => data.siteName === val);
      console.log("Filtered site:", siteIdFilter);
      if (siteIdFilter) {
        setValuedata(siteIdFilter.siteName);
        fetchData(siteIdFilter.siteId);
        const datas = await Equipmentcategorysite(siteIdFilter.siteId);
        setCategoryvalue(datas);
      }
    } else {
      setValuedata(null);
      setSiteSector([]);
    }
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX?.utils?.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .filter((header) => !nonValidatedHeader.includes(header))
          .every((header) =>
            headers
              .map((h) => h.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        const extraHeaders = headers.filter(
          (header) => !requiredHeader.includes(header)
        );
        const hasExtraHeaders = extraHeaders.length > 0;

        const jsonData = XLSX.utils.sheet_to_json(sheet);
        console.log(headers, "filefile");
        const jsonDataTable = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const updatedJsonData = jsonData.map((row) => {
          requiredHeader.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = "";
            }
          });
          if (row.hasOwnProperty("Installation Date")) {
            row["Installation Date"] = excelDateToFormattedDate(
              row["Installation Date"]
            );
          }
          if (row.hasOwnProperty("Warranty Date")) {
            row["Warranty Date"] = excelDateToFormattedDate(
              row["Warranty Date"]
            );
          }
          return row;
        });
        if (
          !headersPresented ||
          //  hasExtraHeaders ||
          jsonData.length === 0
          // ||
          // (warrantyvalidateDate === null || installvalidateDate === null)
        ) {
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
              ? "Excel couldn't be empty"
              : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }
        const errors = [];
        const avoidHeaders = [
          "Installation Date",
          "Warranty Date",
          "AC Capacity",
          "DC Capacity",
          "AcUnits",
          "DcUnits",
        ];
        jsonData.forEach((row, rowIndex) => {
          let header = Object.keys(row);
          let cellValue = Object.values(row);
          let equipCategory = row[requiredHeader[2]];
          cellValue.forEach((value, cellIndex) => {
            const columnName = header[cellIndex];
            const err = isCategoryAndNameValid(equipCategory, null, columnName);
            if (
              err &&
              (value === "" || value === null || value === undefined)
            ) {
              err && errors.push({ rowIndex, columnName });
            }
            if (
              !avoidHeaders.includes(columnName) &&
              (value === "" || value === null || value === undefined)
            ) {
              errors.push({ rowIndex, columnName });
            }
          });
        });

        setErrorIndex(errors);
      };
      reader.readAsBinaryString(file);
    }
  };

  /*-------------------------report Name list dropdown --------------------------------------------*/
  // console.log(errorIndex, 'errorIndexequipCategory');
  const handleExcelUpload = async () => {
    const file = selectedFile;

    if (file) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const errors = [];
        selectedFileData.forEach((row, rowIndex) => {
          console.log("siteName::", requiredHeader[4]);
          let slectedcategory = row[requiredHeader[2]];
          const siteName = row[requiredHeader[0]].length;
          const acCap = row[requiredHeader[4]].length;
          const dcCap = row[requiredHeader[5]].length;
          const acUnit = row[requiredHeader[6]];
          const dcUnit = row[requiredHeader[7]];
          const remark = row[requiredHeader[8]].length;
          const displayName = row[requiredHeader[9]].length;
          const serialNo = row[requiredHeader[13]].length;

          const validationForm = [
            {
              condition: siteName && siteName > 100,
              errorMessage: "Site Name cannot exceed 100 characters.",
            },
            {
              condition: acCap && acCap > 20,
              errorMessage: "Ac Capacity cannot exceed 20 characters.",
            },
            {
              condition: dcCap && dcCap > 20,
              errorMessage: "Dc Capacity cannot exceed 20 characters.",
            },
            {
              condition: remark && remark > 100,
              errorMessage: "Remark cannot exceed 100 characters.",
            },
            {
              condition: displayName && displayName > 50,
              errorMessage: "Display Name cannot exceed 50 characters.",
            },
            {
              condition: serialNo && serialNo > 150,
              errorMessage: "Serial Number cannot exceed 15 characters.",
            },
          ];
          if (
            isCategoryAndNameValid(slectedcategory, "acCapacity") &&
            isCategoryAndNameValid(slectedcategory, "dcCapacity")
          ) {
            validationForm.push(
              {
                condition:
                  // !['kw', 'kwh', 'kwp'].includes(acUnit),
                  acUnit ? Number(acUnit) : null,
                errorMessage: "Enter proper Ac unit.",
              },
              {
                condition:
                  // !['kw', 'kwh', 'kwp'].includes(dcUnit),
                  dcUnit ? Number(dcUnit) : null,
                errorMessage: "Enter proper Dc unit.",
              }
            );
          } else if (isCategoryAndNameValid(slectedcategory, "acCapacity")) {
            validationForm.push({
              condition:
                // !['kw', 'kwh', 'kwp'].includes(acUnit),
                acUnit ? Number(acUnit) : null,
              errorMessage: "Enter proper Ac unit.",
            });
          } else if (isCategoryAndNameValid(slectedcategory, "dcCapacity")) {
            validationForm.push({
              condition:
                // !['kw', 'kwh', 'kwp'].includes(dcUnit),
                dcUnit ? Number(dcUnit) : null,
              errorMessage: "Enter proper Dc unit.",
            });
          }

          validationForm.forEach((validation) => {
            console.log("validation.condition:", validation.condition);
            if (validation.condition) {
              errors.push(validation.errorMessage);
              let error = [
                {
                  rowIndex,
                  columnName: validation.errorMessage,
                  lengthValidation: true,
                },
              ];
              setErrorIndex([...errorIndex, ...error]);
            }
          });
        });
        // const jsonDataLower = jsonData.toLowerCase()
        const processedData = jsonData.map((item) => {
          const lowercaseItem = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              lowercaseItem[key.toLowerCase().replace(/\s/g, "")] =
                typeof item[key] === "string" ? item[key].trim() : item[key];
            }
          }
          console.log(lowercaseItem, "lowercase");
          let lati = lowercaseItem.latitude.toString();
          let longi = lowercaseItem.longitude.toString();

          const mainOutput = {
            acCapacity: isCategoryAndNameValid(
              lowercaseItem?.equipmentcategory,
              "acCapacity"
            )
              ? Number(lowercaseItem?.accapacity)
              : null,
            createdBy: Number(sessionStorage.getItem("id")),
            dcCapacity: isCategoryAndNameValid(
              lowercaseItem?.equipmentcategory,
              "dcCapacity"
            )
              ? Number(lowercaseItem?.dccapacity)
              : null,
            remarks: lowercaseItem?.remark,
            status: 1,
            displayName: lowercaseItem?.displayname,
            equipmentCategory: lowercaseItem?.equipmentcategory,
            equipmentType: lowercaseItem?.equipmenttype,
            isPrimaryEquipment:
              lowercaseItem?.primaryequipment === "no" ? 0 : 1,
            latitude: lati,
            longitude: longi,
            noOfComponents: Number(lowercaseItem?.noofcomponents),
            serialNo: String(lowercaseItem?.serialno),
            siteName: lowercaseItem?.sitename,
            acUnits: isCategoryAndNameValid(
              lowercaseItem?.equipmentcategory,
              "acCapacity"
            )
              ? lowercaseItem?.acunits
              : null,
            dcUnits: isCategoryAndNameValid(
              lowercaseItem?.equipmentcategory,
              "dcCapacity"
            )
              ? lowercaseItem?.dcunits
              : null,
          };
          return lowercaseItem.installationdate && lowercaseItem.warrantydate
            ? {
                ...mainOutput,
                installationDate: excelDateToFormattedDate(
                  lowercaseItem.installationdate
                ),
                warrantyDate: excelDateToFormattedDate(
                  lowercaseItem.warrantydate
                ),
              }
            : lowercaseItem.installationdate
            ? {
                ...mainOutput,
                installationDate: excelDateToFormattedDate(
                  lowercaseItem.installationdate
                ),
              }
            : lowercaseItem.warrantydate
            ? {
                ...mainOutput,
                warrantyDate: excelDateToFormattedDate(
                  lowercaseItem.warrantydate
                ),
              }
            : mainOutput;
        });
        console.log(processedData, "processedData");
        console.log(errors, "procesasedData");
        console.log(errorIndex, "errorIndex");
        try {
          if (errorIndex.length !== 0) {
            setSnack({
              ...snack,
              open: true,
              severity: "error",
              message: `${errorIndex[0].columnName} is missing`,
            });
          } else if (errors.length !== 0) {
            errors.forEach((error) => {
              setSnack({
                ...snack,
                open: true,
                severity: "error",
                message: error,
              });
            });
          } else {
            const responseData = await postEquipmentexceldata(processedData);
            console.log(responseData, "responseStatus");
            if (responseData.status === 200) {
              fetchData();
              setSnack(errorMsg.success);
              handleClose();
            }
          }
        } catch (e) {
          if (e.response.status === 400) {
            console.error("Error:", e.response.status);
            const errorResponse = e.response.data;
            const indexValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.index
            );
            setIndexVal(indexValue);
            const errorValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.error
            );
            setErrorVal(errorValue);
            // setSnack({
            //   ...snack,
            //   open: true,
            //   severity: 'error',
            //   message: errorValue,
            // });
            setSnack(errorMsg.failure);
          } else {
            setSnack(errorMsg.failure);
          }
        }
        // }
      };
      reader.readAsBinaryString(file);
    }
  };
  const AddFieldsLength = stdField.equipmentadditionalfield.length;
  console.log(errorIndex, "errorss");
  // const SiteTypeIdFilter = categoryvalue?.filter((data) => {
  //   return data.siteName === sitestatus;
  // });
  const SiteTypeIdFilter = categoryvalue?.filter((data) => {
    return data.equipmentCategory === category;
  });

  const EquipmentTypeIdFilter = equipmetvalue?.filter((data) => {
    return data.equipmentType === equipment;
  });
  // setSiteTypeIdFilter(SiteTypeIdFilter);

  const filteredCatOption = categoryvalue.map(
    (option) => option.equipmentCategory
  );
  const filteredTypeOptions = equipmetvalue
    .filter((option) => SiteTypeIdFilter[0]?.categoryId === option.categoryId)
    .map((option) => option.equipmentType);

  console.log(filteredTypeOptions, "filteredTypeOptions");

  const handleactiveFlag = (data, event) => {
    setactiveFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      status: data.target.innerHTML,
    }));
  };
  const handleEquipmentvalue = (data, value) => {
    setEquipment(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      equipmentType: data.target.innerHTML,
    }));
  };
  const handleSite = (data, event) => {
    // Replace &amp; with & in the innerHTML value
    const formattedSiteName = data.target.innerHTML.replace(/&amp;/g, "&");

    setSitestatus(formattedSiteName);
    setstdField((prevState) => ({
      ...prevState,
      siteName: formattedSiteName,
    }));

    console.log(formattedSiteName); // This should now log the formatted string "Nippon Paint - RMS & Production Block"

    getEquipmentcategory(formattedSiteName);
  };
  // const handleReplaceSite = () => {
  //   setSitestatus(replace.siteName);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     siteName: replace.siteName
  //   }))
  // }
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate(), +1); // Example modification
    setSelectedDate(selectedDate);
    console.log(selectedDate);
  };

  const handleDatepicChange = (date) => {
    const selectedDatee = new Date(date);
    selectedDatee.setDate(selectedDatee.getDate(), +1); // Example modification
    setSelectedDatee(selectedDatee);
    console.log(selectedDatee);
  };
  const handlepicChange = (date, value, name) => {
    console.log(date.format(), value, name); // Log the value passed to the function
    console.log(date.format()); // Log the formatted date
    console.log(name.toString() == "InstallationDate");
    console.log(name === "WarrantyDate");
    if (value === "InstallationDate") {
      console.log(date.format());
      setSelectedDate(date.toDate());
    } else if (value === "WarrantyDate") {
      handleDatepicChange(date);
    }
  };
  useEffect(() => {
    console.log("selectedDatee:", selectedDatee);
  }, [selectedDate, selectedDatee]);
  const handleDescription = (data, event) => {
    SetDescriptionvalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      description: data.target.innerHTML,
    }));
  };

  const handleCategoryvalue = (data, value) => {
    let val = data.target.innerHTML;

    setstdField({
      ...stdField,
      equipmentType: "",
      acCapacity: "",
      dcCapacity: "",
      acUnits: "",
      dcUnits: "",
    });
    setCategory(val);
    setstdField((prevState) => ({
      ...prevState,
      equipmentCategory: val,
    }));
    console.log("handleCategoryvalue");
  };
  console.log(stdField, "stdField");
  const handleReplaceEquip = (data, event) => {
    let val = data.target.innerHTML === "Yes" ? 1 : 0;
    setstdField((prevState) => ({ ...prevState, isReplaced: val }));
  };
  console.log(SiteTypeIdFilter, "SiteTypeIdFilter");
  const handleReplaceCall = (val) => {
    console.log(val);
    setCategory(val.equipmentCategory);
    setSelectedCategory(val.equipmentCategory);
    setOpen(true);
    setReplace(val);
    setstdField(val);
  };
  const handleDailyGeneration = (data, event) => {
    setdailyGeneration(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      dailyGenBasedOn: data.target.innerHTML,
    }));
  };
  // const handlePrimaryEquipment = (data, event) => {
  //   setPrimaryEquipment(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     isPrimaryEquipment: data.target.innerHTML,
  //   }));
  // };
  // const handleprimaryMapEquip = (data, event) => {
  //   setprimaryMapEquip(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     primaryMapEquip: data.target.innerHTML,
  //   }));
  // };
  const handlePrimaryEquipment = (data, event) => {
    const value = data.target.innerHTML;
    setPrimaryEquipment(value);
    setstdField((prevState) => ({
      ...prevState,
      isPrimaryEquipment: value,
    }));

    // Disable primaryMapEquip if isPrimaryEquipment is 'Yes'
    if (value === "Yes") {
      setprimaryMapEquip(""); // Reset primaryMapEquip value
      setstdField((prevState) => ({
        ...prevState,
        primaryMapEquip: "",
      }));
    }
  };

  const handleprimaryMapEquip = (data, event) => {
    const value = data.target.innerHTML;
    console.log(value, "value");
    setprimaryMapEquip(value);
    setstdField((prevState) => ({
      ...prevState,
      primaryMapEquip: value,
    }));

    // Disable condition
    // if (
    //     (stdField.isPrimaryEquipment === 1 || editcall.isPrimaryEquipment === 1 || stdField.isPrimaryEquipment === '') &&
    //     data.name === 'primaryMapEquip'
    // ) {
    //     return true; // Disable the field
    // } else {
    //     return false; // Enable the field
    // }
  };

  const handleProductionFlag = (data, event) => {
    setProductionFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      prodFlag: data.target.innerHTML,
    }));
  };
  const handleDataLogger = (data, event) => {
    setDataLoggervalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      dataLoggerId: data.target.innerHTML,
    }));
  };
  console.log(errorIndex, "errrorIndex");
  const handleStatusMaster = (data, event) => {
    console.log(data.target.innerHTML, "data.target.innerHTML");
    setMastervalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      statusMasterId: data.target.innerHTML,
    }));
  };
  const handleDropdownChange = (event, value, name) => {
    console.log(name, value, "valavalaa");
    setstdField({ ...stdField, [name]: value });
  };
  // const handleDataLogger = (data, event) => {
  //   console.log(data.target, "innerHTML");
  //   setDataLoggervalue(data.target.innerHTML);
  //   setstdField((prevState) => ({
  //     ...prevState,
  //     dataLoggerId: data.target.innerHTML,
  //   }));
  // };
  const equipmentHeader = (name) => {
    let data = siteName.filter((option) => option.siteName === name);
    let condition = data && data.length > 0 ? false : true;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h4"
            className="modal-head"
            style={{ fontWeight: "bold" }}
          >
            {edit
              ? "Update Equipment"
              : replace
              ? "Replace Equipment "
              : "Add New Equipment"}
          </Typography>
        </div>
        {edit && condition && (
          <Alert
            severity="error"
            style={{
              userSelect: "none",
              height: "40px",
              marginLeft: "auto",
              marginRight: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {`This site is Inactive or not mapped`}
          </Alert>
        )}
      </div>
    );
  };
  console.log(Valuedata);
  const paths = [
    { label: "Home", path: "/menu" },
    { label:"Equipment Details ", path: "equipmentConfiguration" },
  ];
  const additionalFieldSchema = yup.object().shape({
    primaryMapEquip: yup.string().required("Equipment map is required"),
  });
  const addedAcFieldSchema = yup.object().shape({
    acCapacity: yup.string().required("Ac Capacity is required"),
    acUnits: yup.string().required("Ac Units is required"),
  });
  const addedDcFieldSchema = yup.object().shape({
    dcCapacity: yup.string().required("Dc Capacity is required"),
    dcUnits: yup.string().required("Dc Units is required"),
  });
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? "edit" : "create"}
            enableReinitialize={true}
            initialValues={stdField}
            validationSchema={
              stdField.isPrimaryEquipment === "No"
                ? !replace
                  ? equipmentConfigEquipmentSchema.concat(additionalFieldSchema)
                  : equipmentReplaceSchema.concat(additionalFieldSchema)
                : isCategoryAndNameValid(selectedCategory, "acCapacity") &&
                  isCategoryAndNameValid(selectedCategory, "dcCapacity")
                ? equipmentConfigEquipmentSchema
                    .concat(addedAcFieldSchema)
                    .concat(addedDcFieldSchema)
                : isCategoryAndNameValid(selectedCategory, "acCapacity")
                ? equipmentConfigEquipmentSchema.concat(addedAcFieldSchema)
                : isCategoryAndNameValid(selectedCategory, "dcCapacity")
                ? equipmentConfigEquipmentSchema.concat(addedDcFieldSchema)
                : equipmentConfigEquipmentSchema
            }
            onSubmit={async (values, { resetForm }) => {
              console.log(values, "testttt");
              let submitValue = values;
              if (edit) {
                console.log(values);
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else if (replace) {
                const result = await replaceCall(submitValue);
                if (result) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleReset,
              setFieldValue,
            }) => (
              <Form>
                {modalvalue ? (
                  (edit || replace) && values.siteName === null ? (
                    <div>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                      >
                        <FadeLoader color="#000440" loading={true} />
                      </Backdrop>
                    </div>
                  ) : (
                    <div>
                      {/* --------------save and update popup----------- */}
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                      >
                        <Fade in={open}>
                          <Box
                            className={"styleModalSite "}
                            sx={{
                              border: "none",
                              outline: "none",
                            }}
                          >
                            <Card
                              sx={{
                                borderRadius: "20px",
                                // height: '68vh',
                                height: "fit-content",
                                marginTop: "-4.5%",
                              }}
                            >
                              <CardHeader
                                sx={{
                                  padding: "20px 20px 20px 20px",
                                  background: CheckForLight()
                                    ? "rgb(246, 248, 252)"
                                    : "#4f4f4f",
                                }}
                                action={
                                  <Stack spacing={2} direction="row">
                                    {edit ? (
                                      <Button
                                        variant="contained"
                                        style={{
                                          borderRadius: "25px",
                                          textTransform: "capitalize",
                                        }}
                                        startIcon={
                                          toBackend ? (
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          ) : (
                                            <SaveIcon />
                                          )
                                        }
                                        onClick={
                                          toBackend ? null : handleSubmit
                                        }
                                      >
                                        {toBackend ? "Updating..." : "Update"}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        style={{
                                          borderRadius: "25px",
                                          textTransform: "capitalize",
                                        }}
                                        startIcon={
                                          toBackend ? (
                                            <CircularProgress
                                              size={20}
                                              color="inherit"
                                            />
                                          ) : replace ? (
                                            <PublishedWithChangesIcon />
                                          ) : (
                                            <SaveIcon />
                                          )
                                        }
                                        onClick={
                                          toBackend ? null : handleSubmit
                                        }
                                      >
                                        {replace && toBackend
                                          ? "Replacing"
                                          : replace
                                          ? "Replace"
                                          : toBackend
                                          ? "Saving..."
                                          : "Save"}
                                      </Button>
                                    )}
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => {
                                        handleClose();
                                        handleReset();
                                      }}
                                      startIcon={<CancelIcon />}
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                }
                                title={equipmentHeader(values?.siteName)}
                              />
                              <Divider
                                style={{
                                  borderColor: "#888",
                                  marginBottom: "-2%",
                                }}
                              />

                              <div key={editcall?.siteId}>
                                <div className="modelGrid">
                                  <Card
                                    sx={{
                                      overflowY:
                                        AddFieldsLength !== 0
                                          ? "scroll"
                                          : "visible",
                                      height: "34rem",
                                      marginLeft: "-1vw",
                                      paddingLeft: "2.5vw",
                                      scrollBehavior: "smooth",
                                      scrollbarGutter: "stable",
                                      scrollbarWidth: "thin",
                                      "&::-webkit-scrollbar": {
                                        width: "0.4em",
                                      },
                                      "&::-webkit-scrollbar-track": {
                                        background: "#f1f1f1",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#888",
                                        borderRadius: "20px",
                                      },
                                      "&::-webkit-scrollbar-thumb:hover": {
                                        background: "#555",
                                      },
                                    }}
                                    elevation={0}
                                  >
                                    <CardContent sx={{}}>
                                      <Grid container spacing={2} columns={16}>
                                        {text?.length > 0
                                          ? text.map((data, index) => (
                                              <Grid
                                                item
                                                xs={
                                                  data.type === "capacity"
                                                    ? 5
                                                    : 5
                                                }
                                              >
                                                <div key={index}>
                                                  <Typography
                                                    variant="body1"
                                                    className="modal-typo"
                                                    gutterBottom
                                                  >
                                                    {data.label}
                                                  </Typography>
                                                  <>
                                                    {data.type === "date" && (
                                                      <LocalizationProvider
                                                        dateAdapter={
                                                          AdapterDayjs
                                                        }
                                                      >
                                                        <DatePicker
                                                          size="small"
                                                          defaultValue={
                                                            editcall
                                                              ? dayjs(
                                                                  editcall.installationDate
                                                                )
                                                              : replace
                                                              ? dayjs(
                                                                  replace.installationDate
                                                                )
                                                              : null
                                                          }
                                                          sx={{
                                                            width: "15vw",
                                                          }}
                                                          onChange={
                                                            handleDateChange
                                                          }
                                                          slotProps={{
                                                            textField: {
                                                              size: "small",
                                                            },
                                                          }}
                                                        />
                                                      </LocalizationProvider>
                                                    )}
                                                  </>
                                                  <>
                                                    {data.type === "datee" && (
                                                      <LocalizationProvider
                                                        dateAdapter={
                                                          AdapterDayjs
                                                        }
                                                      >
                                                        <DatePicker
                                                          size="small"
                                                          // disablePast
                                                          sx={{
                                                            width: "15vw",
                                                          }}
                                                          defaultValue={
                                                            editcall
                                                              ? dayjs(
                                                                  editcall.warrantyDate
                                                                )
                                                              : replace
                                                              ? dayjs(
                                                                  replace.warrantyDate
                                                                )
                                                              : null
                                                          }
                                                          shouldDisableDate={(
                                                            date
                                                          ) =>
                                                            dayjs(
                                                              date
                                                            ).isBefore(
                                                              dayjs(
                                                                selectedDate
                                                              ),
                                                              "day"
                                                            )
                                                          }
                                                          onChange={
                                                            handleDatepicChange
                                                          }
                                                          slotProps={{
                                                            textField: {
                                                              size: "small",
                                                            },
                                                          }}
                                                        />
                                                      </LocalizationProvider>
                                                    )}
                                                  </>
                                                  {data.type === "textField" ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        disabled={
                                                          toBackend ||
                                                          (replace &&
                                                            data.name ===
                                                              "displayName") ||
                                                          (!edit &&
                                                            data.name ===
                                                              "status")
                                                        }
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        inputProps={{
                                                          maxLength:
                                                            data.length,
                                                        }}
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleChangeTxt(e);
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        value={
                                                          replace || edit
                                                            ? values[data.name]
                                                            : null
                                                        }
                                                        sx={{ width: "15vw" }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type ===
                                                      "dropdown" ||
                                                    data.name ===
                                                      "equipmentCategory" ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          replace &&
                                                          data.name ===
                                                            "siteName"
                                                            ? replace.siteName
                                                            : !edit &&
                                                              data.name ===
                                                                "status"
                                                            ? "Active"
                                                            : edit
                                                            ? data.name ===
                                                                "status" &&
                                                              activeFlag === ""
                                                              ? values[
                                                                  data.name
                                                                ] == 1
                                                                ? "Active"
                                                                : "Inactive"
                                                              : data.name ===
                                                                "priority"
                                                              ? values[
                                                                  data.name
                                                                ] === 1
                                                                ? "Low"
                                                                : values[
                                                                    data.name
                                                                  ] === 2
                                                                ? "Medium"
                                                                : values[
                                                                    data.name
                                                                  ] === 3
                                                                ? "High"
                                                                : values[
                                                                    data.name
                                                                  ]
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Field
                                                          name={data.name}
                                                          render={({
                                                            field,
                                                            form,
                                                          }) => (
                                                            <Tooltip
                                                              title={
                                                                replace
                                                                  ? data.name ===
                                                                    "isPrimaryEquipment"
                                                                    ? values[
                                                                        "isPrimaryEquipment"
                                                                      ] === 1
                                                                      ? "Yes"
                                                                      : "No"
                                                                    : data.name ===
                                                                      "status"
                                                                    ? values[
                                                                        "status"
                                                                      ] === 1
                                                                      ? "Active"
                                                                      : "Inactive"
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                  : !edit &&
                                                                    data.name ===
                                                                      "status"
                                                                  ? "Active"
                                                                  : edit
                                                                  ? data.name ===
                                                                      "status" &&
                                                                    activeFlag ===
                                                                      ""
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ] == 1
                                                                      ? "Active"
                                                                      : "Inactive"
                                                                    : data.name ===
                                                                      "priority"
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ] === 1
                                                                      ? "Low"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ] === 2
                                                                      ? "Medium"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ] === 3
                                                                      ? "High"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                            >
                                                              <Autocomplete
                                                                disableClearable
                                                                autoHighlight
                                                                disabled={
                                                                  toBackend ||
                                                                  (replace &&
                                                                    (data.name ===
                                                                      "siteName" ||
                                                                      data.name ===
                                                                        "isPrimaryEquipment" ||
                                                                      data.name ===
                                                                        "primaryMapEquip")) ||
                                                                  (!edit &&
                                                                    data.name ===
                                                                      "status") ||
                                                                  (data.name ===
                                                                    "primaryMapEquip" &&
                                                                    values.isPrimaryEquipment !==
                                                                      "No") ||
                                                                  (!edit &&
                                                                    !replace &&
                                                                    // data.name ===
                                                                    //   'equipmentType'
                                                                    //   ? category ===
                                                                    //       '' ||
                                                                    //     (!replace &&
                                                                    //       sitestatus ===
                                                                    //         '')
                                                                    //     ? true
                                                                    //     : false
                                                                    //   : false) ||
                                                                    // (!replace &&
                                                                    //   data.name ===
                                                                    //     'equipmentCategory' &&
                                                                    //   !stdField.siteName)
                                                                    data.name ===
                                                                      "equipmentType" &&
                                                                    (!stdField.siteName ||
                                                                      !stdField.equipmentCategory))
                                                                }
                                                                options={getOptions(
                                                                  data.name
                                                                )}
                                                                disablePortal
                                                                size="small"
                                                                id={`combo-box-demo-${index}`}
                                                                onChange={(
                                                                  event,
                                                                  value
                                                                ) =>
                                                                  data.name ===
                                                                  "siteName"
                                                                    ? handleSite(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "equipmentCategory"
                                                                    ? (handleCategoryvalue(
                                                                        event
                                                                      ),
                                                                      handleCategoryChange(
                                                                        event,
                                                                        value
                                                                      ))
                                                                    : data.name ===
                                                                      "equipmentType"
                                                                    ? handleEquipmentvalue(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "isPrimaryEquipment"
                                                                    ? handlePrimaryEquipment(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "primaryMapEquip"
                                                                    ? handleprimaryMapEquip(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "description"
                                                                    ? handleDescription(
                                                                        event
                                                                      )
                                                                    : handleactiveFlag(
                                                                        event
                                                                      )
                                                                }
                                                                value={
                                                                  replace
                                                                    ? data.name ===
                                                                      "isPrimaryEquipment"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] === 1
                                                                        ? "Yes"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          0
                                                                        ? "No"
                                                                        : "No"
                                                                      : data.name ===
                                                                        "status"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] === 1
                                                                        ? "Active"
                                                                        : "Inactive"
                                                                      : edit &&
                                                                        data.name ===
                                                                          "siteName"
                                                                      ? Valuedata
                                                                      : ""
                                                                      ? "Active"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : !edit &&
                                                                      data.name ===
                                                                        "status"
                                                                    ? "Active"
                                                                    : edit &&
                                                                      data.name ===
                                                                        "siteName"
                                                                    ? Valuedata
                                                                    : ""
                                                                    ? "Active"
                                                                    : edit
                                                                    ? data.name ===
                                                                        "status" &&
                                                                      activeFlag ===
                                                                        ""
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] == 1
                                                                        ? "Active"
                                                                        : "Inactive"
                                                                      : data.name ===
                                                                        "priority"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] === 1
                                                                        ? "Low"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          2
                                                                        ? "Medium"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          3
                                                                        ? "High"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ]
                                                                      : data.name ===
                                                                        "isPrimaryEquipment"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] === 1
                                                                        ? "Yes"
                                                                        : "No"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: "15vw",
                                                                }}
                                                                ListboxProps={{
                                                                  style: {
                                                                    maxHeight:
                                                                      "150px",
                                                                  },
                                                                }}
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    placeholder={
                                                                      data.placeholder
                                                                    }
                                                                  />
                                                                )}
                                                              />
                                                            </Tooltip>
                                                          )}
                                                        />
                                                      </Tooltip>
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type === "number" ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        type={data.type}
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleChangeTxt(e);
                                                        }}
                                                        inputProps={{
                                                          maxLength: 10,
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        value={
                                                          values[data.name] ===
                                                          null
                                                            ? ""
                                                            : values[data.name]
                                                        }
                                                        sx={{ width: "15vw" }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type === "float" ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        type={data.type}
                                                        onChange={(e) => {
                                                          let numLength;

                                                          // Setting length based on the field type
                                                          if (
                                                            e.target.name ===
                                                            "latitude"
                                                          ) {
                                                            numLength = 10;

                                                            // Allow only valid format (e.g., 98.988654)
                                                            const regex =
                                                              /^(\d{0,2}(\.\d{0,7})?)?$/;
                                                            if (
                                                              !regex.test(
                                                                e.target.value
                                                              )
                                                            ) {
                                                              return;
                                                            }
                                                          } else if (
                                                            e.target.name ===
                                                            "longitude"
                                                          ) {
                                                            numLength = 10;

                                                            // Allow only valid format (e.g., 123.4567890)
                                                            const regex =
                                                              /^(\d{0,3}(\.\d{0,7})?)?$/;
                                                            if (
                                                              !regex.test(
                                                                e.target.value
                                                              )
                                                            ) {
                                                              return;
                                                            }
                                                          } else {
                                                            numLength = 7;
                                                          }

                                                          if (
                                                            e.target.value
                                                              .length <=
                                                            numLength
                                                          ) {
                                                            handleChange(e);
                                                            handleChangeTxt(e);
                                                          }
                                                        }}
                                                        inputProps={{
                                                          maxLength:
                                                            data.length,
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        value={
                                                          values[data.name] ===
                                                          null
                                                            ? ""
                                                            : values[data.name]
                                                        }
                                                        sx={{ width: "15vw" }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    data.type ===
                                                      "capacity" && (
                                                      <>
                                                        {isCategoryAndNameValid(
                                                          selectedCategory,
                                                          data.name
                                                        ) ? (
                                                          <>
                                                            <Field
                                                              disabled={
                                                                toBackend
                                                              }
                                                              as={TextField}
                                                              id={`outlined-basic-${index}`}
                                                              size="small"
                                                              variant="outlined"
                                                              type="number"
                                                              name={data.name}
                                                              onChange={(e) => {
                                                                handleChange(e);
                                                                handleChangeTxt(
                                                                  e
                                                                );
                                                              }}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                              InputProps={{
                                                                endAdornment: (
                                                                  <Autocomplete
                                                                    size="small"
                                                                    disableClearable
                                                                    options={
                                                                      getOptions(
                                                                        data.option
                                                                      ) || []
                                                                    }
                                                                    onChange={(
                                                                      event,
                                                                      value
                                                                    ) => {
                                                                      console.log(
                                                                        data.option,
                                                                        "data.option"
                                                                      );
                                                                      handleDropdownChange(
                                                                        event,
                                                                        value,
                                                                        data.option
                                                                      );
                                                                      if (
                                                                        data.name ===
                                                                        "acCapacity"
                                                                      )
                                                                        setAcUnits(
                                                                          value
                                                                        );
                                                                      if (
                                                                        data.name ===
                                                                        "dcCapacity"
                                                                      )
                                                                        setDcUnits(
                                                                          value
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                      width:
                                                                        "160px",
                                                                    }}
                                                                    ListboxProps={{
                                                                      style: {
                                                                        maxHeight:
                                                                          "200px",
                                                                      },
                                                                    }}
                                                                    value={
                                                                      edit ||
                                                                      replace
                                                                        ? values[
                                                                            data
                                                                              .option
                                                                          ]
                                                                        : values[
                                                                            data
                                                                              .option
                                                                          ]
                                                                    }
                                                                    renderInput={(
                                                                      params
                                                                    ) => (
                                                                      <TextField
                                                                        {...params}
                                                                        sx={{
                                                                          fieldset:
                                                                            {
                                                                              border:
                                                                                "none",
                                                                              boxShadow:
                                                                                "none",
                                                                            },
                                                                        }}
                                                                        placeholder="kWp"
                                                                      />
                                                                    )}
                                                                  />
                                                                ),
                                                                style: {
                                                                  paddingRight: 0,
                                                                },
                                                              }}
                                                              value={
                                                                values[
                                                                  data.name
                                                                ]
                                                                  ? values[
                                                                      data.name
                                                                    ]
                                                                  : ""
                                                              }
                                                              sx={{
                                                                width: "15vw",
                                                              }}
                                                            />
                                                            <ErrorMessage
                                                              name={data.name}
                                                              component="div"
                                                              className="errorStyle"
                                                              style={{
                                                                color: "red",
                                                                marginTop: "1%",
                                                                textAlign:
                                                                  "left",
                                                                marginLeft:
                                                                  "0%",
                                                              }}
                                                            />
                                                            <ErrorMessage
                                                              name={data.option}
                                                              component="div"
                                                              className="errorStyle"
                                                              style={{
                                                                color: "red",
                                                                marginTop: "1%",
                                                                textAlign:
                                                                  "left",
                                                                marginLeft:
                                                                  "0%",
                                                              }}
                                                            />
                                                          </>
                                                        ) : (
                                                          // Render a disabled input field if the condition is not met
                                                          <TextField
                                                            disabled
                                                            id={`outlined-basic-${index}`}
                                                            size="small"
                                                            variant="outlined"
                                                            type="number"
                                                            name={data.name}
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                            InputProps={{
                                                              endAdornment: (
                                                                <Autocomplete
                                                                  disabled
                                                                  size="small"
                                                                  disableClearable
                                                                  options={getOptions(
                                                                    data.option
                                                                  )}
                                                                  sx={{
                                                                    width:
                                                                      "160px",
                                                                  }}
                                                                  renderInput={(
                                                                    params
                                                                  ) => (
                                                                    <TextField
                                                                      {...params}
                                                                      sx={{
                                                                        fieldset:
                                                                          {
                                                                            border:
                                                                              "none",
                                                                            boxShadow:
                                                                              "none",
                                                                          },
                                                                      }}
                                                                      placeholder="kWp"
                                                                      // {
                                                                      //   data.placeholder
                                                                      // }
                                                                    />
                                                                  )}
                                                                />
                                                              ),
                                                              style: {
                                                                paddingRight: 0,
                                                              },
                                                            }}
                                                            value={
                                                              edit
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : null
                                                            }
                                                            sx={{
                                                              width: "15vw",
                                                            }}
                                                            ListboxProps={{
                                                              style: {
                                                                maxHeight:
                                                                  "200px",
                                                              },
                                                            }}
                                                          />
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </div>
                                              </Grid>
                                            ))
                                          : null}

                                        {AddFieldsLength !== 0 && (
                                          <div>
                                            <Typography
                                              className="modal-head"
                                              style={{ width: "300px" }}
                                              sx={{
                                                marginLeft: "3%",
                                                marginTop: "25px",
                                              }}
                                              gutterBottom
                                            >
                                              Additional Fields
                                            </Typography>
                                            <Grid
                                              container
                                              spacing={3}
                                              columns={30}
                                            >
                                              <Grid
                                                item
                                                xs={8}
                                                sx={{
                                                  marginLeft: "3.5%",
                                                  marginTop: "3px",
                                                }}
                                              >
                                                {/* <div>
                                                {stdField
                                                  .equipmentadditionalfield
                                                  .length !== 0 && (
                                                    <AdditionalFields
                                                      field={stdField}
                                                      setField={setstdField}
                                                      allowedKeys={
                                                        allowedKeys
                                                      }
                                                      deleteAdditionalField={
                                                        deleteAdditionalField
                                                      }
                                                      fieldToAdd={
                                                        'equipmentadditionalfield'
                                                      }
                                                    />
                                                  )}
                                              </div> */}
                                                <AdditionalFields
                                                  field={stdField}
                                                  setField={setstdField}
                                                  allowedKeys={allowedKeys}
                                                  deleteAdditionalField={
                                                    deleteAdditionalField
                                                  }
                                                  fieldToAdd="equipmentadditionalfield"
                                                />
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )}
                                        <Grid item xs={12}>
                                          <div style={{ textAlign: "center" }}>
                                            {stdField.equipmentadditionalfield
                                              .length < 5 && (
                                              <Card
                                                sx={{
                                                  width: "125%",
                                                  // marginLeft: '4%',
                                                  height: "45px",
                                                  border: "2px solid #d0d0d0",
                                                  borderStyle: "dashed",
                                                  borderRadius: "10px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                elevation={0}
                                              >
                                                <CardContent
                                                  onClick={addAdditionalField}
                                                  sx={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    marginTop: "0.45%",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <AddCircleOutlineSharpIcon
                                                    sx={{
                                                      color: "#71766f",
                                                      marginLeft: "10px",
                                                      fontSize: "30px",
                                                    }}
                                                  />
                                                  <Typography
                                                    className="modal-typo"
                                                    fontWeight="410"
                                                    fontSize="20px !important"
                                                  >
                                                    Add Customized Data
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </div>
                              </div>
                              {/* </div> */}
                            </Card>
                          </Box>
                        </Fade>
                      </Modal>
                    </div>
                  )
                ) : (
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={excelOpen}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={excelOpen}>
                      <Box
                        className={"styleModalSmall"}
                        sx={{
                          border: "none",
                          outline: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                          marginTop: "50px",
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: "20px",
                            height: "fit-content",
                            width: "fit-content",
                            // marginLeft: '-2.5%',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: "20px 20px 20px 20px",
                              background: CheckForLight()
                                ? "rgb(246, 248, 252)"
                                : "#4f4f4f",
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                <Button
                                  variant="contained"
                                  disabled={
                                    errorIndex?.length > 0 ||
                                    indexVal?.length > 0
                                  }
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={<SaveIcon />}
                                  onClick={() => handleExcelUpload()}
                                >
                                  Submit
                                </Button>
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={handleClose}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={"Add New Equipments"}
                          />
                          <Divider style={{ borderColor: "#888" }} />
                          <CardContent>
                            <Card
                              elevation={2}
                              sx={{
                                overflowY: "auto",
                                overflowX: "scroll",
                                maxHeight: "50vh",
                                maxWidth: "75vw",
                                // padding: '-1vw',
                                marginBottom: "-1%",
                                scrollBehavior: "smooth",
                                scrollbarGutter: "stable",
                                scrollbarWidth: "thin",
                                "&::-webkit-scrollbar": {
                                  width: "0.4em",
                                },
                                "&::-webkit-scrollbar-track": {
                                  background: "#f1f1f1",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#888",
                                  borderRadius: "20px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                  background: "#555",
                                },
                              }}
                            >
                              <CardContent sx={{ padding: 0 }}>
                                {selectedFileData && (
                                  <div>
                                    <Table
                                      sx={{}}
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      {/* <TableHead>
                                    <TableRow>
                                      {Object.keys(selectedFileData[0]).map(
                                        (header, index) => (
                                          <TableCell key={index}>
                                            {header}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableHead> */}

                                      <TableHead
                                        style={{ borderBottom: "none" }}
                                      >
                                        <TableRow>
                                          {requiredHeader.map(
                                            (header, index) => (
                                              <TableCell
                                                style={{
                                                  borderBottom: "none",
                                                  textAlign: "center",
                                                }}
                                                key={index}
                                              >
                                                {header}
                                              </TableCell>
                                            )
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {selectedFileData.map(
                                          (row, rowIndex) => (
                                            <Tooltip
                                              title={(() => {
                                                let errorMessage = "";
                                                // for (let i = 0; i < errorIndex.length; i += 2) {
                                                //   if (errorIndex[i] === rowIndex) {
                                                //     errorMessage =
                                                //       errorIndex[i + 2] === true
                                                //         ? errorIndex[i + 1]
                                                //         : `${errorIndex[i + 1]
                                                //         } is missing`;
                                                //     break;
                                                //   }
                                                // }
                                                errorIndex?.forEach((error) => {
                                                  console.log(error, rowIndex);
                                                  if (
                                                    !errorMessage &&
                                                    error?.rowIndex === rowIndex
                                                  ) {
                                                    errorMessage =
                                                      error?.lengthValidation
                                                        ? error?.columnName
                                                        : `${error?.columnName} is Missing`;
                                                  }
                                                });
                                                if (
                                                  !errorMessage &&
                                                  indexVal?.includes(rowIndex)
                                                ) {
                                                  errorMessage =
                                                    errorVal[
                                                      indexVal?.indexOf(
                                                        rowIndex
                                                      )
                                                    ];
                                                }
                                                return errorMessage;
                                              })()}
                                              placement="top"
                                              key={rowIndex}
                                            >
                                              <TableRow
                                                key={rowIndex}
                                                style={
                                                  //     errorIndex?.length !== 0
                                                  //       errorIndex.forEach(error => (
                                                  // if (error?.rowIndex === rowIndex) {
                                                  //   border: '2px solid red';
                                                  //         }
                                                  // ))
                                                  errorIndex.some(
                                                    (error) =>
                                                      error?.rowIndex ===
                                                      rowIndex
                                                  ) ||
                                                  indexVal?.includes(rowIndex)
                                                    ? {
                                                        border: "2px solid red",
                                                        backgroundColor:
                                                          "#ff070721",
                                                      }
                                                    : {}
                                                  // border:
                                                  //   errorIndex?.length !== 0
                                                  //     ? errorIndex[0]?.rowIndex === rowIndex
                                                  //       ? '2px solid red'
                                                  //       : 'none'
                                                  //     : indexVal?.includes(rowIndex)
                                                  //       ? '2px solid red'
                                                  //       : 'none',
                                                  // backgroundColor:
                                                  //   errorIndex?.length !== 0
                                                  //     ? errorIndex[0]?.rowIndex === (rowIndex)
                                                  //       ? '#ff070721'
                                                  //       : 'none'
                                                  //     : indexVal?.includes(rowIndex)
                                                  //       ? '#ff070721'
                                                  //       : 'none',
                                                }
                                              >
                                                {requiredHeader.map(
                                                  (header, colIndex) => (
                                                    <TableCell
                                                      sx={{}}
                                                      key={colIndex}
                                                      style={{
                                                        border: "none",
                                                        borderTop: "none",
                                                        // textAlign: 'center',
                                                      }}
                                                    >
                                                      {row[header]}
                                                    </TableCell>
                                                  )
                                                )}
                                              </TableRow>
                                            </Tooltip>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </div>
                                )}
                              </CardContent>
                            </Card>
                          </CardContent>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                )}
              </Form>
            )}
          </Formik>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"Equipment Details"}
              uploadButton={true}
              addButton={"Equipment"}
              SearchLabel={"Search Equipment Here... "}
              searchInput={searchInput}
              header={addHeadCells}
              tabsData={tabsData}
              rowsValue={tableValues}
              userRole={equipmentConfiguration[0]}
              handleChange={handleChangeTab}
              // handleSitedropdownvalue={(val) => handlesitevalue(val)}
              handleSiteChangeExpect={(val) => handleExpect(val)}
              sitevalue={Valuedata}
              siteNameList={siteName}
              //  selectedSite={Valuedata}
              handleSiteFilterChange={handleSiteFilterValue}
              handleDownloadTemplate={() =>
                downloadExcelTemplate("Equip Details")
              }
              handleUploadProp={(e) => handleFileUpload(e)}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handleIsReplace={(val) => handleReplaceCall(val)}
              rawData={SiteSector}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? " Active" : siteTab === 0 ? " Inactive" : ""
              } Equipment has been created yet`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
