import axios from 'axios';

export const postsubscriptiondata = async (obj) => {

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_SAVE}`,
        obj
      );
      return response;
    } catch (error) {
      console.error('Error posting data:', error.message);
      throw error;
    }
  };

  export const fetchsubscriptionTable = async (siteId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_TABLE}/${siteId}`
      );
      console.log(response, "API Response");
      return response.data;
    } catch (error) {
      console.error("Error fetching subscription table data:", error);
      throw error;
    }
  
  };

  export const putSubscriptionEdit = async (data, subDetailsId) => {
    console.log(subDetailsId);
    try {
     
      const response = await axios.put(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SUBSCRIPTION_UPDATE}/${subDetailsId}`,
        data
      );
   
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const putSubscriptionDelete = async (id) => {
    console.log(id);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_API_SUBSCRIPTION_DELETE}/${id}`,
      );
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };