import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  CircularProgress,
  Tooltip,
  Checkbox,
  Card,
  CardHeader,
  Alert,
  Switch,
} from "@mui/material";
import "../../Common.css";
import { saveAs } from "file-saver";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";

// ========Modal================
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FadeLoader } from "react-spinners";
import { styled } from "@mui/material/styles";
import moment from "moment";
import TableTemplate from "../../Template/TableTemplate";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { addDays } from "date-fns";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
/*------------api call-----------------*/
import {
  fetchSiteDetails,
  putReportupdateEdit,
  fetchReportTable,
  postreportdata,
  fetchParameternamelist,
  DownloadReport,
  fetchReportTableById,
  fetchReportDownload,
  postreportnew,
} from "../../Api/ReportApi";
import Download from "../../Template/Excel/Download";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Reportreport } from "../util/TextField";
import { Reportnew } from "../util/TextField";
import { ReportSchema } from "../util/ValidateSchema";
import replaceObjectById from "../util/StateUpdater";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import errorMsg from "../util/errorMessage.json";
import { CheckForLight } from "../util/common/CommanFunction";
import { logDOM } from "@testing-library/react";
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const initialItems = [
  { id: "1", content: "Item 1" },
  { id: "2", content: "Item 2" },
  { id: "3", content: "Item 3" },
];

export default function Reporthome({ Report }) {
  /*------------state-----------------*/
  const Navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState({});
  const [Createreport, setCreatereport] = useState(false);
  /*------------ modal state-----------------*/
  const [siteIdName, setSiteId] = useState([]);
  const [parametertIdName, setparameterId] = useState([]);
  console.log(parametertIdName);

  const [site, setSite] = React.useState("");
  const [customFromDate, setFromDate] = useState("");
  const [SiteReport, setSiteReport] = useState([]);
  const [activeFlag, setactiveFlag] = useState();
  const [timePeriod, settimePeriod] = useState();
  const [editcall, seteditcall] = useState();
  const [textt, setTextFieldd] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [opennewReport, setopennewReport] = useState(false);
  const [edit, setEdit] = useState(false);
  console.log(edit);

  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [validEmail, setValidEmail] = useState(true);
  const [reportData, setReportData] = useState();
  const [downloadPopup, setDownloadPopup] = useState(false);
  const [minToDate, setMinToDate] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [fromDownloadDate, setFromDownloadDate] = useState("");
  const [toDownloadDate, setToDownloadDate] = useState("");
  const [siteTab, setSiteTab] = React.useState("");
  const [loading, setloading] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [todate, setTodate] = useState();
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [checkedStatus, setCheckedStatus] = useState({});

  const [destinationItems, setDestinationItems] = useState([]);

  const [parametertIdNameDrag, setParametertIdName] =
    useState(parametertIdName);
  const [sourceItems, setSourceItems] = useState([]);

  console.log(destinationItems, "---------------------------");

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  console.log(pdf);

  const initalState = {
    siteName: "",
    reportName: "",
    emailId: [email],
    emailIdCC: [],
    status: "",
    range: "",
    parameterName: [],
    range: "",
    assignedTo: "",
    ticketStatus: "",
  };

  const [reportFields, setReportFields] = useState(initalState);
  console.log(reportFields?.range);

  const [SwitchData, setSwitchData] = useState();

  // Update the state

  useEffect(() => {
    // Ensure this logic is not inside the render body
    const updatedState = Object.keys(initalState).reduce((acc, key) => {
      if (switchStates[key]) {
        acc[key] = initalState[key];
      }
      return acc;
    }, {});

    console.log();

    setReportFields(updatedState);

    console.log("Updated State:", updatedState);
  }, []);

  const [reportValue, setReportValue] = useState({
    timePeriodValue: "",
    statusValue: "",
  });
  const [text, setTextField] = useState([]);
  useEffect(() => {
    let data = Reportreport();
    setTextField(data);
  }, []);

  const [switchStates, setSwitchStates] = useState({});
  console.log(switchStates);

  const trueValues = Object.entries(switchStates)
    .filter(([key, value]) => value)
    .map(([key]) => key);

  useEffect(() => {
    if (edit) {
      const initialStates = sourceItems.reduce((acc, item) => {
        const isParameterMatched = reportFields?.parameterName?.includes(
          item.parameterName
        );
        acc[item.parameterName] = isParameterMatched;
        return acc;
      }, {});

      setSwitchStates(initialStates);
    }
  }, [sourceItems, reportFields?.parameterName, edit]);

  // const handleSwitchChange = (parameterName) => (event) => {
  //   if (!edit) {
  //     // If not in edit mode, update switch state directly
  //     setSwitchStates((prevStates) => ({
  //       ...prevStates,
  //       [parameterName]: event.target.checked,
  //     }));
  //   } else if (edit && reportFields?.parameterName?.includes(parameterName)) {
  //     // If in edit mode and parameterName is in the reportFields
  //     setSwitchStates((prevStates) => ({
  //       ...prevStates,
  //       [parameterName]: event.target.checked,
  //     }));
  //   }
  // };

  const handleSwitchChange = (parameterName) => (event) => {
    const isChecked = event.target.checked;

    setSwitchStates((prevStates) => ({
      ...prevStates,
      [parameterName]: isChecked,
    }));
  };

  useEffect(() => {
    fetchData();
    getSiteCall();
    getreportlist();
  }, []);

  const handledatepicchange = (date, name) => {
    setFromDate(date.toString());
  };
  const handledatepic = (date, name) => {
    setTodate(date.toString());
  };
  function getOptions(propName) {
    let option;
    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "parameterName":
        return (option = parametertIdName?.map(
          (option) => option.parameterName
        ));
      case "range":
        return (option = range?.map((option) => option.label));

      case "status":
        return (option = statusList);
      default:
    }
    return option;
  }
  const range = [
    { label: "Daily", value: 3 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 1 },
    { label: "DGR", value: 1 },
    ...(!edit ? [{ label: "Custom Range", value: "range" }] : []),
  ];

  const Status = [
    {
      label: "Active",
      value: "active",
    },
  ];

  /*------------------------- Api --------------------------------------------*/

  const dayjs = require("dayjs");

  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async () => {
    let siteName =
      reportFields.siteName !== "" ? reportFields.siteName : editcall?.siteName;
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    const filteredParameter = parametertIdName.filter((parameter) =>
      trueValues.includes(parameter.parameterName)
    );
    const filteredParameterids = filteredParameter.map(
      (parameter) => parameter.parameterId
    );
    let status = statusList?.filter((data) => {
      return data.label === reportFields.status;
    });

    // if (
    //   !reportFields.parameterName.length > 0 ||
    //   !reportFields.emailId.length > 0
    // ) {
    //   setSnack({
    //     open: true,
    //     severity: "error",
    //     message: "Fields cannot be empty",
    //   });
    //   return;
    // }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emails;

    if (typeof reportFields.emailId === "string") {
      // Split the string by commas and trim each email address
      emails = reportFields.emailId.split(",").map((email) => email.trim());
    } else if (Array.isArray(reportFields.emailId)) {
      emails = reportFields.emailId.map((email) => email.trim());
    } else {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format",
      });
      return;
    }
    console.log(edit, editcall);

    const invalidEmails = emails.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format. After comma check each email address.",
      });
      return;
    }

    let id = editcall.reportId;
    let obj = {
      siteId: siteIdFilter[0]?.siteId,
      range: reportFields.range,
      emailId: Array.isArray(reportFields.emailId)
        ? reportFields.emailId
        : [reportFields.emailId],
      emailId_CC: Array.isArray(reportFields.emailIdCC)
        ? reportFields.emailIdCC
        : [reportFields.emailIdCC],
      reportName: reportFields.reportName,
      parameterIds: filteredParameterids,
      status: edit
        ? typeof reportFields.status === "string"
          ? status[0].value
          : reportFields.status
        : status[0].value,
    };
    // if(reportFields.range=== "DGR"){
    //   delete obj.parameterIds
    // }
    console.log(obj);

    try {
      setToBackend(true);
      // let responsedata =""
      let responsedata = await putReportupdateEdit(obj, id);

      if (responsedata.status === 200) {
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        fetchData();
        setOpen(false);
        return 1;
      }
    } catch (error) {
      setToBackend(false);
      setSnack(errorMsg.failure);
      console.error("Error:", error.response.status);
      return 0;
    }
  };
  const handleDownloadReport = async () => {
    const filteredParameter = parametertIdName.filter((parameter) =>
      reportFields.parameterName.includes(parameter.parameterName)
    );
    const filteredParameterIds = filteredParameter.map(
      (parameter) => parameter.parameterId
    );

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === reportFields.siteName;
    });

    let frequencyRange = range?.filter((data) => {
      return data.label === reportFields.range;
    });
    setToBackend(true);
    let PostData = {
      userId: Number(sessionStorage.getItem("id")),
      siteId: siteIdFilter[0]?.siteId,
      range: frequencyRange[0]?.value,
      parameterIds: filteredParameterIds,
      reportName: reportFields.reportName,
      status: 1,
      toDate: todate ? addDays(todate, 1).toISOString().substring(0, 10) : null,

      fromDate: customFromDate
        ? addDays(customFromDate, 1).toISOString().substring(0, 10)
        : null,
    };

    try {
      const response = await DownloadReport(PostData);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${PostData.reportName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setOpen(false);
      handleClose();
      setToBackend(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlereportdownloaddata = async (val) => {
    let postdata = {
      reportId: val?.reportId,
    };
    let data = await fetchReportDownload(postdata);
  };
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emails;
    if (typeof reportFields.emailId === "string") {
      emails = reportFields.emailId.split(",").map((email) => email.trim());
    } else if (Array.isArray(reportFields.emailId)) {
      emails = reportFields.emailId.map((email) => email.trim());
    } else {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format",
      });
      return;
    }

    const invalidEmails = emails.filter((email) => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setSnack({
        open: true,
        severity: "error",
        message: "Invalid email format. After comma check each email address.",
      });
      return;
    }

    const filteredParameter = parametertIdName.filter((parameter) =>
      trueValues.includes(parameter.parameterName)
    );
    console.log(filteredParameter);

    const parameterIds = filteredParameter.map((item) => item.parameterId);
    console.log(parameterIds);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === reportFields.siteName;
    });
    let data = {
      userId: Number(sessionStorage.getItem("id")),
      siteId: siteIdFilter[0]?.siteId,
      range: reportFields.range,
      parameterIds: parameterIds,
      reportName: reportFields.reportName,
      status: 1,
      emailId: Array.isArray(reportFields.emailId)
        ? reportFields.emailId
        : [reportFields.emailId],
      emailId_CC: Array.isArray(reportFields.emailIdCC)
        ? reportFields.emailIdCC
        : [reportFields.emailIdCC],
    };
    if (reportFields.range==="DGR"){
      delete data.parameterIds
    }
    console.log(data);

    console.log(data, "-----------------------");
    // return
    try {
      setToBackend(true);
      const responseData = await postreportnew(data);
      if (responseData.status === 201) {
        fetchData();
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        setOpen(false);
        return 1;
      }
    } catch (e) {
      setToBackend(false);
      setSnack(errorMsg.failure);
      console.error("Error:", e.response.status);
      return 0;
    }
  };

  useEffect(() => {
    setSelectedValues(
      Array.isArray(editcall?.parameterName)
        ? editcall.parameterName
        : editcall?.parameterName
        ? [editcall.parameterName]
        : []
    );
  }, [edit, editcall]);
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    try {
      const data = await fetchReportTableById();
      if (Array.isArray(data)) {
        setSiteReport(data);
        setloading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteReport([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };
  /*------------------------- Site Name list dropdown --------------------------------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDetails();
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/
  const getreportlist = async () => {
    try {
      let data = await fetchParameternamelist();

      const updatedData = data?.map((item) => ({
        ...item,
        parameterId: item.parameterId.toString(),
      }));
      console.log(updatedData, "--------------");
      setparameterId(updatedData);
      setSourceItems(updatedData);
    } catch (e) {
      console.error(e);
    }
  };

  /*---------------------------------- New Report Call -----------------------*/
  const handleCrate = () => {
    setCreatereport(true);
    setOpen(true);
    setEdit(false);
    seteditcall();
    setToBackend(false);
  };
  const handleReportPopupOpen = () => {
    setopennewReport(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setReportFields(initalState);
    setFromDate("");
    setTodate("");
    setSwitchStates({});
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  const Type = [
    { label: "Generation", value: "Daily Generation" },
    { label: "Generation", value: "Specific Yield" },
    { label: "Generation", value: " Energy Performance" },
  ];

  const statusList = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];
  const optionTime = [
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Last Week", value: "Weekly" },
    { label: "Last Month", value: "Monthly" },
    // { label: "Custom Range", value: "custom" },
  ];

  function filterOptions(hideCustom) {
    const reportName =
      edit && reportFields.reportName === ""
        ? editcall.reportName
        : reportFields.reportName;
    const optionsMap = {
      "Daily Generation Report": ["Today", "Yesterday"],
      "Asset Management Report": ["Weekly", "Monthly"],
    };

    let options = optionsMap[reportName] || [];

    if (!hideCustom && reportFields.reportName) {
      options.push("custom");
    }

    return optionTime.filter((option) => options.includes(option.value));
  }

  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Report Name",
      id: "reportName",
      view: true,
    },
    {
      label: "Time Period",
      id: "range",
      view: true,
    },

    {
      label: "Parameter Name",
      id: "parameterString",
      view: true,
    },
    {
      label: "Mail Id",
      id: "emailId",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (range) {
      case "Today":
        fromDate.setDate(date.getDate());
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case "Yesterday":
        fromDate.setDate(date.getDate() - 1);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case "Weekly":
        fromDate.setDate(date.getDate() - 6);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDownloadDate(responseDate);
        setToDownloadDate(toDate);
        break;
      case "Monthly":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        break;
      default:
    }
  }, [range]);

  const functionDate = (inputValue) => {
    let date = new Date();
    let fromDate = new Date();
    let toDateFormat = new Date();
    let responseDate = "";
    let toDate = "";
    let dateFormat = {
      initalDate: "",
      finalDate: "",
    };
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    switch (inputValue) {
      case "Today":
        fromDate.setDate(date.getDate());
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        dateFormat.initalDate = formatDate(fromDate);
        dateFormat.finalDate = formatDate(fromDate);
        break;
      case "Yesterday":
        fromDate.setDate(date.getDate() - 1);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        dateFormat.initalDate = formatDate(fromDate);
        dateFormat.finalDate = formatDate(fromDate);
        break;
      case "Weekly":
        fromDate.setDate(date.getDate() - date.getDay() - 6);
        const firstDayOfPastWeek = new Date(fromDate);
        toDateFormat.setDate(date.getDate() - date.getDay());
        const lastDayOfPastWeek = new Date(toDateFormat);
        const formattedFirstDay = formatDate(firstDayOfPastWeek);
        const formattedLastDay = formatDate(lastDayOfPastWeek);
        dateFormat.initalDate = formattedFirstDay;
        dateFormat.finalDate = formattedLastDay;
        break;

      case "Monthly":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDownloadDate(formatDate(firstDayOfLastMonth));
        setToDownloadDate(formatDate(lastDayOfLastMonth));
        dateFormat.initalDate = formatDate(firstDayOfLastMonth);
        dateFormat.finalDate = formatDate(lastDayOfLastMonth);
        break;
      default:
    }

    return dateFormat;
  };

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (formulaId) => {
    let filteredreportMapId = Object.keys(initalState).reduce((acc, key) => {
      if (formulaId.hasOwnProperty(key)) {
        acc[key] = formulaId[key];
      }
      return acc;
    }, {});
    seteditcall(formulaId);
    setReportFields(filteredreportMapId);
    setEdit(true);
    // setbutton(true)
    setOpen(true);
    setIsEditOpen(true);
  };

  const handleDropdownChange = (event, value, name) => {
    console.log(event, value, name);

    if (name === "rage" || name === "status") {
      setReportFields({ ...reportFields, [name]: value.label });
      setReportValue({ ...reportValue, [name]: value.value });
    } else if (name === "reportName") {
      setReportFields({ ...reportFields, [name]: value, range: null });
    } else {
      setReportFields({ ...reportFields, [name]: value });
    }
  };
  // ========customrange=========
  const handleChangeFromDate = (data, value) => {
    if (
      data !== null &&
      typeof data === "object" &&
      typeof data.format === "function"
    ) {
      const formattedDatetime = data.format("YYYY-MM-DD");
      setFromDate(formattedDatetime);
    } else {
      console.error("Invalid data format or 'data' is null");
    }
  };

  useEffect(() => {
    setMinToDate(customFromDate);
  }, [customFromDate]);
  const [searchInput, setSearchInput] = useState("");
  const filteredSiteReportData = SiteReport.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  const handleChange = (e) => {
    setReportFields(e);
  };
  const handleTxtChange = (event) => {
    console.log(event.target.name, event.target.value);
    setReportFields({
      ...reportFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleDownloadTable = async (val) => {
    const status = await Download(val, "Report");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  SiteReport.forEach(report => {
    if (report.parameterName) {
        report.parameterString = report.parameterName.join(",\n");
    }
});

  const tableValues = SiteReport.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status === 1) === Number(siteTab);
    } else {
      return row;
    }
  });
  console.log(tableValues);
  
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };

  const Active = SiteReport?.filter((site) => site?.status == 1).length;

  const InActive = SiteReport?.filter((site) => site?.status == 0).length;

  const tabsData = [
    { name: "Active Report", value: "all", badgeCount: Active },
    {
      name: "Inactive Report",
      value: "Inactive",
      badgeCount: InActive,
    },
  ];

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Report", path: "report" },
  ];
  useEffect(() => {
    let data = Reportnew();
    setTextFieldd(data);
  }, []);

  const handleCheckboxChangeStatus = (parameterId) => {
    setCheckedStatus((prevState) => ({
      ...prevState,
      [parameterId]: !prevState[parameterId],
    }));
  };

  // drag and drop

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // If there's no destination, exit
    if (!destination) return;

    // Moving from source to destination
    if (
      source.droppableId === "source" &&
      destination.droppableId === "destination"
    ) {
      const sourceClone = Array.from(sourceItems);
      const destClone = Array.from(destinationItems);
      const [movedItem] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, movedItem);
      setSourceItems(sourceClone);
      setDestinationItems(destClone);

      // Moving from destination back to source
    } else if (
      source.droppableId === "destination" &&
      destination.droppableId === "source"
    ) {
      const sourceClone = Array.from(destinationItems);
      const destClone = Array.from(sourceItems);
      const [movedItem] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, movedItem);
      setSourceItems(destClone);
      setDestinationItems(sourceClone);
    }
  };

  const headerContent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="modelTypo">
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h4"
          className="modal-head"
          style={{ fontWeight: "bold" }}
        >
          {edit ? "Update Report" : "Add New Report"}
        </Typography>
      </div>
      {reportFields.status === "Inactive" ? (
        <Alert
          severity="error"
          style={{
            userSelect: "none",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {`Report generation will be  inactivated.`}
        </Alert>
      ) : null}
    </div>
  );
  return (
    <div>
      {/* {loading ? ( */}
      {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div> */}
      {/* ) : ( */}
      <div>
        {/* ---------search---bar--- Card----------- */}

        <div>
          {/* ---------   -----save andupdate---- popup----------- */}
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <Formik
            key={
              edit
                ? "edit"
                : reportData === "Asset Management Report"
                ? "report"
                : reportData !== "Asset Management Report"
                ? "daily"
                : "add"
            }
            enableReinitialize={true}
            initialValues={reportFields}
            validationSchema={ReportSchema}
            onSubmit={async (values, { resetForm }) => {
              let submitValue = {
                siteName: values.siteName,
                reportName: values.reportName,
                mailId: values.emailId,
                status: values.status,
                range: values.range,
              };
              if (edit) {
                const result = await handleUpdate(submitValue);
                console.log(result, "result");
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                console.log(result, "result");
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  closeAfterTransition
                >
                  <Fade in={open}>
                    <Box
                      className={"styleModalSmal"}
                      style={{ height: "68vh" }}
                      // sx={{ bgcolor: 'background.paper' }}
                    >
                      <Card
                        sx={{
                          borderRadius: "20px",
                          // height: '68vh',
                          marginTop: "5%",
                          height: "fit-content",
                        }}
                      >
                        <CardHeader
                          sx={{
                            padding: "20px 20px 20px 20px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                          }}
                          action={
                            <Stack spacing={2} direction="row">
                              {edit ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Updating..." : "Update"}
                                </Button>
                              ) : reportFields.range === "Custom Range" ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  disabled={
                                    !(
                                      reportFields.siteName &&
                                      reportFields.range &&
                                      reportFields.parameterName &&
                                      reportFields.reportName &&
                                      customFromDate &&
                                      todate
                                    )
                                  }
                                  onClick={
                                    toBackend ? null : handleDownloadReport
                                  }
                                >
                                  {toBackend ? "Downloading" : "Download"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Saving..." : "Save"}
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                disabled={toBackend}
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => {
                                  handleClose();
                                  handleReset();
                                }}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          }
                          title={headerContent}
                        />
                        <Divider sx={{ borderColor: "#888" }} />
                        <div
                          style={{
                            height: "auto",
                            padding: "15px",
                          }}
                        >
                          <Grid container spacing={2} columns={8}>
                            {text?.length > 0
                              ? text?.map((data, index) => (
                                  <Grid item xs={4}>
                                    <div key={index}>
                                      {data.type === "textField" ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Field
                                            as={TextField}
                                            // disabled={toBackend}
                                            id={`outlined-basic-${index}`}
                                            size="small"
                                            variant="outlined"
                                            name={data.name}
                                            inputProps={{
                                              maxLength: data.length,
                                            }}
                                            placeholder={data.placeholder}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleTxtChange(e);
                                            }}
                                            // defaultValue={ sessionStorage.getItem("email") }
                                            value={
                                              values.emailId === null ||
                                              values.emailId === ""
                                                ? ""
                                                : values[data.name]
                                            }
                                            sx={{ width: "16vw" }}
                                          />
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: "red",
                                              marginTop: "1%",
                                              textAlign: "left",
                                              marginLeft: "0%",
                                            }}
                                          />
                                        </>
                                      ) : data.type === "multidropdown" ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={
                                              data.name === "range"
                                                ? filterOptions(edit)
                                                : getOptions(data.name)
                                            }
                                            onChange={(event, value) => {
                                              handleDropdownChange(
                                                event,
                                                value,
                                                data.name
                                              );

                                              // Check if "Select All" is selected
                                              if (
                                                value.includes("Select All")
                                              ) {
                                                const allOptions =
                                                  data.name === "range"
                                                    ? filterOptions(edit)
                                                    : getOptions(data.name);
                                                const newValue =
                                                  value.length ===
                                                  allOptions.length
                                                    ? []
                                                    : allOptions;
                                                handleDropdownChange(
                                                  event,
                                                  newValue,
                                                  data.name
                                                );
                                              }
                                            }}
                                            style={{
                                              display: "flex",
                                            }}
                                            disableCloseOnSelect
                                            size="small"
                                            sx={{
                                              width: "16vw",
                                              height: "40px",
                                              marginRight: 8,
                                            }}
                                            defaultValue={
                                              edit && data.name === "siteName"
                                                ? editcall?.siteName
                                                  ? [editcall.siteName]
                                                  : []
                                                : edit &&
                                                  data.name === "parameterName"
                                                ? Array.isArray(
                                                    editcall?.parameterName
                                                  )
                                                  ? editcall.parameterName
                                                  : editcall?.parameterName
                                                  ? [editcall.parameterName]
                                                  : []
                                                : []
                                            }
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => {
                                              const { key, ...optionProps } =
                                                props;
                                              return (
                                                <li key={key} {...optionProps}>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected}
                                                  />
                                                  {option}
                                                </li>
                                              );
                                            }}
                                            renderInput={(params) => {
                                              console.log(params.inputProps);

                                              return (
                                                <TextField
                                                  {...params}
                                                  size="small"
                                                  placeholder={
                                                    params.inputProps.value ===
                                                    0
                                                      ? data.name
                                                      : ""
                                                  }
                                                  InputProps={{
                                                    ...params.InputProps,
                                                    style: { height: "100%" },
                                                  }}
                                                  onChange={(event) => {
                                                    console.log(
                                                      "Input value on change:",
                                                      event.target.value
                                                    );
                                                    if (
                                                      params.InputProps.onChange
                                                    ) {
                                                      params.InputProps.onChange(
                                                        event
                                                      );
                                                    }
                                                  }}
                                                />
                                              );
                                            }}
                                            renderTags={(value) => {
                                              if (value.length === 0)
                                                return null;
                                              const truncatedValue =
                                                value[0].length > 15
                                                  ? value[0].substring(0, 15) +
                                                    "..."
                                                  : value[0];
                                              return (
                                                <div
                                                  style={{
                                                    maxWidth: "12vw",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    padding: "5px",
                                                    marginRight: 5,
                                                  }}
                                                >
                                                  {truncatedValue}{" "}
                                                  {value.length > 1
                                                    ? `+${value.length - 1}`
                                                    : ""}
                                                </div>
                                              );
                                            }}
                                            noOptionsText="No options"
                                          />

                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: "red",
                                              marginTop: "1%",
                                              textAlign: "left",
                                              marginLeft: "0%",
                                            }}
                                          />
                                        </>
                                      ) : data.type === "dropdown" ? (
                                        <>
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <Tooltip
                                            title={
                                              !edit && data.name === "status"
                                                ? "Active"
                                                : edit
                                                ? data.name === "status"
                                                  ? typeof values[data.name] ===
                                                    "string"
                                                    ? values[data.name]
                                                    : values.status === 1
                                                    ? "Active"
                                                    : "Inactive"
                                                  : values[data.name]
                                                : values[data.name]
                                            }
                                          >
                                            <Field
                                              render={({ field, form }) => (
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === "status"
                                                      ? "Active"
                                                      : edit
                                                      ? data.name === "status"
                                                        ? typeof values[
                                                            data.name
                                                          ] === "string"
                                                          ? values[data.name]
                                                          : values.status === 1
                                                          ? "Active"
                                                          : "Inactive"
                                                        : values[data.name]
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Autocomplete
                                                    disableClearable
                                                    disabled={
                                                      toBackend ||
                                                      (!edit &&
                                                        data.name === "status")
                                                      // (!edit &&
                                                      //   data.name ===
                                                      //     "range" &&
                                                      //   !values.siteName) ||
                                                      // (!edit &&
                                                      //   data.name ===
                                                      //     "range")
                                                    }
                                                    options={getOptions(
                                                      data.name
                                                    )}
                                                    size="small"
                                                    id={`combo-box-demo-${index}`}
                                                    onChange={(event, value) =>
                                                      handleDropdownChange(
                                                        event,
                                                        value,
                                                        data.name
                                                      )
                                                    }
                                                    value={
                                                      !edit &&
                                                      data.name === "status"
                                                        ? "Active"
                                                        : edit
                                                        ? data.name === "status"
                                                          ? typeof values[
                                                              data.name
                                                            ] === "string"
                                                            ? values[data.name]
                                                            : values.status ===
                                                              1
                                                            ? "Active"
                                                            : "Inactive"
                                                          : values[data.name]
                                                        : values[data.name]
                                                    }
                                                    sx={{ width: "16vw" }}
                                                    ListboxProps={{
                                                      style: {
                                                        maxHeight: "200px",
                                                      },
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                      />
                                                    )}
                                                  />
                                                </Tooltip>
                                              )}
                                            />
                                          </Tooltip>
                                          <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: "red",
                                              marginTop: "1%",
                                              textAlign: "left",
                                              marginLeft: "0%",
                                            }}
                                          />
                                        </>
                                      ) : reportFields.range ===
                                          "Custom Range" &&
                                        data.type === "date" ? (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>
                                          <DatePicker
                                            size="small"
                                            sx={{
                                              width: "16vw",
                                            }}
                                            name={data.name}
                                            onChange={(e) => {
                                              handledatepicchange(e, data.name);
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : reportFields.range ===
                                          "Custom Range" &&
                                        data.type === "datee" ? (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Typography
                                            variant="body1"
                                            className="modal-typo"
                                            gutterBottom
                                          >
                                            {data.label}
                                          </Typography>

                                          <DatePicker
                                            size="small"
                                            sx={{
                                              width: "16vw",
                                            }}
                                            // minDate={customFromDate}

                                            shouldDisableDate={(date) =>
                                              dayjs(date).isBefore(
                                                dayjs(customFromDate),
                                                "day"
                                              )
                                            }
                                            name={data.name}
                                            onChange={(e) => {
                                              handledatepic(e, data.name);
                                            }}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : null}
                                    </div>
                                  </Grid>
                                ))
                              : null}

                            <div style={{ padding: "15px" }}>
                              <Grid container spacing={1} columns={32}>
                                {sourceItems.map((item) => (
                                  <Grid
                                    item
                                    xs={8}
                                    key={item.parameterId}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>{item.parameterName}</span>
                                    <Switch
                                      checked={switchStates[item.parameterName]}
                                      onChange={handleSwitchChange(
                                        item.parameterName
                                      )}
                                      disabled={reportFields?.range === "DGR"}
                                      style={{ marginTop: "-1%" }}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                          </Grid>
                        </div>
                      </Card>

                      <div>
                        <Grid container spacing={2} columns={32}></Grid>
                      </div>
                    </Box>
                  </Fade>
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
        <div></div>
        {/* ---------------pass props value----------- */}

        <div style={{ marginTop: "-0.5%" }}>
          <TableTemplate
            PageName={"Report"}
            addButton={"Report"}
            SearchLabel={"Search Report Here... "}
            header={headCells}
            rowsValue={tableValues}
            tabsData={tabsData}
            handleChange={handleChangeTab}
            handleAddPopupOpen={(val) => handleCrate(val)}
            handleEditPopupOpen={(val) => handleEditOpen(val)}
            userRole={Report[0]}
            handleDownloadExcel={(val) => {
              handleDownloadTable(val);
            }}
            handleticketdownloadReportPopupOpen={(val) =>
              handlereportdownloaddata(val)
            }
            handleReportPopupOpen={(val) => handleReportPopupOpen(val)}
            paths={paths}
            // fallbackText={'No reports available'}
            fallbackText={"No reports are available at this time."}
            rawData={SiteReport}
          />
        </div>
      </div>
      {/* )} */}
    </div>
  );
}
