import { Schedule } from '@mui/icons-material';
import * as yup from 'yup';

/*--------------------------------------------- CustomerList  Schema -----------------*/
let validEmail = 'Please enter a valid Email Id';
const emailRequired = (propName) =>
  yup
    .string(true, validEmail)
    .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, validEmail)
    .required(`${propName} is required`);
    // const emailRequiredd = (propName) => 
    //   yup
    //     .string()
    //     .test('multiple-emails', 'Invalid email format. Please check each email after the comma.', function(value) {
    //       if (!value) return false;
    //       const emails = value.split(',').map(email => email.trim());
    //       const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //       return emails.every(email => emailRegex.test(email));
    //     })
    //     .required(`${propName} is required`);
const stringRequired = (propName) =>
  yup.string().required(`${propName} is Required`);

const numberRequired = (propName) => {
  let count;
  if (propName === 'Mobile Number') {
    count = 10;
  } else if (propName === 'Telephone Number') {
    count = 7;
  } else if (propName === 'Postal Code') {
    count = 6;
  } else {
    return yup.string().required(`${propName} is Required`);
  }
  return yup
    .string()
    .required(`${propName} is Required`)
    .matches(new RegExp(`^\\d{${count}}$`), `Must be ${count} Digits`);
};

export const equipmentConfigEquipmentSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  equipmentCategory: stringRequired('Equipment Category'),
  equipmentType: stringRequired('Equipment Type'),
  serialNo: stringRequired('Serial No'),
  // equipmentType: stringRequired('Equipment Type'),
  // acCapacity: stringRequired('AC Capacity'),
  // dcCapacity: stringRequired('DC Capacity'),
  latitude: stringRequired('Latitude'),
  longitude: stringRequired('Longitude'),

  isPrimaryEquipment: stringRequired('Primary Equipment'),
  // primaryMapEquip:stringRequired('Primary Equip Map'),
  noOfComponents: stringRequired('No Of Components'),
  remarks: stringRequired('Remark'),
  displayName: stringRequired('Display Name'),
  // displayName: stringRequired('Display Name'),
  // acUnits: stringRequired('AC Units'),
  // dcUnits: stringRequired('DC Units'),
  // // primaryMapEquip: stringRequired('Primary Equip Map'),
  // status: stringRequired('Status'),
  // lastUpdatedBy: stringRequired('Last Updated By'),
});

export const equipmentReplaceSchema = yup.object().shape({
  ...equipmentConfigEquipmentSchema.fields,
  siteName: undefined,
  displayName: undefined,
  // equipmentCategory: undefined,
  // equipmentType: undefined,
});

export const ConfigurationSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  displayName: stringRequired('Equipment Name'),
  dailyGenBasedOn: stringRequired('Daily Generation'),
  serialNo: stringRequired('Device Serial No'),
  modBusAddress: stringRequired('Mod Bus Address'),
  prodFlag: stringRequired('Production Flag'),
  statusMasterId: stringRequired('Status Master'),
  device_Sn: stringRequired('DeviceSn'),
  energyCounter: stringRequired('energyCounter'),
  dataLoggerName: stringRequired('Data Logger'),
});
export const subcriptionSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  month: stringRequired('Plan'),
  poNumber: stringRequired('Po Number'),
  subscriptionName: stringRequired('Subscription'),
  PoFromDate: stringRequired('From Date'),
  PoToDate: stringRequired('To Date'),
  // curing_Data:stringRequired('Status')
});

export const customerListSchmea = yup.object().shape({
  customerName: stringRequired('Customer Name'),
  subscriptionName: stringRequired('Subcription'),
  emailId: emailRequired('Email Id'),
  address: stringRequired('Address'),
  state: stringRequired('State'),
  country: stringRequired('Country'),
  contactPerson: stringRequired('Contact Person'),
  customerWebsite: stringRequired('Customer Website'),
  // companyLogoPath:stringRequired("Company Logo Path "),
  mobileNumber: numberRequired('Mobile Number'),
  // telePhoneNumber: numberRequired('Telephone Number'),
  // status: stringRequired('Status'),
});

export const userConfigSchema = yup.object().shape({
  firstName: stringRequired('First Name'),
  lastName: stringRequired('Last Name'),
  // customerName: stringRequired('Customer Name'),
  emailId: emailRequired('Email Id'),
  mobileNo: numberRequired('Mobile Number'),
  // organization: stringRequired('Organization'),
  city: stringRequired('City'),
  state: stringRequired('State'),
  country: stringRequired('Country'),
  postalCode: numberRequired('Postal Code'),
  designation: stringRequired('Designation'),
  roleName: stringRequired('Role'),
  // status: stringRequired('Status'),
});

export const profileSchema = yup.object().shape({
  firstName: stringRequired('First Name'),
  lastName: stringRequired('Last Name'),
  emailId: emailRequired('Email Id'),
  mobileNo: numberRequired('Mobile Number'),
  city: stringRequired('City'),
  state: stringRequired('State'),
  country: stringRequired('Country'),
  postalCode: numberRequired('Postal Code'),
});

export const siteConfigSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  longitude: stringRequired('Longitude'),
  latitude: stringRequired('Latitude'),
  installationCapacity: stringRequired('Installation Capacity'),
  units: stringRequired('Unit'),
  address: stringRequired('Address'),
  postalCode: stringRequired('Postal Code'),
  state: stringRequired('State'),
  country: stringRequired('Country'),
  timezoneName: stringRequired('Time Zone'),
  city: stringRequired('City'),
  sectorName: stringRequired('Sector Type'),
  siteType: stringRequired('Site Type'),
});

export const siteTypeValidation = yup.object().shape({
  ...siteConfigSchema.fields,
  siteType: undefined,
});

export const addCustomerName = yup.object().shape({
  ...siteConfigSchema.fields,
  customerName: stringRequired('Customer Name'),
});
export const customerSiteType = yup.object().shape({
  ...siteTypeValidation.fields,
  customerName: stringRequired('Customer Name'),
});

export const equipmentTypeSchema = yup.object().shape({
  equipmentType: stringRequired('Equipment Type'),
  equipmentCategory: stringRequired('Equipment Category'),
  remarks: stringRequired('Remarks'),
  sectorName: stringRequired('Sector Name'),
  manufacturer: stringRequired('Manufacture'),
  // units: stringRequired('Units'),
  // status: stringRequired('Status'),
});

export const standardParameterSchema = yup.object().shape({
  stdParameterName: stringRequired('Parameter Name'),
  description: stringRequired('Description'),
  stdUom: stringRequired('Unit of Measurement'),
});

export const ErrorConfigSchema = yup.object().shape({
  equipmentCategory: stringRequired('Equipment Category'),
  equipmentType: stringRequired('Equipment Type'),
  errorMessage: stringRequired('Error Message'),
  messageType: stringRequired('Error Type'),
  priority: stringRequired('Priority'),
  description: stringRequired('Description'),
  approvedBy: stringRequired('Approved By'),
  errorCode: stringRequired('Error Code'),
  // status: stringRequired('Status'),
});

export const equipmentConfigSchema = yup.object().shape({
  // siteName :stringRequired('Site Name'),
  // equipmentType :stringRequired( 'Equipment Type'),
  // equipmentCategory :stringRequired('Equipment Category'),
  primarySerialNo: stringRequired('Primary Serial No'),
  secondarySerialNo: stringRequired('Secondary Serial No'),
  acCapacity: stringRequired('AC Capacity'),
  dcCapacity: stringRequired('DC Capacity'),
  // description: stringRequired('Description'),
  remarks: stringRequired('Remarks'),
  // disConnectrating: stringRequired('Disconnectrating'),
  // disConnectType: stringRequired('Disconnect Type'),
  displayName: stringRequired('Display Name'),
  dailyGenBasedOn: stringRequired('Daily Generation'),
  ftpDefDevicesn: stringRequired('Devicesn'),
  isPrimaryEquipment: stringRequired('Primary Equipment'),
  latitude: stringRequired('Latitude'),
  longitude: stringRequired('Longitude'),
  modBusAddress: stringRequired('Address'),
  noOfComponents: stringRequired('No Of Components'),
  prodFlag: stringRequired('Production Flag'),
  // status: stringRequired('Status'),
  statusMasterId: stringRequired('Status Master'),
  dataLoggerId: stringRequired('Data Logger'),
});

export const CustomizeAlertSchema = yup.array().of(
  yup.object().shape({
    siteName: stringRequired('Site Name '),
    equipmentName: stringRequired('Equipment Name'),
    parameter: stringRequired('Parameter'),
    logic: stringRequired('Logic'),
    value: numberRequired('Value'),
    emailId: emailRequired('Email'),
  })
);

export const ReportSchema = yup.object().shape({
   siteName: stringRequired('Site Name'),
  reportName: stringRequired('Report Name'),
  // emailId: emailRequiredd('Email'),
  // status: stringRequired('Status'),
//  parameterName: stringRequired('parameter'),
range: stringRequired('Time Frequency'),
});
export const AlarmsSchema = yup.object().shape({
  fromdate: stringRequired('from Date'),
  todate: stringRequired('To date'),
});
export const DocumentSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  documentName: stringRequired('Document Name'),
});
export const FormulaSchema = yup.object().shape({
  siteName: stringRequired('Site Name'),
  displayName: stringRequired('Equipment Name'),
  // stdParameterName: stringRequired('Parameter'),
  // formula: stringRequired('Formula'),
  // : emailRequired('Email'),
  // // status: stringRequired('Status'),
  // timePeriod: stringRequired('Time Period'),
  field1: yup.object().shape({
    type: stringRequired('Field 1 type is required'),
    value: stringRequired('Field 1 value is required'),
  }),
  field2: yup.object().shape({
    type: stringRequired('Field 2 type is required'),
    value: stringRequired('Field 2 value is required'),
  }),

});
export const RoleActive = yup.object().shape({
  activityName: stringRequired('Active Name'),
});
export const SectorSchema = yup.object().shape({
  sectorName: stringRequired('Sector Name'),
  // status: stringRequired('Status'),
});

export const StatusSchema = yup.object().shape({
  statusMasterName: stringRequired('Sector Name'),
  // status: stringRequired('Status'),
});
export const StatusConfigSchema = yup.object().shape({
  statusCode: stringRequired('Status Code'),
  derivedStatus: stringRequired('Derived Status'),
  description: stringRequired('Description'),
  statusMessage: stringRequired('Status Message'),
  // status: stringRequired('Status'),
});

export const IndustryTypeSchema = yup.object().shape({
  sectorName: stringRequired('Sector Name'),
  description: stringRequired('Description'),
  // status: stringRequired('Status'),
});

export const equipCategorySchema = yup.object().shape({
  equipmentCategory: stringRequired('Equipment Category'),
  sectorName: stringRequired('Sector Name'),
  // categoryCode: stringRequired('Category Code'),
  categoryDescription: stringRequired('Category Description'),
  // categoryGroup: stringRequired('Category Group'),
  // status: stringRequired('Status'),
});

export const ticketSchema = yup.object().shape({
  // siteName: stringRequired('Site name'),
  // equipmentCategory: stringRequired('Equipment Category'),
  // equipmentType: stringRequired('Equipment Type'),
  // equipmentName: stringRequired('Equipment name'),
  // subject: stringRequired('Subject'),
  // priority: stringRequired('Priority'),
  // description: stringRequired('Description'),
});
export const mapsite = yup.object().shape({
  // siteName: stringRequired('Site Name'),
  // userName: stringRequired('User Name'),
  // status: stringRequired('Status'),
});
export const blockmap = yup.object().shape({
  // siteName: stringRequired('Site Name'),
  customerName: stringRequired('Customer Name'),
  blockName: stringRequired('Plant'),
});
export const Assign = yup.object().shape({
  AssignedTo: stringRequired('Assigned To'),
  ScheduleOn: stringRequired('Schedule On'),
  Remark: stringRequired('Remark'),
});

export const FTPSchema = yup.object().shape({
  siteName: yup.string().required('Site Name is required'),
  fileFrequency: yup.number().required('File frequency is required'),
  mode: yup.string().required('Mode is required'),
  filetype: yup.string().required('File type is required'),
});
export const MasterTypeSchema = yup.object().shape({
  siteName: yup.string().required('Site name is required'),
  dataLoggerName: yup.string().required('DataLogger Name is required'),
});

export const companyListSchmea = yup.object().shape({
  companyName: stringRequired('Company Name'),
  emailId: emailRequired('Email Id'),
  address: stringRequired('Address'),
  state: stringRequired('State'),
  country: stringRequired('Country'),
  contactPerson: stringRequired('Contact Person'),
  companyWebsite: stringRequired('Company Website'),
  // companyLogoPath:stringRequired("Company Logo Path "),
  mobileNumber: numberRequired('Mobile Number'),
  // telePhoneNumber: numberRequired('Telephone Number'),
  // status: stringRequired('Status'),
});
