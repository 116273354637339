import React, { useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import AccessibilityModule from "highcharts/modules/accessibility";
import HighchartsBoost from "highcharts/modules/boost";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  CheckForLight,
  formatNumber,
  getUnit,
  roundedUp,
} from "../../util/common/CommanFunction";
import { IconButton } from "@mui/material";
import FadeLoader from "react-spinners/FadeLoader";
HighchartsBoost(Highcharts);
AccessibilityModule(Highcharts);
Highcharts.time.useUTC = false;
const ApexChart = (props) => {
  const {
    tableData,
    chartName,
    widgetName,
    chartValue,
    range,
    multiChartData,
    name,
    timeValue,
    siteListAPI,
    equipmentListAPI,
    isDashboard,
    fallBack,
    customName,
    axisData,
    loggerMap,
    from,
  } = props;
  console.log(props);

  const [timeData, setTimedata] = React.useState([]);
  const [multiChartDatas, setMultichartData] = React.useState([]);
  const [totalActivePower, setTotalActivePower] = React.useState([]);
  const [paramter, setParamter] = React.useState([]);
  const [deemed, setDeemed] = React.useState([]);
  const [setPoint, setSetPoint] = React.useState([]);
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  const [specificYieldData, setSpecificYield] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    if (typeof Highcharts === "object") {
      HighchartsExporting(Highcharts);
      HighchartsExportData(Highcharts);
    }
  }, []);
  const seriesData = [];
  const key = generateUUID();

  useEffect(() => {
    setLoading(true);
    if (chartName === "Energy Performance" && range === "daily") {
      chartValue?.map(async (data) => {
        const equipmentMap = {};
        let equipments = equipmentListAPI;
        equipments.forEach((equipment) => {
          equipmentMap[equipment.equipmentId] = equipment.displayName;
        });

        const seriesData = [];
        equipments.forEach((equipment) => {
          const series = {
            name: equipment.displayName
              ? equipment.displayName
              : equipment.customernaming,
            data: [],
          };
          const equipmentData = tableData.filter(
            (data) => data.equipmentId === equipment.equipmentId
          );
          equipmentData.forEach((data) => {
            const timeStamp = new Date(data.timeStamp).getTime();
            const value = data.todayEnergy;
            series.data.push([timeStamp, value]);
          });
          seriesData.push(series);
        });
        let finalArray = seriesData?.filter((value) => {
          return value.data.length > 0;
        });

        setMultichartData(finalArray);
        setTimedata(tableData);
      });
    } else if (chartName === "Parameter Comparision" && from === "analytics") {
      let equipments;
      function doesFieldExist(equipmentName, fieldName) {
        const params = loggerMap[equipmentName];

        if (params && params?.includes(fieldName)) {
          return true;
        }
        return false;
      }
      chartValue?.map(async () => {
        equipments = equipmentListAPI;
        const dataFields = chartValue[0].measure_Parameter;
        dataFields.forEach((field) => {
          tableData.forEach((data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return dataValue.equipmentId == data.EquipmentId;
            });
            const timeStampData = data.timeStamp
              ? data.timeStamp
              : data.TimeStamp;
            const timeStamp = new Date(timeStampData).getTime();
            const value = data[field] !== null ? Number(data[field]) : null;
            const Name = equipmentName[0]?.displayName
              ? equipmentName[0]?.displayName
              : equipmentName[0]?.customernaming;

            const seriesName = `${Name} - ${field}`;
            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timeStamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timeStamp, value]],
              };
              if (loggerMap) {
                if (doesFieldExist(Name, field)) {
                  seriesData.push(newSeries);
                }
              } else {
                seriesData.push(newSeries);
              }
              let seriesDataFilter = seriesData.filter((obj) => {
                return !obj.data.every(([_, value]) => isNaN(value));
              });
              // setMultichartData(seriesDataFilter);
              seriesDataFilter.sort((a, b) => a.name.localeCompare(b.name));
              setParamter(seriesDataFilter);
              setMultichartData([]);
              setTimedata(tableData);
            }
          });
        });
      });
    } else if (chartName === "Total Active Power") {
      function convertData(equipmentData) {
        const result = [
          { name: "Irradiation", data: [] },
          { name: "Active Power", data: [] },
        ];

        equipmentData.forEach((item) => {
          const timestamp = new Date(item.timeStamp).getTime();
          result[0].data.push([timestamp, item.irradiation]);
          result[1].data.push([timestamp, item.totalActivePower]);
        });
        return result;
      }
      const result = convertData(tableData);
      setMultichartData(result);
      setTimedata(tableData);
      setTotalActivePower(result);
    } else if (chartName === "Deemed Generation") {
      // revert the condition to this after testing
      //chartName === 'Deemed Generation' && range === 'daily'
      function convertDailyDeemedData(data) {
        const formattedData = [
          { name: "Today Energy", data: [] },
          { name: "Irradiation", data: [] },
          { name: "Deemed Generation", data: [] },
          { name: "Set Point", data: [] },
          { name: "Potential Generation", data: [] },
        ];

        data?.forEach((point) => {
          const timestamp = new Date(point.timeStamp).getTime();
          formattedData[0].data.push([timestamp, point.todayEnergy]);
          formattedData[1].data.push([timestamp, point.irradiation]);
          formattedData[2].data.push([timestamp, point.deemedGeneration]);
          formattedData[3].data.push([timestamp, point.setPoint]);
          formattedData[4].data.push([timestamp, point.potentialGeneration]);
        });

        return formattedData;
      }
      const result = convertDailyDeemedData(tableData);
      setMultichartData(result);
      setTimedata(tableData);
      setDeemed(result);
    } else if (chartName === "Specific Yield") {
      function convertData(equipmentData) {
        const result = {};

        equipmentData.forEach((item) => {
          const offset = 5.5 * 60 * 60 * 1000;
          const timestamp = new Date(item.timeStamp).getTime() - offset;

          if (!result[item.equipmentName]) {
            result[item.equipmentName] = {
              name: item.equipmentName,
              data: [],
            };
          }

          result[item.equipmentName].data.push([timestamp, item.specificYield]);
        });

        return Object.values(result);
      }
      const result = convertData(tableData);

      setMultichartData(result);
      setTimedata(tableData);
      setSpecificYield(result);
    } else if (chartName === "Set Point") {
      function convertSetPointData(data) {
        const formattedData = [{ name: "Set Point", data: [] }];

        data.forEach((point) => {
          const timestamp = new Date(point.timeStamp).getTime();
          formattedData[0].data.push([timestamp, point.avgSetpoint]);
        });
        return formattedData;
      }
      const result = convertSetPointData(tableData);
      setMultichartData(result);
      setTimedata(tableData);
      setSetPoint(result);
    } else if (name === "paramter" || name === "chartData") {
      if (chartName === "Parameter Comparision") {
        setParamter(multiChartData);
        setTimedata(tableData);
      } else if (chartName === "Deemed Generation") {
        setDeemed(multiChartData);
        setTimedata(tableData);
      } else if (name === "chartData") {
        setMultichartData(multiChartData);
        setTimedata(tableData);
      } else {
        setTimedata(tableData);
      }
    } else if (chartName === "Daily Generation" && range === "daily") {
      let seriesData = [];
      const irradiationSeries = {
        name: "irradiation",
        data: [],
      };
      const todayEnergySeries = {
        name: "todayenergy",
        data: [],
      };
      tableData?.forEach((dataPoint) => {
        const timeStamp = new Date(dataPoint.timeStamp).getTime();
        irradiationSeries.data.push([
          timeStamp,
          roundedUp(dataPoint.irradiation),
        ]);
        todayEnergySeries.data.push([
          timeStamp,
          roundedUp(dataPoint.todayEnergy),
        ]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
      setTimedata(tableData);
    }
    setLoading(false);
  }, [tableData, chartName, range]);
  // const dailyGenChartData = useMemo(() => {
  //   if (!timeData) return [];
  //   return timeData.map((item) => ({
  //     x: new Date(item.timeStamp),
  //     y: item.todayEnergy !== null ? parseFloat(item.todayEnergy) : null,
  //   }));
  // }, [timeData]);

  const dailyGenChartData = useMemo(() => {
    if (!timeData) return [];
    return multiChartDatas;
  }, [multiChartDatas]);

  const energyPerformChartData = useMemo(() => {
    if (!timeData) return [];
    return multiChartDatas;
  }, [multiChartDatas]);
  const parameterComparisonData = useMemo(() => {
    if (!timeData) return [];
    return paramter;
  }, [paramter]);
  const isEnergyGenerationVisible =
    chartName === "Energy Performance" || chartName === "Daily Generation";
  const isDailyIrradiationVisible =
    (chartName === "Daily Generation" || chartName === "Energy Performance") &&
    dailyGenChartData.some((series) => series.name.includes("irradiation"));
  const isActivePowerVisible =
    (chartName === "Parameter Comparision" &&
      paramter.some((series) => series.name.includes("ActivePower"))) ||
    chartName === "Total Active Power";
  const isIrradiationVisible =
    (chartName === "Parameter Comparision" &&
      paramter.some((series) => series.name.includes("Irradiation"))) ||
    chartName === "Total Active Power";
  const isVoltageVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("Voltage"));
  const isCurrentVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("Current"));
  const isTemperatureVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("ModuleTemperature"));
  const isPowerfactorVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("PowerFactor"));
  const isIsolationVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("Isolationresistance"));
  const isReactivePowerVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("ReactivePower"));
  const isTodayVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("TodayEnergy"));
  const isTotalVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("TotalEnergy"));
  const isFrequencyVisible =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("Frequency"));
  const isInputPower =
    chartName === "Parameter Comparision" &&
    paramter.some((series) => series.name.includes("InputPower"));
  const isTodayEnergy =
    chartName === "Deemed Generation" &&
    deemed.some((series) => series.name.includes("Today Energy"));
  const isIrradiation =
    chartName === "Deemed Generation" &&
    deemed.some((series) => series.name.includes("Irradiation"));
  const isDeemedGeneration =
    chartName === "Deemed Generation" &&
    deemed.some((series) => series.name.includes("Deemed Generation"));
  const isSetpoint =
    (chartName === "Deemed Generation" &&
      deemed.some((series) => series.name.includes("Set Point"))) ||
    chartName === "Set Point";
  const isPotentialGeneration =
    chartName === "Deemed Generation" &&
    deemed.some((series) => series.name.includes("Potential Generation"));

  const minMaxTimeStamp = useMemo(() => {
    if (!timeData || timeData.length === 0) return {};

    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;

    timeData.forEach((item) => {
      const timeStampData = item.timeStamp ? item.timeStamp : item.TimeStamp;
      const timeStamp = new Date(timeStampData).getTime();
      minTimestamp = Math.min(minTimestamp, timeStamp);
      maxTimestamp = Math.max(maxTimestamp, timeStamp);
    });

    minTimestamp -= 30 * 60 * 1000;

    return { minTimestamp, maxTimestamp };
  }, [timeData]);

  const newDays = useMemo(() => {
    if (!timeData || timeData.length === 0) return [];

    const newDaysArray = [];
    let prevDate = null;

    timeData.forEach((item) => {
      const timeStampData = item.timeStamp ? item.timeStamp : item.TimeStamp;
      const currentDate = new Date(timeStampData);

      // Check if the current date is a new day compared to the previous date
      if (
        !prevDate ||
        currentDate.getDate() !== prevDate.getDate() ||
        currentDate.getMonth() !== prevDate.getMonth() ||
        currentDate.getFullYear() !== prevDate.getFullYear()
      ) {
        newDaysArray.push(currentDate.getTime());
      }

      // Update prevDate for the next iteration
      prevDate = currentDate;
    });

    return newDaysArray;
  }, [timeData]);

  function transformArray(dataArray, isLight, chartName) {
    if (!dataArray) return [];

    const formatName = (name) => {
      return name
        .replace(/(DC|AC)([A-Z])/g, "$1 $2")
        .replace(/([a-z])([A-Z])/g, "$1 $2");
    };

    const excludeArray = [
      "ActivePower",
      "Irradiation",
      "Voltage",
      "Current",
      "ModuleTemperature",
      "PowerFactor",
      "Isolationresistance",
      "ReactivePower",
      "TodayEnergy",
      "TotalEnergy",
      "Frequency",
      "InputPower",
    ];

    return dataArray
      .filter((item) => {
        // Check if stdParameterName contains any string from excludeArray
        return !excludeArray.some((excludeStr) =>
          item.stdParameterName.includes(excludeStr)
        );
      })
      .map((item, index) => {
        const formattedName = formatName(item.stdParameterName);

        return {
          title: {
            text: `${formattedName} (${item.stdUom})`,
            style: {
              color: isLight ? "black" : "white",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: index % 2 !== 0,
          visible: chartName === "Parameter Comparision",
          labels: {
            style: {
              color: isLight ? "black" : "white",
            },
          },
        };
      });
  }

  const isLight = CheckForLight();
  const nameForFile = isDashboard ? customName : widgetName;
  const newSeires = useMemo(() => {
    return transformArray(axisData, isLight, chartName, paramter);
  }, [isLight, axisData, chartName, paramter]);

  const options = {
    chart: {
      zoomType: "x",
      backgroundColor: isLight ? "white" : "#121212",
    },
    boost: {
      useGPUTranslations: true,
      // Chart-level boost when there are more than 1 series in the chart
      seriesThreshold: 1,
      enabled: false,
    },

    accessibility: {
      enabled: true,
    },
    title: {
      text: widgetName !== undefined ? widgetName : "",
      style: {
        textTransform: "captalize",
        color: isLight ? "black" : "white",
        fontWeight: "normal",
      },
    },
    exporting: {
      filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadJPEG",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
            from === "Vanalytics"
              ? {
                  text: "View Table",
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    // Format timestamps in the table
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                      function (match, p1) {
                        const date = new Date(p1);
                        return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          }
                        )} ${date.toLocaleTimeString("en-GB", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}</th>`;
                      }
                    );
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : "",
          ],
        },
      },
      enabled: true,
      // showTable: true,
      csv: {
        filename: widgetName,
        itemDelimiter: ",",
        lineDelimiter: "\n",
        columnHeaderFormatter: function (item, key) {
          if (item.isXAxis) {
            return item.options.title.text || item.name;
          }
          if (key === "y") {
            if (chartName === "Parameter Comparision") {
              const equipName = item.name.split(" - ")[0];
              const paramName = item.name.split(" - ")[1];
              const param = axisData?.find(
                (data) => data.stdParameterName === paramName
              );
              return param
                ? `${equipName} - ${param.stdParameterName} (${param.stdUom})`
                : `${equipName} - ${item.yAxis.options.title.text}`;
            }
            return `${item.name}${getUnit(item.name.replace(/\s+/g, ""))}`;
          }
        },
      },
    },
    xAxis:
      chartName === "Specific Yield"
        ? {
            type: "datetime",
            dateTimeLabelFormats: {
              day: "%d/%m/%Y",
            },
            title: {
              text: "Time (HH:MM)",
            },
            // min: minMaxTimeStamp.minTimestamp,
            // max: minMaxTimeStamp.maxTimestamp,
            // tickInterval: specificYieldData[0]?.data.length,
            labels: {
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "5px" : null,
              },
              formatter: function () {
                return Highcharts.dateFormat("%d/%m/%Y", this.value);
              },
            },
          }
        : {
            type: "datetime",
            dateTimeLabelFormats: {
              // hour: "%H:%M",
              day: "%d/%m/%Y",
            },
            min: minMaxTimeStamp.minTimestamp - 60 * 60 * 1000,
            max: minMaxTimeStamp.maxTimestamp + 60 * 60 * 1000,
            tickInterval:
              timeValue === "Daily" || range === "daily"
                ? 2 * 60 * 60 * 1000
                : 60 * 60 * 1000,
            labels: {
              // rotation: isDashboard ? null : -45,
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "10px" : "14px",
              },
              formatter: function () {
                const localDate = new Date(this.value);

                const options =
                  this.isFirst || newDays.includes(this.value)
                    ? {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                        day: "numeric",
                        month: "short",
                      }
                    : {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      };

                return new Intl.DateTimeFormat("en-US", options).format(
                  localDate
                );
              },
            },
            title: {
              text: "Time (HH:MM)",
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "10px" : "14px",
                fontFamily: "Inter",
              },
            },
          },
    // yAxis: getChartYAxis,
    yAxis: [
      {
        //0
        title: {
          text: "Energy  Generation (kWh)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        visible: isEnergyGenerationVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
      },
      {
        //1
        title: {
          text: "Active Power (kW)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: false,
        visible: isActivePowerVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //2
        min: 0,
        // max: 1000,
        title: {
          text: "Irradiation (Wh/m2)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        visible: isIrradiationVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //3
        min: 0,
        // max: 500,
        title: {
          text: "Voltage (v) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: false,
        visible: isVoltageVisible,
        yAxis: {
          tickInterval: 100,
        },
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //4
        min: 0,
        // max: 50,
        title: {
          text: "Current (I) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: false,
        visible: isCurrentVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //5
        // min: 0,
        // max: 100,
        title: {
          text: "Temperature (°C) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: false,
        visible: isTemperatureVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //6 powerfactor
        min: 0,
        title: {
          text: "Power Factor ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        visible: isPowerfactorVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //7 isolationResistance
        // min: 25000,
        title: {
          text: "Isolation Resistance (ohm) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: false,
        visible: isIsolationVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //8 reactivepower
        // min: 100,
        title: {
          text: "Reactive Power ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        min: 0,
        visible: isReactivePowerVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //9 todayenergy
        title: {
          text: "Today Energy (kWh) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: false,
        visible: isTodayVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //10 totalenergy
        title: {
          text: "Total Energy (MWh) ",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        visible: isTotalVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //11 output frequency
        title: {
          text: "Output Frequency (Hz)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: false,
        min: 0,
        visible: isFrequencyVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        //12

        title: {
          text: "Irradiance (W/m2)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: isDailyIrradiationVisible,
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
      },
      {
        //13

        title: {
          text: " Power (W)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        min: 0,
        visible: isInputPower,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Today Energy (kWh)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: false,
        visible: isTodayEnergy, // You can set this dynamically based on your conditions
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Irradiation",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: isIrradiation, // You can set this dynamically based on your conditions
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Deemed Generation (kWh)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: false,
        visible: isDeemedGeneration, // You can set this dynamically based on your conditions
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Set Point (%)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: isSetpoint, // You can set this dynamically based on your conditions
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Potential Generation (kWh)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: false,
        visible: isPotentialGeneration, // You can set this dynamically based on your conditions
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        title: {
          text: "Specific Yield",
          color: isLight ? "black" : "white",
        },
        opposite: false,
        visible: chartName === "Specific Yield" ? true : false,
        min: 0,
        max: 10,
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      ...newSeires,
    ],
    legend: {
      maxHeight: isDashboard ? 44 : 70,
      itemStyle: {
        color: isLight ? "black" : "white",
        fontSize: isDashboard ? "12px" : "14px",
      },
      itemHoverStyle: {
        color: isLight ? "gray" : "lightgray",
      },
      labelFormatter: function () {
        return `<p>${this.name
          .replace(/(DC|AC)([A-Z])/g, "$1 $2")
          .replace(/([a-z])([A-Z])/g, "$1 $2")}</p>`;
      },
      navigation: {
        enabled: true,
        animation: true,
        arrowSize: 11,
        style: {
          fontWeight: "bold",
        },
      },
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get("rgba"),
            ],
          ],
        },
        marker: {
          radius: 0,

          enabled: false,
        },
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? "white" : "#121212",
      shared: true,
      formatter: function () {
        const options =
          chartName === "Specific Yield"
            ? {
                weekday: "long",
                month: "short",
                day: "numeric",
              }
            : {
                weekday: "long",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: false,
              };

        function getColorIndicator(color) {
          return `<span style="width: 10px; height: 10px; display: inline-block; background-color: ${color}; margin-right: 5px;"></span>`;
        }

        const tooltipHeader = `<div style="font-size: 12px;"><b>${new Intl.DateTimeFormat(
          "en-US",
          options
        ).format(this.x)}</b></div>`;
        let tooltipContent = "";
        let displayedFirstParts = new Set();
        let groupedPoints = {};

        // Group points by the first part if present, otherwise as standalone
        this.points.forEach((point) => {
          if (!Number.isNaN(point.y)) {
            let seriesName = point.series.name;

            if (seriesName.includes(" - ")) {
              const splitName = seriesName.split(" - ");
              const firstPart = splitName[0].trim();
              const remainingParts = splitName
                .slice(1)
                .map((part) => part.trim())
                .join("<br>");

              if (!groupedPoints[firstPart]) {
                groupedPoints[firstPart] = [];
              }

              // Store each displayName (remainingParts) along with its value
              groupedPoints[firstPart].push({
                color: point.series.color,
                displayName: remainingParts,
                value: point.y,
              });
            } else {
              // Handle cases without "inv" or only parameters
              if (!groupedPoints["ungrouped"]) {
                groupedPoints["ungrouped"] = [];
              }

              groupedPoints["ungrouped"].push({
                color: point.series.color,
                displayName: seriesName, // Directly use seriesName as displayName
                value: point.y,
              });
            }
          }
        });

        // Now construct the tooltip content from grouped and ungrouped points
        Object.keys(groupedPoints).forEach((firstPart) => {
          if (firstPart !== "ungrouped") {
            tooltipContent += `<div style="font-size: 12px; padding-top: 8px; padding-bottom: 1px;"><b>${firstPart}:</b></div>`;
          }
          groupedPoints[firstPart].forEach((item) => {
            tooltipContent += `<div style="font-size: 14px;padding-top: 2px; padding-bottom: 2px; padding-right: 10px">
              <span>${getColorIndicator(item.color)}${
              item.displayName
            }</span> <span>:</span> <span>${formatNumber(item.value)}</span>
            </div>`;
          });
        });

        // Generate a unique ID for the tooltip container using the chart index or a unique key
        const uniqueId = this.series.chart.index || Highcharts.uniqueKey();
        const tooltipContainerId = `custom-tooltip-container-${uniqueId}`;

        // Disable document overflow scrolling initially
        document.body.style.overflow = "hidden";

        const chartElement = this.series.chart.renderTo; // The actual chart container

        if (chartElement) {
          // Attach a mouseout event listener scoped to this chart
          if (!chartElement.hasMouseOutListener) {
            chartElement.hasMouseOutListener = true;
            chartElement.addEventListener("mouseout", (event) => {
              if (!chartElement.contains(event.relatedTarget)) {
                // Enable document overflow scrolling
                document.body.style.overflow = "";
              }
            });
          }
        }

        // Ensure only one scroll listener is attached to this chart's tooltip
        if (!chartElement.hasScrollListener) {
          chartElement.hasScrollListener = true;
          chartElement.addEventListener("wheel", (event) => {
            const tooltipContainer =
              document.getElementById(tooltipContainerId);
            if (tooltipContainer) {
              tooltipContainer.scrollTop += event.deltaY;
              event.preventDefault();
            }
          });
        }

        return `<div id="${tooltipContainerId}" style="max-height: 180px; min-width: 180px; overflow-y: scroll; pointer-events: auto;">${
          tooltipHeader + tooltipContent
        }</div>`;
      },
      style: {
        pointerEvents: "auto",
        color: CheckForLight() ? "black" : "white",
      },
    },

    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        y: 0,
      },
    },
    credits: {
      enabled: false,
    },
    //new chart colors
    colors: [
      "#1E90FF",
      "#FF5733",
      "#32CD32",
      "#FFD700",
      "#FF1493",
      "#8A2BE2",
      "#00CED1",
      "#FF4500",
      "#7FFF00",
      "#DC143C",
      "#6495ED",
      "#FF8C00",
      "#00FA9A",
      "#8B4513",
      "#483D8B",
      "#00BFFF",
      "#FF6347",
      "#DA70D6",
      "#008080",
      "#ADFF2F",
    ],
    series:
      chartName === "Energy Performance"
        ? energyPerformChartData?.map((series) => {
            series.yAxis = 0;
            series.boostThreshold = 1;
            return series;
          })
        : chartName === "Total Active Power"
        ? totalActivePower?.map((series) => {
            if (series.name.includes("Active Power")) {
              series.yAxis = 1;
            } else {
              series.yAxis = 2;
            }
            return series;
          })
        : chartName === "Parameter Comparision"
        ? paramter !== undefined
          ? paramter.map((series) => {
              if (series.name.includes("Irradiation")) {
                series.yAxis = 2;
                return series;
              } else if (series.name.includes("Voltage")) {
                series.yAxis = 3;
                return series;
              } else if (series.name.includes("Current")) {
                series.yAxis = 4;
                return series;
              } else if (series.name.includes("Temperature")) {
                series.yAxis = 5;
                return series;
              } else if (series.name.includes("PowerFactor")) {
                series.yAxis = 6;
                return series;
              } else if (series.name.includes("Isolationresistance")) {
                series.yAxis = 7;
                return series;
              } else if (series.name.includes("ReactivePower")) {
                series.yAxis = 8;
                return series;
              } else if (series.name.includes("TodayEnergy")) {
                series.yAxis = 9;
                return series;
              } else if (series.name.includes("TotalEnergy")) {
                series.yAxis = 10;
                return series;
              } else if (series.name.includes("Frequency")) {
                series.yAxis = 11;
                return series;
              } else if (series.name.includes("InputPower")) {
                series.yAxis = 13;
                return series;
              } else if (series.name.includes("ActivePower")) {
                series.yAxis = 1;
                return series;
              } else if (Array.isArray(newSeires)) {
                newSeires.forEach((item, index) => {
                  const seriesName = series?.name?.split("-")[2]
                    ? series?.name?.split("-")[2]
                    : series?.name?.split("-")[1];
                  // console.log(
                  //   seriesName.trim(),
                  //   seriesName
                  //     .toLowerCase()
                  //     .trim()
                  //     .includes(
                  //       item.title.text
                  //         .split("(")[0]
                  //         .split(" ")
                  //         .map(
                  //           (word) =>
                  //             word.charAt(0).toUpperCase() +
                  //             word.slice(1).toLowerCase()
                  //         )
                  //         .join("")
                  //         .toLowerCase()
                  //         .trim()
                  //     ),
                  //   item.title.text
                  //     .split("(")[0]
                  //     .split(" ")
                  //     .map(
                  //       (word) =>
                  //         word.charAt(0).toUpperCase() +
                  //         word.slice(1).toLowerCase()
                  //     )
                  //     .join("")
                  // );
                  if (
                    item?.title?.text &&
                    seriesName
                      ?.toLowerCase()
                      ?.trim()
                      ?.includes(
                        item.title.text
                          ?.split("(")[0]
                          ?.split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join("")
                          .toLowerCase()
                          .trim()
                      )
                  ) {
                    series.yAxis = 20 + index;
                  }
                });
                return series;
              }
              return series;
            })
          : seriesData
        : chartName === "Daily Generation"
        ? dailyGenChartData?.map((series) => {
            if (
              series.name.includes("todayenergy") ||
              series.name.includes("Today Energy")
            ) {
              series.name = "Today Energy";
              series.yAxis = 0;
            } else if (
              series.name.includes("irradiation") ||
              series.name.includes("Irradiation")
            ) {
              series.name = "Irradiance";
              series.boostThreshold = 1;
              series.yAxis = 12;
            }
            return series;
          })
        : chartName === "Deemed Generation"
        ? deemed !== undefined
          ? deemed.map((series) => {
              if (series.name.includes("Today Energy")) {
                series.yAxis = 14;
                return series;
              } else if (series.name.includes("Irradiation")) {
                series.yAxis = 15;
                return series;
              } else if (series.name.includes("Deemed Generation")) {
                series.yAxis = 16;
                return series;
              } else if (series.name.includes("Set Point")) {
                series.yAxis = 17;
                return series;
              } else if (series.name.includes("Potential Generation")) {
                series.yAxis = 18;
                return series;
              } else {
                series.yAxis = 1; // Default value if no match is found
                series.boostThreshold = 1; // Optional, if needed
                return series;
              }
            })
          : seriesData
        : chartName === "Set Point"
        ? setPoint?.map((series) => {
            series.yAxis = 17;
            return series;
          })
        : chartName === "Specific Yield"
        ? specificYieldData.map((series) => {
            series.yAxis = 19;
            return series;
          })
        : seriesData,
  };
  console.log(options, "options", setPoint);

  function generateUUID() {
    // Generate random numbers and place them in the correct positions
    const template = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    return template.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0; // Generate a random number between 0 and 15
      const v = c === "x" ? r : (r & 0x3) | 0x8; // Use 'r' for 'x' and a modified version for 'y'
      return v.toString(16); // Convert to hexadecimal
    });
  }

  return (
    <div
      style={{
        width: "100%",
        height: isDashboard
          ? "300px"
          : from === "Vanalytics"
          ? "500px"
          : "fit-content",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "300px",
            alignItems: "center",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : tableData?.length > 0 ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{widgetName.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <div id={`${key}`}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              type="area"
              containerProps={{
                style: {
                  width: "100%",
                  height: isDashboard
                    ? "300px"
                    : from === "Vanalytics"
                    ? "500px"
                    : null,
                },
              }}
              sx={{
                ".highcharts-xaxis-labels, .highcharts-yaxis-labels, .highcharts-scale":
                  {
                    visibility: "visible !important",
                  },
                paddingBottom: 0,
              }}
            />
          </div>
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
export default ApexChart;
