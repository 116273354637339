import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  Divider,
  Stack,
  Typography,
  Menu,
  MenuItem,
  TableContainer,
  TableCell,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";
import "./Style.css";
import { fetchSpecificYieldEira, getPeakPower } from "../../Api/DataAnalystApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CheckForLight,
  roundedUp,
  sortedDataByMonth,
  transformDataForSpecificYield,
} from "../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import CombinedChart from "../Charts/CombainedChart";
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from "../../Api/CommonApi";
import { fetchEquipmentlistTable } from "../../Api/SiteListApi";

const optionTime = [
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" }, // { label: 'This Month', value: 'This Month' },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
];

export const EnergyPerfTable = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("daily");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("inverter"); // Default sort column (can be any column like 'inverter', 'power', etc.)

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortData = (data, orderBy, orderDirection) => {
    return data.sort((a, b) => {
      if (orderDirection === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  };

  useEffect(() => {
    setSite(siteName);
    setChartName("Specific Yield");
    setSelectedOption("Specific Yield");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  function calculateCumulativeData(data) {
    const groupedData = {};

    // Group data by equipmentId
    data.forEach((item) => {
      const {
        equipmentid,
        todayEnergy,
        specificYield,
        timeStamp,
        dcCapacity,
        equipmentName,
      } = item;

      // Initialize group if it doesn't exist
      if (!groupedData[equipmentid]) {
        groupedData[equipmentid] = {
          totalEnergy: 0,
          totalYield: 0,
          startDate: timeStamp,
          endDate: timeStamp,
          count: 0,
        };
      }

      // Update cumulative values
      groupedData[equipmentid].totalEnergy += todayEnergy;
      groupedData[equipmentid].totalYield += specificYield;
      groupedData[equipmentid].endDate = timeStamp;
      groupedData[equipmentid].dcCapacity = dcCapacity;
      groupedData[equipmentid].equipmentName = equipmentName;
      groupedData[equipmentid].count += 1;
    });

    // Format the output
    const result = Object.keys(groupedData).map((equipmentid) => {
      const group = groupedData[equipmentid];
      const dayDiff =
        (new Date(group.endDate) - new Date(group.startDate)) /
          (1000 * 60 * 60 * 24) +
        1;

      return {
        todayEnergy: roundedUp(group.totalEnergy),
        specificYield: roundedUp(group.totalYield / dayDiff),
        equipmentid: Number(equipmentid),
        dcCapacity: group.dcCapacity,
        equipmentName: group.equipmentName,
        timeStamp: `sum of ${dayDiff} days`,
      };
    });

    return result;
  }

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    setRange("Daily");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Specific Yield",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchSpecificYieldEira(dataModel);
    responseData = sortedDataByMonth(responseData);
    const peakPower = await fetchEquipmentlistTable(`${dataModel.siteId}/0`);
    function transformToChartData(initialData) {
      const equipmentMap = new Map();
      equipDetails.forEach((item) => {
        equipmentMap.set(item.equipmentId, item);
      });

      // Transform the initialData array
      const transformedData = initialData.map((item) => {
        const equipment = equipmentMap.get(item.equipmentId);
        // console.log(equipment, equipmentMap, "equipment", item.equipmentId);

        return {
          todayEnergy: roundedUp(item.todayEnergy),
          timeStamp: item.timestamp || item.timeStamp,
          specificYield: roundedUp(item.specificYield),
          dcCapacity: equipment.dcCapacity,
          peakPower: peakPower.find(
            (equip) => equip.equipmentId === item.equipmentId
          ).peakPower,
          equipmentid: item.equipmentId,
          equipmentName: equipment ? equipment.displayName : "Unknown",
        };
      });

      return transformedData;
    }
    let result = transformToChartData(responseData);
    console.log("result", result);
    if (range.toLowerCase() !== "daily") {
      result = calculateCumulativeData(result);
      console.log("result", result);
    }
    setLoading(false);
    setTableData(result);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range: "daily",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await fetchSpecificYieldEira(data);
    responseData = sortedDataByMonth(responseData);
    const today =
      data.fromDate === data.toDate &&
      data.toDate === moment().format("YYYY-MM-DD");
    let peakPower;
    if (today) {
      peakPower = await fetchEquipmentlistTable(`${data.siteId}/0`);
    } else {
      peakPower = await getPeakPower({
        fromDate: data.fromDate,
        toDate: data.toDate,
        siteId: data.siteId,
      });
    }
    console.log(peakPower, "peakPower");

    function transformToChartData(initialData) {
      const equipmentMap = new Map();
      equipm.forEach((item) => {
        equipmentMap.set(item.equipmentId, item);
      });

      // Transform the initialData array
      const transformedData = initialData.map((item) => {
        const equipment = equipmentMap.get(item.equipmentId);
        return {
          todayEnergy: roundedUp(item.todayEnergy),
          timeStamp: item.timestamp || item.timeStamp,
          specificYield: roundedUp(item.specificYield),
          dcCapacity: equipment.dcCapacity,
          peakPower: peakPower?.find(
            (equip) => equip.equipmentId === item.equipmentId
          )?.peakPower,
          equipmentid: item.equipmentId,
          equipmentName: equipment ? equipment.displayName : "Unknown",
        };
      });

      return transformedData;
    }
    let result = transformToChartData(responseData);

    if (data.fromDate !== data.toDate) {
      result = calculateCumulativeData(result);
      console.log("result", result);
    }
    setLoading(false);
    setTableData(result);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }
    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const BarCell = ({ value, max, color }) => {
    const percentage = (value / max) * 100;
    return (
      <div
        style={{
          width: "70px",
          backgroundColor: "#e0e0e0",
          borderRadius: "5px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            backgroundColor: color,
            height: "10px",
            borderRadius: "5px",
          }}
        />
      </div>
    );
  };

  const findMaxAndRound = (data, key) => {
    // Step 1: Find the maximum value for the given key
    const maxValue = Math.max(...data.map((item) => item[key]));

    let roundedMax, additionalValue;

    // handle differently for 3 digit and below 3 digit
    if (maxValue > 99) {
      // Step 2: Round up the max value to the nearest multiple of 10
      roundedMax = Math.ceil(maxValue / 10) * 10;

      // Step 3: Calculate 20% of the rounded value
      additionalValue = roundedMax * 0.2;
    } else {
      // Step 2: Round up the max value to the nearest multiple of 5
      roundedMax = Math.ceil(maxValue / 5) * 5;

      // Step 3: Calculate 15% of the rounded value
      additionalValue = roundedMax * 0.15;
    }

    // Step 4: Add the additional value to the rounded max value
    const result = roundedMax + additionalValue;

    return Math.round(result);
  };

  const maxToday = useMemo(
    () => findMaxAndRound(tableData, "todayEnergy"),
    [tableData]
  );

  const maxSpecificYield = useMemo(
    () => findMaxAndRound(tableData, "specificYield"),
    [tableData]
  );

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "37vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "37vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "37vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "37vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem", fontWeight: 400 }}>
                Inverter Performance
              </Typography>
            </div>
            <div style={{ visibility: "visible" }}>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range == "Daily"
                      ? `${moment(fromDate).format("DD/MM/YYYY")}`
                      : range == "Yearly"
                      ? `${moment(fromDate).format("YYYY")}`
                      : `${moment(fromDate).format("DD/MM")} - ${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime
                    .filter((option) => option.value !== "Yearly")
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) =>
                          handleMenuItemClick(option.value, "Time")
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "90%",
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <>
            <TableContainer
              component={Paper}
              elevation={1}
              sx={{ width: "100%", height: "300px", overflowX: "hidden" }}
            >
              <Table
                size="small"
                aria-label="Inverter Performance Table"
                stickyHeader
              >
                <TableHead>
                  <TableRow style={{ fontSize: "10px" }}>
                    <TableCell
                      sx={{
                        background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                      }}
                    >
                      <TableSortLabel
                        sx={{ fontSize: "12px" }}
                        active={orderBy === "equipmentName"} // The current sorting column
                        direction={orderDirection}
                        onClick={() => handleSortRequest("equipmentName")} // Sort by inverter name
                      >
                        Inverter
                      </TableSortLabel>
                    </TableCell>

                    {/* <TableCell
                      align="center"
                      style={{
                        width: "50px",
                        background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        zIndex: 2,
                      }}
                    >
                      <TableSortLabel
                        sx={{ fontSize: "12px" }}
                        active={orderBy === "dcCapacity"}
                        direction={orderDirection}
                        onClick={() => handleSortRequest("dcCapacity")} // Sort by power
                      >
                        Capacity (kWp)
                      </TableSortLabel>
                    </TableCell> */}

                    <TableCell
                      align="center"
                      style={{
                        width: "50px",
                        background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        zIndex: 2,
                      }}
                    >
                      <TableSortLabel
                        sx={{ fontSize: "12px" }}
                        active={orderBy === "peakPower"}
                        direction={orderDirection}
                        onClick={() => handleSortRequest("peakPower")} // Sort by power
                      >
                        Peak Power (kW)
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      sx={{
                        background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                      }}
                    >
                      <TableSortLabel
                        sx={{ fontSize: "12px" }}
                        active={orderBy === "todayEnergy"}
                        direction={orderDirection}
                        onClick={() => handleSortRequest("todayEnergy")} // Sort by today's energy
                      >
                        Production (kWh)
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      sx={{
                        background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                      }}
                    >
                      <TableSortLabel
                        sx={{ fontSize: "12px" }}
                        active={orderBy === "specificYield"}
                        direction={orderDirection}
                        onClick={() => handleSortRequest("specificYield")} // Sort by specific yield
                      >
                        Inverter Yield
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortData(tableData, orderBy, orderDirection).map((row) => (
                    <TableRow key={row.equipmentid}>
                      <TableCell>{row.equipmentName}</TableCell>
                      {/* <TableCell>{row.dcCapacity}</TableCell> */}
                      <TableCell>{roundedUp(row.peakPower) || "-"}</TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "40px" }}>{row.todayEnergy}</div>
                          <BarCell
                            value={row.todayEnergy}
                            max={maxToday}
                            color="lightskyblue"
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "40px" }}>
                            {row.specificYield}
                          </div>
                          <BarCell
                            value={row.specificYield}
                            max={maxSpecificYield}
                            color="navy"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
};
