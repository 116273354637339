import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  IconButton,
  Backdrop,
  Divider,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import * as XLSX from "xlsx";
import { FadeLoader } from "react-spinners";
// ========Modal================
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
/*------------api call--------------*/
import {
  postExpecteddataSave,
  putExpecteddateUpdate,
  fetchExpectedData,
} from "../../Api/ExpectedDateApi";
import { fetchSiteDetails } from "../../Api/ReportApi";
import { getMonthList } from "../../Api/DataLoggerAPi";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { Form, Formik } from "formik";
import { ErrorConfigSchema } from "../util/ValidateSchema";
import { Statusdropdown } from "../../Api/CommonApi";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import errorMsg from "../util/errorMessage.json";
import { CheckForLight } from "../util/common/CommanFunction";
export default function Expecteddata({ errorConfiguration }) {
  /*------------state-----------------*/
  const [expectedata, setExpectData] = useState([]);
  console.log(expectedata);

  const [existingMonths, setExistingMonths] = useState([]);
  const [activeFlag, setactiveFlag] = useState("");
  const [statusDrop, setStatusDrop] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [sitestatus, setSitestatus] = useState("");
  const [siteExpect, setsiteExpect] = useState("");
  const [replace, setReplace] = useState(null);
  const [siteName, setSiteNameList] = useState([]);
  const [category, setCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const [errorVal, setErrorVal] = useState(null);
  const [siteTab, setSiteTab] = React.useState("");
  console.log(siteName);
  const initalState = {
    siteName: "",
    expectedGeneration: "",
    month: "",
    globHor: "",
    diffHor: "",
    tAmb: "",
    globInc: "",
    globEff: "",
    eGrid: "",
    pr: "",
    expectedGenerationMonth: "",
    status: "",
    lastUpdatedBy: "",
    equipmentTypeId: "",
    customerName: [],
    categoryId: "",
    equipmentType: "",
    AveragePr: "",
    egrid: "",
    globE: "",
    globI: "",
    AmbTemp: "",
    glob: "",
    diff: "",
  };

  // const [expectFields, setExpectFields] = useState([initalState]);
  const [expectFields, setExpectFields] = useState([
    {
      id: "",
      month: "",
      currentYear: "",
      averagePr: "",
      ambTemp: "",
      eGrid: "",
      globHor: "",
      diffHor: "",
      globInc: "",
      globEff: "",
    },
  ]);

  const [stdField, setstdField] = useState(initalState);
  console.log(stdField?.siteName);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const [expectedGeneration, setExpectedGeneration] = useState([]);
  const [expectedMonth, setExpectedMonth] = useState([]);
  const [editFilteredData, setEditFilteredData] = useState([]);
  const [autocompleteDefaultValues, setAutocompleteDefaultValues] =
    useState(expectedMonth);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  console.log(expectedata);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  useEffect(() => {
    getMonth();
  }, []);
  const getMonth = async () => {
    const getmonthdata = await getMonthList();
    setExpectedMonth(getmonthdata);
  };
  const handleMonth = (newMonth, event, index) => {
    if (newMonth) {
      const monthName = newMonth.monthName.toUpperCase();
      
      setExpectFields((prevFields) => {
        const newFields = [...prevFields];
        newFields[index] = {
          ...newFields[index],
          month: monthName
        };
        return newFields;
      });

      setSelectedMonths((prevSelectedMonths) => {
        const newSelectedMonths = [...prevSelectedMonths];
        // Remove old month if it exists
        const oldMonth = expectFields[index].month;
        if (oldMonth) {
          const oldIndex = newSelectedMonths.indexOf(oldMonth.toUpperCase());
          if (oldIndex > -1) {
            newSelectedMonths.splice(oldIndex, 1);
          }
        }
        // Add new month
        newSelectedMonths.push(monthName);
        return newSelectedMonths;
      });
    }
  };
  
  const handleChangeData = (event, index) => {
    const { name, value } = event.target;
    const numericFields = [
      "month",
      "globHor",
      "diffHor",
      "tAmb",
      "globInc",
      "globEff",
      "eGrid",
      "pr"
    ];

    if (numericFields.includes(name)) {
      // Allow only numeric and decimal values
      const regex = /^-?\d*\.?\d*$/;
      if (regex.test(value) || value === "") {
        setExpectFields((prevFields) => {
          const newFields = [...prevFields];
          newFields[index] = { ...newFields[index], [name]: value };
          return newFields;
        });
      }
    } else {
      // For other fields, update as normal
      setExpectFields((prevFields) => {
        const newFields = [...prevFields];
        newFields[index] = { ...newFields[index], [name]: value };
        return newFields;
      });
    }
  };

  const typoStyle = { fontSize: "14px", marginBottom: "4px" };
  const fieldStyle = { width: "100%", minWidth: "120px" };
  const requiredHeader = [
    "Site Name",
    "Year",
    "Month",
    "Performance Ratio",
    "Ambient Temperature",
    "Energy Grid",
    "Global Horizontal",
    "Horizontal Diffuse",
    "Global Incident",
    "Effective Global",
  ];
  const isOEM = sessionStorage.getItem("userTypeId") == 1;
  /*----------------------------Fields-------------------------------*/
  const [clickCount, setClickCount] = useState(1);
  const maxFields = 12;
  const handleAddButtonClick = () => {
    setExpectFields((prevFields) => {
      if (prevFields.length < 12) {
        return [
          ...prevFields,
          {
            // month: "",
            // averagepr: "",
            // ambtemp: "",
            // egid: "",
            // globhor: "",
            // diffhor: "",
            // globinc: "",
            // globeff: "",
            month: "",
            globHor: "",
            diffHor: "",
            tAmb: "",
            globInc: "",
            globEff: "",
            eGrid: "",
            pr: "",
          },
        ];
      }
      return prevFields;
    });
  };

  const handleRemoveButtonClick = (index) => {
    setExpectFields((prevFields) => {
      if (prevFields.length > 1) {
        const removedMonth = prevFields[index].month;
        
        // Remove month from selectedMonths
        if (removedMonth) {
          setSelectedMonths((prevSelectedMonths) => 
            prevSelectedMonths.filter(month => month !== removedMonth.toUpperCase())
          );
        }
  
        return prevFields.filter((_, i) => i !== index);
      }
      return prevFields;
    });
  };

  const handleYearChange = (value, fieldIndex) => {
    setSelectedYear(value);
    
    setExpectFields(prevFields => 
      prevFields.map(field => ({
        ...field,
        currentYear: value
      }))
    );
  };
  const currentYear = new Date().getFullYear(); // Get the current year
  // Options for Year dropdown from 2010 to 2024
  const yearOptions = Array.from({ length: 2031 - 2010 }, (_, i) => 2010 + i);

  const headerMapping = {
    "Performance Ratio": "pr",
    "Ambient Temperature": "tAmb",
    "Energy Grid (kWh)": "eGrid",
    "Global Horizontal(kWh/m2)": "globHor",
    "Horizontal Diffuse(kWh/m2)": "diffHor",
    "Global Incident(kWh/m2)": "globInc",
    "Effective Global(kWh/m2)": "globEff",
  };

  /*------------------------- Api --------------------------*/
  useEffect(() => {
    fetchStatus();
    fetchSitenameList();
    setSitestatus();
    setSiteNameList();
    setExpectedMonth();
    setExpectedGeneration();
  }, []);
  useEffect(() => {
    if (siteExpect !== "") {
      let SiteTypeIdsFilter = siteName?.filter((data) => {
        return data.siteName === siteExpect;
      });
      let siteExpectId = SiteTypeIdsFilter[0]?.siteId;
      getExpectData(siteExpectId, 2024);
    }
  }, [siteExpect, siteName]);
  console.log(siteExpect);

  /*-------------------------Table get call -------------------------------*/
  const getExpectData = async (Id, year) => {
    setloading(true);
    try {
      let id = Id;
      if (Array.isArray(Id)) {
        id = Id[0]?.siteId;
      }
      console.log(siteName);

      let siteIdFilter = siteName?.filter((data) => {
        console.log(data);
        return siteName.siteId === id;
      });
      console.log(siteIdFilter);

      const data = await fetchExpectedData(id, year);
      console.log(siteIdFilter);
      if (Array.isArray(data)) {
        console.log(data);
        setExpectData(data);
        const months = data.map((item) => item.month);
        setExistingMonths(months); // Store the existing months
      }
      setloading(false);
    } catch (e) {
      console.error(e);
      setloading(false); // Ensure loading is stopped in case of error
    }
  };

  /*----------------------- handle save --------------------*/
  const handleSave = async () => {
    let siteIdFilter = siteName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });

    const dataToSave = expectFields.map((field) => ({
      siteId: siteIdFilter[0]?.siteId,
      // createdBy: Number(sessionStorage.getItem("id")),
      month: field.month.toUpperCase(),
      // globHor: field.globhor,
      // diffHor: field.diffhor,
      // tAmb: field.ambtemp,
      // globInc: field.globinc,
      // globEff: field.globeff,
      // eGrid: field.egid,
      // pr: field.averagepr,
      // currentYear: field.currentYear || currentYear,
      currentYear: selectedYear, // Use the selectedYear state here
      globHor: field.globHor || "", // Match backend key
      diffHor: field.diffHor || "", // Match backend key
      tAmb: field.tAmb || "", // Match backend key
      globInc: field.globInc || "", // Match backend key
      globEff: field.globEff || "", // Match backend key
      eGrid: field.eGrid || "", // Match backend key
      pr: field.pr || "", // Match backend key
    }));

    try {
      setToBackend(true);
      const responseData = await postExpecteddataSave(dataToSave);
      getExpectData(siteIdFilter[0]?.siteId, selectedYear);
      setSnack({
        open: true,
        severity: "success",
        message: responseData.statusDescription,
      });
      handleClose();
      setToBackend(false);
      setOpen(false);
      setExistingMonths((prevMonths) => [
        ...prevMonths,
        ...dataToSave.map((d) => d.month),
      ]);
      return 1;
    } catch (e) {
      setSnack({
        open: true,
        severity: "error",
        message: "Failed to save data",
      });
      console.error("Error:", e);
      setToBackend(false);
      return 0;
    }
  };
  /*----------------------- handle Update --------------------*/

  const handleUpdate = async (values) => {
    console.log(values);

    let siteIdFilter = siteName?.filter((data) => {
      return data.siteName === stdField.siteName;
    });

    const updatedData = values.map((value) => ({
      createdBy: Number(sessionStorage.getItem("id")),
      id: value.id,
      siteId: siteIdFilter[0]?.siteId,
      status: 1,
      month: value.month,
      currentYear: value.currentYear,
      globHor: value.globHor || "",
      diffHor: value.diffHor || "",
      tAmb: value.tAmb || "",
      globInc: value.globInc || "",
      globEff: value.globEff || "",
      eGrid: value.eGrid || "",
      pr: value.pr || "",
    }));

    try {
      setToBackend(true);
      const responseData = await Promise.all(
        updatedData.map((data) => putExpecteddateUpdate(data, data.id))
      );

      setExpectData((prevData) =>
        prevData.map((item) => {
          const updatedItem = responseData.find(
            (updated) => updated.id === item.id
          );
          return updatedItem ? { ...item, ...updatedItem } : item;
        })
      );

      setSnack({
        open: true,
        severity: "success",
        message: "Data updated successfully",
      });
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      setSnack({
        open: true,
        severity: "error",
        message: "Failed to update data",
      });
      console.error("Error:", e);
      setToBackend(false);
      return 0;
    }
  };

  const fetchSitenameList = async () => {
    const data = await fetchSiteDetails();
    if (Array.isArray(data)) {
      setSiteNameList(data);
    } else if (typeof data === "object" && data !== null) {
      setSiteNameList([data]);
    }
    getExpectData(data, 2024);
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        console.log(headers, "headers");
        const extraHeaders = headers.filter(
          (header) => !requiredHeader.includes(header)
        );
        const hasExtraHeaders = extraHeaders.length > 0;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const jsonDataTable = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const updatedJsonData = jsonData.map((row) => {
          requiredHeader.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = ""; // Add missing header with empty string value
            }
          });
          return row;
        });
        if (!headersPresented || jsonData.length === 0) {
          console.log("headersPresented", !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
              ? "Excel couldn't be empty"
              : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleExcelUpload = async () => {
    if (selectedFileData) {
      try {
        setToBackend(true);
        const processedData = selectedFileData.map((item) => {
          const siteObject = siteName?.find(
            (site) => site.siteName === item["Site Name"]
          );

          return {
            siteId: siteObject ? siteObject.siteId : null,
            siteName: item["Site Name"],
            month: item["Month"].toUpperCase(),
            // createdBy: Number(sessionStorage.getItem("id")),
            currentYear: parseFloat(item["Year"]),
            pr: parseFloat(item["Performance Ratio"]),
            tAmb: parseFloat(item["Ambient Temperature"]),
            eGrid: parseFloat(item["Energy Grid"]),
            globHor: parseFloat(item["Global Horizontal"]),
            diffHor: parseFloat(item["Horizontal Diffuse"]),
            globInc: parseFloat(item["Global Incident"]),
            globEff: parseFloat(item["Effective Global"]),
            status: 1,
          };
        });

        const validData = processedData.filter((item) => item.siteId !== null);

        if (validData.length === 0) {
          throw new Error(
            "No valid data to save. Please check the site names in your Excel file."
          );
        }

        // Check for duplicate months already saved for the same site
        const duplicateMonth = validData.some((item) => {
          return expectedata.some(
            (existing) =>
              existing.siteId === item.siteId && existing.month === item.month
          );
        });

        if (duplicateMonth) {
          throw new Error(
            "The month is already configured for one or more sites."
          );
        }

        const responseData = await postExpecteddataSave(validData);
        if (responseData.status === 200) {
          // Close the modal
          handleClose();

          // Update the table data
          const updatedData = [...expectedata, ...validData];
          setExpectData(updatedData);

          // If a site is currently selected, filter the data for that site
          if (selectedSite) {
            const filteredData = updatedData.filter(
              (item) => item.siteId === selectedSite.siteId
            );
            setExpectData(filteredData);
          }

          setSnack({
            open: true,
            severity: "success",
            message: "Data uploaded successfully",
          });
        }
      } catch (e) {
        console.error("Error uploading data:", e);
        setSnack({
          open: true,
          severity: "error",
          message:
            e.message ||
            "Failed to upload data. Please check your Excel file and try again.",
        });
      } finally {
        setToBackend(false);
      }
    }
  };
  const handleSite = (newValue) => {
    console.log(newValue);

    if (edit) {
      setstdField((prevState) => ({
        ...prevState,
        siteName: newValue,
      }));
    }
  };

  /*---------------------------------- New Error Call -----------------------*/

  const handleCrate = () => {
    setOpen(true);
    setEdit(false); // Ensure we're not in edit mode
    seteditcall("");
    setstdField((prevState) => ({
      ...prevState,
      siteName: selectedSite ? selectedSite.siteName : "",
    }));
  };
  const handleyear = async (val) => {
    setExpectData([]);
    console.log(site);
    let siteIdFilter = siteName?.filter((data) => {
      console.log(data);
      return data.siteName === site;
    });
    const idd = siteIdFilter[0]?.siteId;
    console.log(siteIdFilter);

    console.log(val);
    const datavalue = await fetchExpectedData(idd, val);
    setExpectData(datavalue);
  };
  const handleExpect = (value, event) => {
    if (value === null) {
      setsiteExpect("");
    } else {
      setsiteExpect(value);
    }
  };
  console.log(siteExpect);

  const handleClose = () => {
    setSelectedMonths([]);
    setSelectedFileData(null);
    setstdField(initalState);
    seteditcall("");
    setCategory("");
    setactiveFlag("");
    setModalvalue(true);
    setEdit(false);
    setOpen(false);
    setReplace(null);
    setClickCount(1);
    setExpectFields([initalState]);
  };
  useEffect(() => {
    if (edit && editFilteredData.length > 0) {
      const months = editFilteredData.map(data => data.month.toUpperCase());
      setSelectedMonths(months);
    }
  }, [edit, editFilteredData]);

  const handleEditOpen = async (errorId) => {
    let filterederrorId = Object.keys(initalState).reduce((acc, key) => {
      if (errorId.hasOwnProperty(key)) {
        acc[key] = errorId[key];
      }
      return acc;
    }, {});
    seteditcall(errorId);

    // Find the correct site object from siteName array
    const siteObject = siteName?.find((site) => site.siteId === errorId.siteId);

    // Set the site name and year
    setstdField({
      ...filterederrorId,
      siteName: siteObject ? siteObject.siteName : "",
    });
    setSelectedYear(errorId.currentYear);

    // Fetch all data for the selected site and year
    const allSiteYearData = await fetchExpectedData(
      errorId.siteId,
      errorId.currentYear
    );
    setEditFilteredData(allSiteYearData);

    setCategory(errorId.equipmentCategory);
    setEdit(true);
    setOpen(true);

    // Set the form fields with all data for the selected site and year
    setExpectFields(
      allSiteYearData.map((data) => ({
        id: data.id,
        month: data.month,
        currentYear: data.currentYear,
        pr: data.pr,
        tAmb: data.tAmb,
        eGrid: data.eGrid,
        globHor: data.globHor,
        diffHor: data.diffHor,
        globInc: data.globInc,
        globEff: data.globEff,
      }))
    );

    console.log("Setting site name to:", siteObject ? siteObject.siteName : "");
    console.log("Fetched data for editing:", allSiteYearData);
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "ExpectedData");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const headCells = [
    {
      label: "Month",
      id: "month",
      view: true,
      default: true,
    },
    {
      label: "Global Horizontal (kWh/m2)",
      id: "globHor",
      view: true,
      default: true,
    },
    {
      label: "Horizontal Diffuse (kWh/m2)",
      id: "diffHor",
      view: true,
      default: true,
    },
    {
      label: "Ambient Temperature",
      id: "tAmb",
      view: true,
      default: true,
    },
    {
      label: "Global Incident (kWh/m2)",
      id: "globInc",
      view: true,
      default: true,
    },
    {
      label: "Effective Global (kWh/m2)",
      id: "globEff",
      view: true,
      default: true,
    },
    {
      label: "Energy Grid (kWh)",
      id: "eGrid",
      view: true,
      default: true,
    },
    {
      label: "Performance Ratio",
      id: "pr",
      view: true,
      default: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  const tableValues = expectedata.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  useEffect(() => {
    const SiteTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory == category;
    });
    setSiteTypeIdFilter(SiteTypeIdFilter);
  }, [category, categoryvalue, stdField.status]);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Expected Data", path: "expecteddata" },
  ];
  const [selectedSite, setSelectedSite] = useState(null);
  const [Valuedata, setValuedata] = useState();
  const [site, setsite] = useState("");
  console.log(site);
  const handleSiteFilterValue = (val) => {
    setsite(val);
    if (val) {
      const siteIdFilter = siteName?.find((data) => data.siteName === val);
      console.log("Filtered site:", siteIdFilter);
      if (siteIdFilter) {
        setValuedata(siteIdFilter.siteName);
        setSelectedSite(siteIdFilter); // Store the selected site
        // getExpectData(siteIdFilter.siteId, 2024);
        getExpectData(siteIdFilter.siteId, selectedYear);
      }
    } else {
      // Clear the site selection
      setValuedata(null);
      setSelectedSite(null); // Clear the selected site
      // Clear the table data
      setSitestatus([]);
      // Optionally fetch all equipment data
      setExpectData([]); // Clear table values if no site is selected
    }
  };

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [filteredData, setFilteredData] = useState(tableValues);
  const handleYearFilterValue = (year) => {
    setSelectedYear(year);
    const newFilteredData = tableValues.filter(
      (item) =>
        item.year === year &&
        item.siteId === (selectedSite ? selectedSite.siteId : null)
    );
    setFilteredData(newFilteredData); // Update filtered data
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? "edit" : "create"}
            enableReinitialize={true}
            initialValues={expectFields}
            validationSchema={ErrorConfigSchema}
            onSubmit={async (values, { resetForm }) => {
              console.log("Formik onSubmit triggered");
              let submitValue = values;
              if (edit) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                  handleClose(); // Close the modal after successful update
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <div>
                  {/* ---------   -----save andupdate---- popup----------- */}
                  {modalvalue ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box
                          className={"styleModalExpectedData"}
                          sx={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: "20px",
                              height: "68vh",
                              width: "80%",
                              // height: "fit-content",
                            }}
                          >
                            <CardHeader
                              sx={{
                                // padding: "20px 20px 20px 20px",
                                background: CheckForLight()
                                  ? "rgb(246, 248, 252)"
                                  : "#4f4f4f",
                              }}
                              action={
                                <Stack spacing={2} direction="row">
                                  {edit ? (
                                    <Button
                                      variant="contained"
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Updating..." : "Update"}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Saving..." : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    variant="contained"
                                    disabled={toBackend}
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                      handleClose();
                                      handleReset();
                                    }}
                                    startIcon={<CancelIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                              }
                              title={
                                edit ? "Update Expected Data" : "Add Generation"
                              }
                            />
                            <Divider style={{ borderColor: "#888" }} />
                            <div className="modelGrid">
                              <Card
                                sx={{
                                  overflowY: "visible",
                                  marginLeft: "-1vw",
                                  // paddingLeft: '-1vw',
                                  marginTop: "-2%",
                                  // marginBottom: '-2%',
                                  scrollBehavior: "smooth",
                                  scrollbarGutter: "stable",
                                  scrollbarWidth: "thin",
                                  "&::-webkit-scrollbar": {
                                    width: "0.4em",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#f1f1f1",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#888",
                                    borderRadius: "20px",
                                  },
                                  "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#555",
                                  },
                                }}
                                elevation={0}
                              >
                                <CardContent sx={{ marginLeft: "1%" }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container item xs={3} sm={5.2}>
                                        <Grid item xs={12} md={6}>
                                          <div>
                                            <Typography style={typoStyle}>
                                              Site Name
                                            </Typography>
                                            <Autocomplete
                                              size="small"
                                              disablePortal
                                              disableClearable
                                              id="combo-box-demo"
                                              disabled
                                              options={siteName?.map(
                                                (option) => option.siteName
                                              )}
                                              onChange={(event, newValue) =>
                                                handleSite(newValue)
                                              }
                                              value={stdField.siteName || null}
                                              sx={{ width: "12vw" }}
                                              ListboxProps={{
                                                style: { maxHeight: "200px" },
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder="IFP"
                                                  disabled
                                                />
                                              )}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <div
                                            style={{
                                              flex: "0 0 auto",
                                              width: "12vw",
                                              // marginRight: "10px",
                                            }}
                                          >
                                            <Typography style={typoStyle}>
                                              Year
                                            </Typography>

                                            {/* {edit
                                              ? editFilteredData.map(
                                                  (field, fieldIndex) => (
                                                    <Autocomplete
                                                      key={fieldIndex}
                                                      size="small"
                                                      name="currentYear"
                                                      value={
                                                        field.currentYear ||
                                                        currentYear
                                                      } // Show saved year or default to the current year
                                                      options={yearOptions}
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.toString()}
                                                      disableClearable
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        handleYearChange(
                                                          value,
                                                          fieldIndex
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder="Select Year"
                                                          style={{
                                                            ...fieldStyle,
                                                            marginBottom:
                                                              "10px",
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  )
                                                )
                                              : expectFields.map(
                                                  (field, fieldIndex) => (
                                                    <Autocomplete
                                                      key={fieldIndex}
                                                      size="small"
                                                      name="currentYear"
                                                      value={
                                                        field.currentYear ||
                                                        currentYear
                                                      } // Show saved year or default to the current year
                                                      options={yearOptions}
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.toString()}
                                                      disableClearable
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        handleYearChange(
                                                          value,
                                                          fieldIndex
                                                        )
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder="Select Year"
                                                          style={{
                                                            ...fieldStyle,
                                                            marginBottom:
                                                              "10px",
                                                          }}
                                                        />
                                                      )}
                                                    />
                                                  )
                                                )} */}
                                            {edit
                                              ? editFilteredData.map(
                                                  (field, fieldIndex) => (
                                                    <React.Fragment
                                                      key={fieldIndex}
                                                    >
                                                      {/* Render the Year field only for the first item */}
                                                      {fieldIndex === 0 && (
                                                        <Autocomplete
                                                          size="small"
                                                          name="currentYear"
                                                          disabled={edit}
                                                          value={selectedYear}

                                                          // value={
                                                          //   field.currentYear ||
                                                          //   currentYear
                                                          // } // Show saved year or default to the current year
                                                          options={yearOptions}
                                                          getOptionLabel={(
                                                            option
                                                          ) =>
                                                            option.toString()
                                                          }
                                                          disableClearable
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) =>
                                                            handleYearChange(
                                                              value,
                                                              fieldIndex
                                                            )
                                                          }
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder="Select Year"
                                                              style={{
                                                                ...fieldStyle,
                                                                marginBottom:
                                                                  "10px",
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      )}

                                                      {/* Render other fields */}
                                                      {/* Add other input fields here as needed */}
                                                    </React.Fragment>
                                                  )
                                                )
                                              : expectFields.map(
                                                  (field, fieldIndex) => (
                                                    <React.Fragment
                                                      key={fieldIndex}
                                                    >
                                                      {/* Render the Year field only for the first item */}
                                                      {fieldIndex === 0 && (
                                                        <Autocomplete
                                                          size="small"
                                                          name="currentYear"
                                                          value={
                                                            field.currentYear ||
                                                            currentYear
                                                          } // Show saved year or default to the current year
                                                          options={yearOptions}
                                                          getOptionLabel={(
                                                            option
                                                          ) =>
                                                            option.toString()
                                                          }
                                                          disableClearable
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) =>
                                                            handleYearChange(
                                                              value,
                                                              fieldIndex
                                                            )
                                                          }
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder="Select Year"
                                                              style={{
                                                                ...fieldStyle,
                                                                marginBottom:
                                                                  "10px",
                                                              }}
                                                            />
                                                          )}
                                                        />
                                                      )}

                                                      {/* Render other fields */}
                                                      {/* Add other input fields here as needed */}
                                                    </React.Fragment>
                                                  )
                                                )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        overflowY: "auto",
                                        height: "290px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: "sticky",
                                            }}
                                          >
                                            <Typography style={typoStyle}>
                                              Month
                                            </Typography>

                                            {expectFields.map(
                                              (field, index) => (
                                                <Autocomplete
                                                key={index}
                                                size="small"
                                                name="month"
                                                value={field.month ? { monthName: field.month } : null}
                                                disablePortal
                                                disableClearable
                                                id={`month-${index}`}
                                                onChange={(event, value) => handleMonth(value, event, index)}
                                                disabled={edit}
                                                options={expectedMonth?.map((data) => ({
                                                  monthName: data.monthName,
                                                  disabled: selectedMonths.includes(data.monthName.toUpperCase()) &&
                                                           field.month !== data.monthName.toUpperCase()
                                                }))}
                                                getOptionDisabled={(option) => option.disabled}
                                                getOptionLabel={(option) => option.monthName}
                                                renderOption={(props, option) => (
                                                  <li
                                                    {...props}
                                                    style={{
                                                      pointerEvents: option.disabled ? "none" : "auto",
                                                      opacity: option.disabled ? 0.5 : 1,
                                                    }}
                                                  >
                                                    {option.monthName}
                                                  </li>
                                                )}
                                                style={{
                                                  ...fieldStyle,
                                                  marginBottom: "10px",
                                                  // width: "180px"
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    placeholder="Select month..."
                                                    disabled={disabledSiteName}
                                                  />
                                                )}
                                              />
                                                // <Autocomplete
                                                //   key={index}
                                                //   size="small"
                                                //   name="month"
                                                //   value={
                                                //     field.month
                                                //       ? {
                                                //           monthName:
                                                //             field.month,
                                                //         }
                                                //       : null
                                                //   }
                                                //   disablePortal
                                                //   disableClearable
                                                //   id={`month-${index}`}
                                                //   onChange={(event, value) =>
                                                //     handleMonth(
                                                //       value,
                                                //       event,
                                                //       index
                                                //     )
                                                //   }
                                                //   disabled={edit}
                                                //   options={expectedMonth?.map(
                                                //     (data) => ({
                                                //       monthName: data.monthName,
                                                //       // Convert both existingMonths and data.monthName to lowercase for comparison
                                                //       disabled:
                                                //         existingMonths
                                                //           .map((month) =>
                                                //             month.toLowerCase()
                                                //           )
                                                //           .includes(
                                                //             data.monthName.toLowerCase()
                                                //           ) &&
                                                //         data.monthName.toLowerCase() !==
                                                //           field.month.toLowerCase(),
                                                //     })
                                                //   )}
                                                //   getOptionDisabled={(option) =>
                                                //     option.disabled
                                                //   }
                                                //   getOptionLabel={(option) =>
                                                //     option.monthName
                                                //   }
                                                //   renderOption={(
                                                //     props,
                                                //     option
                                                //   ) => (
                                                //     <li
                                                //       {...props}
                                                //       style={{
                                                //         pointerEvents:
                                                //           option.disabled
                                                //             ? "none"
                                                //             : "auto",
                                                //         opacity: option.disabled
                                                //           ? 0.5
                                                //           : 1,
                                                //       }}
                                                //     >
                                                //       {option.monthName}
                                                //     </li>
                                                //   )}
                                                //   style={{
                                                //     ...fieldStyle,
                                                //     marginBottom: "10px",
                                                //   }}
                                                //   renderInput={(params) => (
                                                //     <TextField
                                                //       {...params}
                                                //       placeholder="Select month..."
                                                //       disabled={
                                                //         disabledSiteName
                                                //       }
                                                //     />
                                                //   )}
                                                // />
                                              )
                                            )}
                                          </div>
                                          {/* Year Field */}
                                          {/* <div
                                            style={{
                                              flex: "0 0 auto",
                                              width: "150px",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Typography style={typoStyle}>
                                              Year
                                            </Typography>
                                            {expectFields.map(
                                              (field, fieldIndex) => (
                                                <Autocomplete
                                                  key={fieldIndex}
                                                  size="small"
                                                  name="currentYear"
                                                  value={
                                                    field.currentYear ||
                                                    currentYear
                                                  } // Show saved year or default to the current year
                                                  options={yearOptions}
                                                  getOptionLabel={(option) =>
                                                    option.toString()
                                                  }
                                                  disableClearable
                                                  onChange={(event, value) =>
                                                    handleYearChange(
                                                      value,
                                                      fieldIndex
                                                    )
                                                  }
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      placeholder="Select Year"
                                                      style={{
                                                        ...fieldStyle,
                                                        marginBottom: "10px",
                                                      }}
                                                    />
                                                  )}
                                                />
                                              )
                                            )}
                                          </div> */}

                                          <div
                                            style={{
                                              display: "flex",
                                              overflowX: "auto",
                                              scrollbarWidth: "thin",
                                              marginLeft: "10px",
                                              width: "65vw",
                                              overflowY: "hidden",
                                              minHeight: "270px",
                                            }}
                                          >
                                            {/* {[
                                              // 'Average PR',
                                              // 'Amb Temp',
                                              // 'E Gid',
                                              // 'GlobHor',
                                              // 'DiffHor',
                                              // 'GlobInc',
                                              // 'GlobEff',
                                              'Performance Ratio',
                                              'Ambient Temperature',
                                              'Energy Gid',
                                              'Global Horizontal',
                                              'Horizontal Diffuse',
                                              'Global Incident',
                                              'Effective Global',
                                            ].map((header, headerIndex) => (
                                              <div
                                                key={headerIndex}
                                                style={{
                                                  flex: '0 0 auto',
                                                  width: '150px',
                                                  marginRight:
                                                    headerIndex === 6
                                                      ? '5vw'
                                                      : '10px',
                                                }}
                                              >
                                                <Typography style={typoStyle}>
                                                  {header}
                                                </Typography>
                                                {expectFields.map(
                                                  (field, fieldIndex) => (
                                                    <TextField
                                                      key={fieldIndex}
                                                      size="small"
                                                      name={header
                                                        .toLowerCase()
                                                        .replace(' ', '')}
                                                      value={
                                                        field[
                                                          header
                                                            .toLowerCase()
                                                            .replace(' ', '')
                                                        ] || ''
                                                      }
                                                      placeholder="E.g: 23.12"
                                                      onChange={(event) =>
                                                        handleChangeData(
                                                          event,
                                                          fieldIndex
                                                        )
                                                      }
                                                      style={{
                                                        ...fieldStyle,
                                                        marginBottom: '10px',
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </div>
                                            ))} */}
                                            {Object.entries(headerMapping).map(
                                              (
                                                [header, backendKey],
                                                headerIndex
                                              ) => (
                                                <div
                                                  key={headerIndex}
                                                  style={{
                                                    flex: "0 0 auto",
                                                    width: "200px",
                                                    marginRight:
                                                      headerIndex === 6
                                                        ? "5vw"
                                                        : "10px",
                                                  }}
                                                >
                                                  <Typography style={typoStyle}>
                                                    {header}
                                                  </Typography>
                                                  {expectFields.map(
                                                    (field, fieldIndex) => (
                                                      <TextField
                                                        key={fieldIndex}
                                                        size="small"
                                                        name={backendKey} // Use the backend key for the name
                                                        value={
                                                          field[backendKey] ||
                                                          ""
                                                        } // Access using the backend key
                                                        placeholder="E.g: 23.12"
                                                        onChange={(event) =>
                                                          handleChangeData(
                                                            event,
                                                            fieldIndex
                                                          )
                                                        }
                                                        style={{
                                                          ...fieldStyle,
                                                          marginBottom: "10px",
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: 0,
                                              top: -10,
                                              width: "50px",
                                              height: "99%",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              backgroundColor: "#fff",
                                              zIndex: 2,
                                            }}
                                          >
                                            {!edit && (
                                              <>
                                                <IconButton
                                                  onClick={handleAddButtonClick}
                                                  disabled={
                                                    expectFields.length >= 12
                                                  }
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  <AddIcon
                                                    style={{
                                                      color:
                                                        expectFields.length >=
                                                        12
                                                          ? "grey"
                                                          : CheckForLight()
                                                          ? "black"
                                                          : "white",
                                                    }}
                                                  />
                                                </IconButton>
                                                {expectFields.length > 1 && (
                                                  <IconButton
                                                    onClick={() =>
                                                      handleRemoveButtonClick(
                                                        expectFields.length - 1
                                                      )
                                                    }
                                                  >
                                                    <CloseIcon
                                                      style={{
                                                        color: CheckForLight()
                                                          ? "black"
                                                          : "white",
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </div>
                          </Card>
                        </Box>
                      </Fade>
                    </Modal>
                  ) : (
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={excelOpen}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                      >
                        <Fade in={excelOpen}>
                          <Box
                            className={"styleModalSmall"}
                            sx={{
                              border: "none",
                              outline: "none",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Card
                              sx={{
                                borderRadius: "20px",
                                height: "fit-content",
                                width: "fit-content",
                                marginLeft: "-2.5%",
                              }}
                            >
                              <CardHeader
                                sx={{
                                  padding: "20px 20px 20px 20px",
                                  background: CheckForLight()
                                    ? "rgb(246, 248, 252)"
                                    : "#4f4f4f",
                                }}
                                action={
                                  <Stack spacing={2} direction="row">
                                    <Button
                                      variant="contained"
                                      disabled={
                                        errorIndex?.length > 0 ||
                                        indexVal?.length > 0 ||
                                        toBackend
                                      }
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      // onClick={() =>
                                      //   toBackend ? null : handleExcelUpload()
                                      // }
                                      onClick={() => {
                                        if (!toBackend) {
                                          handleExcelUpload();
                                          handleClose();
                                        }
                                      }}
                                    >
                                      Submit
                                    </Button>
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={handleClose}
                                      startIcon={<CancelIcon />}
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                }
                                title={"Add New Expected Data"}
                              />
                              <Divider
                                sx={{ marginTop: "0%", borderColor: "#888" }}
                              />
                              <CardContent>
                                <Card
                                  sx={{
                                    overflowY: "auto",
                                    maxHeight: "40vh",
                                    maxWidth: "75vw",
                                    marginLeft: "0vw",
                                    // padding: '-1vw',
                                    marginTop: "0%",
                                    marginBottom: "-1%",
                                    scrollBehavior: "smooth",
                                    scrollbarGutter: "stable",
                                    scrollbarWidth: "thin",
                                    "&::-webkit-scrollbar": {
                                      width: "0.4em",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      background: "#f1f1f1",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "#888",
                                      borderRadius: "20px",
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                      background: "#555",
                                    },
                                  }}
                                  elevation={2}
                                >
                                  <CardContent sx={{ padding: 0 }}>
                                    {selectedFileData && (
                                      <div>
                                        <Table
                                          sx={{ width: "100%" }}
                                          size="small"
                                          aria-label="a dense table"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              {requiredHeader.map(
                                                (header, index) => (
                                                  <TableCell
                                                    sx={{
                                                      borderBottom: "none",
                                                      textAlign: "center",
                                                    }}
                                                    key={index}
                                                  >
                                                    {header}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {selectedFileData.map(
                                              (row, rowIndex) => {
                                                return (
                                                  <Tooltip
                                                    key={rowIndex}
                                                    title={(() => {
                                                      let errorMessage = "";
                                                      errorIndex?.forEach(
                                                        (error) => {
                                                          if (
                                                            !errorMessage &&
                                                            error?.rowIndex ===
                                                              rowIndex
                                                          ) {
                                                            errorMessage =
                                                              error?.lengthValidation
                                                                ? error?.columnName
                                                                : `${error?.columnName} is Missing`;
                                                          }
                                                        }
                                                      );
                                                      if (
                                                        !errorMessage &&
                                                        indexVal?.includes(
                                                          rowIndex
                                                        )
                                                      ) {
                                                        errorMessage =
                                                          errorVal[
                                                            indexVal.indexOf(
                                                              rowIndex
                                                            )
                                                          ];
                                                      }
                                                      return errorMessage;
                                                    })()}
                                                    placement="top"
                                                  >
                                                    <TableRow
                                                      key={rowIndex}
                                                      style={
                                                        errorIndex?.some(
                                                          (error) =>
                                                            error?.rowIndex ===
                                                            rowIndex
                                                        ) ||
                                                        indexVal?.includes(
                                                          rowIndex
                                                        )
                                                          ? {
                                                              border:
                                                                "2px solid red",
                                                              backgroundColor:
                                                                "#ff070721",
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                      {requiredHeader.map(
                                                        (header, colIndex) => (
                                                          <TableCell
                                                            sx={{}}
                                                            key={colIndex}
                                                            style={{
                                                              border: "none",
                                                              borderTop: "none",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {row[header]}
                                                          </TableCell>
                                                        )
                                                      )}
                                                    </TableRow>
                                                  </Tooltip>
                                                );
                                              }
                                            )}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    )}
                                  </CardContent>
                                </Card>
                              </CardContent>
                            </Card>
                          </Box>
                        </Fade>
                      </Modal>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"Expected Data"}
              ExpectedYear={"ExpectedData Year"}
              uploadButton={true}
              addButton={"Expected Data"}
              header={headCells}
              rowsValue={tableValues}
              siteExpect={siteExpect}
              userRole={errorConfiguration[0]}
              handleChange={handleChangeTab}
              handleSiteFilterChangeExData={handleSiteFilterValue}
              handleYearChange={handleYearFilterValue}
              handleDownloadTemplate={() =>
                downloadExcelTemplate("Expected Data", isOEM ? true : false)
              }
              handleyearPopupOpen={(e) => handleyear(e)}
              handleUploadProp={(e) => handleFileUpload(e)}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              handleSiteChangeExpect={(val) => handleExpect(val)}
              sitevalueEx={Valuedata}
              siteNameListEx={siteName}
              fallbackText={"No expected data has been created yet"}
            />
          </div>
        </div>
      )}
    </div>
  );
}
