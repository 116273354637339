import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import FadeLoader from "react-spinners/FadeLoader";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Statusdropdown } from "../../Api/CommonApi";
import {
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Modal,
  Divider,
  Checkbox,
  Backdrop,
  CircularProgress,
  Card,
  CardHeader,
  Tooltip,
} from "@mui/material";

import {
  fetchSiteTableById,
  postSiteSave,
  Getdropdown,
  Getdropdownvalue,
  putsiteupdateEdit,
  deleteMapByUser,
  GetCustomerdropdowndata,
} from "../../Api/MapsiteApi";

import { mapsite } from "../util/ValidateSchema";
import Download from "../../Template/Excel/Download";
import TableTemplate from "../../Template/TableTemplate";
import { SiteNew } from "../util/TextField";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSnackbar from "../util/components/CustomSnackbar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import errorMsg from "../util/errorMessage.json";
import { CheckForLight } from "../util/common/CommanFunction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const isOEM = sessionStorage.getItem("userTypeId") == 1;

export default function MultiSite({ Multisite }) {
  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState(false);
  const [stdedit, setstdedit] = useState(false);
  const [statusDrop, setStatusDrop] = useState([]);

  const [loading, setLoading] = useState(true);

  const [stdTable, setstdTable] = useState([]);
  console.log(stdTable);

  const [stdStatus, setstdStatus] = useState();
  const [Sector, setSector] = useState([]);
  const [siteId, setSiteId] = useState([]);

  const [userId, setUserId] = useState([]);
  console.log(userId);
  
  const [customerid, setCustomerId] = useState([]);
  const [text, setTextField] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const initalState = {
    customerId: "",
    companyName: "",
    createdBy: "",
    createdDate: "",
    lastUpdatedBy: "",
    lastUpdatedDate: "",
    siteId: "",
    siteMapId: "",
    siteName: [],
    status: "",
    userName: "",
    userTypeName: "",
  };
  const [userFields, setUserFields] = useState(initalState);
  console.log(userFields?.userName.label);
  
  const [customerName, setCustomerName] = useState("");
  const [userName, setuserName] = useState();
  const [dropdown, setDropdown] = useState(false);
  const [datadrop,setdatadrop]=useState(false)
  const [tablevalue, setTablevalue] = useState();
  const [tablevaluedata,setTablevaluedata]=useState()

  const transformedData =
    tablevalue?.flatMap((item) =>
      item.siteName.map((name, index) => ({
        index: index + 1,
        siteNamesString: name,
        siteName: [name],
        siteIds: [item.siteIds[index]],
        customerName:[item.customerName[index]],
        userName: item.userName,
        userId: item.userId,
        lastUpdatedBy: item.lastUpdatedBy,
        lastUpdatedDate: item.lastUpdatedDate,
        createdBy: item.createdBy,
      }))
    ) || []; // Return an empty array if tablevalue is undefined

  console.log(transformedData);
  const table = dropdown === true ? transformedData :datadrop===true?tablevaluedata: stdTable;
  const [stdField, setstdField] = useState({
    userName: "",
    siteName: "",
    status: "",
    companyName: "",
  });
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  function convertData(inputData) {
    console.log(inputData, "data from fetch");
    let result = [];

    inputData.forEach((item) => {
      let siteNamesArray = item.sitemap.map((site) => site.siteName?.trim());
      let siteIdsArray = item.sitemap.map((site) => site.siteId);
      let customeraarray= item.sitemap.map((site) => site.customerName)

      result.push({
        siteNamesString: siteNamesArray.join(", "),
        siteName: siteNamesArray,
        siteIds: siteIdsArray,
        customerName:customeraarray,
        userName: [item.userName],
        userId: item.userId,
        lastUpdatedBy: item.lastUpdatedBy,
        lastUpdatedDate: item.lastUpdatedDate,
        createdBy: item.createdBy,
        createdDate: item.createdDate,
      });
    });
    console.log(result, "jdhfjjdsh");
    return result;
  }
  const handleClose = () => {
    setedit([]);
    setstdedit([]);
    setUserFields(initalState);
    setOpen(false);
    setModalvalue(true);
    setSelectedFileData(null);
  };
  // ========Table Data==== //

  const fetchData = async () => {
    try {
      const data = await fetchSiteTableById();
      if (Array.isArray(data)) {
        setstdTable(convertData(data));
      } else if (typeof data === "object" && data !== null) {
        setstdTable([convertData(data)]);
      }
      setstdTable(convertData(data));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  console.log(stdTable, setstdTable);
  useEffect(() => {
    fetchData();
    getsitelist();
    getuserlist();
    fetchStatus();
    getcustomerlist();
  }, []);

  useEffect(() => {
    let data = SiteNew();
    // console.log(data, "data");
    setTextField(data);
  }, []);
  // ==============Edit api ===========
  const handleEdit = async (userId) => {
    setedit(true);
    setOpen(true);
    console.log(userId, "dfgh");
    setstdedit(userId);
    setUserFields(userId);
    setOpen(true);
    setOpen(true);
  };

  console.log(stdedit);

  const handleChange = (event) => {
    setstdField({ ...stdField, [event.target.name]: event.target.value });
  };

  // ============================Save Api====================

  const getSelectedIds = (
    selectedValues,
    sourceArray,
    targetPropertyName,
    resultPropertName
  ) => {
    return selectedValues.map((selectedValue) => {
      const selectedItem = sourceArray.find(
        (item) => item[targetPropertyName] === selectedValue
      );
      return selectedItem ? selectedItem[resultPropertName] : undefined;
    });
  };

  const handleSave = async () => {
    if (userFields.siteName.length !== 0 && userFields.userName.length !== 0) {
      const selectedSiteIds = getSelectedIds(
        userFields.siteName,
        siteId,
        "siteName",
        "siteId"
      );
      console.log(userName);
      console.log(userFields?.userName.label);
      
      
      let userfilterFilter = userId?.filter((data) => {
        return data.userName === userFields?.userName.label;
      });
      console.log(userfilterFilter);
      
      const userfilter=userfilterFilter[0]?.userId
      console.log(userfilter);
      
    
      // const selectedUserIds = getSelectedIds(
      //   userFields.userName,
      //   userId,
      //   "userName",
      //   "userId"
      // );

      const data = {
        // customerId: Number(sessionStorage.getItem("customerId")),

        createdBy: Number(sessionStorage.getItem("id")),
        lastUpdatedBy: Number(sessionStorage.getItem("id")),
        siteUserMappings: [
          {
            siteIds: selectedSiteIds,
            userId: [userfilter],
          },
        ],
      };
console.log(data);

      try {
        setToBackend(true);

        const responseData = await postSiteSave(data);
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: "success",
          message: responseData.statusDescription,
        });
        handleClose();
        setToBackend(false);
        setOpen(false);
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
        setSnack(errorMsg.failure);
        setToBackend(false);
      }
    } else {
      setSnack({
        open: true,
        severity: "error",
        message: "Fields cannot be empty",
      });
    }
  };

  const handleCreate = () => {
    setedit(false);
    setOpen(true);
    setstdedit("");
  };

  const handleDelete = async (val) => {
    console.log(val?.userId,val?.siteIds);
    const dele = await deleteMapByUser(dropdown === true|| datadrop===true?val?.userId+"&siteId="+val?.siteIds:val?.userId)
      .then(() => {
        fetchData();
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "success",
            message: "Data deleted successfully",
          };
        });
        window.location.reload()
      }
   )
      .catch((err) => {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "warning",
            message: "Unable to delete try again later",
          };
        });
        console.log(err);
      });
  };

  // ============dropdown============
  const getsitelist = async () => {
    try {
      let data = await Getdropdownvalue();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setSiteId(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteId([data]);
      }
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
  console.log(siteId, "fghjfg");

  const getuserlist = async () => {
    try {
      let data = await Getdropdown();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setUserId(data);
      } else if (typeof data === "object" && data !== null) {
        setUserId([data]);
      }
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
  const getcustomerlist = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };
  console.log(userId, "fghj");

  const headCells = [
    {
      label: "Serial No",
      id: "index",
      view: true,
      default: true,
    },
    {
      label: "Site Name",
      id: "siteNamesString",
      view: true,
      default: true,
    },
    {
      label: "Customer Name",
      id: "customerName",
      view: false,
      default: false,
    },
    {
      label: "Action",
      id: "action",
      view: true,
    },
  ];
  const headCell = [
    {
      label: "User Name",
      id: "userName",
      view: true,
      default: true,
    },
    {
      label: "Site Name",
      id: "siteNamesString",
      view: true,
      default: true,
    },
    {
      label: "Customer Name",
      id: "customerName",
      view: false,
      default: false,
    },
    {
      label: "Action",
      id: "action",
      view: true,
       default: true,
    },
  ];
  const cells = dropdown === true||  datadrop===true? headCells : headCell;
  const handleDownloadTable = async (val) => {
    const status = await Download(val, "MulitipleSite");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  console.log(stdTable);
  const handleuserdropdownvaluee = (val) => {
    const userNamefilter = userId?.filter((data) => data.userName === val);
    const filteredTable = stdTable.filter((row) =>
      row.userName.includes(userNamefilter[0]?.userName)
    );

    setTablevalue(filteredTable);
    setDropdown(true);
    handlecustomerdropdownvaluee("");
    setuserName(val);
    setDropdown(true);
    setdatadrop(false)
    setCustomerName("")
  };
  console.log(stdTable);

  const handlecustomerdropdownvaluee = (val) => {
    const customerNamefilter = customerid?.filter(
        (data) => data.customerName === val
    );
    const selectedCustomerName = customerNamefilter[0]?.customerName;
    const filteredTable = transformedData.filter((row) => 
        row.customerName.includes(selectedCustomerName) 
    );

    setTablevaluedata(filteredTable);
    setCustomerName(val);
    setDropdown(false);
    setdatadrop(true)
};

  // -------- Excel Bulk Upload Function --------

  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [selectedFileData, setSelectedFileData] = useState(null);
  let customerId = sessionStorage.getItem("setsiteId");
  const styleTable = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  const handleFileUpload = (e) => {
    setOpen(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setSelectedFileData(jsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleExcelUpload = async () => {
    const file = selectedFile;

    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        // const jsonDataLower = jsonData.toLowerCase()
        const processedData = jsonData.map((item) => {
          const lowercaseItem = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              lowercaseItem[key.toLowerCase().replace(/\s/g, "")] = item[key];
            }
          }
          let sectorTypeIdFilter = userId?.filter((data) => {
            return data.sectorName === lowercaseItem.sectorname;
          });
          return {
            // sectorName:lowercaseItem.sectorname,
            stdParameterName: lowercaseItem.parametername,
            stdUom: lowercaseItem.uom,
            createdBy: Number(sessionStorage.getItem("id")),
            description: lowercaseItem.description,
            status: 1,
            setsiteId: sessionStorage.getItem("setsiteId"),
          };
        });
        console.log(processedData, "processedData");
        try {
          const responseData = await postSiteSave(processedData);
          if (responseData === 200) {
            fetchData();
            handleClose();
          }
        } catch (e) {
          if (e.response.status === 500) {
            console.log("error is 500");
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  };
  const handleDropDownChange = (event, value, name) => {
    console.log(value, name);
    setUserFields({ ...userFields, [name]: value });
  };
  const customerr = [
    { customer: "Dmart" },
    { customer: "Thea Energy" },
    { customer: "Miura Infrastructure" },
    { customer: "Keerti Enterprises" },
    { customer: "VV Nationals" },
    { customer: "Cleantech Solar Energy Pvt Ltd" },
  ];
  let datas = sessionStorage.getItem("customerName");
  console.log(datas);

  function getOptions(propName) {
    let options = [];
    switch (propName) {
      case "userName":
        options = userId.map((option) => ({ label: option.userName }));
        break;
      case "siteName":
        options = siteId.map((option) => ({ label: option.siteName }));
        break;
      case "customerName":
        options = customerr.map((option) => ({ label: option.customer }));
        break;
      case "status":
        return (options = statusDrop?.map((option) => option.label));
      default:
        break;
    }
    return options;
  }

  function convertToOptions(array) {
    return array?.map((label) => ({ label }));
  }

  const handleDropDownChanges = (event, values, name) => {
    console.log(values);

    const labelCounts = {};
    values.forEach((item) => {
      labelCounts[item.label] = (labelCounts[item.label] || 0) + 1;
    });

    const filteredValues = values.filter(
      (item) => labelCounts[item.label] === 1
    );

    console.log("Current values selected:", filteredValues);

    setUserFields((prev) => {
      const updated = {
        ...prev,
        [name]: filteredValues.map((value) => value.label),
      };
      console.log("Updated state:", updated);
      return updated;
    });
  };

  const handleUpdate = async (values) => {
    let id = stdedit?.userId;
    console.log(id, "id");
    console.log(values, "values");
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    // Determine the selected site name
    let siteNameEdit =
      userFields.siteName !== "" ? values.siteName : stdedit.siteName;

    // Find the corresponding site object in siteId
    const siteIds = getSelectedIds(siteNameEdit, siteId, "siteName", "siteId");
    // Prepare the object to be sent in the update request
    let obj = {
      lastUpdatedBy: Number(sessionStorage.getItem("id")),
      siteIds: siteIds,
      createdBy: Number(sessionStorage.getItem("id")),
      status: edit
        ? typeof values.status === "string"
          ? status[0].value
          : values.status
        : status[0].value,
    };

    console.log(obj, "save");

    try {
      setToBackend(true);
      let responsedata = await putsiteupdateEdit(obj, id);
      setSnack(errorMsg.success);
      handleClose();
      fetchData();
      // const convertdata = convertData(responsedata.data);
      // replaceObjectById(stdTable, 'userId', id, convertdata);
      setOpen(false);
      setToBackend(false);
      return 1;
    } catch (err) {
      setSnack(errorMsg.failure);
      console.error("Error:", err.response.status);
      console.log(err);
      setToBackend(false);
      return 0;
    }
  };

  const paths = [
    { label: "Admin", path: "/config/admin/userconf" },
    { label: "User Site Map", path: "admin/multiSite" },
  ];
  const handleDropDownnChanges = (event, value, name) => {
   console.log(value);
   
      setUserFields({ ...userFields,[name] : value });
  


  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="standard-whole">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <Formik
              key={!edit ? "add" : "edit"}
              enableReinitialize={true}
              initialValues={userFields}
              validationSchema={mapsite}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = {
                  siteName: values.siteName,
                  userName: values.userName,
                  status: values.status,
                };
                console.log(values, "values");
                if (!edit) {
                  const result = await handleSave(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      className={"styleModalSmall"}
                      style={{ width: "35%", height: "40%", left: "45%" }}
                      sx={{ outline: "none" }}
                    >
                      <Card
                        sx={{
                          borderRadius: "20px",
                          // height: '68vh',
                          height: "fit-content",
                        }}
                      >
                        <CardHeader
                          sx={{
                            padding: "20px 20px 20px 20px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                          }}
                          action={
                            <Stack spacing={2} direction="row">
                              {edit ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Updating..." : "Update"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  s
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  onClick={toBackend ? null : handleSubmit}
                                >
                                  {toBackend ? "Saving..." : "Save"}
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                disabled={toBackend}
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => {
                                  handleClose();
                                  handleReset();
                                }}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          }
                          title={edit ? "Update Site Map" : "Add Site Map"}
                        />
                        <Divider style={{ borderColor: "#888" }} />

                        <div
                          className="modelGrid"
                          style={{
                            marginTop: "3%",
                            paddingBottom: "2%",
                            marginBottom: "2%",
                            marginLeft: "1%",
                          }}
                        >
                          <Box sx={{ flexGrow: 3 }}>
                            <Grid
                              container
                              spacing={2}
                              columns={8}
                              style={{ marginLeft: "30px" }}
                            >
                              {text?.length > 0
                                ? text.map((data, index) => (
                                    <Grid item xs={6}>
                                      <div key={index}>
                                        <Typography className="modal-typo">
                                          {data.label}
                                        </Typography>
                                        {data.type === "dropdown" ? (
                                          // =<Field
                                          //  name={data.name}
                                          //  render={({ field, form }) => (
                                          <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={getOptions(data.name)}
                                            limitTags={2}
                                            disableClearable
                                            disableCloseOnSelect
                                            sx={{ width: "25vw" }}
                                            size="small"
                                            disabled={
                                              edit && data.name === "userName"
                                            }
                                            onChange={(event, values) => {
                                              console.log(values, "handler");
                                              handleDropDownChanges(
                                                event,
                                                values,
                                                data.name
                                              );
                                            }}
                                            value={
                                              userFields[data.name]
                                                ? convertToOptions(
                                                    userFields[data.name]
                                                  )
                                                : []
                                            }
                                            placeholder={data.placeholder}
                                            renderOption={(props, option) => (
                                              <li {...props}>
                                                <Checkbox
                                                  icon={icon}
                                                  checkedIcon={checkedIcon}
                                                  style={{ marginRight: 8 }}
                                                  checked={userFields[data.name]
                                                    ?.map((item) =>
                                                      item
                                                        .toLowerCase()
                                                        .replace(/\s+/g, "")
                                                    )
                                                    .includes(
                                                      option.label
                                                        .toLowerCase()
                                                        .replace(/\s+/g, "")
                                                    )}
                                                />
                                                {option.label}
                                              </li>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder={data.placeholder}
                                              />
                                            )}
                                          />
                                        ) : data.type === "dropdownn" ? (
                                          <>
                                            <Tooltip>
                                              <Field
                                                render={({ field, form }) => (
                                                  <Tooltip>
                                                    <Autocomplete
                                                      options={
                                                        data?.name
                                                          ? getOptions(
                                                              data.name
                                                            )
                                                          : []
                                                      }
                                                      // options={
                                                      //   getOptions(
                                                      //     data?.name
                                                      //   ) || []
                                                      // }
                                                      // value={
                                                      //   userFields[data.name]
                                                      //     ? convertToOptions(
                                                      //         userFields[
                                                      //           data.name
                                                      //         ]
                                                      //       )
                                                      //     : isOEM === false
                                                      //     ? datas
                                                      //     : "" || []
                                                      // }
                                                      // value={isOEM===false?datas:""}
                                                      size="small"
                                                      id={`combo-box-demo-${index}`}
                                                      onChange={(
                                                        event,
                                                        values
                                                      ) => {
                                                        console.log(
                                                          values,
                                                         data.name
                                                        );
                                                        handleDropDownnChanges(
                                                          event,
                                                          values,
                                                          data.name
                                                        );
                                                      }}
                                                      disabled={
                                                        toBackend ||
                                                        (!edit &&
                                                          isOEM === false &&
                                                          data.name ===
                                                            "customerName") ||
                                                        (edit &&
                                                          isOEM === false &&
                                                          data.name ===
                                                            "customerName")
                                                      }
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder={
                                                            data.placeholder
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </Tooltip>
                                                )}
                                              />
                                            </Tooltip>
                                            {/* <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="error"
                                              style={{
                                                color: 'red',
                                                marginTop: '1%',
                                                textAlign: 'left',
                                                marginLeft: '0%',
                                              }} */}
                                            {/* /> */}
                                          </>
                                        ) : null}
                                      </div>
                                    </Grid>
                                  ))
                                : null}
                            </Grid>
                          </Box>
                        </div>
                      </Card>
                    </Box>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          <div>
            <TableTemplate
              PageName={"User Site Map"}
              addButton={"Site Map"}
              SearchLabel={"Search User Here... "}
              header={cells}
              rowsValue={table}
              user={userId}
              customer={customerid}
              userRole={Multisite[0]}
              handleAddPopupOpen={(val) => handleCreate(val)}
              handleEditPopupOpen={(val) => handleEdit(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handleuserdropdownvalue={(val) => {
                handleuserdropdownvaluee(val);
              }}
              handlecustomerdropdownvalue={(val) => {
                handlecustomerdropdownvaluee(val);
              }}
              userName={userName}
              CustomerName={customerName}
              handleDelete={(val) => {
                handleDelete(val);
              }}
              rawData={stdTable}
              paths={paths}
              fallbackText={"No User has been mapped to sites yet"}
            />
          </div>
        </div>
      )}
    </div>
  );
}