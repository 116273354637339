import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip,
  Stack,
  Table,
  TableCell,
  CardContent,
  Backdrop,
  Card,
  Fade,
  Modal,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { fetchSiteDetails ,fetchcurring,fetchequipmentcumulative} from "../../Api/CurringApi";
import CustomBreadcrumbs from "../util/components/CustomBread";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
const dayjs = require("dayjs");
export default function Ftpgetfile() {
  const initialState = {
    // hostName: "",
    // port: "",
    // userName: "",
    // passWord: "",
    // directory: "",
    // localdirectory: "",
    siteName: "",
  };
  const [stdField, setstdField] = useState(initialState);
  console.log(stdField);
  
  const [siteIdName, setSiteId] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatee, setSelectedDatee] = useState(null);
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [toBackend, setToBackend] = useState(false);
  console.log(snack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Cumulative ", path: "ftppget" },
  ];
  const Item = styled(Paper)(({ theme }) => ({}));
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async () => {
    // Extract Site ID
    const siteIdFilter = siteIdName?.find((data) => data.siteName === stdField.siteName);
    const siteId = siteIdFilter?.siteId;
  
    // Utility for Adding Days
    const addDays = (date, days) => {
      if (!date) return "";
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString().substring(0, 10);
    };
  
    const fromDate = addDays(selectedDate, 1);
    const toDate = addDays(selectedDatee, 1);
  
    try {
      setToBackend(true);
      const responseData = await fetchcurring(fromDate, toDate, siteId);
      // const  responseData =""
      if (responseData.status === 200) {
        const equipmentData = await fetchequipmentcumulative(fromDate, toDate, siteId);
        if (equipmentData?.status === 200) {
          setSelectedDate("")
          setSelectedDatee("")
          setSnack({
            open: true,
            severity: "success",
            message: "Equipment Cumulative Data saved successfully" ,
          });
          window.location.reload()
        }
      }
      setToBackend(false);
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: "An error occurred. Please try again.",
      });
      console.error("Error:", error);
    }
    setToBackend(false);
  };
  
  useEffect(() => {
    getSiteCall();
  }, []);
  const handleDropdownChange = (event, value, name) => {
    setstdField({ ...stdField, [name]: value });
  };
  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleTxtChange = (event, name) => {
    setstdField({ ...stdField, [name]: event.target.value });
  };

  const handleDateChange = (date,name) => {
    console.log(date,name);
     setstdField({ ...stdField, [name]: new Date(date) });
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate(), +1); // Example modification
    setSelectedDate(selectedDate);
  };

  const handleDatepicChange = (date) => {
   
    const selectedDatee = new Date(date);
    selectedDatee.setDate(selectedDatee.getDate(), +1); // Example modification
    setSelectedDatee(selectedDatee);
  };
  function getOptions(propName) {
    let option = [];
    console.log(propName);

    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));

      default:
    }
    return option;
  }
  return (
    <div>
         <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
      <div>
      <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Paper
            elevation={0}
            style={{
           
              width: '100%',
              borderRadius: '2px 2px 0px 0px',
              userSelect: 'none',
              height: '5vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              container
           
              width={'100%'}
              direction="row"
              justifyContent="space-between"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid
                item
                xs={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>

             
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: "1%" }}>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "15vh",
            }}
          >
            <div style={{ padding: "30px" }}>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={3}>
                  <Typography className="modal-typo">Site Name</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      // id={`combo-box-demo-${index}`}
                      size="small"
                      options={getOptions("siteName")}
                      onChange={(event, value) =>
                        handleDropdownChange(event, value, "siteName")
                      }
                      sx={{ width: "15vw" }}
                      value={stdField.siteName}
                      renderInput={(params) => (
                        <div>
                          <TextField {...params} placeholder={"E.g: IFP"} />
                        </div>
                      )}
                      classes={{
                        option: "autocomplete",
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <Typography className="modal-typo">From Date</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        sx={{
                          width: "14vw",
                        }}
                        onChange={(event) =>
                          handleDateChange(event,  "fromdate")
                        }
                        // onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={3}>
  <Typography className="modal-typo">To Date</Typography>
  <div style={{ marginTop: "1%" }}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        size="small"
        sx={{
          width: "14vw",
        }}
        shouldDisableDate={(date) => {
          // Calculate minDate as selectedDate and maxDate as one month after selectedDate
          const minDate = dayjs(selectedDate).startOf('day');
          const maxDate = minDate.add(1, 'month').endOf('day');
          return date.isBefore(minDate) || date.isAfter(maxDate);
        }}
        onChange={handleDatepicChange}
        slotProps={{
          textField: {
            size: "small",
          },
        }}
      />
    </LocalizationProvider>
  </div>
</Grid>
{/* <Grid item xs={1}></Grid> */}

<Grid item xs={3}>
  <Typography className="modal-typo" style={{visibility:"hidden"}}>To Date</Typography>
  <Button
                    variant="contained"
                    style={{
                      borderRadius: "25px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSave}
                    disabled={
                        !(
                          stdField?.siteName &&
                          selectedDatee &&
                          selectedDate
                        )
                      }
                      startIcon={
                        toBackend ?(
                          <CircularProgress
                            size={20}
                            color="inherit"
                          />
                        ) :(<></>)
                        
                      }
                    >
                 
                  {toBackend?"Cumulative":"Cumulative"} 
                  </Button>
</Grid>

              </Grid>
            </div>

            {/* <div style={{ padding: "20px" }}>
              <Stack direction="row" spacing={2} style={{display:"flex",justifyContent:"center"}}>
                <Item>
                <Button
                    variant="contained"
                    style={{
                      borderRadius: "25px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSave}
                    disabled={
                      !(
                        stdField?.hostName &&
                        stdField?.port &&
                        stdField?.userName &&
                        stdField?.passWord &&
                        stdField?.directory &&
                        stdField?.localdirectory &&
                        selectedDatee &&
                        selectedDate
                      )
                    }
                  >
                    Get File
                  </Button>
                </Item>
                <Item>
                  <Button
                    style={{ textTransform: "capitalize" }}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Item>
              </Stack>
            </div> */}
          </Paper>
        </Box>
      </div>
    </div>
  );
}
