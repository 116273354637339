let data = {
  siteName: " IFP",
  equipCategory: "String Inverter",
  equipType: "SG250HX-IN",
  cap: "200",
  capUnits: "kWp",
  lati: 11.562195,
  longi: 78.979457,
  equipName: "INV-01",
  one: 1,
  mail: "xyz@gmail.com",
  manufacture: "Sun Grow",
  sectorName: "Solar",
  country: "India",
  state: "Tamil Nadu",
  city: "Chennai",
  address: "No.01, 1st xyz street",
  mobile: "9876543221",
  postalCode: "600042",
  status: "Active",
  timezoneName: "UTC/UTC",
};

const isOEM = sessionStorage.getItem("userTypeId") == 1;

export const EquipmentList = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Equipment Name *",
      type: "textField",
      name: "displayName",
      length: 50,
      placeholder: "INV-02",
    },
    {
      label: "Equipment Category *",
      type: "dropdown",
      name: "equipmentCategory",
      placeholder: data.equipCategory,
    },
    {
      label: "Equipment Type *",
      type: "dropdown",
      name: "equipmentType",
      placeholder: data.equipType,
    },
    {
      label: "AC Capacity *",
      type: "capacity",
      name: "acCapacity",
      option: "acUnits",
      placeholder: data.cap,
    },
    {
      label: "DC Capacity *",
      type: "capacity",
      name: "dcCapacity",
      option: "dcUnits",
      placeholder: data.cap,
    },
    {
      label: "Installation Date ",
      type: "date",
      name: "InstallationDate ",
      placeholder: "Installation Date",
    },
    {
      label: "Warranty Expiry",
      type: "datee",
      name: "WarrantyDate ",
      placeholder: "Warranty Date",
    },
    {
      label: "Serial No *",
      type: "textField",
      name: "serialNo",
      length: 30,
      placeholder: "A2181602502",
    },
    {
      label: "Latitude *",
      type: "float",
      name: "latitude",
      length: 9,
      placeholder: data.lati,
    },
    {
      label: "Longitude *",
      type: "float",
      name: "longitude",
      length: 10,
      placeholder: data.longi,
    },

    {
      label: "Primary Equipment *",
      type: "dropdown",
      name: "isPrimaryEquipment",
      placeholder: "Yes",
    },
    {
      label: "Primary Equip Map ",
      type: "dropdown",
      name: "primaryMapEquip",
      placeholder: data.equipName,
    },
    {
      label: "No Of Components *",
      type: "textField",
      length: 10,
      name: "noOfComponents",
      placeholder: "14",
    },
    {
      label: "Remark *",
      type: "textField",
      name: "remarks",
      length: 50,
      placeholder: "Remark",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];
  return array;
};

export const SubscriptionPOList = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Month *",
      type: "dropdown",
      name: "month",
      placeholder: "Yes",
    },
    // {
    //   label: "Primary Equipment *",
    //   type: "dropdown",
    //   name: "isPrimaryEquipment",
    //   placeholder: "Yes",
    // },
    { label: "Subscription Type ", 
      type: "dropdown", 
      name: "SubscriptionType" 
    },
    {
      label: "From Date ",
      type: "date",
      name: "InstallationDate ",
      placeholder: "From Date",
    },
    {
      label: "To Date",
      type: "datee",
      name: "WarrantyDate ",
      placeholder: "To Date",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];
  return array;
};

export const ConfigurationList = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Equipment Name *",
      type: "dropdown",
      name: "displayName",
      placeholder: data.equipName,
    },
    {
      label: "Serial No *",
      type: "textField",
      name: "serialNo",
      length: 50,
      placeholder: "A1512051145",
    },
    {
      label: "Daily Generation *",
      type: "dropdown",
      name: "dailyGenBasedOn",
      placeholder: "Today Energy",
    },
    {
      label: "Mod Bus Address *",
      type: "textField",
      length: 50,
      name: "modBusAddress",
      placeholder: data.one,
    },

    {
      label: "Production Flag *",
      type: "dropdown",
      name: "prodFlag",
      placeholder: data.one,
    },
    {
      label: "Device Sn *",
      type: "textField",
      length: 50,
      name: "device_Sn",
      placeholder: "4",
    },

    {
      label: "Energy Counter *",
      type: "dropdown",
      name: "energyCounter",
      placeholder: "yes",
      view: true,
    },
    {
      label: "Controller *",
      type: "textField",
      name: "controller",
      placeholder: "1",
    },
    {
      label: "Data Logger *",
      type: "dropdown",
      // name: 'dataLoggerId',
      name: "dataLoggerName",
      placeholder: data.one,
    },
    {
      label: "Status Master *",
      type: "dropdown",
      name: "statusMasterId",
      placeholder: "Status Master",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];
  return array;
};
export const alermsview = () => {
  let array = [
    {
      label: "From Date ",
      type: "date",
      name: "fromdate ",
      placeholder: "From Date",
    },
    {
      label: "To Date",
      type: "datee",
      name: "todate ",
      placeholder: "To Date",
    },
    {
      label: "Priority `",
      type: "dropdown",
      name: "priority",
      placeholder: "Less",
    },
    // {
    //   label: 'Equipment Name ',
    //   type: 'multidropdown',
    //   name: 'equipment',
    //   placeholder: 'INV-01',
    // },
  ];

  return array;
};

export const taskManagerList = () => {
  let array = [
    // {
    //   label: 'Site Name',
    //   type: 'dropdown',
    //   name: 'siteName',
    // },
    {
      label: "User Name",
      type: "dropdown",
      name: "userName",
    },
    {
      label: "Project",
      type: "dropdown",
      name: "projectName",
    },
  ];
  return array;
};

export const customizeAlerts = () => {
  let array = [
    // {label:siteName,type:'dropdown',name:'siteName'},
    {
      label: "Equipment *",
      type: "dropdown",
      name: "equipmentName",
      placeholder: data.equipName,
    },
    {
      label: "Parameter *",
      type: "dropdown",
      name: "parameter",
      placeholder: "Select Parameter",
    },
    {
      label: "Logic *",
      type: "dropdown",
      name: "logic",
      placeholder: "Less",
    },
    { label: "Value *", type: "number", name: "value", placeholder: "10" },
    {
      label: "Email *",
      type: "textField",
      length: 50,
      name: "emailId",
      placeholder: data.mail,
    },
    {
      label: "Error Message *",
      type: "textField",
      length: 50,
      name: "errorMessage",
      placeholder: "Low Generation",
    },
    {
      // label: 'From Time',
      type: "time",
      name: "fromTime",
    },
    // {
    //   label: 'To Time',
    //   type: 'time',
    //   name: 'toTime'
    // },
    { label: "Status *", type: "dropdown", name: "status" },
  ];
  return array;
};

export const SiteNew = () => {
  let array = [
    {
      label: "User *",
      type: "dropdownn",
      name: "userName",
      placeholder: "Select User Name",
    },
    // {
    //   label: "Customer Name ",
    //   type: "dropdownn",
    //   name: "customerName",
    //   placeholder: "Select Customer Name",
    // },
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
  ];

  return array;
};

export const EquipmentType = () => {
  let array = [
    {
      label: "Equipment Type *",
      type: "textField",
      length: 50,
      name: "equipmentType",
      placeholder: data.equipType,
    },
    {
      label: "Sector Name*",
      type: "dropdown",
      name: "sectorName",
      placeholder: data.sectorName,
    },
    {
      label: "Equipment Category *",
      type: "dropdown",
      name: "equipmentCategory",
      placeholder: data.equipCategory,
    },
    {
      label: "Remarks *",
      type: "textField",
      length: 50,
      name: "remarks",
      placeholder: "Remark",
    },

    {
      label: "Manufacture *",
      type: "textField",
      name: "manufacturer",
      placeholder: data.manufacture,
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];

  return array;
};
export const Equipmentcategory = () => {
  let array = [
    {
      label: "Equipment Category*",
      type: "textField",
      length: 50,
      name: "equipmentCategory",
      placeholder: data.equipCategory,
    },
    {
      label: "Sector Name*",
      type: "dropdown",
      name: "sectorName",
      placeholder: data.sectorName,
    },

    {
      label: "Category Description*",
      type: "textField",
      name: "categoryDescription",
      placeholder: "Category Description",
    },
    // {
    //   label: 'Category Group',
    //   type: 'textField',
    //   name: 'categoryGroup',
    //   placeholder: 'Category Group',
    // },
    {
      label: "Status*",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];

  return array;
};
export const siteblock = () => {
  let array = [
    {
      label: "Plant*",
      type: "textField",
      length: 50,
      name: "blockName",
      placeholder: "plant ",
    },
    {
      label: "Customer Name*",
      type: "dropdown",
      name: "customerName",
      placeholder:"Choose CustomerName",
    },
    {
      label: "Site Name*",
      type: "multidropdown",
      name: "siteName",
      placeholder:data.siteName,
    },
   
  ];

  return array;
};
export const CustomerList = () => {
  let array = [
    {
      label: "Customer Name *",
      type: "textField",
      name: "customerName",
      length: 50,
      placeholder: "Enter Customer Name",
      view: true,
    },
    {
      label: "Subscription *",
      type: "dropdown",
      name: "subscriptionName",
      view: isOEM ? true : false,
      placeholder: "Select Subscription",
    },
    {
      label: "Email Id *",
      type: "textField",
      length: 50,
      name: "emailId",
      placeholder: data.mail,
      view: true,
    },
    {
      label: "Customer Website *",
      type: "textField",
      length: 50,
      name: "customerWebsite",
      placeholder: " https://xyz.com",
      view: true,
    },
    {
      label: "Country *",
      type: "dropdown",
      name: "country",
      placeholder: data.country,
      view: true,
    },
    {
      label: "State *",
      type: "dropdown",
      name: "state",
      placeholder: data.state,
      view: true,
    },
    {
      label: "Address *",
      type: "textField",
      name: "address",
      placeholder: data.address,
      view: true,
    },
    {
      label: "Contact Person *",
      type: "textField",
      length: 20,
      name: "contactPerson",
      placeholder: "Jhon",
      view: true,
    },
    {
      label: "Mobile Number *",
      type: "number",
      name: "mobileNumber",
      placeholder: data.mobile,
      view: true,
    },
    // { label: 'Telephone Number *', type: 'number', name: 'telePhoneNumber', placeholder: "Telephone Number" },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
    {
      label: "Company Logo *",
      type: "logo",
      name: "companyLogoPath",
      placeholder: "Company Logo",
      view: true,
    },
  ];

  return array;
};

export const StandardParameter = () => {
  let array = [
    {
      label: "Parameter Name *",
      type: "textField",
      name: "stdParameterName",
      placeholder: "ActivePower",
      length: 50,
      view: true,
    },
    {
      label: "UOM *",
      type: "textField",
      name: "stdUom",
      placeholder: " watt",
      length: 50,
      view: true,
    },
    {
      label: "Description *",
      type: "textField",
      name: "description",
      placeholder: "Description",
      length: 100,
      view: true,
    },
    {
      label: "Customer Name *",
      type: "dropdown",
      name: "customerName",
      placeholder: "Choose Customer",

      view: isOEM ? true : false,
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
  ];

  return array;
};

export const StatusConfig = () => {
  let array = [
    {
      label: "Status Master Name *",
      type: "textField",
      name: "statusMasterName",
      length: 50,
      placeholder: "Status Master Name",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];

  return array;
};

export const userConfigurationField = () => {
  let array = [
    {
      label: "First Name *",
      type: "textField",
      length: 50,
      name: "firstName",
      placeholder: "Jhon",
      view: true,
    },
    {
      label: "Last Name *",
      type: "textField",
      name: "lastName",
      placeholder: "Kumar",
      view: true,
    },
    {
      label: "Email ID *",
      type: "textField",
      name: "emailId",
      placeholder: data.mail,
      view: true,
    },
    {
      label: "Mobile No *",
      type: "number",
      name: "mobileNo",
      placeholder: data.mobile,
      view: true,
    },
    {
      label: "Country *",
      type: "dropdown",
      name: "country",
      placeholder: data.country,
      view: true,
    },
    {
      label: "State *",
      type: "dropdown",
      name: "state",
      placeholder: data.state,
      view: true,
    },
    {
      label: "City *",
      type: "textField",
      length: 50,
      name: "city",
      placeholder: data.city,
      view: true,
    },
    {
      label: "Postal Code *",
      type: "number",
      name: "postalCode",
      placeholder: data.postalCode,
      view: true,
    },
    {
      label: "Designation *",
      type: "textField",
      length: 50,
      name: "designation",
      placeholder: "Designation",
      view: true,
    },
    {
      label: "User Type *",
      type: "dropdown",
      name: "userTypeName",
      placeholder: "Choose user type",
      view: isOEM ? true : false,
    },
    {
      label: "Customer Name *",
      type: "dropdown",
      name: "customerName",
      placeholder: "Choose Customer",
      view: isOEM ? true : false,
    },
    {
      label: "Role *",
      type: "dropdown",
      name: "roleName",
      placeholder: "Super Admin",
      view: true,
    },
    {
      label: "User Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
  ];

  return array;
};

export const profileFields = () => {
  let array = [
    {
      label: "First Name",
      type: "textField",
      section: "profile",
      name: "firstName",
      length: 50,
      placeholder: "Jhon",
      view: true,
    },
    {
      label: "Last Name",
      type: "textField",
      section: "profile",
      name: "lastName",
      length: 50,
      placeholder: "Kumar",
      view: true,
    },
    {
      label: "Email ID",
      section: "profile",
      type: "textField",
      name: "emailId",
      length: 50,
      placeholder: data.mail,
      view: true,
    },
    {
      label: "Mobile No",
      type: "number",
      section: "profile",
      name: "mobileNo",
      placeholder: data.mobile,
      view: true,
    },
    {
      label: "Designation *",
      type: "textField",
      name: "designation",
      length: 50,
      section: "profile",
      placeholder: "Designation",
      view: true,
    },
    {
      label: "Role *",
      type: "dropdown",
      section: "profile",
      length: 50,
      name: "roleName",
      placeholder: "Super Admin",
      view: true,
    },
    {
      label: "Country",
      section: "address",
      type: "dropdown",
      length: 50,
      name: "country",
      placeholder: data.country,
      view: true,
    },
    {
      label: "State",
      section: "address",
      type: "dropdown",
      name: "state",
      length: 50,
      placeholder: data.state,
      view: true,
    },
    {
      label: "City",
      section: "address",
      type: "textField",
      name: "city",
      length: 50,
      placeholder: data.city,
      view: true,
    },
    {
      label: "Postal Code",
      section: "address",
      type: "number",
      name: "postalCode",
      placeholder: data.postalCode,
      view: true,
    },
  ];

  return array;
};

export const errorConfigField = () => {
  let array = [
    {
      label: "Error Code *",
      type: "number",
      name: "errorCode",
      placeholder: "Error Code ",
      view: true,
    },
    {
      label: "Customer Name *",
      type: "dropdownMulti",
      name: "customerName",
      placeholder: "Choose Customer",
      view: isOEM ? true : false,
    },
    {
      label: "Equipment Category *",
      type: "dropdown",
      name: "equipmentCategory",
      placeholder: data.equipCategory,
      view: true,
    },
    {
      label: "Equipment Type *",
      type: "dropdown",
      name: "equipmentType",
      placeholder: data.equipType,
      view: true,
    },
    {
      label: "Description",
      type: "textField",
      length: 100,
      name: "description",
      placeholder: "Description",
      view: true,
    },
    {
      label: "Error Message *",
      type: "textField",
      length: 50,
      name: "errorMessage",
      placeholder: "Grid Abnormal",
      view: true,
    },
    {
      label: "Error Type *",
      type: "textField",
      name: "messageType",
      length: 100,
      placeholder: "Inverter Down",
      view: true,
    },
    {
      label: "Priority *",
      type: "dropdown",
      name: "priority",
      placeholder: "Less",
      view: true,
    },
    {
      label: "Approved By *",
      type: "textField",
      name: "approvedBy",
      placeholder: "Approver Name",
      view: true,
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
  ];
  return array;
};
export const Statusconfig = () => {
  let array = [
    {
      label: "Status Master Name *",
      type: "textField",
      name: "statusMasterName",
      placeholder: "Status Master Name",
      view: true,
    },
    {
      label: "Customer Name *",
      type: "dropdown",
      name: "customerName",
      placeholder: "Choose Customer",
      view: isOEM ? true : false,
    },
    {
      label: "Status*",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
  ];

  return array;
};

export const Reportreport = () => {
  let array = [
    {
      label: "Report Name *",
      type: "textField",
      name: "reportName",
      placeholder: "Report Name",
    },
    {
      label: "Email *",
      type: "textField",
      name: "emailId",
      length: 100,
      placeholder: data.mail,
    },

    {
      label: "CC Email *",
      type: "textField",
      name: "emailIdCC",
      length: 100,
      placeholder: data.mail,
    },

    // {
    //   label: 'Site Name *',
    //   type: 'new',
    //   name: 'siteName',
    //   placeholder: data.siteName,
    // },
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },

    // {
    //   label: 'Report Details *',
    //   type: 'multidropdown',
    //   name: 'parameterName',
    //   placeholder: 'Parameter',
    // },

    // {
    //   label: 'Site Name *',
    //   type: 'new',
    //   name: 'siteName',
    //   placeholder: data.siteName,
    // },

    {
      label: "Schedule Frequency *",
      type: "dropdown",
      name: "range",
      placeholder: "Monthly",
    },
    {
      label: 'Status *',
      type: 'dropdown',
      name: 'status',
      placeholder: 'Status',
    },
    // {
    //   label: 'Site Name *',
    //   type: 'new',
    //   name: 'siteName',
    //   placeholder: data.siteName,
    // },

    {
      label: 'From *',
      type: 'date',
      name: 'from',
      placeholder: 'From',
    },
    {
      label: 'To *',
      type: 'datee',
      name: 'To',
      placeholder: 'To',
    },
  ];

  return array;
};

export const Reportnew = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Select  *",
      type: "dropdownn",
      name: "select",
      placeholder: "Report Name",
    },
    {
      label: "Time Period *",
      type: "dropdown",
      name: "timePeriod",
      placeholder: "Monthly",
    },
  ];

  return array;
};
export const Tickteview = () => {
  let array = [
    {
      label: "From Date *",
      type: "date",
      name: "fromdate ",
      placeholder: "From Date",
    },
    {
      label: "To Date *",
      type: "datee",
      name: "todate ",
      placeholder: "To Date",
    },
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "State ",
      type: "dropdown",
      name: "ticketstate",
      placeholder: "Open",
    },

    {
      label: "Ticket Category ",
      type: "dropdown",
      name: "ticketcategory",
      placeholder: "Module Cleaning",
    },

    {
      label: "Priority ",
      type: "dropdown",
      name: "priority",
      placeholder: "Less",
    },

    {
      label: "Assign To",
      type: "multidropdown",
      name: "assignedTo",
      placeholder: "Less",
    },
    {
      label: "ticketStatus",
      type: "dropdown",
      name: "ticketStatus",
      placeholder: "Less",
    },
  ];

  return array;
};

export const ftp = () => {
  let array = [
    {
      label: "From Date *",
      type: "date",
      name: "fromdate ",
      placeholder: "From Date",
    },
    {
      label: "To Date *",
      type: "datee",
      name: "todate ",
      placeholder: "To Date",
    },
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "State ",
      type: "dropdown",
      name: "ticketstate",
      placeholder: "Open",
    },

    {
      label: "Ticket Category ",
      type: "dropdown",
      name: "ticketcategory",
      placeholder: "Module Cleaning",
    },

    {
      label: "Priority ",
      type: "dropdown",
      name: "priority",
      placeholder: "Less",
    },

    {
      label: "Assign To",
      type: "multidropdown",
      name: "assignedTo",
      placeholder: "Less",
    },
    {
      label: "ticketStatus",
      type: "dropdown",
      name: "ticketStatus",
      placeholder: "Less",
    },
  ];

  return array;
};
export const dgreview = () => {
  let array = [
    {
      label: "From Date *",
      type: "date",
      name: "fromdate ",
      placeholder: "From Date",
    },
    {
      label: "To Date *",
      type: "datee",
      name: "todate ",
      placeholder: "To Date",
    },
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },

    {
      label: "Equipment Name ",
      type: "multidropdown",
      name: "equipmentName",
      placeholder: "Inv-02",
    },
  ];

  return array;
};
export const Document = () => {
  let array = [
    {
      label: "Document Type*",
      type: "dropdown",
      name: "documentname",
      placeholder: "Contractual",
    },
  ];

  return array;
};
export const Formula = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Equipment Name *",
      type: "dropdown",
      name: "displayName",
      placeholder: data.equipName,
    },
    // {
    //   label: 'Parameter Name *',
    //   type: 'dropdown',
    //   name: 'stdParameterName',
    //   placeholder: 'PITCH',
    // },
    // {
    //   label: 'Fomula *',
    //   type: 'textField',
    //   length: 50,
    //   name: 'formula',
    //   placeholder: '(X)+(y)',
    // },
  ];

  return array;
};
export const Scriptions = () => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Po Number *",
      type: "textField",
      name: "poNumber",
      placeholder: "123",
    },
    {
      label: "From Date *",
      type: "date",
      name: "PoFromDate",
      placeholder: "",
    },
    {
      label: "To Date",
      type: "date",
      name: "PoToDate",
      placeholder: "",
    },
    // {
    //   label: 'Flog *',
    //   type: 'dropdown',
    //   name: 'flog',
    //   placeholder: 'No',
    // },

    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
    {
      label: "Data Processing",
      type: "switch",
      name: "curingData",
      placeholder: "No",
    },
  ];

  return array;
};

export const ActiveName = () => {
  let array = [
    {
      label: "Active Name",
      type: "textField",
      length: 50,
      name: "activityName",
      placeholder: "activityName",
    },
  ];
  return array;
};

export const CustomizeAlert = () => {
  let array = [
    { label: "siteName", type: "dropdown", name: "siteName" },
    { label: "Equipment", type: "dropdown", name: "customerNaming" },
    { label: "Parameter", type: "dropdown", name: "parameter" },
    { label: "Logic", type: "dropdown", name: "logic" },
    { label: "Value", type: "textField", name: "value", length: 50 },
    { label: "Email", type: "textField", name: "emailId" },
    {
      label: "Error Message",
      type: "textField",
      name: "errorMessage",
      length: 50,
    },
    // { label: 'From Time', type: 'textField', name: 'fromTime'},
    // { label: 'To Time', type: 'textField', name: 'toTime'},
  ];

  return array;
};

export const siteConfigField = () => {
  let array = [
    {
      label: "Site Name *",
      type: "textField",
      name: "siteName",
      placeholder: data.siteName,
      view: true,
    },
    {
      label: "Customer Name *",
      type: "dropdown",
      name: "customerName",
      placeholder: "Choose Customer",
      view: isOEM ? true : false,
    },
    {
      label: "Sector Type *",
      type: "dropdown",
      name: "sectorName",
      placeholder: data.sectorName,
      view: true,
    },
    {
      label: "Site Type *",
      type: "dropdown",
      name: "siteType",
      placeholder: " Utility",
      view: true,
    },
    {
      label: "Installation Capacity *",
      type: "capacity",
      name: "installationCapacity",
      placeholder: data.cap,
      option: "units",
      view: true,
    },
    {
      label: "Energy Gen Based *",
      type: "dropdown",
      name: "energyGenBasedOn",
      placeholder: "Energy Gen Based",
      view: true,
    },
    {
      label: "Latitude *",
      type: "float",
      name: "latitude",
      length: 9,
      placeholder: data.lati,
      view: true,
    },
    {
      label: "Longitude *",
      type: "float",
      name: "longitude",
      length: 10,
      placeholder: data.longi,
      view: true,
    },
    {
      label: "Time Zone*",
      type: "dropdown",
      name: "timezoneName",
      placeholder: data.timezoneName,
      view: true,
    },
    // {
    //   label: 'energyCounter*',
    //   type: 'dropdown',
    //   name: 'energyCounter',
    //   placeholder: 'yes',
    //   view: true,
    // },
    {
      label: "Address*",
      type: "textField",
      name: "address",
      length: 100,
      placeholder: data.address,
      view: true,
    },
    {
      label: "Postal Code *",
      type: "number",
      name: "postalCode",
      placeholder: data.postalCode,
      length: 6,
      view: true,
    },
    {
      label: "Country *",
      type: "dropdown",
      name: "country",
      placeholder: data.country,
      view: true,
    },
    {
      label: "State *",
      type: "dropdown",
      name: "state",
      placeholder: data.state,
      view: true,
    },
    {
      label: "City *",
      type: "textField",
      name: "city",
      length: 50,
      placeholder: data.city,
      view: true,
    },
    {
      label: "Reference Code",
      type: "labelWithField",
      name: "siteReference",
      length: 50,
      // placeholder: data.city,
      view: true,
    },
    {
      label: "Multiple Controller",
      type: "dropdown",
      name: "multipleController",
      placeholder: "Multiple Contoller",
      view: true,
    },
    {
      label: "Combined DataLogger",
      type: "dropdown",
      name: "combinedDataLogger",
      placeholder: "No",
      view: true,
    },
    {
      label: "Combined Sites",
      type: "dropdown",
      name: "combinedSites",
      placeholder: "Select Sites",
      view: true,
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
      view: true,
    },
  ];
  return array;
};
export const signUpField = () => {
  let array = [
    { label: "First Name", type: "text", name: "firstName", length: 30 },
    { label: "Last Name", type: "text", name: "lastName", length: 30 },
    { label: "Email ID", type: "text", name: "emailId", length: 50 },
    { label: "Mobile Number", type: "number", name: "mobileNo" },
    { label: "User Type", type: "dropdown", name: "userType" },
    { label: "Subscription Type ", type: "dropdown", name: "SubscriptionType" },
    { label: "Company Name", type: "text", name: "customerName", length: 50 },
  ];
  return array;
};
export const sectorField = () => {
  let array = [
    {
      label: "Sector Name *",
      type: "dropdown",
      name: "sectorName",
      placeholder: data.sectorName,
    },
    { label: "Status *", type: "dropdown", name: "status" },
  ];
  return array;
};

export const IndustryType = () => {
  let array = [
    {
      label: "Sector Name *",
      type: "textField",
      name: "sectorName",
      length: 50,
      placeholder: data.sectorName,
    },
    {
      label: "Description *",
      type: "textField",
      name: "description",
      length: 100,
      placeholder: "Description",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "Status",
    },
  ];
  return array;
};

export const ticketConfigField = (ticketType) => {
  let array = [
    {
      label: "Site Name *",
      type: "dropdown",
      name: "siteName",
      placeholder: data.siteName,
    },
    {
      label: "Ticket Type *",
      type: "dropdown",
      name: "equipmentType",
      placeholder: data.equipType,
    },
    {
      label: "Ticket Category *",
      type: "dropdown",
      name: "equipmentCategory",
      placeholder: data.equipCategory,
    },
    {
      label: "Equipment Name *",
      type: "dropdown",
      name: "equipmentName",
      placeholder: data.equipName,
    },
    // {
    //   label: 'Equipment Name *',
    //   type: 'multiselect',
    //   name: 'equipmentName',
    //   placeholder: data.equipName,
    // },

    {
      label: "Subject *",
      type: "textField",
      name: "subject",
      length: 50,
      placeholder: "Subject",
    },
    {
      label: "Priority *",
      type: "dropdown",
      name: "priority",
      placeholder: "Low",
    },
    {
      label: "Description *",
      type: "textField",
      name: "description",
      length: 100,
      placeholder: "Description",
    },
  ];
  return array;
};

export const MasterField = () => {
  let array = [
    {
      label: "Site Name",
      type: "dropdown",
      name: "sectorName",
      placeholder: data.siteName,
    },
    {
      label: "Description",
      type: "text",
      name: "decription",
      length: 100,
      placeholder: "Description",
    },
    {
      label: "Remarks",
      type: "text",
      name: "remark",
      placeholder: "Remark",
      length: 50,
    },
  ];
  return array;
};

export const CompanyListTextField = () => {
  let array = [
    {
      label: "Company Name *",
      type: "textField",
      name: "companyName",
      length: 50,
      placeholder: "Company Name",
    },
    {
      label: "Email Id *",
      type: "textField",
      name: "emailId",
      length: 50,
      placeholder: data.mail,
    },
    {
      label: "Company Website *",
      type: "textField",
      length: 50,
      name: "companyWebsite",
      placeholder: "Company Website",
    },
    {
      label: "Country *",
      type: "dropdown",
      name: "country",
      placeholder: data.country,
    },
    {
      label: "State *",
      type: "dropdown",
      name: "state",
      placeholder: data.state,
    },
    {
      label: "Address *",
      type: "textField",
      length: 100,
      name: "address",
      placeholder: data.address,
    },
    {
      label: "Contact Person *",
      type: "textField",
      name: "contactPerson",
      length: 50,
      placeholder: "Contact Person",
    },
    {
      label: "Mobile Number *",
      type: "number",
      name: "mobileNumber",
      placeholder: data.mobile,
    },
    // { label: 'Telephone Number *', type: 'number', name: 'telePhoneNumber', placeholder: "Telephone Number" },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: data.status,
    },
    {
      label: "Company Logo *",
      type: "logo",
      name: "companyLogoPath",
      placeholder: "Company Logo",
    },
  ];

  return array;
};

export const StatusMaster = () => {
  const array = [
    {
      label: "Status Code *",
      type: "number",
      name: "statusCode",
      placeholder: "Status Code",
    },
    {
      label: "Derived Status *",
      type: "dropdown",
      name: "derivedStatus",
      placeholder: "Derived Status",
      view: true,
    },
    {
      label: "Status Message *",
      type: "textField",
      name: "statusMessage",
      length: 50,
      placeholder: "Status Message",
    },
    {
      label: "Description *",
      type: "textField",
      name: "description",
      length: 100,
      placeholder: "Description",
    },
    {
      label: "Status *",
      type: "dropdown",
      name: "status",
      placeholder: "data.status",
      view: true,
    },
  ];
  return array;
};
