import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Backdrop,
  Divider,
  CircularProgress,
  Tooltip,
  Card,
  Checkbox,
  CardHeader,
  CardContent,
} from '@mui/material';
import FadeLoader from 'react-spinners/FadeLoader';
// ========Modal================

import { styled } from '@mui/material/styles';

/*------------api call-----------------*/
import {
  fetchEquipmentTable,
  putEquipmenttype,
  postEquipmentSave,
  Equipmentcategorydropdown,
  Equipmenttypedropdown,
  fetchEquipmentTableById,
} from '../../Api/EquipmenttypeApi';
import TableTemplate from '../../Template/TableTemplate';
import Download from '../../Template/Excel/Download';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import errorMsg from '../util/errorMessage.json';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import{
    GetCustomerdropdowndata,
    fetchSiteTableById,
    fetchsiteblockTable,
    postsiteblockTable,
    putsiteblockTable
} from '../../Api/Siteblockapi'
import { siteblock } from '../util/TextField';
import CustomSnackbar from '../util/components/CustomSnackbar';
import { blockmap } from '../util/ValidateSchema';
import { Statusdropdown } from '../../Api/CommonApi';
import replaceObjectById from '../util/StateUpdater';
import { CheckForLight } from '../util/common/CommanFunction';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Blockmap({  standardParameter }) {
  const [SiteEquipment, setSiteEquipment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [editcall, seteditcall] = useState();
  const [siteTab, setSiteTab] = useState('');
  const [SiteId, setSiteId] = useState([]);
  const [text, setTextField] = useState([]);
  const [customerid, setCustomerId] = useState([]);
  const initalState = {
    customerName:"",
    blockName:"",
    siteName:[],
    blockId:""
  };
  const [stdField, setstdField] = useState(initalState);
  console.log(stdField);
  
  const initialSnack = { open: false, severity: '', message: '' };
  const [toBackend, setToBackend] = useState(false);
  const [snack, setSnack] = useState(initialSnack);
  const [statusDrop, setStatusDrop] = useState([]);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  console.log(SiteId,"----");
  
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchStatus();
    let data = siteblock();
    setTextField(data);
    // fetchEquipmenttype();
    // fetchEquipmentcategory();
    getcustomerlist()
  }, []);

  const getsitelist = async (Id) => {
    console.log(Id);
    
  
    let customer = customerid?.filter((data) => {
      return data.customerName === Id;
    });
    console.log(customer);
    try {
      let data = await fetchSiteTableById(
        customer[0]?.customerId
        );
        console.log(data);
        
 
        setSiteId(data);
      // } else if (typeof data === 'object' && data !== null) {
      //   setSiteId([data]);
      // }
    } catch (e) {
      console.error(e, 'errrrrror');
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === 'object' && data !== null) {
      setStatusDrop([data]);
    }
  };
  console.log(customerid, 'ghjk');

  const handleDropdownChange = (event, value, name) => {
    console.log( value, name);
   
    console.log(name,value);
    if (name==="customerName"){
   
      getsitelist(value)
    }
    
    setstdField({ ...stdField,siteName:[] ,[name]: value });
  };
  const handleChangeTxt = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };
  const handleDropDownChangess = (event, values, name) => {
    console.log("Selected values:", values);

    
    // Filter out duplicate labels
    const labelCounts = {};
    values.forEach((item) => {
      labelCounts[item.label] = (labelCounts[item.label] || 0) + 1;
    });
  
    const filteredValues = values.filter((item) => labelCounts[item.label] === 1);
    console.log("Filtered unique values:", filteredValues);
  
    // Update state with only the labels (strings)
    setstdField((prev) => {
      const updated = {
        ...prev,
        [name]: filteredValues.map((item) => item.label),
      };
      console.log("Updated stdField state:", updated);
      return updated;
    });
  };
  
  function convertToOptions(array) {
    return array?.map((label) => ({ label }));
  }
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    const Id =editcall?.blockId
    
    const selectedSiteIds = getSelectedIds(
      stdField.siteName,
      SiteId,
      "siteName",
      "siteId"
    );
    let customer=customerid?.filter((data)=>{
      return data.customerName===stdField.customerName
    })
    console.log(selectedSiteIds);
    console.log(customer);
    
    let data = {
      siteIds:selectedSiteIds,
      blockName:stdField?.blockName,
      customerId:customer[0]?.customerId
    };
    console.log(data);
    try {
      setToBackend(true);
      let responsedata = await putsiteblockTable(Id,data );
      setSnack(errorMsg.success);
      handleClose();
      window.location.reload()
      setToBackend(false);
     
      return 1;
    } catch (e) {
        setSnack(errorMsg.failure);
      setToBackend(false);
      return 0;
    }
  };
  const getSelectedIds = (
    selectedValues,
    sourceArray,
    targetPropertyName,
    resultPropertName
  ) => {
    return selectedValues.map((selectedValue) => {
      const selectedItem = sourceArray.find(
        (item) => item[targetPropertyName] === selectedValue
      );
      return selectedItem ? selectedItem[resultPropertName] : undefined;
    });
  };
  const handleSave = async (value) => {
    const selectedSiteIds = getSelectedIds(
      stdField.siteName,
      SiteId,
      "siteName",
      "siteId"
    );
let customer=customerid?.filter((data)=>{
  return data.customerName===stdField.customerName
})
console.log(customer);

    let data = {
      siteIds:selectedSiteIds,
    blockName:stdField?.blockName,
    customerId: customer[0]?.customerId,
   
    };
    console.log(data, 'data');
    try {
      setToBackend(true);
      const responseData = await postsiteblockTable(data);
      console.log(responseData);
      console.log();
      fetchData(data, 'ghjm');
      setSnack({
        ...snack,
        open: true,
        severity: 'success',
        message: responseData.statusDescription,
      });
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      if (e.response.status === 406) {
        setSnack(errorMsg.unique.equipmntCategory);
      } else {
        setSnack(errorMsg.failure);
        console.log('equipment:', e.message);
      }
      setToBackend(false);
      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setloading(false);
    
    try {
      const data = await fetchsiteblockTable();
  
      console.log(data);
      if (Array.isArray(data)) {
        setSiteEquipment(convertData(data));
        
      } else if (typeof data === "object" && data !== null) {
        setSiteEquipment([convertData(data)]);
      }
      setSiteEquipment(convertData(data));
   
   

      console.log(data);
    } catch (e) {
      console.error(e);
    }
  };
  console.log(SiteEquipment, 'ghjkl;');

  /*---------------------------------- New Equipment Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall('');
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    seteditcall('');
    setEdit(false);
    setstdField(initalState);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };
  const statusvalue = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (val) => {
    console.log(val);
    
    // let filteredcategoryId = Object.keys(initalState).reduce((acc, key) => {
    //   if (categoryId.hasOwnProperty(key)) {
    //     acc[key] = categoryId[key];
    //   }
    //   return acc;
    // }, {});
   console.log(val?.customerName);
   
     getsitelist(val?.customerName)
    seteditcall(val);
    setstdField(val);
    setEdit(true);
    setOpen(true);
  };
  function convertData(inputData) {
    console.log(inputData, "data from fetch");
    let result = [];

    inputData.forEach((item) => {
      let siteNamesArray = item.siteMasterDTOs.map((site) => site.siteName?.trim());
      let siteIdsArray = item.siteMasterDTOs.map((site) => site.siteId);
     

      result.push({
        siteNamesString: siteNamesArray.join(", "),
        siteName: siteNamesArray,
     siteId:siteIdsArray,
        customerName:item.customerName,
       blockName: item.blockName,
       blockId:item.blockId
       
      });
    });
    console.log(result, "jdhfjjdsh");
    return result;
  }
  const headCells = [
    {
      label: 'plant',
      id: 'blockName',
      view: true,
      default: true,
    },
    {
      label: 'Customer Name',
      id: 'customerName',
      view: true,
      default: true,
    },
    {
      label: 'SiteName',
      id: 'siteNamesString',
      view: true,
      default: true,
    },
   
    {
      label: 'Action',
      id: 'action',
      view: true,
      default: true,
    },
   
  ];

  const handleDownloadTable = (val) => {
    Download(val, 'Equipment Category');
  };
let data =SiteEquipment


data.forEach(item => {
  // Join the siteName array into a comma-separated string
  const siteNamesString = item.siteName.join(", ");
  console.log(siteNamesString); // Logs each site's name as a comma-separated string
});
console.log(data);

  
  const getcustomerlist = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      // console.log(data,"sectorData");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e, "errrrrror");
    }
  };

  function getOptions(propName) {
    let options = [];
    switch (propName) {
 
      case "siteName":
        options = SiteId.map((option) => ({  label:option.siteName }));
        // return (options = SiteId?.map((option) => option.siteName));
        break;
      case "customerName":
      
        return (options = customerid?.map((option) => option.customerName));
        break;
      case "status":
        return (options = statusDrop?.map((option) => option.label));
      default:
        break;
    }
    return options;
  }

  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Block Map', path: 'equipmentCategory' },
  ];

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            {/* ---------   -----save andupdate---- popup----------- */}
            <Formik
              key={edit ? 'edit' : 'create'}
              enableReinitialize={true}
              initialValues={stdField}
              validationSchema={blockmap}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (edit) {
                  console.log(values);
                  const result = await handleUpdate(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);
                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({ values, handleChange, handleSubmit, handleReset }) => (
                <Form>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 500,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box
                        className={'styleModalSmall'}
                        sx={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        <Card
                          sx={{
                            borderRadius: '20px',
                            // height: '68vh',
                            height: 'fit-content',
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: '20px 20px 20px 20px',
                              background: CheckForLight()
                                ? 'rgb(246, 248, 252)'
                                : '#4f4f4f',
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                {edit ? (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Updating...' : 'Update'}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: '25px',
                                      textTransform: 'capitalize',
                                    }}
                                    
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSubmit}
                                  >
                                    {toBackend ? 'Saving...' : 'Save'}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: '25px',
                                    textTransform: 'capitalize',
                                  }}
                                  onClick={() => {
                                    handleClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={
                              edit
                                ? 'Update Block'
                                : 'Add New Block'
                            }
                          />
                          <Divider style={{ borderColor: '#888' }} />
                          <Card
                            sx={{
                              overflowY: 'visible',
                              marginLeft: '-1vw',
                              // paddingLeft: '-1vw',
                              // marginTop: '-2%',
                              // marginBottom: '-2%',
                              scrollBehavior: 'smooth',
                              scrollbarGutter: 'stable',
                              scrollbarWidth: 'thin',
                              '&::-webkit-scrollbar': {
                                width: '0.4em',
                              },
                              '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '20px',
                              },
                              '&::-webkit-scrollbar-thumb:hover': {
                                background: '#555',
                              },
                            }}
                            elevation={0}
                          >
                            <CardContent sx={{ paddingTop: 0 }}>
                              {text?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginBottom: '2.2vh',
                                  }}
                                  className="modelGrid"
                                >
                                  <Grid container spacing={3} columns={32}>
                                    {text?.map((data, index) => {
                                      return (
                                        <>
                                          {data.type === 'dropdown' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Tooltip
                                                  title={
                                                    !edit &&
                                                    data.name === 'status'
                                                      ? 'Active'
                                                      : edit &&
                                                        data.name === 'status'
                                                      ? typeof values[
                                                          data.name
                                                        ] === 'string'
                                                        ? values[data.name]
                                                        : values.status === 1
                                                        ? 'Active'
                                                        : 'Inactive'
                                                      : values[data.name]
                                                  }
                                                >
                                                  <Field
                                                    name={data.name}
                                                    render={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === 'status'
                                                            ? 'Active'
                                                            : edit &&
                                                              data.name ===
                                                                'status'
                                                            ? typeof values[
                                                                data.name
                                                              ] === 'string'
                                                              ? values[
                                                                  data.name
                                                                ]
                                                              : values.status ===
                                                                1
                                                              ? 'Active'
                                                              : 'Inactive'
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Autocomplete
                                                          disabled={
                                                            toBackend ||
                                                            (!edit &&
                                                              data.name ===
                                                                'status')
                                                          }
                                                          disableClearable
                                                          options={getOptions(
                                                            data.name
                                                          )}
                                                          onChange={(
                                                            event,
                                                            value
                                                          ) => {
                                                            handleDropdownChange(
                                                              event,
                                                              value,
                                                              data.name
                                                            );
                                                          }}
                                                          disablePortal
                                                          size="small"
                                                          value={
                                                            !edit &&
                                                            data.name ===
                                                              'status'
                                                              ? 'Active'
                                                              : edit &&
                                                                data.name ===
                                                                  'status'
                                                              ? typeof values[
                                                                  data.name
                                                                ] === 'string'
                                                                ? values[
                                                                    data.name
                                                                  ]
                                                                : values.status ===
                                                                  1
                                                                ? 'Active'
                                                                : 'Inactive'
                                                              : values[
                                                                  data.name
                                                                ]
                                                          }
                                                          id={`combo-box-demo-${index}`}
                                                          sx={{ width: '15vw' }}
                                                          renderInput={(
                                                            params
                                                          ) => (
                                                            <TextField
                                                              {...params}
                                                              placeholder={
                                                                data.placeholder
                                                              }
                                                            />
                                                          )}
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  />
                                                </Tooltip>
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          )
                                         :data.type === "multidropdown" ? (
                                            // =<Field
                                            //  name={data.name}
                                            //  render={({ field, form }) => (

                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                            <Autocomplete
                                              multiple
                                              id="checkboxes-tags-demo"
                                              options={getOptions(data.name)}
                                              limitTags={1}
                                              disableClearable
                                              disableCloseOnSelect
                                              sx={{ width: "15vw" }}
                                              size="small"
                                           disabled={!stdField?.customerName}
                                              onChange={(event, values) => {
                                                console.log(values, "handler");
                                                handleDropDownChangess(
                                                  event,
                                                  values,
                                                  data.name
                                                );
                                              }}
                                              value={
                                                stdField[data.name]
                                                  ? convertToOptions(
                                                    stdField[data.name]
                                                    )
                                                  : []
                                              }
                                              placeholder={data.placeholder}
                                              renderOption={(props, option) => (
                                                <li {...props}>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={stdField[data.name]
                                                      ?.map((item) =>
                                                        item
                                                          .toLowerCase()
                                                          .replace(/\s+/g, "")
                                                      )
                                                      .includes(
                                                        option.label
                                                          .toLowerCase()
                                                          .replace(/\s+/g, "")
                                                      )}
                                                  />
                                                  {option.label}
                                                </li>
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder={data.placeholder}
                                                />
                                              )}
                                            />
                                              </Grid>
                                          ):
                                           data.type === 'textField' ? (
                                            <>
                                              <Grid item xs={10}>
                                                <Typography
                                                  variant="body1"
                                                  className="modal-typo"
                                                  gutterBottom
                                                >
                                                  {data.label}
                                                </Typography>
                                                <Field
                                                  as={TextField}
                                                  disabled={toBackend}
                                                  id={`outlined-basic-${index}`}
                                                  size="small"
                                                  variant="outlined"
                                                  name={data.name}
                                                  inputProps={{
                                                    maxLength: data.length,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleChangeTxt(e);
                                                  }}
                                                  placeholder={data.placeholder}
                                                  value={values[data.name]}
                                                  sx={{ width: '15vw' }}
                                                />
                                                <ErrorMessage
                                                  name={data.name}
                                                  component="div"
                                                  className="errorStyle"
                                                  style={{
                                                    color: 'red',
                                                    marginTop: '1%',
                                                    textAlign: 'left',
                                                    marginLeft: '0%',
                                                  }}
                                                />
                                              </Grid>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </div>
                              ) : null}
                            </CardContent>
                          </Card>
                        </Card>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: '-0.5%' }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={'Block Map'}
              addButton={'Block Map'}
              SearchLabel={'Search category Here... '}
              header={headCells}
              rowsValue={SiteEquipment}
            
              rawData={SiteEquipment}
              userRole={ standardParameter[0]}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No plant available"`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
