import axios from 'axios';
export const fetchSiteDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
          process.env.REACT_APP_SITELIST_BYUSER
        }/${sessionStorage.getItem('id')}`
      );
      console.log(response, 'resssss');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  export const fetchcurring = async (fromDate,ToDate,siteId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DGRVALUE}${
          process.env.REACT_APP_CUMULATIVE_SAVE
        }=${fromDate}&toDate=${ToDate}&siteIds=${siteId}`
      );
      console.log(response, 'resssss');
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  export const fetchequipmentcumulative = async (fromDate,ToDate,siteId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DGRVALUE}${
          process.env.REACT_APP_CUMULATIVE_EQUIPMENT_SAVE
        }=${fromDate}&toDate=${ToDate}&siteIds=${siteId}`
      );
      console.log(response, 'resssss');
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }