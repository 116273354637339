import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import TollIcon from "@mui/icons-material/Toll";
import CloudIcon from "@mui/icons-material/Cloud";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useNavigate } from "react-router-dom";
import { CustomIcons } from "../../util/components/customSvgIcon";

const siteIcon = CustomIcons;

const TypographyStyled = ({ children, variant, ...props }) => (
  <Typography
    style={{
      textTransform: "capitalize",
      lineHeight: "14.52px !important",
      color: "rgb(113, 118, 111)",
      fontSize: "14px !important",
    }}
    variant={variant}
    {...props}
  >
    {children}
  </Typography>
);

const CardItem = ({ item, navigate }) => (
  <Card
    elevation={0}
    style={{
      //   border: "2px solid rgb(17, 1, 41)",
      marginBottom: "10px",
      width: "16vw",
      padding: "0px 1px",
    }}
  >
    <CardContent
      elevation={0}
      style={{
        paddingBottom: "16px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        elevation={0}
      >
        <TypographyStyled
          style={{
            fontWeight: 500,
            fontSize: "1.2rem",
            color: "rgba(0, 0, 0, 0.87);",
          }}
        >
          {item.maintitle}
        </TypographyStyled>
        <item.Icon
          icon={
            item.maintitle === "Total Sites"
              ? "solarPanel"
              : item.maintitle === "Tickets"
              ? "ticketFill"
              : item.maintitle === "Alarms"
              ? "alertBell"
              : item.maintitle === "Generation"
              ? "generationGreen"
              : ""
          }
          style={{ fontSize: "40px" }}
          sx={{
            alignSelf: {
              xs: "flex-start",
              md: "flex-end",
            },
            cursor: item.isButton ? "pointer" : null,
            color: item.isButton ? "blue" : null,
          }}
          onClick={() =>
            item.maintitle === "Total Sites"
              ? navigate("/menu/sitelist")
              : item.maintitle === "Tickets"
              ? navigate("/menu/ticket")
              : null
          }
        />
      </Box>
      <Box mt={item.maintitle === "Generation" ? 2 : 1}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", md: "row" }}
        >
          {item.subtitles.map((subtitle, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mb={{ xs: 2, md: 0 }}
            >
              <TypographyStyled
                style={{
                  fontWeight: 400,
                  fontSize: "0.9rem",
                  color: "rgba(0, 0, 0, 0.87);",
                }}
              >
                {subtitle.title}
              </TypographyStyled>
              <TypographyStyled
                style={{
                  fontWeight: 650,
                  fontSize: "1rem",
                  color:
                    item.maintitle === "Alarms"
                      ? "rgb(232, 58, 42)"
                      : item.maintitle === "Tickets"
                      ? "#2974a8"
                      : // : item.maintitle === "Total Sites"
                      // ? "#51cccc"
                      item.maintitle === "Generation"
                      ? "forestgreen"
                      : "rgba(0, 0, 0, 0.87);",
                }}
              >
                {subtitle.value}
              </TypographyStyled>
            </Box>
          ))}
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const TotalCard2 = (props) => {
  const [cardsData, setCardsData] = useState([]);
  const data = props.dashboardDetails;
  let subscriptionId = sessionStorage.getItem("subscriptionId");

  useEffect(() => {
    let mainTitle = (
      <span>
        CO{" "}
        <span
          style={{
            fontSize: "0.8rem",
            verticalAlign: "sub",
            lineHeight: "0",
            marginLeft: "-5px",
          }}
        >
          2
        </span>{" "}
        Avoided
      </span>
    );

    if (data) {
      console.log(data, "data");
      const transformToCardsData = (data) => {
        const totalSites = data.siteCount;

        const cardsData = [
          {
            maintitle: "Total Sites",
            maintitlevalue: String(totalSites),
            subtitles: [
              { title: "Roof Top", value: String(data.roofTopCount) },
              { title: "Utility", value: String(data.utilityCount) },
            ],
            Icon: siteIcon,
            isButton: true,
          },
          {
            maintitle: "Alarms",
            maintitlevalue: "",
            subtitles: [
              { title: "Today", value: String(data.alarmTodayCount) },
              { title: "Total", value: String(data.alarmTotalCount) },
            ],
            Icon: siteIcon,
            isButton: false,
          },
          {
            maintitle: "Tickets",
            maintitlevalue: "",
            subtitles: [
              { title: "Open", value: data.totalOpenTickets },
              { title: "Close", value: data.totalCloseTickets },
            ],
            Icon: siteIcon,
            isButton: true,
          },
          {
            maintitle: "Generation",
            maintitlevalue: "",
            subtitles: [
              { title: "Today", value: data.totalTodayEnergy },
              { title: "Total", value: data.sumOfTotalEnergy },
            ],
            Icon: siteIcon,
            isButton: false,
          },
        ];
        const filteredCardsData =
          Number(subscriptionId) !== 2
            ? cardsData.filter((card) => card.title !== "Events")
            : cardsData.filter((card) => card.maintitle !== "Tickets");

        return filteredCardsData;
      };

      setCardsData(transformToCardsData(data));
    }
  }, [data]);

  const navigate = useNavigate();

  return (
    <div
      className="card-main"
      style={{
        textTransform: "capitalize",
        lineHeight: "14.52px !important",
        color: "rgb(113, 118, 111)",
        fontSize: "14px !important",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {cardsData.map((item, index) => (
          <CardItem key={index} item={item} navigate={navigate} />
        ))}
      </div>
    </div>
  );
};

export default TotalCard2;
