import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  Divider,
  Stack,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import "./Style.css";
import { getTotalActivePower } from "../../Api/DataAnalystApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CheckForLight,
  sortedDataByMonth,
  transformDataForSpecificYield,
} from "../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from "../../Api/CommonApi";
import ApexChart from "../Components/AnalyticsDashboard/ApexexChart";

const optionTime = [
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" }, // { label: 'This Month', value: 'This Month' },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
];

export const TotalActivePower = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [range, setRange] = useState("Daily");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Default sort column (can be any column like 'inverter', 'power', etc.)

  useEffect(() => {
    setSite(siteName);
    setChartName("Total Active Power");
    setSelectedOption("Total Active Power");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    setRange("Daily");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      [
        "Weather Station",
        "Energy Meter",
        "String Inverter",
        "Central Inverter",
      ].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Specific Yield",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      timeperiod: "Today",
      parameters: ["ActivePower"],
      capacity: siteDetails.installationCapacity,
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    let responseData = await getTotalActivePower(dataModel);
    let result = responseData;
    setLoading(false);
    setTableData(result);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      [
        "Weather Station",
        "Energy Meter",
        "String Inverter",
        "Central Inverter",
      ].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      capacity: siteIdFilter[0]?.installationCapacity,
      toDate: value.toDate ? value.toDate : toDate,
      range: "daily",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      parameters: ["ActivePower"],
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await getTotalActivePower(data);
    responseData = sortedDataByMonth(responseData);
    let result = responseData;
    setLoading(false);
    setTableData(result);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }
    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "37vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "37vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "37vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "37vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "-10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem", fontWeight: 400 }}>
                Total Active Power
              </Typography>
            </div>
            <div style={{ visibility: "visible" }}>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range == "Daily"
                      ? `${moment(fromDate).format("DD/MM/YYYY")}`
                      : range == "Yearly"
                      ? `${moment(fromDate).format("YYYY")}`
                      : `${moment(fromDate).format("DD/MM")} - ${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime
                    .filter((option) => option.value !== "Yearly")
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) =>
                          handleMenuItemClick(option.value, "Time")
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "90%",
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <>
            <ApexChart
              chartName={chartName}
              tableData={tableData}
              multiChartData={multiChartData}
              name={"paramter"}
              timeValue={range}
              widgetName={``}
              fallBack={fallBack}
              isDashboard={true}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};
