import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  Card,
  CardContent,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  CircularProgress,
  TableHead,
  Tooltip,
  CardHeader,
  TablePagination,
  Pagination,
  useTheme,
  PaginationItem,
} from "@mui/material";

import * as yup from "yup";
import FadeLoader from "react-spinners/FadeLoader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Backdrop from "@mui/material/Backdrop";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
// ========Modal================
import { styled } from "@mui/material/styles";
import {
  fetchSiteTable,
  putsiteupdateEdit,
  Getdropdown,
  postSiteSave,
  GetSiteTypedropdown,
  fetchSectordropdownvalue,
  fetchSiteTableById,
  fetchTimeZone,
} from "../../Api/SiteConfigurationAPI";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  customerBasedSiteDropdown,
  Equipmentsitedropdown,
} from "../../Api/EquipmentApi";
import AdditionalFields from "../util/components/AdditionalFields";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { Delete } from "@mui/icons-material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import {
  addCustomerName,
  customerSiteType,
  siteConfigSchema,
  siteTypeValidation,
} from "../util/ValidateSchema";
import {
  fetctDocumentDetails,
  postDocumentdata,
  putDocumentDelete,
  fetchDocumentTable,
} from "../../Api/DocumentApi";
import { siteConfigField, Document } from "../util/TextField";
import {
  CountryDropdown,
  StateDropdown,
  Statusdropdown,
  fetchInstalltionCapacity,
} from "../../Api/CommonApi";
import replaceObjectById from "../util/StateUpdater";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import errorMsg from "../util/errorMessage.json";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GetCustomerdropdowndata } from "../../Api/UserConfigurationAPI";
import { CheckForLight } from "../util/common/CommanFunction";
export default function Reporthome({ siteConfiguration }) {
  const [open, setOpen] = React.useState(false);
  const [openn, setOpenn] = React.useState(false);
  /*------------ modal state-----------------*/
  const [Siteconfig, setSiteconfig] = useState([]);
  const [activeButton, setActiveButton] = useState("siteDetails");
  const [editValue, setEditValue] = useState({});
  const theme = useTheme();
  const [toBackend, setToBackend] = useState(false);
  const [activeTab, setActiveTab] = useState("siteDetails");
  const [Edit, setEdit] = useState(false);
  const [statevalue, setStatevalue] = useState();
  const [open1, openchange] = useState(false);
  const [timezoneName, setTimeZone] = useState();

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [customerId, setCustomerId] = useState([]);
  const [sitedropvalue, setSitedropvalue] = useState([]);
  const [Cityvalue, setCityvalue] = useState();
  const [Countryvalue, setCountryvalue] = useState();
  const [stdcompony, setstdcompony] = useState();
  const [Statusvalue, setStatusvalue] = useState();
  const [siteTypeDrop, setSiteTypeDrop] = useState();
  const [sectorTypeDrop, setSectorTypeDrop] = useState();
  const [loading, setLoading] = useState(true);
  const [siteTab, setSiteTab] = React.useState("");
  const [addHeadCells, setAddHeadCells] = useState([]);
  const [base64Image, setBase64Image] = useState("");
  const [upload, setupload] = useState(false);
  const [DocuemntIdName, setDocumentId] = useState([]);
  const [selectedImgName, setSelectedImgName] = useState("");
  const [value, setvalue] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageValue, setPageValue] = useState(0);
  const [SiteReport, setSiteReport] = useState([]);
  console.log(SiteReport);
  const colorMode = theme.palette.mode;
  console.log(value?.siteId);
  const initialState = {
    sitedetailadditionalfield: [],
    address: "",
    status: "",
    siteType: "",
    siteName: "",
    siteId: "",
    installationCapacity: "",
    country: "",
    postalCode: "",
    longitude: "",
    latitude: "",
    city: "",
    sectorName: "",
    companyName: "",
    customerName: "",
    state: "",
    units: "kWp",
    documentname: "",
    timezoneName: "",
    energyGenBasedOn: "",
    multipleController: "No",
    timeZoneId: "",
    utcoffset: "",
    energyCounter: "",
    siteReference: {
      state: null,
      siteType: null,
      siteId: null,
    },
    combinedDataLogger: "No",
    combinedSites: null,
  };
  const [fieldValue, setField] = useState(initialState);
  const [listOfSites, setListOfSites] = useState([]);
  console.log(fieldValue);
  console.log(timezoneName);
  const [text, setTextField] = useState([]);
  const [textvalue, setTextvalue] = useState();
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [countryDrop, setCountryDrop] = useState([]);
  const [stateDrop, setStateDrop] = useState([]);
  const [statusDrop, setStatusDrop] = useState([]);
  const [unitsIC, setUnitsIC] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("documentTypeName"); // Default sort column

  const handleSort = (column) => {
    const newSortDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
    setSortColumn(column);
  };

  const sortedReports = [...SiteReport].sort((a, b) => {
    const isAscending = sortDirection === "asc" ? 1 : -1;

    // Customize this logic based on the data type of the columns
    if (a[sortColumn] < b[sortColumn]) return -1 * isAscending;
    if (a[sortColumn] > b[sortColumn]) return 1 * isAscending;
    return 0;
  });

  console.log(fieldValue.documentname);
  const handleCapChange = (e, name) => {
    setField({ ...fieldValue, [name]: e.target.value });
  };

  const isOEM = sessionStorage.getItem("userTypeId") == 1;

  const handleTxtChange = (event) => {
    console.log(event, event.target.value, "valavalaa");
    if (event.target.name === "siteReference") {
      setField({
        ...fieldValue,
        siteReference: {
          ...fieldValue?.siteReference,
          siteId: event.target.value,
        },
      });
    } else {
      setField({ ...fieldValue, [event.target.name]: event.target.value });
    }
    console.log(fieldValue, "fieldValues");
  };
  // const handleDropdownChange = (event, value, name) => {
  //   if (name === "installationCapacity") {
  //     setField({ ...fieldValue, capacityUnits: value });
  //   } else if (name === "energyGenBasedOn") {
  //     setField(name, value.value);
  //   } else if (name === "country") {
  //     setField({ ...fieldValue, [name]: value, state: "" });
  //   } else {
  //     setField({ ...fieldValue, [name]: value });
  //   }
  // };
  function updatedState(name, value, refCode, addOn = {}) {
    const updatedFiled = { [name]: value, ...addOn };
    let updatedRef = { ...fieldValue.siteReference, ...refCode, ...addOn };
    setField({
      ...fieldValue,
      ...updatedFiled,
      siteReference: {
        ...fieldValue?.siteReference,
        ...updatedRef,
      },
    });
  }
  const handleDropdownChange = (event, value, name) => {
    if (name === "installationCapacity") {
      setField({ ...fieldValue, capacityUnits: value });
    } else if (
      name === "energyGenBasedOn" ||
      name === "multipleController" ||
      name === "combinedDataLogger"
    ) {
      setField({ ...fieldValue, [name]: value.value });
    } else if (name === "siteType" || name === "country" || name === "state") {
      let addOnData = {};
      if (name === "state") {
        let data = stateDrop?.filter((option) => option.stateName === value);
        addOnData = { state: data[0].stateCode };
      } else if (name === "siteType") {
        let data = siteTypeDrop?.filter((option) => option.siteType === value);
        addOnData = { siteType: data[0].shortName };
      }
      console.log(addOnData, "addOnData");
      updatedState(
        name,
        value,
        addOnData,
        name === "country" ? { state: "" } : {}
      );
    } else if (name === "customerName") {
      let id = customerId?.filter((option) => option.customerName === value)[0]
        .customerId;
      setField({ ...fieldValue, [name]: value });
      fetchSiteByCustomerId(id);
    } else {
      setField({ ...fieldValue, [name]: value });
    }
  };
  console.log(fieldValue.customerName, "addOnData");

  const Style = [
    { label: "yes", value: 1 },
    { label: "No", value: 1 },
  ];
  function getRefCode(data) {
    // alert(data);
    // Need to Trim the data, If needed
    if (data?.state || data?.siteType)
      return `${data.state || ""}${data.siteType || ""}-`;
    else return null;
  }
  const getDailyGenBasedOptions = () => [
    { label: "Inverter", value: 0 },
    { label: "Energy Meter", value: 1 },
  ];

  const getMultipleController = () => [
    // { value: true, label: "Yes" },
    // { value: false, label: "No" },

    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  function getOptions(propName) {
    let option;
    switch (propName) {
      case "status":
        return statusDrop?.map((option) => option.label);
      case "energyGenBasedOn":
        return getDailyGenBasedOptions();
      case "multipleController":
        return getMultipleController();
      case "state":
        return stateDrop?.map((option) => option.stateName);
      case "country":
        return countryDrop?.map((option) => option.countryName);
      case "sectorName":
        let name = sectorTypeDrop?.map((option) => option.sectorName);
        return name;
      case "siteType":
        let type = siteTypeDrop?.map((option) => option.siteType);
        return type;
      case "units":
        let unit = unitsIC?.map((option) => option.units);
        return unit;
      case "timezoneName":
        let Time = timezoneName?.map(
          (option) => "(" + option.utcoffset + ")" + option.timezoneName
        );
        return Time;
      case "customerName":
        let customer = customerId.map((option) => {
          return `${option.customerName}`;
        });
        return customer;
      case "energyCounter":
        let energyCounter = Style.map((option) => {
          return `${option.label}`;
        });
        return energyCounter;
      case "energyGenBasedOn":
        return getDailyGenBasedOptions();
      // case 'multipleController':
      //   return getMultipleController();
      case "documentname":
        return (option = DocuemntIdName?.map(
          (option) => option.documentTypeName
        ));
      case "combinedDataLogger":
        return getMultipleController();
      case "combinedSites":
        return listOfSites.map((option) => option.siteName);

      default:
    }

    return option;
  }
  function getOptionss(propName) {
    let option;
    switch (propName) {
      case "documentname":
        return (option = DocuemntIdName?.map(
          (option) => option.documentTypeName
        ));

      default:
    }

    return option;
  }
  const addAdditionalField = () => {
    const newField = {
      fieldName: "",
      status: 1,
      value: "",
    };
    console.log(fieldValue, "fieldValue");
    setField((prevState) => ({
      ...prevState,
      sitedetailadditionalfield: [
        ...prevState.sitedetailadditionalfield,
        newField,
      ],
    }));
  };

  const deleteAdditionalField = (index) => {
    const updatedFields = [...fieldValue.sitedetailadditionalfield];
    updatedFields.splice(index, 1);
    setField((prevState) => ({
      ...prevState,
      sitedetailadditionalfield: updatedFields,
    }));
  };
  useEffect(() => {
    let data = siteConfigField();
    setTextField(data);
  }, []);
  useEffect(() => {
    let data = Document();
    setTextvalue(data);
  }, []);

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    getCustomerList();
    fetchSiteTypedrop();
    fetchSectorTypedrop();
    getCountryList();
    fetchStatus();
    fetchUnits();
    fetchTime();
    fetchdocumentCategory();
  }, [toBackend]);

  useEffect(() => {
    if (fieldValue.country !== "") {
      let countryID = countryDrop?.filter((data) => {
        return data.countryName === fieldValue?.country;
      });
      getStateList(countryID[0]?.countryId);
    }
  }, [fieldValue]);

  const getStateList = async (Id) => {
    try {
      let data = await StateDropdown(Id);
      setStateDrop(data);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const getCountryList = async () => {
    try {
      let data = await CountryDropdown();
      setCountryDrop(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };
  const fetchTime = async () => {
    const data = await fetchTimeZone();
    if (Array.isArray(data)) {
      setTimeZone(data);
    } else if (typeof data === "object" && data !== null) {
      setTimeZone([data]);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, "GetCustomerdropdowndata");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function fetchSiteByCustomerId(id) {
    try {
      const response = await customerBasedSiteDropdown(id);
      setListOfSites(response);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchsitedrop = async () => {
    const data = await Equipmentsitedropdown();
    if (Array.isArray(data)) {
      setSitedropvalue(data);
    } else if (typeof data === "object" && data !== null) {
      setSitedropvalue([data]);
    }
  };
  const fetchSiteTypedrop = async () => {
    const data = await GetSiteTypedropdown();
    setSiteTypeDrop(data);
  };

  const fetchSectorTypedrop = async () => {
    const data = await fetchSectordropdownvalue();
    if (Array.isArray(data)) {
      // If data is an array, set tableValue to data directly
      setSectorTypeDrop(data);
    } else if (typeof data === "object" && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setSectorTypeDrop([data]);
    }
  };
  /*------------------------- handle Update --------------------------------------------*/
  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchSiteTableById();
      console.log(data, "data12345");
      data?.forEach((obj) => {
        obj?.sitedetailadditionalfield.forEach((element) => {
          const fieldExists = data.some((item) =>
            item.hasOwnProperty(element.fieldName)
          );
          if (!fieldExists) {
            data.forEach((item) => (item[element.fieldName] = "-"));
          }
          obj[element.fieldName] = element.value || "-";
        });
      });
      if (Array.isArray(data)) {
        setSiteconfig(data);
        setLoading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteconfig([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUnits = async () => {
    try {
      let data = await fetchInstalltionCapacity();
      console.log(data, "dataOutside");
      setUnitsIC(data);
    } catch (error) {
      console.error(error);
    }
  };

  /*---------------------------------- New Report Call -----------------------*/

  const handleClose = () => {
    setEdit([]);
    setField(initialState);
    setStateDrop([]);
    setOpen(false);
    setEdit(false);
  };
  const handleClosee = () => {
    setOpenn(false);
    // setEdit(false);
    // setReportFields(initalState);
    setSelectedImgName("");
  };

  const Item = styled(Paper)(({ theme }) => ({}));

  const handleEditOpen = async (siteId) => {
    let filteredSiteId = Object.keys(initialState).reduce((acc, key) => {
      if (siteId.hasOwnProperty(key)) {
        acc[key] = siteId[key];
      }
      return acc;
    }, {});
    console.log(customerId, "opening");
    let country = countryDrop?.filter(
      (option) => option.countryName === filteredSiteId.country
    );
    const stateList = await getStateList(country[0]?.countryId);
    let data =
      stateList?.filter(
        (option) => option.stateName === filteredSiteId.state
      ) || [];
    // let addOnData = { state: data[0]?.stateCode }
    let typeData =
      siteTypeDrop?.filter(
        (option) => option.siteType === filteredSiteId.siteType
      ) || [];
    let multiControllerFlag =
      filteredSiteId.multipleController === true ? "Yes" : "No";
    let refSiteId = filteredSiteId?.siteReference?.split("-")[2];
    let customId = customerId?.filter(
      (option) => option.customerName === filteredSiteId.customerName
    )[0].customerId;
    fetchSiteByCustomerId(customId);
    // let typeaddOnData = { siteType: typeData[0]?.shortName }
    setEditValue({
      ...editValue,
      ...filteredSiteId,
      multipleController: multiControllerFlag,
      siteReference: {
        ...editValue?.siteReference,
        state: data[0]?.stateCode,
        siteType: typeData[0]?.shortName,
        siteId: refSiteId,
      },
    });
    setField({
      ...editValue,
      ...filteredSiteId,
      multipleController: multiControllerFlag,
      siteReference: {
        ...editValue?.siteReference,
        state: data[0]?.stateCode,
        siteType: typeData[0]?.shortName,
        siteId: refSiteId,
      },
    });
    setOpen(true);
    setEdit(true);
    setShowUpdateButton(true);
    console.log(refSiteId, "editvalue");
  };
  console.log(editValue, "fieldValuea");
  const handledocumentUpload = (row) => {
    setOpenn(true);
    setvalue(row);
    console.log(row?.siteId);
    getDataTable(row);
  };
  const handleCreate = () => {
    setOpen(true);
    setEditValue();
    setShowUpdateButton(false);
    setupload(true);
  };
  const functionopenpopup = () => {
    openchange(true);
  };
  const closepopup = () => {
    openchange(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === "configuration") {
      setActiveButton("save");
    } else {
      setActiveButton("next");
    }
  };
  // ========customrange=========

  const buttonStyles = {
    textTransform: "capitalize",
    boxShadow: "4px 4px 17px rgba(0, 0, 0.50, 0.50)",
    width: "7vw",
    fontSize: "1.1rem",
    border: "none",
  };
  const handleUpdate = async (values) => {
    const id = editValue?.siteId;
    console.log(editValue);
    const { latitude, longitude } = values;
    let customerIdFilter = customerId?.filter((data) => {
      return data.customerName === values.customerName;
    });

    const siteMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    let sectorTypeId = sectorTypeDrop?.filter((data) => {
      return data.sectorName === values.sectorName;
    });
    let siteTypeId = siteTypeDrop?.filter((data) => {
      return data.siteType === values.siteType;
    });
    let status = statusDrop?.filter((data) => {
      return data.label === values.status;
    });
    console.log(values?.timezoneName);
    // let TimeIdd = timezoneName?.filter((data) => {
    // const Name=values?.timezoneName.split(")")[1]?.trim();
    // const isNamePresent = Name=='undefined'||Name== undefined||Name== "null"||Name== null
    //   return data.timezoneName === isNamePresent?Name: values.timezoneName
    // });
    console.log(values);
    let TimeIdd = timezoneName?.filter((data) => {
      return data.timezoneName === values?.timezoneName.split(")")[1]?.trim();
    });
    console.log(TimeIdd[0]?.timezoneId);
    let Time;
    if (TimeIdd[0]?.timezoneId === undefined) {
      Time = editValue?.timeZoneId;
    } else {
      Time = TimeIdd[0]?.timezoneId;
    }
    console.log(Time);

    let userAddField = [];
    fieldValue.sitedetailadditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        if (obj.fieldId) {
          userAddField.push({
            lastUpdatedBy: sessionStorage.getItem("id"),
            fieldId: obj.fieldId,
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        } else {
          userAddField.push({
            createdBy: Number(sessionStorage.getItem("id")),
            fieldName: obj.fieldName,
            status: Number(obj.status),
            value: obj.value,
          });
        }
      }
    });
    function handleSiteRef(data) {
      const response = data.split("-");
      if (
        response[2] === undefined ||
        response[2] === null ||
        response[2] === ""
      ) {
        return `${data}${values.siteId}`;
      } else if (
        typeof response[2] === "number" ||
        typeof response[2] === "string"
      ) {
        return data;
      }
    }

    // let siteRef;
    // if (fieldValue?.siteReference.state && fieldValue?.siteReference.siteType) {
    //   const data = getRefCode(fieldValue?.siteReference);
    //   siteRef = handleSiteRef(data);
    //   alert('If block');
    // } else {
    //   siteRef = handleSiteRef(values?.siteReference);
    //   alert('Else block');
    // }
    let siteRef = `${values?.siteReference?.state}${values?.siteReference?.siteType}-${values?.siteReference?.siteId}`;

    let data = {
      address: values?.address,
      city: values?.city,
      country: values?.country,
      installationCapacity: values?.installationCapacity,
      units: fieldValue.units === null ? "kWp" : values.units,
      latitude: values?.latitude,
      longitude: values?.longitude,
      postalCode: values?.postalCode,
      siteLocationUrl: siteMapUrl,
      energyGenBasedOn: values.energyGenBasedOn,
      // multipleController: values.multipleController,
      multipleController:
        values?.multipleController === "Yes" ? "true" : "false",
      siteName: values.siteName,
      siteTypeId: siteTypeId[0].siteTypeId,
      energyCounter: values?.energyCounter === "yes" ? "1" : "0",
      timezoneId: Time,
      energyGenBasedOn: values.energyGenBasedOn,
      state: values?.state,
      status: Edit
        ? typeof values.status === "string"
          ? status[0].value
          : values.status
        : status[0].value,
      customerId: isOEM
        ? Number(customerIdFilter[0]?.customerId)
        : Number(sessionStorage.getItem("customerId")),
      lastUpdatedBy: sessionStorage.getItem("id"),
      sectorTypeId: sectorTypeId[0].sectorTypeId,
      sitedetailadditionalfield: userAddField,
      siteReference: siteRef,
    };
    console.log(data, "dataUpdate");
    try {
      setToBackend(true);
      const responseData = await putsiteupdateEdit(data, id);
      if (responseData.status === 200) {
        // fetchData();
        const updatedTable = replaceObjectById(
          Siteconfig,
          "siteId",
          id,
          responseData.data
        );
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        return 1;
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error("Error:", error.response.status);
      setToBackend(false);
      return 0;
    }

    //   console.log(data, ' EditData');
  };
  // function getSiteIDRefCode(data) {
  //   console.log(data, 'datas');
  //   let returnValue;
  //   if (data == null) {
  //     returnValue = editValue??.siteReference?.siteId;
  //     return returnValue
  //   } else if (typeof data === 'string' && data) {
  //     const response = data.split('-');
  //     if (typeof response[2] === 'number' || typeof response[2] === 'string') {
  //       returnValue = response[2]
  //       return returnValue;
  //     }
  //   } else if (typeof data === 'object') {
  //     returnValue = data?.siteId
  //     return returnValue;
  //   }
  //   setField({
  //     ...fieldValue,
  //     siteReference: {
  //       siteId: returnValue
  //     }
  //   })
  // }
  // console.log(fieldValue?.siteReference, 'fieldValue?.siteReference');

  function getSiteRefCodePre(data) {
    if (typeof data === "string" && data) {
      const response = data.split("-");
      if (typeof response[2] === "number" || typeof response[2] === "string") {
        return `${response[0]}-${response[1]}-`;
      }
    } else if (typeof data === "object") {
      return data?.siteId;
    }
  }
  console.log(
    fieldValue.sitedetailadditionalfield,
    "fieldValue.sitedetailadditionalfield"
  );
  const handleSave = async (values) => {
    console.log(values, "saveData");
    let timezoneString = values.timezoneName;
    let splitTimezone = timezoneString.split(")")[1]?.trim();

    console.log(splitTimezone);
    console.log(values?.timezoneName);
    console.log(fieldValue.timezoneName);
    let sectorTypeId = sectorTypeDrop?.filter((data) => {
      return data.sectorName === values.sectorName;
    });

    let siteTypeId = siteTypeDrop?.filter((data) => {
      return data.siteType === values.siteType;
    });
    let TimeIdd = timezoneName?.filter((data) => {
      return data.timezoneName === values?.timezoneName.split(")")[1]?.trim();
    });
    console.log(TimeIdd);
    let customerIdFilter = customerId?.filter((data) => {
      return data.customerName === values.customerName;
    });
    console.log(siteTypeId, "siteTypeId");
    console.log(values.sectorName, values);
    const { latitude, longitude } = values;
    let combinedSiteId = listOfSites?.filter(
      (option) => option.siteName === values.combinedSites
    )[0]?.siteId;

    const siteMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    console.log("Google Maps URL:", siteMapUrl);
    let userAddField = [];
    fieldValue.sitedetailadditionalfield.forEach((obj) => {
      if (obj.fieldName && obj.value) {
        userAddField.push({
          createdBy: Number(sessionStorage.getItem("id")),
          fieldName: obj.fieldName,
          status: Number(obj.status),
          value: obj.value,
        });
      }
    });
    let data = {
      address: values?.address,
      city: values?.city,
      country: values?.country,
      installationCapacity: values?.installationCapacity,
      units: fieldValue?.units,
      latitude: values?.latitude,
      longitude: values?.longitude,
      postalCode: values?.postalCode,
      energyGenBasedOn: values.energyGenBasedOn,
      // multipleController: values?.multipleController,
      multipleController:
        values?.multipleController === "Yes" ? "true" : "false",
      siteLocationUrl: siteMapUrl,
      siteName: values.siteName,
      state: values?.state,
      timezoneId: TimeIdd[0]?.timezoneId,
      energyCounter: values?.energyCounter === "yes" ? "1" : "0",
      status: 1,
      energyGenBasedOn: values.energyGenBasedOn,
      customerId: isOEM
        ? Number(customerIdFilter[0]?.customerId)
        : Number(sessionStorage.getItem("customerId")),
      createdBy: sessionStorage.getItem("id"),
      sectorTypeId: sectorTypeId[0].sectorTypeId,
      siteReference: `${getRefCode(values?.siteReference)}${
        values?.siteReference.siteId || ""
      }`,
      combinedDataLogger: values?.combinedDataLogger == "Yes" ? true : false,
      combinedSites: combinedSiteId,
      sitedetailadditionalfield: userAddField,
    };
    if (fieldValue.sectorName === "Solar") {
      data.siteTypeId = siteTypeId[0].siteTypeId;
    }

    console.log(data, "saveData");
    try {
      setToBackend(true);
      console.log("Prepared data:", data);
      const responseData = await postSiteSave(data);
      console.log(responseData.status, responseData.statusText, "response");
      handleClose();
      setToBackend(false);
      fetchData();
      console.log(responseData, "responseData");
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        message: responseData.statusDescription,
      });
      return 1;
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error("Error:", error.response.status);
      setToBackend(false);
      return 0;
    }
  };

  const getTypeId = (type, find, identifier, data) => {
    const filteredData = data.filter((item) => item[type] === identifier);

    if (filteredData.length > 0) {
      return filteredData[0][`${find}`];
    } else {
      return null;
    }
  };

  // const getDailyGenBasedOptions = () => [
  //   { label: 'Inverter', value: 0 },
  //   { label: 'Energy Meter', value: 1 },
  // ];

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "SiteConfig");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const allowedKeys = ["fieldName", "value", "status"];

  const headCells = [
    {
      id: "siteName",
      label: "Site Name",
      view: true,
      default: true,
    },
    {
      id: "siteType",
      label: "Site Type",
      view: true,
    },
    {
      id: "sectorName",
      label: "Sector Type",
      view: true,
      default: true,
    },
    {
      id: "withUnits",
      label: "Installation Capacity ",
      view: true,
    },
    {
      id: "latitude",
      label: "Latitude",
      view: true,
    },
    {
      id: "longitude",
      label: "Longitude",
      view: true,
    },
    {
      id: "state",
      label: "State",
      view: false,
    },
    {
      id: "city",
      label: "City",
      view: true,
    },
    {
      id: "country",
      label: "Country",
      view: false,
    },
    {
      id: "createdDate",
      label: "Created On",
      view: false,
    },
    {
      id: "lastUpdatedDate",
      label: "Updated On",
      view: false,
    },
    {
      id: "status",
      label: "Status",
      view: true,
      default: true,
    },
    {
      id: "action",
      label: "Action",
      view: true,
      default: true,
    },
  ];
  const headCell = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Datalogger Name",
      id: "documentTypeName",
      view: true,
    },

    {
      label: "Action",
      id: "action",
      view: true,
    },
  ];
  useEffect(() => {
    // setLoading(true);
    let viewArr = [...headCells];
    Siteconfig.forEach((obj) => {
      const addField = obj.sitedetailadditionalfield;
      if (addField.length > 0) {
        addField.forEach((field) => {
          let key = field.fieldName;
          if (!viewArr.some((item) => item.id === key)) {
            let lastIndex = viewArr.length - 1;
            viewArr.splice(lastIndex, 0, { id: key, label: key, view: false });
          }
        });
      }
      setLoading(false);
    });
    setAddHeadCells(viewArr);
  }, [headCells.length, addHeadCells.length, Siteconfig]);
  const tableValues = Siteconfig.map((row) => {
    if (row.installationCapacity) {
      return {
        ...row,
        withUnits: row.installationCapacity + " " + row.units,
      };
    } else {
      return row;
    }
  }).filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      console.log(row, "row");
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  function getSize(name) {
    switch (name) {
      case "Site Description *":
        return 4;
      case "capacity *":
        return 6;
      case "Site Name *":
        return 5;
      default:
        return 5;
    }
  }
  const Total = Siteconfig.length;

  const Active = Siteconfig.filter((site) => site.status == 1).length;

  const Inactive = Siteconfig.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: "All Site", value: "all", badgeCount: Total },
    { name: "Active Site", value: "Active", badgeCount: Active },
    { name: "Inactive site", value: "Inactive", badgeCount: Inactive },
  ];

  const AddFieldsLength = fieldValue.sitedetailadditionalfield.length;
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  let splice = {
    spliceCount: addHeadCells.length - headCells.length,
    headCellCount: headCells.length,
  };
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Site Configuration", path: "siteconfiguration" },
  ];

  const handleFileChange = (event) => {
    // console.log(event, 'fileFileFile');
    setSelectedImgName("");

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBase64Image(reader.result);
    };

    let fileSize = event.target.files[0]?.size;
    let fileType = event.target.files[0]?.type;
    console.log(fileSize, "fileSize");

    if (file) {
      const unsupportedTextTypes = [
        "text/plain",
        "text/csv",
        "text/html",
        "text/xml",
      ];
      const fileTypeCheck = !unsupportedTextTypes.includes(fileType);
      if (fileTypeCheck) {
        reader.readAsDataURL(file);
        console.log(reader, "imageFile");
        setSelectedImgName(file.name);
      } else {
        setSnack({
          ...initialSnack,
          open: true,
          severity: "warning",
          message: "Text file  is not supported",
        });
      }
    } else {
      setBase64Image("");
      setSelectedImgName(""); // Clear image name if no file is selected
    }
  };

  const handleSavee = async () => {
    try {
      let siteId = value?.siteId;
      console.log(siteId);
      let DocumentIdFilter = DocuemntIdName?.filter((data) => {
        return data.documentTypeName === fieldValue.documentname;
      });
      console.log(DocumentIdFilter);
      if (selectedImgName) {
        let file = document.getElementById("upload-logo").files[0];
        let reader = new FileReader();

        reader.onloadend = async () => {
          let base64String = reader.result.split(",")[1]; // Extract base64 string part
          let fileExtensionvalue = selectedImgName.split(".");

          let fileExtension = "." + selectedImgName.split(".").pop();
          let fileNameWithoutExtension = selectedImgName.substring(
            0,
            selectedImgName.lastIndexOf(".")
          );
          let data = {
            document: base64String,
            documentName: fileNameWithoutExtension,
            fileFormat: fileExtension,
            createdBy: sessionStorage.getItem("id"),
            documentTypeId: DocumentIdFilter[0]?.documentTypeId,
            siteId: siteId,
            status: 1,
          };

          try {
            setToBackend(true);
            const responseData = await postDocumentdata(data);
            if (responseData.status === 200) {
              setSnack(errorMsg.success);
              handleClosee();
              setToBackend(false);
              setOpen(false);
              getDataTable();
              setField(initialState);

              return 1;
            }
          } catch (e) {
            setSnack(errorMsg.failure);
            console.error("Error:", e.response?.status);
            setToBackend(false);
            console.log("error:", e.message);
            return 0;
          }
        };

        reader.readAsDataURL(file); // Read file as data URL (base64)
      }
    } catch (error) {
      console.error("Error occurred during file processing:", error);
    }
  };
  const handleDelete = async (row) => {
    console.log(row);
    console.log(row?.documentId);
    const dele = await putDocumentDelete(row?.documentId)
      .then(() => {
        getDataTable();
        handleClosee();
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "success",
            message: "Data deleted successfully",
          };
        });
      })

      .catch((err) => {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "warning",
            message: "Unable to delete try again later",
          };
        });
        console.log(err);
      });
  };
  const fetchdocumentCategory = async () => {
    try {
      let data = await fetctDocumentDetails();
      setDocumentId(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getDataTable = async (row) => {
    let id = row?.siteId;
    console.log(id);
    try {
      const data = await fetchDocumentTable(id);
      if (Array.isArray(data)) {
        setSiteReport(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteReport([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handledocumentdownload = (row) => {
    if (row?.documentUrl) {
      window.open(row.documentUrl, "_blank");
    } else {
      console.error("No document URL provided.");
    }
  };

  const rowsPerPageOptions = [5, 10, 15, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleClickGo = () => {
    if (
      pageValue > 0 &&
      pageValue <= Math.ceil(SiteReport.length / rowsPerPage)
    ) {
      setPage(pageValue - 1);
    }
  };

  const PageChange = (value) => {
    const newPageValue = parseInt(value, 5);
    if (!isNaN(newPageValue)) {
      setPageValue(newPageValue);
    }
  };

  const paginatedData = SiteReport.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );
  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          // sx={{ color: colorMode === 'light' ? '#484C46' : '#007AFF' }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );

  console.log(fieldValue.documentname);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div style={{ marginTop: "-0.5%" }}>
            <TableTemplate
              PageName={"Site Configuration"}
              addButton={"Site"}
              SearchLabel={"Search Site Here... "}
              header={addHeadCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              toggleLimit={splice}
              rawData={Siteconfig}
              handleChange={handleChangeTab}
              userRole={siteConfiguration[0]}
              handleAddPopupOpen={(val) => handleCreate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handledocumentdata={(val) => handledocumentUpload(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? " Active" : siteTab === 0 ? " Inactive" : ""
              } site has been configured yet`}
            />
          </div>

          {/*--------------------------------------- Modal Download Popup ---------------*/}
          <Formik
            key={Edit ? "edit" : "create"}
            enableReinitialize={true}
            initialValues={fieldValue}
            validationSchema={
              isOEM
                ? fieldValue.sectorName === "Solar" ||
                  fieldValue.sectorName === ""
                  ? addCustomerName
                  : customerSiteType
                : fieldValue.sectorName === "Solar" ||
                  fieldValue.sectorName === ""
                ? siteConfigSchema
                : siteTypeValidation
            }
            onSubmit={async (values, { resetForm }) => {
              let submitValue = values;
              if (Edit) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => {
              return (
                <Form>
                  <div>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box
                          className={"styleModalSite"}
                          sx={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: "20px",
                              // height: '68vh',
                              height: "fit-content",
                            }}
                          >
                            <CardHeader
                              sx={{
                                padding: "20px 20px 20px 20px",
                                background: CheckForLight()
                                  ? "rgb(246, 248, 252)"
                                  : "#4f4f4f",
                              }}
                              action={
                                <Stack spacing={2} direction="row">
                                  {Edit ? (
                                    <Button
                                      variant="contained"
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Updating..." : "Update"}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      s
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Saving..." : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    variant="contained"
                                    disabled={toBackend}
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                      handleClose();
                                      handleReset();
                                    }}
                                    startIcon={<CancelIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                              }
                              title={Edit ? "Update Site" : "Add New Site"}
                            />
                            <Divider sx={{ borderColor: "#888" }} />
                            <div className="site-tab">
                              <Box
                                style={{
                                  width: "100%",
                                  // height: '50vh',
                                  background: CheckForLight()
                                    ? null
                                    : "#121212",
                                  height: "fit-content",
                                }}
                              >
                                {activeTab === "siteDetails" && (
                                  <div key={editValue?.siteId}>
                                    <div
                                      className="modelGrid"
                                      style={{ marginTop: "0" }}
                                    >
                                      <Card
                                        sx={{
                                          overflowY:
                                            AddFieldsLength !== 0
                                              ? "scroll"
                                              : "visible",
                                          height: "34.5rem",
                                          scrollBehavior: "smooth",
                                          scrollbarGutter: "stable",
                                          scrollbarWidth: "thin",
                                          "&::-webkit-scrollbar": {
                                            width: "0.4em",
                                          },
                                          "&::-webkit-scrollbar-track": {
                                            background: "#f1f1f1",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#888",
                                            borderRadius: "20px",
                                          },
                                          "&::-webkit-scrollbar-thumb:hover": {
                                            background: "#555",
                                          },
                                        }}
                                        elevation={0}
                                      >
                                        <CardContent>
                                          <Grid
                                            container
                                            spacing={2}
                                            columns={16}
                                          >
                                            {text?.length > 0
                                              ? text.map((data, index) => {
                                                  console.log(
                                                    values,
                                                    "valuesvalues",
                                                    data.name
                                                  );
                                                  return (
                                                    data.view && (
                                                      <Grid
                                                        item
                                                        xs={getSize(data.label)}
                                                      >
                                                        <div key={index}>
                                                          <Typography
                                                            variant="body1"
                                                            className="modal-typo"
                                                            gutterBottom
                                                          >
                                                            {data.label}
                                                          </Typography>
                                                          {data.type ===
                                                          "textField" ? (
                                                            <>
                                                              <Field
                                                                disabled={
                                                                  toBackend
                                                                }
                                                                as={TextField}
                                                                id={`outlined-basic-${index}`}
                                                                size="small"
                                                                variant="outlined"
                                                                name={data.name}
                                                                inputProps={{
                                                                  maxLength:
                                                                    data.length,
                                                                }}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleChange(
                                                                    e
                                                                  );
                                                                  handleTxtChange(
                                                                    e
                                                                  );
                                                                }}
                                                                placeholder={
                                                                  data.placeholder
                                                                }
                                                                value={
                                                                  Edit
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: "15vw",
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={data.name}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                            </>
                                                          ) : data.type ===
                                                            "labelWithField" ? (
                                                            <>
                                                              <TextField
                                                                variant="outlined"
                                                                name={data.name}
                                                                sx={{
                                                                  width: "15vw",
                                                                  paddingRight: 0,
                                                                }}
                                                                size="small"
                                                                inputProps={{
                                                                  readOnly: true,
                                                                  maxLength: 3,
                                                                }}
                                                                value={
                                                                  Edit &&
                                                                  !fieldValue
                                                                    ?.siteReference
                                                                    ?.state &&
                                                                  !fieldValue
                                                                    ?.siteReference
                                                                    ?.siteType &&
                                                                  typeof fieldValue?.siteReference ==
                                                                    "string"
                                                                    ? getSiteRefCodePre(
                                                                        values?.siteReference
                                                                      )
                                                                    : getRefCode(
                                                                        fieldValue?.siteReference ||
                                                                          editValue?.siteReference
                                                                      ) || ""
                                                                }
                                                                InputProps={{
                                                                  endAdornment:
                                                                    (
                                                                      <TextField
                                                                        name={
                                                                          data.name
                                                                        }
                                                                        type="number"
                                                                        // disabled={toBackend || (values.state.length > 0 && values.siteType.length > 0)}
                                                                        sx={{
                                                                          width:
                                                                            "15vw",
                                                                          fieldset:
                                                                            {
                                                                              border:
                                                                                "none",
                                                                              borderLeft:
                                                                                "1px solid #c4c4c4",
                                                                              boxShadow:
                                                                                "none",
                                                                            },
                                                                        }}
                                                                        size="small"
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleTxtChange(
                                                                            e
                                                                          )
                                                                        }
                                                                        value={
                                                                          values
                                                                            ?.siteReference
                                                                            ?.siteId
                                                                        }
                                                                      />
                                                                    ),
                                                                }}
                                                              />
                                                            </>
                                                          ) : data.type ===
                                                              "dropdown" &&
                                                            data.view ? (
                                                            <>
                                                              <Tooltip
                                                                title={
                                                                  !Edit &&
                                                                  data.name ===
                                                                    "status"
                                                                    ? "Active"
                                                                    : Edit &&
                                                                      data.name ===
                                                                        "status"
                                                                    ? typeof values[
                                                                        data
                                                                          .name
                                                                      ] ===
                                                                      "string"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ] == 1
                                                                      ? "Active"
                                                                      : "Inactive"
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                              >
                                                                <Field
                                                                  name="status" // Ensure this matches the field name in your validation schema
                                                                  render={({
                                                                    field,
                                                                    form,
                                                                  }) => (
                                                                    <Tooltip
                                                                      title={
                                                                        !Edit &&
                                                                        data.name ===
                                                                          "status"
                                                                          ? "Active"
                                                                          : Edit &&
                                                                            data.name ===
                                                                              "status"
                                                                          ? typeof values[
                                                                              data
                                                                                .name
                                                                            ] ===
                                                                            "string"
                                                                            ? values[
                                                                                data
                                                                                  .name
                                                                              ]
                                                                            : values[
                                                                                data
                                                                                  .name
                                                                              ] ==
                                                                              1
                                                                            ? "Active"
                                                                            : "Inactive"
                                                                          : data.name ===
                                                                            "energyGenBasedOn"
                                                                          ? getDailyGenBasedOptions().find(
                                                                              (
                                                                                option
                                                                              ) => {
                                                                                console.log(
                                                                                  option
                                                                                );

                                                                                return (
                                                                                  option.value ===
                                                                                  values[
                                                                                    data
                                                                                      .name
                                                                                  ]
                                                                                )
                                                                                  .label;
                                                                              }
                                                                            )
                                                                          : data.name ===
                                                                            "multipleController"
                                                                          ? getMultipleController().find(
                                                                              (
                                                                                option
                                                                              ) =>
                                                                                option.value ===
                                                                                values[
                                                                                  data
                                                                                    .name
                                                                                ]
                                                                            )
                                                                              ?.label ||
                                                                            values[
                                                                              data
                                                                                .name
                                                                            ] ===
                                                                              true
                                                                            ? "Yes"
                                                                            : values[
                                                                                data
                                                                                  .name
                                                                              ] ===
                                                                              false
                                                                            ? "No"
                                                                            : ""
                                                                          : // : values[data.name]
                                                                            values[
                                                                              data
                                                                                .name
                                                                            ]
                                                                      }
                                                                    >
                                                                      <Autocomplete
                                                                        disabled={
                                                                          toBackend ||
                                                                          (!Edit &&
                                                                            data.name ===
                                                                              "status") ||
                                                                          (!Edit &&
                                                                            data.name ===
                                                                              "siteType" &&
                                                                            fieldValue?.sectorName !==
                                                                              "Solar") ||
                                                                          (!fieldValue.country &&
                                                                            data.name ===
                                                                              "state") ||
                                                                          (data.name ===
                                                                            "combinedSites" &&
                                                                            (values?.combinedDataLogger ==
                                                                              false ||
                                                                              values?.combinedDataLogger ==
                                                                                "No" ||
                                                                              values.customerName ==
                                                                                ""))
                                                                        }
                                                                        disableClearable
                                                                        options={getOptions(
                                                                          data.name
                                                                        )}
                                                                        disablePortal
                                                                        size="small"
                                                                        id={`combo-box-demo-${index}`}
                                                                        onChange={(
                                                                          event,
                                                                          value
                                                                        ) =>
                                                                          handleDropdownChange(
                                                                            event,
                                                                            value,
                                                                            data.name
                                                                          )
                                                                        }
                                                                        renderInput={(
                                                                          params
                                                                        ) => (
                                                                          <TextField
                                                                            {...params}
                                                                            placeholder={
                                                                              data.placeholder
                                                                            }
                                                                          />
                                                                        )}
                                                                        value={
                                                                          data.name ===
                                                                          "status"
                                                                            ? !Edit
                                                                              ? "Active"
                                                                              : typeof values[
                                                                                  data
                                                                                    .name
                                                                                ] ===
                                                                                "string"
                                                                              ? values[
                                                                                  data
                                                                                    .name
                                                                                ]
                                                                              : values[
                                                                                  data
                                                                                    .name
                                                                                ] ===
                                                                                1
                                                                              ? "Active"
                                                                              : "Inactive"
                                                                            : data.name ===
                                                                              "timezoneName"
                                                                            ? Edit
                                                                              ? `(${values.utcoffset})${values.timezoneName}`
                                                                              : values[
                                                                                  data
                                                                                    .name
                                                                                ]
                                                                            : data.name ===
                                                                              "energyGenBasedOn"
                                                                            ? getDailyGenBasedOptions().find(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option.value ===
                                                                                  values[
                                                                                    data
                                                                                      .name
                                                                                  ]
                                                                              ) ||
                                                                              null
                                                                            : data.name ===
                                                                              "multipleController"
                                                                            ? getMultipleController().find(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option.value ===
                                                                                  values[
                                                                                    data
                                                                                      .name
                                                                                  ]
                                                                              )
                                                                                ?.label ||
                                                                              values[
                                                                                data
                                                                                  .name
                                                                              ]
                                                                            : data.name ===
                                                                              "combinedDataLogger"
                                                                            ? values.combinedDataLogger ==
                                                                                false ||
                                                                              values.combinedDataLogger ==
                                                                                "No"
                                                                              ? "No"
                                                                              : "Yes"
                                                                            : values[
                                                                                data
                                                                                  .name
                                                                              ]
                                                                        }
                                                                        sx={{
                                                                          width:
                                                                            "15vw",
                                                                        }}
                                                                      />
                                                                    </Tooltip>
                                                                  )}
                                                                  sx={{
                                                                    width:
                                                                      "15vw",
                                                                  }}
                                                                  ListboxProps={{
                                                                    style: {
                                                                      maxHeight:
                                                                        "200px", // Set your desired height here
                                                                    },
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                              <ErrorMessage
                                                                name={data.name}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                            </>
                                                          ) : data.type ===
                                                            "capacity" ? (
                                                            <>
                                                              <Field
                                                                disabled={
                                                                  toBackend
                                                                }
                                                                as={TextField}
                                                                id={`outlined-basic-${index}`}
                                                                size="small"
                                                                variant="outlined"
                                                                type="number"
                                                                name={data.name}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  handleChange(
                                                                    e
                                                                  );
                                                                  handleTxtChange(
                                                                    e
                                                                  );
                                                                }}
                                                                placeholder={
                                                                  data.placeholder
                                                                }
                                                                InputProps={{
                                                                  endAdornment:
                                                                    (
                                                                      <Autocomplete
                                                                        size="small"
                                                                        disableClearable
                                                                        options={
                                                                          getOptions(
                                                                            data.option
                                                                          ) ||
                                                                          []
                                                                        }
                                                                        onChange={(
                                                                          event,
                                                                          value
                                                                        ) =>
                                                                          handleDropdownChange(
                                                                            event,
                                                                            value,
                                                                            "units"
                                                                          )
                                                                        }
                                                                        sx={{
                                                                          width:
                                                                            "160px",
                                                                        }}
                                                                        value={
                                                                          values[
                                                                            "units"
                                                                          ]
                                                                        }
                                                                        renderInput={(
                                                                          params
                                                                        ) => (
                                                                          <TextField
                                                                            {...params}
                                                                            sx={{
                                                                              fieldset:
                                                                                {
                                                                                  border:
                                                                                    "none",
                                                                                  boxShadow:
                                                                                    "none",
                                                                                },
                                                                            }}
                                                                            placeholder={
                                                                              data.placeholder
                                                                            }
                                                                          />
                                                                        )}
                                                                      />
                                                                    ),
                                                                  style: {
                                                                    paddingRight: 0,
                                                                  },
                                                                }}
                                                                value={
                                                                  Edit
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                    : null
                                                                }
                                                                sx={{
                                                                  width: "15vw",
                                                                }}
                                                                ListboxProps={{
                                                                  style: {
                                                                    maxHeight:
                                                                      "200px", // Set your desired height here
                                                                  },
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={data.name}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={"units"}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                            </>
                                                          ) : data.type ===
                                                            "float" ? (
                                                            <>
                                                              <Field
                                                                as={TextField}
                                                                id={`outlined-basic-${index}`}
                                                                size="small"
                                                                variant="outlined"
                                                                name={data.name}
                                                                type={data.type}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  let numLength;

                                                                  // Setting length based on the field type
                                                                  if (
                                                                    e.target
                                                                      .name ===
                                                                    "latitude"
                                                                  ) {
                                                                    numLength = 10;

                                                                    // Allow only valid format (e.g., 98.988654)
                                                                    const regex =
                                                                      /^(\d{0,2}(\.\d{0,7})?)?$/;
                                                                    if (
                                                                      !regex.test(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }
                                                                  } else if (
                                                                    e.target
                                                                      .name ===
                                                                    "longitude"
                                                                  ) {
                                                                    numLength = 10;

                                                                    // Allow only valid format (e.g., 123.4567890)
                                                                    const regex =
                                                                      /^(\d{0,3}(\.\d{0,7})?)?$/;
                                                                    if (
                                                                      !regex.test(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    ) {
                                                                      return;
                                                                    }
                                                                  } else {
                                                                    numLength = 7;
                                                                  }

                                                                  if (
                                                                    e.target
                                                                      .value
                                                                      .length <=
                                                                    numLength
                                                                  ) {
                                                                    handleChange(
                                                                      e
                                                                    );
                                                                    handleTxtChange(
                                                                      e
                                                                    );
                                                                  }
                                                                }}
                                                                inputProps={{
                                                                  maxLength:
                                                                    data.length,
                                                                }}
                                                                placeholder={
                                                                  data.placeholder
                                                                }
                                                                value={
                                                                  values[
                                                                    data.name
                                                                  ] === null
                                                                    ? ""
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: "15vw",
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={data.name}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                            </>
                                                          ) : data.type ===
                                                            "number" ? (
                                                            <div>
                                                              <Field
                                                                as={TextField}
                                                                id={`outlined-basic-${index}`}
                                                                type="number"
                                                                disabled={
                                                                  toBackend
                                                                }
                                                                size="small"
                                                                variant="outlined"
                                                                name={data.name}
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  let values =
                                                                    e.target
                                                                      .value;
                                                                  if (
                                                                    data.name ===
                                                                    "postalCode"
                                                                  ) {
                                                                    if (
                                                                      values.length <=
                                                                      6
                                                                    ) {
                                                                      handleChange(
                                                                        e
                                                                      );
                                                                      handleTxtChange(
                                                                        e
                                                                      );
                                                                    }
                                                                  } else {
                                                                    handleChange(
                                                                      e
                                                                    );
                                                                    handleTxtChange(
                                                                      e
                                                                    );
                                                                  }
                                                                }}
                                                                placeholder={
                                                                  data.placeholder
                                                                }
                                                                value={
                                                                  values[
                                                                    data.name
                                                                  ] === null
                                                                    ? ""
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: "15vw",
                                                                }}
                                                                InputProps={{
                                                                  inputProps: {
                                                                    inputMode:
                                                                      "numeric",
                                                                  },
                                                                }}
                                                              />
                                                              <ErrorMessage
                                                                name={data.name}
                                                                component="div"
                                                                className="errorStyle"
                                                                style={{
                                                                  color: "red",
                                                                  marginTop:
                                                                    "1%",
                                                                  textAlign:
                                                                    "left",
                                                                  marginLeft:
                                                                    "0%",
                                                                }}
                                                              />
                                                            </div>
                                                          ) : null}
                                                        </div>
                                                      </Grid>
                                                    )
                                                  );
                                                })
                                              : null}
                                            <Grid item xs={4}>
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  marginLeft: "-7rem",
                                                  marginTop: "-0.5rem",
                                                }}
                                              ></div>
                                            </Grid>
                                            {AddFieldsLength !== 0 && (
                                              <>
                                                <div
                                                  style={{
                                                    margin: "0 150px 0 0",
                                                  }}
                                                >
                                                  <Stack
                                                    spacing={0}
                                                    direction="row"
                                                  ></Stack>
                                                </div>
                                                <Typography
                                                  className="modal-head"
                                                  style={{ width: "300px" }}
                                                  sx={{
                                                    marginLeft: "3%",
                                                    marginTop: "25px",
                                                  }}
                                                  gutterBottom
                                                >
                                                  Additional Fields
                                                </Typography>
                                                <Grid
                                                  container
                                                  spacing={3}
                                                  columns={30}
                                                >
                                                  <Grid
                                                    item
                                                    xs={8}
                                                    sx={{
                                                      marginLeft: "3.5%",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <div>
                                                      <AdditionalFields
                                                        field={fieldValue}
                                                        setField={setField}
                                                        allowedKeys={
                                                          allowedKeys
                                                        }
                                                        deleteAdditionalField={
                                                          deleteAdditionalField
                                                        }
                                                        fieldToAdd="sitedetailadditionalfield"
                                                      />
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            )}
                                            <Grid container>
                                              <Grid
                                                item
                                                xs={6}
                                                style={{
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  marginTop: "2.5vh",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    marginTop: "5px",
                                                  }}
                                                >
                                                  {fieldValue
                                                    ?.sitedetailadditionalfield
                                                    ?.length < 5 && (
                                                    <Card
                                                      sx={{
                                                        width: "200%",
                                                        // marginLeft: '4%',
                                                        height: "45px",
                                                        border:
                                                          "2px solid #d0d0d0",
                                                        borderStyle: "dashed",
                                                        borderRadius: "10px",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                      elevation={0}
                                                    >
                                                      <CardContent
                                                        onClick={
                                                          addAdditionalField
                                                        }
                                                        sx={{
                                                          cursor: "pointer",
                                                          display: "flex",
                                                          marginTop: "0.65%",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <AddCircleOutlineSharpIcon
                                                          sx={{
                                                            color: "#71766f",
                                                            marginRight: "10px",
                                                            fontSize: "30px",
                                                          }}
                                                        />
                                                        <Typography
                                                          className="modal-typo"
                                                          fontWeight="410"
                                                          fontSize="20px !important"
                                                        >
                                                          Add Customized Data
                                                        </Typography>
                                                      </CardContent>
                                                    </Card>
                                                  )}
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  </div>
                                )}
                              </Box>
                            </div>
                          </Card>
                        </Box>
                      </Fade>
                    </Modal>
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openn}
                        closeAfterTransition
                      >
                        {/* <Fade in={openn}> */}
                        <Box
                          className={"styleModalcustom"}
                          sx={{ height: "60vh" }}
                          // sx={{ bgcolor: 'background.paper' }}
                        >
                          <Card
                            sx={{
                              borderRadius: "20px",
                              // height: 'fit-content',
                            }}
                          >
                            <CardHeader
                              sx={{
                                padding: "20px 20px 20px 20px",
                                background: CheckForLight()
                                  ? "rgb(246, 248, 252)"
                                  : "#4f4f4f",
                              }}
                              action={
                                <Stack spacing={2} direction="row">
                                  <Button
                                    variant="contained"
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    startIcon={
                                      toBackend ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        <SaveIcon />
                                      )
                                    }
                                    onClick={toBackend ? null : handleSavee}
                                  >
                                    {toBackend ? "Uploading..." : "Upload"}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    disabled={toBackend}
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                      handleClosee();
                                      handleReset();
                                    }}
                                    startIcon={<CancelIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                              }
                              title={"Add New Document"}
                            />
                            <Divider sx={{ borderColor: "#888" }} />
                            <div
                              style={{
                                height: "60vh",
                                padding: "20px",
                              }}
                            >
                              <Grid container spacing={2} columns={16}>
                                {textvalue?.length > 0
                                  ? textvalue?.map((data, index) => (
                                      <Grid item xs={4}>
                                        <div key={index}>
                                          {data.type === "textField" ? (
                                            <>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <Field
                                                as={TextField}
                                                disabled={toBackend}
                                                id={`outlined-basic-${index}`}
                                                size="small"
                                                variant="outlined"
                                                name={data.name}
                                                inputProps={{
                                                  maxLength: data.length,
                                                }}
                                                placeholder={data.placeholder}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  handleTxtChange(e);
                                                }}
                                                value={
                                                  values.mailId === null ||
                                                  values.mailId === ""
                                                    ? ""
                                                    : values[data.name]
                                                }
                                                sx={{ width: "12vw" }}
                                              />
                                              <ErrorMessage
                                                name={data.name}
                                                component="div"
                                                className="error"
                                                style={{
                                                  color: "red",
                                                  marginTop: "1%",
                                                  textAlign: "left",
                                                  marginLeft: "0%",
                                                }}
                                              />
                                            </>
                                          ) : data.type === "dropdown" ? (
                                            <>
                                              <Typography
                                                variant="body1"
                                                className="modal-typo"
                                                gutterBottom
                                              >
                                                {data.label}
                                              </Typography>
                                              <Tooltip>
                                                <Field
                                                  render={({ field, form }) => (
                                                    <Tooltip>
                                                      <Autocomplete
                                                        options={
                                                          getOptions(
                                                            data?.name
                                                          ) || []
                                                        }
                                                        size="small"
                                                        id={`combo-box-demo-${index}`}
                                                        onChange={(
                                                          event,
                                                          value
                                                        ) =>
                                                          handleDropdownChange(
                                                            event,
                                                            value,
                                                            data.name
                                                          )
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                          />
                                                        )}
                                                      />
                                                    </Tooltip>
                                                  )}
                                                />
                                              </Tooltip>
                                              {/* <ErrorMessage
                                            name={data.name}
                                            component="div"
                                            className="error"
                                            style={{
                                              color: 'red',
                                              marginTop: '1%',
                                              textAlign: 'left',
                                              marginLeft: '0%',
                                            }} */}
                                              {/* /> */}
                                            </>
                                          ) : null}
                                        </div>
                                      </Grid>
                                    ))
                                  : null}

                                <Grid item xs={3} style={{ marginTop: "2%" }}>
                                  <div>
                                    <label
                                      htmlFor="upload-logo"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <TextField
                                        type="file"
                                        id="upload-logo"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                          handleFileChange(event);
                                        }}
                                      />
                                      <Button
                                        variant="contained"
                                        component="span"
                                        disabled={
                                          fieldValue?.documentname?.length === 0
                                        }
                                      >
                                        Select File
                                      </Button>
                                    </label>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{ marginTop: "2%", marginLeft: "-4%" }}
                                >
                                  <div>{selectedImgName}</div>
                                </Grid>
                              </Grid>

                              <div style={{ marginTop: "3%" }}>
                                <Card
                                  elevation={1}
                                  style={{ height: "44vh", overflow: "auto" }}
                                >
                                  <div className="table-container-wrapper">
                                    {SiteReport.length > 0 ? (
                                      <TableContainer>
                                        <Table aria-label="a dense table">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center">
                                                Document Type
                                              </TableCell>
                                              <TableCell align="center">
                                                Document Name
                                              </TableCell>
                                              <TableCell align="center">
                                                Created By
                                              </TableCell>
                                              <TableCell align="center">
                                                Created Date
                                              </TableCell>

                                              <TableCell align="center">
                                                Action
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {SiteReport.slice(
                                              page * rowsPerPage,
                                              page * rowsPerPage + rowsPerPage
                                            ).map((row, index) => (
                                              <TableRow key={index}>
                                                <TableCell align="center">
                                                  {row.documentTypeName}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {decodeURIComponent(
                                                    row.documentUrl
                                                      .split("/")
                                                      .pop()
                                                  )}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row.userName}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row.createdDate
                                                    ? new Date(row.createdDate)
                                                        .toLocaleDateString(
                                                          "en-GB",
                                                          {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                          }
                                                        )
                                                        .replace(/\//g, "-")
                                                    : ""}
                                                </TableCell>

                                                <TableCell align="center">
                                                  <SaveAltIcon
                                                    style={{ color: "Grey" }}
                                                    onClick={() =>
                                                      handledocumentdownload(
                                                        row
                                                      )
                                                    }
                                                  />
                                                  <Delete
                                                    style={{
                                                      color: "Grey",
                                                      marginLeft: "5px",
                                                    }}
                                                    onClick={() =>
                                                      handleDelete(row)
                                                    }
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    ) : (
                                      <div>
                                        <Typography
                                          style={{
                                            marginTop: "10%",
                                            textAlign: "center",
                                            color: "rgb(113, 118, 111)",
                                          }}
                                        >
                                          No Document are available at this
                                          time.
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                </Card>
                              </div>
                            </div>
                            <div
                              style={{ padding: "20px", marginTop: "-50px" }}
                            >
                              {SiteReport.length > 0 && (
                                <Card
                                  component="div"
                                  elevation={1}
                                  style={{ height: "4.8vh" }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={2}></Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Stack spacing={2} direction="row">
                                        <Pagination
                                          count={Math.ceil(
                                            SiteReport.length / rowsPerPage
                                          )}
                                          shape="rounded"
                                          color="primary"
                                          page={page + 1}
                                          onChange={handleChangePage}
                                          renderItem={(item) => (
                                            <PaginationItem
                                              slots={{
                                                previous: renderCustomPrevious,
                                                next: renderCustomNext,
                                              }}
                                              {...item}
                                            />
                                          )}
                                        />
                                        <Divider
                                          orientation="vertical"
                                          flexItem
                                          style={{
                                            color: "#E6E7E9",
                                            height: "30px",
                                            marginRight: "16px",
                                          }}
                                        />
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                          marginLeft: "1%",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            lineHeight: "14.52px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Go to page
                                        </Typography>
                                        <TextField
                                          variant="standard"
                                          style={{
                                            width: "24px",
                                            marginLeft: "5px",
                                          }}
                                          value={
                                            pageValue !== 0 ? pageValue : ""
                                          }
                                          onChange={(e) =>
                                            PageChange(e.target.value)
                                          }
                                        />
                                        <Button
                                          style={{
                                            padding: "0",
                                            width: "12px",
                                            fontSize: "14px",
                                          }}
                                          onClick={handleClickGo}
                                          endIcon={
                                            <ArrowForwardIcon
                                              style={{ fontSize: "16px" }}
                                            />
                                          }
                                        >
                                          GO
                                        </Button>
                                        <Autocomplete
                                          options={rowsPerPageOptions}
                                          getOptionLabel={(option) =>
                                            option.toString()
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                              sx={{
                                                fontFamily: "Inter, sans-serif",
                                                width: 40,
                                              }}
                                            />
                                          )}
                                          value={rowsPerPage}
                                          onChange={handleChangeRowsPerPage}
                                          disableClearable
                                          style={{ width: 120 }}
                                        />
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Card>
                              )}
                            </div>
                          </Card>
                        </Box>
                        {/* </Fade> */}
                      </Modal>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}
