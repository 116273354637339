import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useRef } from "react";
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  TextField,
  Autocomplete,
  Pagination,
  TableBody,
  PaginationItem,
  Typography,
  Card,
  CardHeader,
  TableCell,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  Modal,
  Grid,
  useTheme,
  CircularProgress,
  Divider,
} from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Delete } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import replaceObjectById from "../util/StateUpdater";
/*-------------------------------API----------------------------*/
import errorMsg from "../util/errorMessage.json";
import {
  fetchsubscriptionTable,
  postsubscriptiondata,
  putSubscriptionEdit,
  putSubscriptionDelete
} from "../../Api/SubscriptionApi";

import { FetchSubscriptionType } from "../../Api/SignupApi";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { FadeLoader } from "react-spinners";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckForLight } from "../util/common/CommanFunction";
/*------------------------- External compoenents ---------------------------*/
import Download from "../../Template/Excel/Download";
import TableTemplate from "../../Template/TableTemplate";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { EquipmentlistDropdown } from "../../Api/TicketApi";
import { fetchSiteDetails } from "../../Api/TicketApi";
import { useLocation } from "react-router";
const stylemodal = {
  margin: "20px 0px 0px 0px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  borderRadius: "15px",
  left: "47%",
  height: "auto",
  bgcolor: "background.paper",
  p: 4,
  marginLeft: "5%",
};

const Status = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];
const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  fontSize: "15px",
};
const DocumentType = [
  { label: "PO", value: "po" },
  { label: "Others", value: "others" },
];
const SubscriptioPO = ({ equipmentConfiguration }) => {
  const location = useLocation();

  const siteId = location.state?.siteId || ""; // Extract siteId from state
  const siteName = location.state?.siteName || "";

  console.log("location.state:", location.state);
  console.log("siteId from location:", siteId);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [openn, setOpenn] = React.useState(false);
  const [toBackend, setToBackend] = useState(false);
  const [selectedImgName, setSelectedImgName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [base64Image, setBase64Image] = useState("");

  const [siteTemp, setSiteTemp] = useState("");
  const [loading, setloading] = useState(true);
  const [tableValue, setTableValue] = useState([]);
  const [poTable, setPoTable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [pageValue, setPageValue] = useState(0);
  const [editValue, setEditValue] = useState();
  console.log(editValue, "editValue before sending");
  const [siteIdName, setSiteId] = useState([]);
  const [isTableVisible, setTableVisible] = useState(false);

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [defaultSubscriptionValue, setDefaultSubscriptionValue] =
    useState(null);
  const [equipments, setEquipments] = useState([]);
  const [open, setOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [siteTab, setSiteTab] = React.useState("");
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [fieldValue, setFieldValue] = useState([]);
  const [validEmail, setValidEmail] = useState(true);
  console.log(fieldValue, "equipmentName");
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const ITEM_HEIGHT = 2;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 1.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let initialState = {
    poNumber: "",
    subscriptionName: "",
    siteName: "",
    PoFromDate: "",
    PoToDate: "",
    status: "",
    file: "",
  };
  // const [subscriptionField, setSubscriptionField] = useState([initialState]);
  const [subscriptionField, setSubscriptionField] = useState({
    poNumber: "",
    // siteName: "",
    subscriptionName: "",
    plan: "",
    siteName: siteName,
    PoFromDate: "",
    PoToDate: "",
    fileName: "",
    status: "",
    file: "",
  });
  console.log(subscriptionField);

  const formatDateWithTime = (date) => {
    if (!date) return null;
    
    // Handle different input types (Dayjs, Date, or string)
    const jsDate = dayjs(date).isValid() ? dayjs(date).toDate() : new Date(date);
    
    // Check if the date is valid
    if (isNaN(jsDate.getTime())) return null;
    
    const year = jsDate.getFullYear();
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const day = String(jsDate.getDate()).padStart(2, "0");
    const hours = String(jsDate.getHours()).padStart(2, "0");
    const minutes = String(jsDate.getMinutes()).padStart(2, "0");
    const seconds = String(jsDate.getSeconds()).padStart(2, "0");
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [textField, setTextField] = useState([]);

  useEffect(() => {
    const { siteName, poNumber, PoFromDate, PoToDate } = subscriptionField;
    if (
      siteName &&
      selectedSubscriptionId &&
      selectedFrequency &&
      poNumber &&
      PoFromDate &&
      PoToDate &&
      selectedFile &&
      selectedImgName &&
      fromDate &&
      toDate
    ) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [
    subscriptionField,
    selectedFile,
    selectedImgName,
    selectedFrequency,
    selectedSubscriptionId,
    fromDate,
    toDate,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionField((prev) => ({ ...prev, [name]: value }));
  };
  // const handleFileChange = (e) => { const file = e.target.files[0]; setSelectedFile(file); setSelectedImgName(file ? file.name : ""); };

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  // Helper function to calculate date ranges
  const calculateMaxDate = (baseDate) => {
    if (!baseDate) return null;

    switch (selectedFrequency) {
      case "Monthly":
        return dayjs(baseDate).add(1, "month").subtract(1, "day");
      case "Quarterly":
        return dayjs(baseDate).add(3, "month").subtract(1, "day");
      case "Half Yearly":
        return dayjs(baseDate).add(6, "month").subtract(1, "day");
      case "Annual":
        return dayjs(baseDate).add(1, "year").subtract(1, "day");
      default:
        return null;
    }
  };

  //----------------User Table----------------//
  // const fetchData = async () => {
  //   setloading(true);
  //   try {
  //     const data = await fetchsubscriptionTable();
  //     setTableValue(data);
  //     setloading(false);
  //   } catch (e) {}
  // };

  useEffect(() => {
    if (tableValue.length === 0 && !loading) {
      setModalOpen(true);
    }
  }, [tableValue, loading]);

  const fetchData = async () => {
    setloading(true);
    try {
      const data = await fetchsubscriptionTable(siteId);
      // Check if siteName is provided for filtering
      const filteredData = siteName
        ? data.filter((item) => item.siteName === siteName)
        : data;
      // Map through the filtered data and add DocumentName by decoding the fileUrl
      const updatedData = filteredData.map((row) => ({
        ...row,
        DocumentName: row?.fileUrl ? row.fileUrl?.split("/").pop() : null,
      }));
      setTableValue(updatedData);
    } catch (e) {
      console.error(e);
      setTableValue([]);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [siteId]);

  // const fetchData = async () => {
  //   setloading(true);
  //   try {
  //     const data = await fetchsubscriptionTable();
  //     const filteredData = siteName
  //       ? data.filter((item) => item.siteName === siteName)
  //       : data;
  //     setTableValue(filteredData);
  //   } catch (e) {
  //     console.error(e);
  //     setTableValue([]);
  //   } finally {
  //     setloading(false);
  //   }
  // };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleStatus = (event, newValue) => {
    if (newValue) {
      setEditValue((prev) => ({
        ...prev,
        activeFlag: newValue.value === "active" ? 1 : 0,
      }));
    }
  };

  /*----------------- Document Upload ---------------------*/
  const handledocumentUpload = (row) => {
    setOpenn(true);
    // setvalue(row);
    console.log(row?.siteId);
    fetchData(row);
  };

  /*----------------------Edit Call--------------------*/
  const handleEditClick = async (subdetailsId) => {
    console.log(subdetailsId);
    setTableVisible(true); // Show the table
    setSubscriptionField(subdetailsId);
    setSubscriptionField({
      ...subscriptionField,
      PoFromDate: editValue?.poFromDate || "",
      PoToDate: editValue?.poToDate || "",
    });
    
    FetchSubscriptionType();
    setEditValue(subdetailsId);
    setModalOpen(true);
    setDisabledSiteName(true);
    setEditMode(true);
  };

  /*--------------- Settings (Optional) Modal Components-----------------*/
  const defaultLoadingStates = {
    selectalert: true,
    selectsite: true,
    equipments: true,
    parameter: true,
    logic: true,
    value: true,
    errormessage: true,
    status: true,
    action: true,
  };
  useEffect(() => {
    getSiteCall();
  }, []);

  const fetchSubscription = async () => {
    let responseData = await FetchSubscriptionType();
    console.log(responseData.data[0].subscriptionName, "fetched data");
    setSubscriptionOptions(responseData?.data);
    setDefaultSubscriptionValue(responseData?.data[0].subscriptionName);
  };

  /*----------------------------- Site Name API Call-----------------------*/
  const handleSite = (data, val) => {
    setSiteTemp(val);
    subscriptionField.forEach((obj) => {
      obj.siteName = val;
    });
    fetchEquipmentDetails(val);
  };
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    console.log(siteIdFilter[0]?.siteId);
    try {
      let data = await EquipmentlistDropdown(siteIdFilter[0]?.siteId);
      console.log(data, "datasss");
      setEquipments(data);
      // fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSiteCall();
    fetchSubscription();
  }, [equipments]);
  /*-------------------------- Site Get ---------------------*/
  // const getSiteCall = async () => {
  //   try {
  //     // let id = sessionStorage.getItem('id');
  //     let id = 263;
  //     let data = await fetchSiteDetails();
  //     if (data.length > 0) {
  //       setSiteId([...new Set(data)]);
  //       // setSite(data[0]?.siteName);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };
  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------Modal Component--------------------*/
  const [modalopen, setModalOpen] = React.useState(false);
  const handleCrate = () => {
    setModalOpen(true);
    setEditState(false);
    // setUpdateIconState(false)
    setEditValue();
    setEditMode(false);
  };

  const handledocumentdownload = (val) => {
    if (val?.fileUrl) {
      window.open(val.fileUrl, "_blank");
    } else {
      console.error("No document URL provided.");
    }
  };

  const handleDelete = async (row) => {
    console.log(row);
    console.log(row?.poId);
    const dele = await putSubscriptionDelete(row?.poId)
      .then(() => {
        fetchData(row);
        handleClosee();
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "success",
            message: "Data deleted successfully",
          };
        });
      })

      .catch((err) => {
        setSnack((prev) => {
          return {
            ...prev,
            open: true,
            severity: "warning",
            message: "Unable to delete try again later",
          };
        });
        console.log(err);
      });
  };

  const rowsPerPageOptions = [5, 10, 15, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleClickGo = () => {
    if (pageValue > 0 && pageValue <= Math.ceil(poTable.length / rowsPerPage)) {
      setPage(pageValue - 1);
    }
  };

  const PageChange = (value) => {
    const newPageValue = parseInt(value, 5);
    if (!isNaN(newPageValue)) {
      setPageValue(newPageValue);
    }
  };

  const renderCustomPrevious = (props) => (
    <CustomIconSlot
      name="PREVIOUS"
      icon={<ArrowBackIcon style={{ fontSize: "16px" }} />}
      {...props}
    />
  );

  const CustomIconSlot = ({ name, icon, ...props }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: name === "NEXT" ? "row-reverse" : "row",
      }}
    >
      {React.cloneElement(icon, props)}
      <Typography
        style={{
          margin: "14px",
          color: colorMode === "light" ? "#484C46" : "#007AFF",
          fontSize: "12px",
        }}
      >
        {name}
      </Typography>
    </div>
  );
  const renderCustomNext = (props) => (
    <CustomIconSlot
      name="NEXT"
      icon={
        <ArrowForwardIcon
          // sx={{ color: colorMode === 'light' ? '#484C46' : '#007AFF' }}
          style={{ fontSize: "16px" }}
        />
      }
      {...props}
    />
  );

  const handleClosee = () => {
    setOpenn(false);
    setSelectedImgName("");
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
    // setOpenn(true);
  };

  const handleFileChange = (event) => {
    setSelectedImgName("");
    const file = event.target.files[0];
    setSelectedFile(file); // Store the file in state
    const reader = new FileReader();
    setBase64Image("");
    setSnack({ ...initialSnack, open: false });

    if (file) {
      const fileSize = file.size;
      const fileType = file.type;
      console.log(fileSize, "fileSize");
      const unsupportedTextTypes = [
        "text/plain",
        "text/csv",
        "text/html",
        "text/xml",
      ];
      const fileTypeCheck = !unsupportedTextTypes.includes(fileType);

      if (fileTypeCheck) {
        reader.onloadend = () => {
          setBase64Image(reader.result);
          setSelectedImgName(file.name);
        };
        reader.readAsDataURL(file);
        console.log(reader, "imageFile");
      } else {
        setSelectedFile(null); // Clear selected file if type not supported
        setSnack({
          ...initialSnack,
          open: true,
          severity: "warning",
          message: "Text file is not supported",
        });
      }
    } else {
      setSelectedFile(null);
      setBase64Image("");
      setSelectedImgName("");
    }
  };

  // const handleFileChange = (event) => {
  //   setSelectedImgName("");
  //   const file = event.target.files[0];
  //   setSelectedFile(file); // Store the file in state
  //   const reader = new FileReader();
  //   setBase64Image("");
  //   setSnack({ ...initialSnack, open: false });

  //   if (file) {
  //     const fileSize = file.size;
  //     const fileType = file.type;
  //     console.log(fileSize, "fileSize");

  //     // Remove text/csv from unsupportedTextTypes
  //     const unsupportedTextTypes = [
  //       "text/plain",
  //       "text/html",
  //       "text/xml",
  //     ];
  //     const fileTypeCheck = !unsupportedTextTypes.includes(fileType);

  //     if (fileTypeCheck) {
  //       reader.onloadend = () => {
  //         setBase64Image(reader.result);
  //         setSelectedImgName(file.name);
  //       };
  //       reader.readAsDataURL(file);
  //       console.log(reader, "imageFile");
  //     } else {
  //       setSelectedFile(null); // Clear selected file if type not supported
  //       setSnack({
  //         ...initialSnack,
  //         open: true,
  //         severity: "warning",
  //         message: "Text file is not supported",
  //       });
  //     }
  //   } else {
  //     setSelectedFile(null);
  //     setBase64Image("");
  //     setSelectedImgName("");
  //   }
  // };

  /*------------------- handleSave -----------------------*/
  const handleSave = async () => {
    try {
      // Filter site ID based on the selected site name
      let siteIdFilter = siteIdName?.filter((data) => {
        return data.siteName === subscriptionField.siteName;
      });

      let siteId = null;
      if (siteIdName && subscriptionField.siteName) {
        const matchingSite = siteIdName.find(
          (data) =>
            data.siteName.trim().toLowerCase() ===
            subscriptionField.siteName.trim().toLowerCase()
        );

        if (matchingSite) {
          siteId = matchingSite.siteId;
        } else {
          console.error(
            "No matching site found for:",
            subscriptionField.siteName
          );
          // Handle the error - maybe set a default siteId or show an error message
        }
      } else {
        console.error("siteIdName or siteName is undefined");
      }

      // Find subscriptionId based on the selected subscriptionName
      let selectedSubscription = subscriptionOptions?.find(
        (option) =>
          option.subscriptionName === subscriptionField.subscriptionName
      );

      if (selectedFile && selectedImgName) {
        let reader = new FileReader();

        reader.onloadend = async () => {
          let base64String = reader.result?.split(",")[1]; // Extract base64 string part

          let fileNameWithoutExtension = selectedImgName.substring(
            0,
            selectedImgName.lastIndexOf(".")
          );

          // Format dates with timestamp (YYYY-MM-DD HH:mm:ss)
          const formatDateWithTime = (date) => {
            const jsDate = new Date(date);
            const year = jsDate.getFullYear();
            const month = String(jsDate.getMonth() + 1).padStart(2, "0");
            const day = String(jsDate.getDate()).padStart(2, "0");
            const hours = String(jsDate.getHours()).padStart(2, "0");
            const minutes = String(jsDate.getMinutes()).padStart(2, "0");
            const seconds = String(jsDate.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          };

          // Construct the payload with the new format
          let data = {
            // siteId: siteIdFilter[0]?.siteId,
            siteId: siteId,
            subscriptionId: selectedSubscriptionId,
            poNumber: subscriptionField.poNumber,
            PoFromDate: subscriptionField.PoFromDate
              ? formatDateWithTime(subscriptionField.PoFromDate)
              : null,
            PoToDate: subscriptionField.PoToDate
              ? formatDateWithTime(subscriptionField.PoToDate)
              : null,
            createdBy: sessionStorage.getItem("id"),
            status: 1,
            file: base64String,
            fileName: fileNameWithoutExtension,
          };
          console.log(data, "dataPO");

          try {
            setToBackend(true);
            const responseData = await postsubscriptiondata(data);
            if (responseData.status === 200) {
              setSnack(errorMsg.success);
              handleClosee();
              setToBackend(false);
              setOpen(false);
              fetchData();
              window.location.reload();
            }
          } catch (e) {
            setSnack(errorMsg.failure);
            console.error("Error:", e.response?.status);
            setToBackend(false);
            console.log("error:", e.message);
          }
        };

        reader.readAsDataURL(selectedFile); // Use selectedFile directly instead of getting from DOM
      } else {
        // Handle case when no file is selected
        setSnack({
          open: true,
          severity: "warning",
          message: "Please select a file to upload",
        });
      }
    } catch (error) {
      console.error("Error occurred during file processing:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error processing file",
      });
    }
  };
  /*--------------------- handleUpdate ----------------*/

  // const handleUpdate = async () => {
  //   try {
  //     // Use subDetailsId instead of sub_detailsId
  //     let subDetailsId = editValue?.sub_detailsId;
  //     let siteIdFilter = siteIdName?.filter((data) => {
  //       return data.siteName === subscriptionField.siteName;
  //     });

  //     let siteId = null;
  //     if (siteIdName && subscriptionField.siteName) {
  //       const matchingSite = siteIdName.find(
  //         (data) =>
  //           data.siteName.trim().toLowerCase() ===
  //           subscriptionField.siteName.trim().toLowerCase()
  //       );

  //       if (matchingSite) {
  //         siteId = matchingSite.siteId;
  //       } else {
  //         console.error(
  //           "No matching site found for:",
  //           subscriptionField.siteName
  //         );
  //         // Handle the error - maybe set a default siteId or show an error message
  //       }
  //     } else {
  //       console.error("siteIdName or siteName is undefined");
  //     }


  //     // Validate selectedSubscriptionId
  //     if (!selectedSubscriptionId) {
  //       setSnack({
  //         open: true,
  //         severity: "error",
  //         message: "Please select a subscription",
  //       });
  //       return;
  //     }

  //     if (selectedFile && selectedImgName) {
  //       let reader = new FileReader();

  //       reader.onloadend = async () => {
  //         let base64String = reader.result?.split(",")[1];
          

  //         // Format dates with timestamp (YYYY-MM-DD HH:mm:ss)
  //         const formatDateWithTime = (date) => {
  //           const jsDate = new Date(date);
  //           const year = jsDate.getFullYear();
  //           const month = String(jsDate.getMonth() + 1).padStart(2, "0");
  //           const day = String(jsDate.getDate()).padStart(2, "0");
  //           const hours = String(jsDate.getHours()).padStart(2, "0");
  //           const minutes = String(jsDate.getMinutes()).padStart(2, "0");
  //           const seconds = String(jsDate.getSeconds()).padStart(2, "0");
  //           return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  //         };

  //         // Get current timestamp for lastUpdatedDate
  //         const currentTimestamp = formatDateWithTime(new Date());

  //         // Construct the updated payload
  //         let obj = {
  //           subDetailsId: subDetailsId, // Changed from id to subDetailsId
  //           siteId: subscriptionField.siteName
  //           ? siteIdName.find(
  //               (site) => site.siteName.trim().toLowerCase() === 
  //                         subscriptionField.siteName.trim().toLowerCase()
  //             )?.siteId
  //           : null,
  //           // siteId: siteId,
  //             // PoFromDate: subscriptionField.PoFromDate || null,
  //             PoFromDate: subscriptionField.PoFromDate 
  //             ? formatDateWithTime(subscriptionField.PoFromDate)
  //             : null,
  //             PoToDate: subscriptionField.PoToDate || null,

  //           // PoFromDate: subscriptionField.PoFromDate
  //           //   ? formatDateWithTime(subscriptionField.PoFromDate)
  //           //   : null,
  //           // PoToDate: subscriptionField.PoToDate
  //           //   ? formatDateWithTime(subscriptionField.PoToDate)
  //           //   : null,
  //           createdBy: parseInt(sessionStorage.getItem("id")),
  //           status: subscriptionField.status === "Inactive" ? 0 : 1,
  //           subscriptionId: selectedSubscriptionId,
  //           lastUpdatedDate: currentTimestamp,
  //           podetails: [
  //             {
  //               // fileName: fileName,
  //               file: base64String,
  //             },
  //           ],
  //         };

  //         console.log(obj, "Updated Payload");

  //         try {
  //           setToBackend(true);
  //           let response = await putSubscriptionEdit(obj, subDetailsId);

  //           const updatedTable = replaceObjectById(
  //             "sub_detailsId",
  //             subDetailsId,
  //             response.data
  //           );

  //           fetchData();
  //           setSnack(errorMsg.success);
  //           handleClosee();
  //           setOpen(false);
  //           setToBackend(false);
  //           setEditValue("");
  //           window.location.reload();
  //           return 1;
  //         } catch (error) {
  //           setSnack(errorMsg.failure);
  //           console.error("Error:", error.response?.status);
  //           setToBackend(false);
  //           return 0;
  //         }
  //       };

  //       reader.readAsDataURL(selectedFile);
  //     }
  //     if (!editMode)  {
  //       setSnack({
  //         open: true,
  //         severity: "warning",
  //         message: "Please select a file to upload",
  //       });
  //     }
     
  //   } catch (error) {
  //     console.error("Error occurred during update:", error);
  //     setSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Error processing update",
  //     });
  //   }
  // };
  const handleUpdate = async () => {
    try {
      // Use subDetailsId instead of sub_detailsId
      let subDetailsId = editValue?.sub_detailsId;
  

      if (selectedFile && selectedImgName) {
        let reader = new FileReader();

        reader.onloadend = async () => {
          let base64String = reader.result?.split(",")[1];
          let fileNameWithoutExtension = selectedImgName.substring(
            0,
            selectedImgName.lastIndexOf(".")
          );

          // Format dates with timestamp (YYYY-MM-DD HH:mm:ss)
          const formatDateWithTime = (date) => {
            const jsDate = new Date(date);
            const year = jsDate.getFullYear();
            const month = String(jsDate.getMonth() + 1).padStart(2, "0");
            const day = String(jsDate.getDate()).padStart(2, "0");
            const hours = String(jsDate.getHours()).padStart(2, "0");
            const minutes = String(jsDate.getMinutes()).padStart(2, "0");
            const seconds = String(jsDate.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          };

          // Get current timestamp for lastUpdatedDate
          const currentTimestamp = formatDateWithTime(new Date());

          // Construct the updated payload
          let obj = {
            subDetailsId: subDetailsId, // Changed from id to subDetailsId
            
            siteId: editValue.siteId,
              // PoFromDate: subscriptionField.PoFromDate || null,
              PoFromDate: subscriptionField.PoFromDate 
              ? formatDateWithTime(subscriptionField.PoFromDate)
              : null,
              PoToDate: subscriptionField.PoToDate || null,

            // PoFromDate: subscriptionField.PoFromDate
            //   ? formatDateWithTime(subscriptionField.PoFromDate)
            //   : null,
            // PoToDate: subscriptionField.PoToDate
            //   ? formatDateWithTime(subscriptionField.PoToDate)
            //   : null,
            createdBy: parseInt(sessionStorage.getItem("id")),
            status: subscriptionField.status === "Inactive" ? 0 : 1,
            subscriptionId: selectedSubscriptionId,
            lastUpdatedDate: currentTimestamp,
            podetails: [
              {
            fileName: fileNameWithoutExtension,
            file: base64String,
              },
            ],
          };
          console.log(obj, "Updated Payload");
          Object.keys(obj).forEach(key => {
            if (obj[key] === "") {
              delete obj[key];
            }
          });
          // console.log(obj, "Updated Payload");

          try {
            setToBackend(true);
            // let response =""
          let response = await putSubscriptionEdit(obj, subDetailsId);

            const updatedTable = replaceObjectById(
              "sub_detailsId",
              subDetailsId,
              response.data
            );

            fetchData();
            setSnack(errorMsg.success);
            handleClosee();
            setOpen(false);
            setToBackend(false);
            setEditValue("");
            window.location.reload();
            return 1;
          } catch (error) {
            setSnack(errorMsg.success);
            handleModalClose()
            console.error("Error:", error.response?.status);
            setToBackend(false);
            return 0;
          }
        };

        reader.readAsDataURL(selectedFile);
      }
      if (!editMode)  {
        setSnack({
          open: true,
          severity: "warning",
          message: "Please select a file to upload",
        });
      }
     
    } catch (error) {
      console.error("Error occurred during update:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error processing update",
      });
    }
  };
  /* ------------------ Working Fine -------------------- */
  // const handleUpdate = async () => {
  //   try {
  //     let id = editValue?.sub_detailsId;

  //     // Filter site ID based on the selected site name
  //     let siteIdFilter = siteIdName?.filter((data) => {
  //       return data.siteName === subscriptionField.siteName;
  //     });

  //     // Validate selectedSubscriptionId
  //     if (!selectedSubscriptionId) {
  //       setSnack({
  //         open: true,
  //         severity: "error",
  //         message: "Please select a subscription"
  //       });
  //       return;
  //     }

  //     if (selectedFile && selectedImgName) {
  //       let reader = new FileReader();

  //       reader.onloadend = async () => {
  //         let base64String = reader.result.split(",")[1];

  //         // Format dates with timestamp (YYYY-MM-DD HH:mm:ss)
  //         const formatDateWithTime = (date) => {
  //           const jsDate = new Date(date);
  //           const year = jsDate.getFullYear();
  //           const month = String(jsDate.getMonth() + 1).padStart(2, "0");
  //           const day = String(jsDate.getDate()).padStart(2, "0");
  //           const hours = String(jsDate.getHours()).padStart(2, "0");
  //           const minutes = String(jsDate.getMinutes()).padStart(2, "0");
  //           const seconds = String(jsDate.getSeconds()).padStart(2, "0");
  //           return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  //         };

  //         // Construct the updated payload
  //         let obj = {
  //           siteId: siteIdFilter[0]?.siteId,
  //           subscriptionId: selectedSubscriptionId, // Use the state variable directly
  //           poNumber: subscriptionField.poNumber,
  //           PoFromDate: subscriptionField.PoFromDate
  //             ? formatDateWithTime(subscriptionField.PoFromDate)
  //             : null,
  //           PoToDate: subscriptionField.PoToDate
  //             ? formatDateWithTime(subscriptionField.PoToDate)
  //             : null,
  //           createdBy: sessionStorage.getItem("id"),
  //           file: base64String,
  //           status:
  //             subscriptionField.status === "Inactive"
  //               ? 0
  //               : subscriptionField.status === "Active"
  //               ? 1
  //               : subscriptionField.status,
  //         };

  //         console.log(obj, "Updated Payload");

  //         try {
  //           setToBackend(true);
  //           let response = await putSubscriptionEdit(obj, id);
  //           // if (response.status === 200) {
  //             const updatedTable = replaceObjectById(
  //               "sub_detailsId",
  //               id,
  //               response.data
  //             );
  //             fetchData();
  //             setSnack(errorMsg.success);
  //             handleClosee();
  //             setOpen(false);
  //             setToBackend(false);
  //             setEditValue("");
  //             window.location.reload();
  //             return 1;
  //           // }
  //         } catch (error) {
  //           setSnack(errorMsg.failure);
  //           console.error("Error:", error.response?.status);
  //           setToBackend(false);
  //           return 0;
  //         }
  //       };
  //       reader.readAsDataURL(selectedFile);
  //     } else {
  //       // Handle case when no file is selected
  //       setSnack({
  //         open: true,
  //         severity: "warning",
  //         message: "Please select a file to upload",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error occurred during update:", error);
  //     setSnack({
  //       open: true,
  //       severity: "error",
  //       message: "Error processing update",
  //     });
  //   }
  // };

  const handleModalClose = () => {
    setModalOpen(false);
    setSubscriptionField([initialState]);
    setSiteTemp("");
    setEquipments([]);
  };

  /*---------------------Search Option ----------------*/
  // const handleDownloadTable = async (val) => {
  //   const status = await Download(val, 'Subscription PO');
  //   if (status === 0) {
  //     setSnack(errorMsg.Download);
  //   }
  // };
  const headCells = [
    // {
    //   label: "Site Name",
    //   id: "siteName",
    //   view: true,
    // },
    {
      label: "Po Number",
      id: "poNumber",
      view: true,
    },
    {
      label: "From Date",
      id: "poFromDate",
      view: true,
    },
    {
      label: "To Date",
      id: "poToDate",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
    },
  ];
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = tableValue.length;
  const Active = tableValue.filter((site) => site.status == 1).length;
  const Inactive = tableValue.filter((site) => site.status == 0).length;

  const tabsData = [
    { name: "Active Subscriptions", value: "all", badgeCount: Active },
    {
      name: "Inactive Subscriptions",
      value: "Inactive",
      badgeCount: Inactive,
    },
  ];

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Site List", path: -1 },
    { label: "Subscription PO", path: "subscriptionPO" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    mr: 0,
                    mb: 2,
                    mt: 2,
                    width: "100%",
                    minHeight: "7vh",
                    maxHeight: "max-content",
                    m: 0,
                    padding: "0",
                  },
                }}
              >
                <TableTemplate
                  // PageName={'Custom Alerts'}
                  PageName={"Subscription PO"}
                  addButton={"Subscription PO"}
                  SearchLabel={"Search SubscriptionPO Here... "}
                  header={headCells}
                  rowsValue={tableValues}
                  tabsData={tabsData}
                  userRole={equipmentConfiguration[0]}
                  rawData={tableValue}
                  handleChange={handleChangeTab}
                  handledownloaddocument={(val) => handledocumentdownload(val)}
                  handleAddPopupOpen={(val) => handleCrate(val)}
                  handleEditPopupOpen={(val) => handleEditClick(val)}
                  handledocumentdata={(val) => handledocumentUpload(val)}
                  // handleDownloadExcel={(val) => {
                  //   handleDownloadTable(val);
                  // }}
                  paths={paths}
                  fallbackText={"No Subscription configured yet"}
                />
              </Box>
            </div>
            <div>
              <Modal
                open={modalopen}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                // sx={{ marginBottom: "3%" }}
                sx={editMode ? { marginBottom: "9%" } : {}}
              >
                {/* <div className="monitoring-points" style={{ marginLeft: "2%" }}> */}
                <Box
                  // sx={stylemodal}
                  className={"styleModalcustomPO"}
                  sx={{ height: "60vh" }}
                >
                  <Card
                    sx={{
                      borderRadius: "20px",
                      // height: 'fit-content',
                    }}
                  >
                    <CardHeader
                      sx={{
                        padding: "20px 20px 20px 20px",
                        background: CheckForLight()
                          ? "rgb(246, 248, 252)"
                          : "#4f4f4f",
                      }}
                      action={
                        <Stack spacing={2} direction="row">
                          {editValue !== undefined ? (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleUpdate}
                              startIcon={<SaveIcon />}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: "25px",
                                textTransform: "capitalize",
                              }}
                              onClick={handleSave}
                              startIcon={<SaveIcon />}
                              disabled={isSaveDisabled}
                            >
                              Save
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            // style={buttonStyles}
                            style={{
                              borderRadius: "25px",
                              textTransform: "capitalize",
                            }}
                            onClick={handleModalClose}
                            startIcon={<CancelIcon />}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      }
                      title={"Add New Subscription"}
                    />
                    <Divider sx={{ borderColor: "#888" }} />
                    <div
                      style={{
                        // height: "60vh",
                        height: "auto",
                        padding: "20px",
                      }}
                    >
                      <Grid container spacing={2} columns={12}>
                        {/* Site Name */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Site Name *</Typography>
                          <Autocomplete
                            size="small"
                            disablePortal
                            disabled
                            options={siteIdName.map(
                              (option) => option.siteName
                            )}
                            defaultValue={siteName}
                            onChange={(event, value) =>
                              setSubscriptionField({
                                ...subscriptionField,
                                siteName: value,
                              })
                            }
                            sx={{ width: "13vw" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Site"
                              />
                            )}
                          />
                        </Grid>

                        {/* Plan */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>Plan *</Typography>
                          <Autocomplete
                            size="small"
                            disablePortal
                            // disabled={editMode}
                            options={[
                              "Monthly",
                              "Quarterly",
                              "Half Yearly",
                              "Annual",
                            ]}
                            value={subscriptionField?.selectedFrequency}
                            onChange={(event, value) =>
                              setSelectedFrequency(value)
                            }
                            sx={{ width: "13vw" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Monthly"
                              />
                            )}
                          />
                        </Grid>

                        {/* PO Number */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>PO Number *</Typography>
                          <TextField
                            disablePortal
                            disableClearable
                            id="combo-box-demo"
                            size="small"
                              disabled={editMode}
                            defaultValue={
                              editMode &&
                                'poNumber'
                                ? editValue
                                  ? (editValue.poNumber)
                                  : ''
                                : editMode &&
                                  'poNumber'
                                  ? editValue
                                    ? (
                                      editValue.poNumber
                                    )
                                    : ''
                                  : editValue
                            }
                            onChange={(event) =>
                              setSubscriptionField({
                                ...subscriptionField,
                                poNumber: event.target.value,
                              })
                            }
                            sx={{ width: "13vw" }}
                            placeholder="Enter PO Number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <div style={{ flex: 1, minWidth: "150px" }}>
                            <Typography style={typoStyle}>
                              Subscription *
                            </Typography>
                            <Autocomplete
                              size="small"
                              disablePortal
                              disableClearable
                              id="combo-box-demo"
                              // value={subscriptionField?.subscriptionName}
                              defaultValue={
                                editMode &&
                                  'subscriptionName'
                                  ? editValue
                                    ? (editValue.subscriptionName)
                                    : ''
                                  : editMode &&
                                    'subscriptionName'
                                    ? editValue
                                      ? (
                                        editValue.subscriptionName
                                      )
                                      : ''
                                    : editValue
                              }
                              // disabled={editMode}
                              options={subscriptionOptions?.map(
                                (option) => option.subscriptionName
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "100px",
                                },
                              }}
                              onChange={(event, newValue) => {
                                const selectedOption = subscriptionOptions.find(
                                  (option) =>
                                    option.subscriptionName === newValue
                                );
                                setSelectedSubscriptionId(
                                  selectedOption?.subscriptionId || null
                                );
                              }}
                              sx={{ width: "13vw" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Ticketing"
                                  // disabled={disabledSiteName}
                                />
                              )}
                              classes={{ option: "autocomplete" }}
                            />
                          </div>
                        </Grid>

  {/* From Date */}
  <Grid item xs={12} sm={6} md={3}>
                          <Typography>From Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // value={subscriptionField?.PoFromDate}
                              defaultValue={
                                editMode &&
                                  'PoFromDate'
                                  ? editValue
                                    ? dayjs(editValue.poFromDate)
                                    : ''
                                  : editMode &&
                                    'PoToDate'
                                    ? editValue
                                      ? dayjs(
                                        editValue.poToDate
                                      )
                                      : ''
                                    : editValue
                              }
                              // onChange={(newDate) => {
                              //   setFromDate(newDate);
                              //   setSubscriptionField({
                              //     ...subscriptionField,
                              //     PoFromDate: newDate,
                              //   });
                              // }}
                              onChange={(newDate) => {
                                const formattedDate = formatDateWithTime(newDate);
                                setFromDate(newDate);
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoFromDate: formattedDate,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select From Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={3}>
  <Typography>From Date *</Typography>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      defaultValue={editMode && subscriptionField.PoFromDate ? dayjs(subscriptionField.PoFromDate) : null}
      onChange={(newDate) => {
        setSubscriptionField({
          ...subscriptionField,
          PoFromDate: newDate ? dayjs(newDate).format("YYYY-MM-DD HH:mm:ss") : "",
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select From Date"
          size="small"
        />
      )}
      slotProps={{
        textField: {
          size: "small",
          InputProps: {
            style: {
              overflow: "hidden",
              width: "13vw",
            },
          },
        },
      }}
    />
  </LocalizationProvider>
</Grid> */}

                        {/* To Date */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography>To Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // value={toDate}
                              defaultValue={
                                editMode &&
                                  'PoToDate'
                                  ? editValue
                                    ? dayjs(editValue.poToDate)
                                    : ''
                                  : editMode &&
                                    'PoFromDate'
                                    ? editValue
                                      ? dayjs(
                                        editValue.poFromDate
                                      )
                                      : ''
                                    : editValue
                              }
                              // onChange={(newDate) => {
                              //   setToDate(newDate);
                              //   setSubscriptionField({
                              //     ...subscriptionField,
                              //     PoToDate: newDate,
                              //   });
                              // }}
                              onChange={(newDate) => {
                                const formattedDate = formatDateWithTime(newDate);
                                setToDate(newDate);
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoToDate: formattedDate,
                                });
                              }}
                              shouldDisableDate={(date) =>
                                fromDate &&
                                dayjs(date).isAfter(calculateMaxDate(fromDate))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select To Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>

                        {/* From Date */}
                        {/* <Grid item xs={12} sm={6} md={3}>
                          <Typography>From Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // value={subscriptionField?.PoFromDate}
                              defaultValue={
                                editMode &&
                                  'PoFromDate'
                                  ? editValue
                                    ? dayjs(editValue.PoFromDate)
                                    : ''
                                  : editMode &&
                                    'PoFromDate'
                                    ? editValue
                                      ? dayjs(
                                        editValue.PoFromDate
                                      )
                                      : ''
                                    : editValue
                              }
                              // value={
                              //   editMode && editValue?.PoFromDate
                              //     ? dayjs(editValue.PoFromDate, "YYYY-MM-DD") // Adjust format if necessary
                              //     : null
                              // }
                                                            
                              onChange={(newDate) => {
                                setFromDate(newDate);
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoFromDate: newDate,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select From Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid> */}

                        {/* To Date */}
                        {/* <Grid item xs={12} sm={6} md={3}>
                          <Typography>To Date *</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              // value={toDate}
                              defaultValue={
                                editMode &&
                                  'PoToDate'
                                  ? editValue
                                    ? dayjs(editValue.PoToDate)
                                    : ''
                                  : editMode &&
                                    'PoToDate'
                                    ? editValue
                                      ? dayjs(
                                        editValue.PoToDate
                                      )
                                      : ''
                                    : editValue
                              }
                              onChange={(newDate) => {
                                setToDate(newDate);
                                setSubscriptionField({
                                  ...subscriptionField,
                                  PoToDate: newDate,
                                });
                              }}
                              shouldDisableDate={(date) =>
                                fromDate &&
                                dayjs(date).isAfter(calculateMaxDate(fromDate))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select To Date"
                                  size="small"
                                />
                              )}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  InputProps: {
                                    style: {
                                      overflow: "hidden",
                                      width: "13vw",
                                    },
                                  },
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Grid> */}

                        {/* <Grid item xs={12} sm={6} md={3}>
                                    <div style={{ flex: 1, minWidth: "150px" }}>
                                      <Typography style={typoStyle}>
                                        Document Type
                                      </Typography>
                                      <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        size="small"
                                        // defaultValue={}
                                        onChange={{}}
                                        options={DocumentType}
                                        sx={{ width: "13vw" }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder="PO"
                                          />
                                        )}
                                      />
                                    </div>
                                  </Grid> */}
                        <Grid item xs={12} sm={6} md={3}>
                          <div style={{ flex: 1, minWidth: "150px" }}>
                            <Typography style={{ marginBottom: "10px" }}>
                              Document Upload *
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="file-upload">
                                <UploadFileOutlinedIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "#1976d2",
                                    fontSize: "24px",
                                    // margin: "10px",
                                  }}
                                />
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                style={{ display: "none" }}
                                accept=".pdf,.xls,.xlsx,.doc,.docx,.png,.jpg,.jpeg" // Include all desired file types
                                onChange={handleFileChange}
                              />

                              {selectedFile && (
                                <Typography
                                  style={{
                                    marginLeft: "10px", // Space between the button and the file name
                                    fontSize: "14px",
                                    overflow: "visible", // Allow the file name to be fully visible
                                    whiteSpace: "normal", // Allows file name to wrap to next line if needed
                                    wordWrap: "break-word", // Ensures long file names break properly if needed
                                    flexGrow: 1, // Make sure the file name takes remaining space without truncating
                                  }}
                                >
                                  {selectedFile.name}
                                </Typography>
                              )}
                            </div>
                            {/* {snack.open && (
                              <Typography
                                style={{
                                  color:
                                    snack.severity === "warning"
                                      ? "orange"
                                      : "red",
                                  fontSize: "12px",
                                }}
                              >
                                {snack.message}
                              </Typography>
                            )} */}
                          </div>
                          {/* <div style={{ marginTop: "20px" }}>
        <Typography variant="h6">Uploaded Files</Typography>
        {fetchedFiles.length > 0 ? (
          fetchedFiles.map((file, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <a
                href={file.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#1976d2",
                  textDecoration: "underline",
                  marginRight: "10px",
                }}
              >
                {file.poNumber || `File ${index + 1}`}
              </a>
            </div>
          ))
        ) : (
          <Typography>No files uploaded for this subscription.</Typography>
        )}
      </div> */}
                        </Grid>
                      </Grid>
                      {/* <div style={{ marginTop: "3%" }}>
                        <Card
                          elevation={1}
                          style={{ height: "44vh", overflow: "auto" }}
                        >
                          <div className="table-container-wrapper">
                          {isTableVisible && editValue?.poDetails?.length > 0 ? (
  <TableContainer>
    <Table aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell align="center">Document Name</TableCell>
          <TableCell align="center">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {editValue.poDetails
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">
              {decodeURIComponent(
            row.fileUrl.split("/").slice(-2, -1)[0]
          )}
              </TableCell>
              <TableCell align="center">
                <SaveAltIcon
                  style={{ color: "Grey", cursor: "pointer" }}
                  onClick={() => handledocumentdownload(row)}
                />
                <Delete
                  style={{
                    color: "Grey",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                  // Uncomment and add delete handler if needed
                  // onClick={() => handleDelete(row)}
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
) : (
  <div>
    <Typography
      style={{
        marginTop: "10%",
        textAlign: "center",
        color: "rgb(113, 118, 111)",
      }}
    >
      No PO Details are available at this time.
    </Typography>
  </div>
)}


                          </div>
                        </Card>
                      </div> */}
                      <div style={{ marginTop: "3%" }}>
  {isTableVisible && editValue?.poDetails?.length > 0 ? ( // Check condition here
    <Card elevation={1} style={{ height: "44vh", overflow: "auto" }}>
      <div className="table-container-wrapper">
        <TableContainer>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Document Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editValue.poDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {decodeURIComponent(row.fileUrl.split("/").slice(-2, -1)[0])}
                    </TableCell>
                    <TableCell align="center">
                      <SaveAltIcon
                        style={{ color: "Grey", cursor: "pointer" }}
                        onClick={() => handledocumentdownload(row)}
                      />
                      <Delete
                        style={{
                          color: "Grey",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        // Uncomment and add delete handler if needed
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  ) : null} {/* Render nothing if condition is not met */}
</div>

                    </div>
                    {isTableVisible && editValue?.poDetails?.length > 0 && (

                    <div style={{ padding: "20px", marginTop: "-50px" }}>
                      <Card
                        component="div"
                        elevation={1}
                        style={{ height: "4.8vh" }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item xs={2}></Grid>
                          <Grid
                            item
                            xs={10}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Stack spacing={2} direction="row">
                              <Pagination
                                count={Math.ceil(poTable.length / rowsPerPage)}
                                shape="rounded"
                                color="primary"
                                page={page + 1}
                                onChange={handleChangePage}
                                renderItem={(item) => (
                                  <PaginationItem
                                    slots={{
                                      previous: renderCustomPrevious,
                                      next: renderCustomNext,
                                    }}
                                    {...item}
                                  />
                                )}
                              />
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{
                                  color: "#E6E7E9",
                                  height: "30px",
                                  marginRight: "16px",
                                }}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginLeft: "1%",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "14.52px",
                                  fontWeight: "400",
                                }}
                              >
                                Go to page
                              </Typography>
                              <TextField
                                variant="standard"
                                style={{
                                  width: "24px",
                                  marginLeft: "5px",
                                }}
                                value={pageValue !== 0 ? pageValue : ""}
                                onChange={(e) => PageChange(e.target.value)}
                              />
                              <Button
                                style={{
                                  padding: "0",
                                  width: "12px",
                                  fontSize: "14px",
                                }}
                                onClick={handleClickGo}
                                endIcon={
                                  <ArrowForwardIcon
                                    style={{ fontSize: "16px" }}
                                  />
                                }
                              >
                                GO
                              </Button>
                              <Autocomplete
                                options={rowsPerPageOptions}
                                getOptionLabel={(option) => option.toString()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                      fontFamily: "Inter, sans-serif",
                                      width: 40,
                                    }}
                                  />
                                )}
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                disableClearable
                                style={{ width: 120 }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Card>
                    
                    </div>
                    )}
                  </Card>
                </Box>
                {/* </div> */}
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SubscriptioPO;


