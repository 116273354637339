import React, { useMemo, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { fetchEquipmentIdDetails } from "../../Api/ExcelUploadAPi";
import {
  CheckForLight,
  formatNumber,
  getUnit,
} from "../util/common/CommanFunction";
import { IconButton } from "@mui/material";
import { object } from "yup";
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const BarChart = (props) => {
  const {
    tableData,
    chartName,
    barchartdata,
    widgetName,
    chartValue,
    range,
    equipmentListAPI,
    customView,
    from,
    fallBack,
    isDashboard,
    customName,
  } = props;

  const [barChart, setBarchartData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  console.log(props, "props");
  // console.log('tableData:', tableData);
  // console.log("chartName:", chartName);
  // console.log('barchartdata:', barchartdata);
  // console.log("siteId:", siteId);
  // console.log("widgetName:", widgetName);
  // console.log("chartValue:", chartValue);
  // console.log("range:", range);
  // console.log('barChart:', barChart);
  const isLight = CheckForLight();

  useEffect(() => {
    if (chartName === "Energy Performance" && from === "analytics") {
      chartValue?.map(async (data) => {
        let equipmentsdata = equipmentListAPI;
        const convertedData = tableData.reduce((result, item) => {
          const found = result.find(
            (x) => x.name === item.equipmentId.toString()
          );
          if (found) {
            found.data.push([
              customView?.range === "yearly" ||
              customView?.range === "yearlyCum" ||
              range === "yearly" ||
              range === "yearlyCum"
                ? item.timeStamp
                : new Date(item.timeStamp).getTime(),
              item.todayEnergy,
            ]);
          } else {
            result.push({
              name: item.equipmentId.toString(),
              data: [
                [
                  customView?.range === "yearly" ||
                  customView?.range === "yearlyCum" ||
                  range === "yearly" ||
                  range === "yearlyCum"
                    ? item.timeStamp
                    : new Date(item.timeStamp).getTime(),
                  item.todayEnergy,
                ],
              ],
            });
          }
          return result;
        }, []);
        let converteddata = convertedData;

        let dataMap = new Map(
          equipmentsdata.map((item) => [
            String(item.equipmentId),
            item.customernaming,
          ])
        );

        converteddata.forEach((item) => {
          if (dataMap.has(item.name)) {
            item.name = dataMap.get(item.name);
          }
        });
        console.log(converteddata, "converteddata");

        setBarchartData(convertedData);
      });
    } else if (chartName === "DG PV Grid Management") {
      // Transformation for DG PV Grid Management chart

      const meters = ["gridMeter", "solarMeter", "dgMeter"];
      const allTimestamps = Array.from(
        new Set(
          meters
            .flatMap((meter) => tableData[meter] || [])
            .map((data) => data.timeStamp)
        )
      );

      const transformedData = meters.map((meter) => {
        const meterData = tableData[meter] || [];
        const meterValues = allTimestamps.map((timeStamp) => {
          const entry = meterData.find((data) => data.timeStamp === timeStamp);
          return [
            customView?.range === "yearly" ||
            customView?.range === "yearlyCum" ||
            range === "yearly" ||
            range === "yearlyCum"
              ? timeStamp
              : new Date(timeStamp).getTime(),
            entry ? entry.todayEnergy : 0,
          ];
        });
        console.log(meterValues, "meterValues");
        // Map meter names to desired format
        const meterNameMap = {
          gridMeter: "Grid",
          solarMeter: "Solar",
          dgMeter: "DG",
        };

        return {
          name: meterNameMap[meter],
          data: meterValues,
        };
      });

      setBarchartData(transformedData);
    } else if (chartName === "Deemed Generation") {
      function transformData(inputArray) {
        const formattedData = [
          { name: "Today Energy", data: [] },
          { name: "Irradiation", data: [] },
          { name: "Deemed Generation", data: [] },
          { name: "Set Point", data: [] },
          { name: "Potential Generation", data: [] },
        ];

        inputArray?.forEach((point) => {
          formattedData[0].data.push([point.timeStamp, point.todayEnergy]);
          formattedData[1].data.push([point.timeStamp, point.irradiation]);
          formattedData[2].data.push([point.timeStamp, point.deemedGeneration]);
          formattedData[3].data.push([point.timeStamp, point.setPoint]);
          formattedData[4].data.push([
            point.timeStamp,
            point.potentialGeneration,
          ]);
        });

        return formattedData;
      }

      const transformedData = transformData(tableData);
      setBarchartData(transformedData);
    } else if (chartName === "Expected Deviation") {
      function transformData(inputArray) {
        const formattedData = [
          // { name: "Estimated Energy", data: [] },
          // { name: "Expected Energy", data: [] },
          // { name: "Estimated Percentage", data: [] },
          { name: "Expected Generation", data: [] },
          { name: "Extrapolated Generation", data: [] },
          { name: "Achieved Percentage", data: [] },
        ];

        inputArray?.forEach((point) => {
          const expected = point.expectedEnergy || point.exceptedEnergy;

          formattedData[0].data.push([point.timestamp, expected]);
          formattedData[1].data.push([
            point.timestamp,
            point.estimatedTodayEnergy,
          ]);
          formattedData[2].data.push([
            point.timestamp,
            point.estimatedPercentage,
          ]);
        });

        return formattedData;
      }
      const transformedData = transformData(tableData);
      setBarchartData(transformedData);
    } else {
      setBarchartData(barchartdata);
    }
  }, [props, chartValue]);

  const chartData = useMemo(() => {
    if (
      !tableData ||
      chartName === "DG PV Grid Management" ||
      chartName === "Deemed Generation"
    ) {
      return [];
    }
    return tableData?.map((data) => [
      customView?.range === "yearly" ||
      customView?.range === "yearlyCum" ||
      range === "yearly" ||
      range === "yearlyCum"
        ? data.timeStamp
          ? data.timeStamp
          : data.timestamp
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      Number(data.todayEnergy),
    ]);
  }, [tableData, chartName]);

  const Irradiation = useMemo(() => {
    if (
      !tableData ||
      chartName === "DG PV Grid Management" ||
      chartName === "Deemed Generation"
    )
      return [];
    return tableData?.map((data) => [
      customView?.range === "yearly" ||
      customView?.range === "yearlyCum" ||
      range === "yearly" ||
      range === "yearlyCum"
        ? data.timeStamp
          ? data.timeStamp
          : data.timestamp
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      Number(data.irradiation),
    ]);
  }, [tableData, chartName]);

  const performanceSeries = useMemo(() => {
    if (
      !tableData ||
      chartName === "DG PV Grid Management" ||
      chartName === "Deemed Generation"
    )
      return [];
    if (!tableData) return [];
    return tableData.map((data) => [
      data.timestamp,
      Number(data.performanceRatio),
    ]);
  }, [tableData]);

  // const formattedDates = timeStampData.map(dateStr => {
  //   const parts = dateStr.split('-');
  //   return `${parts[2]}-${parts[1]}-${parts[0]}`;
  // });
  const nameForFile = isDashboard ? customName : widgetName;

  const categoricalChartCondition =
    (chartName === "Deemed Generation" && customView?.range !== "daily") ||
    ((customView?.range === "yearly" ||
      customView?.range === "yearlyCum" ||
      range === "yearly" ||
      range === "yearlyCum") &&
      (chartName === "DG PV Grid Management" ||
        chartName === "Energy Performance" ||
        chartName === "Daily Generation")) ||
    chartName === "Performance Ratio" ||
    chartName === "Expected Deviation";

  const columnChartOptions = {
    chart: {
      type: "column",
      backgroundColor: isLight ? "white" : "#121212",
    },
    title: {
      text: widgetName,
      style: {
        textTransform: "captalize",
        color: isLight ? "black" : "white",
        fontWeight: "normal",
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: categoricalChartCondition
      ? {
          type: "category",
          labels: {
            rotation: -45,
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
        }
      : {
          type: "datetime",
          dateTimeLabelFormats: {
            day: "%d/%m/%Y",
          },
          title: {
            text: "Time (HH:MM)",
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
          labels: {
            rotation: -45,
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "14px",
            },
            formatter: function () {
              return Highcharts.dateFormat("%d/%m/%Y", this.value);
            },
          },
        },

    exporting: {
      filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
      csv: {
        dateFormat: "%d/%m/%Y",
        columnHeaderFormatter: function (item, key) {
          console.log(item, key);
          if (item.coll === "xAxis") {
            return "Date";
          }

          if (key === "y") {
            return `${item.name}${getUnit(
              item.name.replace(/\s+/g, ""),
              "PVcum"
            )}`;
          }
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadJPEG",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
            // from === 'analytics' ? '' : 'viewData',
            from === "Vanalytics"
              ? {
                  text: "View Table",
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data
                    // console.log(dataHtml, 'before');

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    // Format timestamps in the table
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                      function (match, p1) {
                        const date = new Date(p1);
                        return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          }
                        )} </th>`;
                      }
                    );
                    // console.log(dataHtml, 'after');
                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : "",
          ],
        },
      },
      enabled: true,
      // showTable: true,
      columnHeaderFormatter: function (item, key) {
        if (item.isXAxis) {
          return item.options.title.text || item.name;
        }
        if (key === "y") {
          return item.yAxis.options.title.text || item.name;
        }
      },
    },
    yAxis: [
      {
        // 0
        title: {
          text: `Energy Generation (kWh)`,
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
        visible: chartName === "Performance Ratio" ? false : true,
        style: {
          color: isLight ? "black" : "white",
          fontSize: isDashboard ? "10px" : "0.8em",
          fontFamily: "Inter",
        },
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
          },
        },
      },
      {
        // 1
        title: {
          text: "Irradiation",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
        opposite: true,
        visible: chartName === "Daily Generation" ? true : false,
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
          },
        },
      },
      {
        //2
        min: 0,
        // max: 1000,
        title: {
          text: "Irradiation (Wh/m2)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
            fontFamily: "Inter",
          },
        },
        opposite: true,
        visible: chartName === "Deemed Generation",
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
          },
        },
      },
      {
        // 3
        title: {
          text: "Set Point (%)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: chartName === "Deemed Generation",
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        // 4
        title: {
          text: "Performance Ratio (%)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: chartName === "Performance Ratio",
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
      {
        // 5
        title: {
          text: "Estimated Percentage (%)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: "0.8em",
            fontFamily: "Inter",
          },
        },
        min: 0,
        opposite: true,
        visible: chartName === "Expected Deviation",
        labels: {
          style: {
            color: isLight ? "black" : "white",
          },
        },
      },
    ],
    plotOptions: {
      series: {
        stacking: chartName === "DG PV Grid Management" ? "normal" : undefined,
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );
                      console.log(
                        visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1,
                        visbleSeries
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
    },
    legend: {
      maxHeight: 110,
      itemStyle: {
        color: isLight ? "black" : "white",
        fontSize: isDashboard ? "10px" : "14px",
      },
      itemHoverStyle: {
        color: isLight ? "gray" : "lightgray",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? "white" : "#121212",
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (chartName === "DG PV Grid Management") {
          var currentPoint = this,
            currentSeries = currentPoint.series,
            chart = currentSeries.chart,
            stackValues = "";

          chart.series.forEach(function (series) {
            series.points.forEach(function (point) {
              const pointKey =
                customView?.range === "yearly" ||
                customView?.range === "yearlyCum" ||
                range === "yearly" ||
                range === "yearlyCum"
                  ? "name"
                  : "x";
              if (currentPoint.key === point.options[pointKey]) {
                stackValues +=
                  series.name +
                  ": " +
                  formatNumber(point.y.toFixed(2)) +
                  "  kWh" +
                  "<br/>";
              }
            });
          });

          const xLabel =
            customView?.range === "yearly" ||
            customView?.range === "yearlyCum" ||
            range === "yearly" ||
            range === "yearlyCum"
              ? this.key
              : Highcharts.dateFormat("%d/%m/%Y", this.x);

          return (
            `<div class="custom-tooltip"  style="color:${
              isLight ? "black" : "white"
            };">` +
            "<b>Energy Generation</b>" +
            "<br/><b>" +
            xLabel +
            "</b><br/>" +
            stackValues +
            "Total: " +
            formatNumber(this.point.stackTotal.toFixed(2)) +
            "  kWh" +
            "</div>"
          );
        } else if (chartName === "Deemed Generation") {
          const xLabel = Highcharts.dateFormat(
            "%d/%m/%Y",
            this.point.options.x
          );
          if (this.series.name === "Deemed Generation") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };" >
          <p>${xLabel}</p>
          <p>Deened Generation: ${formatNumber(this.point.y.toFixed(2))}</p>
        </div>`;
          } else if (this.series.name === "Potential Generation") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Potential Generation: ${formatNumber(this.point.y.toFixed(2))}</p>
        </div>`;
          } else if (this.series.name === "Set Point") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Set Point: ${formatNumber(this.point.y.toFixed(2))}</p>
        </div>`;
          } else if (this.series.name === "Today Energy") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Today Energy: ${formatNumber(this.point.y.toFixed(2))}</p>
        </div>`;
          } else {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Irradiation: ${formatNumber(this.point.y.toFixed(2))}</p>
        </div>`;
          }
        } else if (chartName === "Performance Ratio") {
          const xLabel = this.point.options.name;
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };" >
          <p>${xLabel}</p>
          <p>Performance Ratio: ${formatNumber(this.point.y)} %</p>
        </div>`;
        } else if (chartName === "Expected Deviation") {
          const xLabel = this.point.options.name;
          if (this.series.name === "Expected Generation") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };" >
          <p>${xLabel}</p>
          <p>Expected Generation: ${formatNumber(this.point.y)} kWh</p>
        </div>`;
          }
          if (this.series.name === "Extrapolated Generation") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };" >
          <p>${xLabel}</p>
          <p>Extrapolated Generation: ${formatNumber(this.point.y)} kWh</p>
        </div>`;
          }
          if (this.series.name === "Achieved Percentage") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };" >
          <p>${xLabel}</p>
          <p>Achieved Percentage: ${formatNumber(this.point.y)} %</p>
        </div>`;
          }
        } else {
          const isCumulative =
            customView?.range === "yearly" ||
            customView?.range === "yearlyCum" ||
            range === "yearly" ||
            range === "yearlyCum";

          const xLabel =
            this.point.options.name &&
            chartName === "Energy Performance" &&
            !isCumulative
              ? this.point.options.name
              : chartName === "Energy Performance" && isCumulative
              ? this.point.series.name
              : chartName === "Energy Performance" && !isCumulative
              ? this.point.series.userOptions.name
              : Highcharts.dateFormat("%d/%m/%Y", this.point.options.x);

          // Default tooltip for other charts
          if (this.series.name === "Energy Generation (kWh)") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };" >
          <p>${xLabel}</p>
          <p>Energy Generation: ${formatNumber(this.point.y)} (kWh)</p>
        </div>`;
          } else if (this.series.name === "Irradiation") {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Irradiation: ${formatNumber(this.point.y)}</p>
        </div>`;
          } else {
            return `<div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
          <p>${xLabel}</p>
          <p>Energy Generation: ${formatNumber(this.point.y)} (kWh)</p>
        </div>`;
          }
        }
      },
    },

    series:
      chartName === "Daily Generation"
        ? [
            {
              name: "Energy Generation (kWh)",
              data: chartData,
              type: "column",
              color: "LightSkyBlue",
            },
            {
              name: "Irradiation",
              data: Irradiation,
              type: "line",
              yAxis: 1,
              color: "Navy",
            },
          ]
        : tableData && chartName === "DG PV Grid Management"
        ? (barChart ?? [])
            .map((item) => ({
              ...item,
              data: item.data,
              color:
                item.name === "Grid"
                  ? "Grey"
                  : item.name === "Solar"
                  ? "Orange"
                  : item.name === "DG"
                  ? "LightSkyBlue"
                  : undefined,
            }))
            .sort((a, b) => {
              const sumA = a.data.reduce((sum, value) => sum + value, 0);
              const sumB = b.data.reduce((sum, value) => sum + value, 0);
              return sumA - sumB; // Sort in ascending order of total values
            })
        : tableData && chartName === "Deemed Generation"
        ? (barChart ?? []).map((series) => {
            if (series.name === "Set Point") {
              series.yAxis = 3;
            } else if (series.name === "Irradiation") {
              series.yAxis = 2;
            } else {
              series.yAxis = 0;
            }
            return series;
          })
        : chartName === "Performance Ratio"
        ? [
            {
              name: "Performance Ratio (%)",
              data: performanceSeries,
              yAxis: 4,
              type: "column",
              color: "LightSkyBlue",
            },
          ]
        : chartName === "Expected Deviation" && barChart
        ? barChart.map((series) => {
            if (series.name === "Achieved Percentage") {
              series.yAxis = 5;
            } else {
              series.yAxis = 0;
            }
            return series;
          })
        : barChart,
  };
  console.log(columnChartOptions, tableData?.length > 0, barChart);

  return (
    <div
      style={{
        width: "100%",
        height: isDashboard
          ? "300px"
          : from === "Vanalytics"
          ? "500px"
          : "fit-content",
      }}
    >
      {tableData?.length > 0 ||
      (chartName === "DG PV Grid Management" &&
        (tableData?.dgMeter?.length > 0 ||
          tableData?.gridMeter?.length > 0 ||
          tableData?.solarMeter?.length > 0)) ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{widgetName.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: "100%",
                height: isDashboard
                  ? "300px"
                  : from === "Vanalytics"
                  ? "500px"
                  : "fit-content",
              },
            }}
            options={columnChartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
export default BarChart;
