import React from "react";
import { Card, CardContent } from "@mui/material";

export default function VoltageCurrentTable({ row, currentConfig }) {
  console.log(row);
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        padding: "10px",
        backgroundColor: "#f5f5f5",
      }}
    >
      {/* Function to split data */}
      {(() => {
        // Extract inputCurrent values and filter out null, empty, or 0 values
        const currents = Object.entries(row?.scbStringValue || {})
          .filter(([key, value]) => key.includes("inputCurrent") && value !== null && value !== "" && value !== "0")
          .map(([, value]) => value);

        // Extract inputVoltage values
        const voltages = Object.entries(row?.scbStringValue || {})
          .filter(([key]) => key.includes("inputVoltage"))
          .map(([, value]) => value);

        const maxLength = Math.max(currents.length, voltages.length);

        const alignedCurrents = Array.from(
          { length: maxLength },
          (_, i) => currents[i] || "0" // Ensure empty current values are treated as 0
        );
        const alignedVoltages = Array.from(
          { length: maxLength },
          (_, i) => voltages[i] || "0" // Ensure empty voltage values are treated as 0
        );

        // Use the first voltage value for all voltages
        const firstVoltage = alignedVoltages[0] || "0";
        const updatedVoltages = alignedVoltages.map(() => firstVoltage);

        // Split data into two halves
        const midpoint = Math.ceil(maxLength / 2);
        const firstHalf = alignedCurrents.slice(0, midpoint).map((current, index) => {
          // If current is 0, set voltage to 0
          const voltage = current === "0" ? "0" : updatedVoltages[index];
          const power = current === "0" || voltage === "0" ? "0:00" : (parseFloat(current) * parseFloat(voltage)).toFixed(2); // Calculate power
          return { current, voltage, power };
        });
        const secondHalf = alignedCurrents.slice(midpoint).map((current, index) => {
          // If current is 0, set voltage to 0
          const voltage = current === "0" ? "0" : updatedVoltages[midpoint + index];
          const power = current === "0" || voltage === "0" ? "0:00" : (parseFloat(current) * parseFloat(voltage)).toFixed(2); // Calculate power
          return { current, voltage, power };
        });

        return [firstHalf, secondHalf].map((data, cardIndex) => {
          console.log(data);

          // Determine the starting index for each card
          const startingIndex = cardIndex === 0 ? 0 : firstHalf.length;

          return (
            <Card key={cardIndex} style={{ width: "auto", padding: "10px" }}>
              <CardContent>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <td
                        style={{
                          padding: "3px",
                          textAlign: "center",
                          visibility: "hidden",
                        }}
                      >
                        Current (A)
                      </td>
                      <td style={{ padding: "3px",fontSize: "14px", textAlign: "center" }}>
                        Current (A)
                      </td>
                      <td style={{ padding: "8px",fontSize: "14px", textAlign: "center" }}>
                        Voltage (V)
                      </td>
                      <td style={{ padding: "8px",fontSize: "14px", textAlign: "center" }}>
                        Power (W) {/* New column header */}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .filter(({ current }) => current !== undefined)
                      .map(({ current, voltage, power }, index) => (
                        <tr
                          key={index}
                          style={{ color: current === "0" ? "red" : "" }}
                        >
                          <th
                            style={{
                              padding: "15px",
                              textAlign: "center",
                              color: current === "0" ? "red" : "rgba(0, 0, 0, 0.87)",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {`String - ${startingIndex + index + 1}`}
                          </th>
                          <td
                            style={{
                              padding: "15px",
                              textAlign: "center",
                              color: current === "0" ? "red" : "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {current}
                          </td>
                          <td
                            style={{
                              padding: "15px",
                              textAlign: "center",
                              color: current === "0" ? "red" : "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {current === "0" ? "0" : voltage}
                          </td>
                          <td
                            style={{
                              padding: "15px",
                              textAlign: "center",
                              color: current === "0" ? "red" : "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {power} {/* Display the calculated power */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </CardContent>
            </Card>
          );
        });
      })()}
    </div>
  );
}
