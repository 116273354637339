import { useState, useEffect } from "react";
import { SiteListApi } from "../../Api/SiteListApi";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { CheckForLight, roundedUp } from "../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import { paddedEncryptQuery, queryDecrypt } from "../util/security/Cipher";
import { useNavigate } from "react-router";
import { CustomChartIcon } from "../util/common/CustomChartIcon";
import CachedIcon from "@mui/icons-material/Cached";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

function SiteStatusTable() {
  const [loading, setLoading] = useState(true);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");
  const [siteDetails, setSiteDetails] = useState([]);
  const [isReferesh, setRefresh] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(
    sessionStorage.getItem("refresh") === "true"
  );

  const navigate = useNavigate();

  function getColor(status) {
    switch (status) {
      case "Active":
        return "green";
      case "Offline":
        return "#98A3B2";
      case "Warning":
        return "#FFB10E";
      case "Down":
        return "#E83A2A";
      default:
        return "transparent";
    }
  }

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      console.log("Auto-refresh enabled");
      interval = setInterval(handleRefresh, 5 * 60 * 1000);
      console.log("Interval set for 5 minutes");
    } else {
      clearInterval(interval);
      console.log("Auto-refresh disabled");
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  function navigateEncryptedURL(data, URL) {
    let passSite = {
      id: data.siteId,
      name: data.siteName,
      irradiation: data.irradiation,
    };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }

  function handleRowClick(rowValue) {
    navigateEncryptedURL(rowValue, "/menu/site_overview");
  }

  const handleAnalytics = () => {
    navigate("/menu/block-analyst");
  };

  const handleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
    sessionStorage.setItem("refresh", !autoRefresh);
  };

  const handleRefresh = () => {
    setRefresh(true);
    const RefreshTimeout = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => clearTimeout(RefreshTimeout);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortData = (data, orderBy, orderDirection) => {
    return data.sort((a, b) => {
      if (orderDirection === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  };

  const rolesString = queryDecrypt(sessionStorage.getItem("roles"));
  function hasPermission(activityName) {
    const rolesArray = rolesString ? JSON.parse(rolesString) : [];
    const activityEntry = rolesArray.find(
      (entry) => entry.activityName === activityName
    );

    const result =
      (activityEntry && activityEntry.status == 1 && activityEntry.view == 1) ||
      false;

    return result;
  }

  useEffect(() => {
    fetchSiteData();
  }, []);

  useEffect(() => {
    if (isReferesh) {
      fetchSiteData(siteDetails);
    }
  }, [isReferesh]);

  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : "0";
  };

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await SiteListApi();
    let updatedSites = response.map((site) => ({
      ...site,
      specificYield: specificYiled(site.specificYield),
      inverter: `${site.activeInverterCount}/${site.inverterCount}`,
      status:
        site.siteStatus === "Active"
          ? 1
          : site.siteStatus === "Warning"
          ? 2
          : site.siteStatus === "Offline"
          ? 4
          : 3,
      irradiation: roundedUp(site.irradiation),
    }));
    console.log(updatedSites);

    setSiteDetails(updatedSites);

    setLoading(false);
  };
  return (
    <>
      <Card
        sx={{ width: "100%", height: "340px", paddingBottom: 0 }}
        elevation={0}
        key={"siteStatusTable"}
      >
        <CardContent
          sx={{
            height: "100%",
            paddingBottom: "0 !important",
            paddingTop: "10px !important",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                }}
              >
                Quick Insights
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRefresh}
                    onChange={(e) => handleAutoRefresh(e.target.checked)}
                  />
                }
                label="Auto Refresh (5 min)"
              />
            </div> */}
              <div>
                <Tooltip title="Refresh">
                  <IconButton onClick={handleRefresh} disabled={isReferesh}>
                    <CachedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ marginTop: "1px" }}>
                <Tooltip title="Analytics">
                  <IconButton
                    onClick={handleAnalytics}
                    disabled={!hasPermission("blockAnalytics")}
                  >
                    <AnalyticsOutlinedIcon
                      sx={{
                        color: !hasPermission("blockAnalytics")
                          ? "gray"
                          : CheckForLight()
                          ? "black"
                          : "white",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
          {loading ? (
            <Card
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              elevation={0}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <FadeLoader color="#000440" loading={true} />
              </CardContent>
            </Card>
          ) : (
            <>
              <TableContainer
                component={Paper}
                elevation={1}
                sx={{ width: "100%", height: "322px", overflowX: "hidden" }}
              >
                <Table
                  size="small"
                  aria-label="Inverter Performance Table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow style={{ fontSize: "10px" }}>
                      <TableCell
                        align="center"
                        sx={{
                          width: "300px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "siteName"} // The current sorting column
                          direction={orderDirection}
                          onClick={() => handleSortRequest("siteName")} // Sort by inverter name
                        >
                          Site Name
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "100px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                          zIndex: 2,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "performanceRatio"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("performanceRatio")} // Sort by power
                        >
                          PR (%)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          width: "200px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                          zIndex: 2,
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "sumOfActivePower"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("sumOfActivePower")} // Sort by power
                        >
                          Active Power (kW)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "irradiation"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("irradiation")} // Sort by today's energy
                        >
                          Irradiation (kWh/m²)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          width: "50px",
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "specificYield"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("specificYield")} // Sort by today's energy
                        >
                          Specific Yield
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "sumOfTodayEnergy"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("sumOfTodayEnergy")} // Sort by specific yield
                        >
                          Today Energy (kWh)
                        </TableSortLabel>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          background: CheckForLight() ? "#E8E8EA" : "#4f4f4f",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === "cuf"}
                          direction={orderDirection}
                          onClick={() => handleSortRequest("cuf")} // Sort by specific yield
                        >
                          CUF (%)
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortData(siteDetails, orderBy, orderDirection).map(
                      (row) => (
                        <TableRow key={row.siteId}>
                          <TableCell
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              // justifyContent: "space-between",
                              alignItems: "center",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRowClick(row)}
                          >
                            <div
                              style={{
                                backgroundColor: getColor(row.siteStatus),
                                height: "15px",
                                width: "10px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div> {row.siteName}</div>
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                +row.performanceRatio?.split("%")[0] > 100
                                  ? "red"
                                  : null,
                            }}
                            align="center"
                          >
                            {row.performanceRatio?.split("%")[0] ??
                              row.performanceRatio}
                          </TableCell>
                          <TableCell align="center">
                            {row.sumOfActivePower?.split("kW")[0] ??
                              row.sumOfActivePower}
                          </TableCell>
                          <TableCell align="center">
                            {row.irradiation}
                          </TableCell>
                          <TableCell align="center">
                            {row.specificYield}
                          </TableCell>
                          <TableCell align="center">
                            {row.sumOfTodayEnergy?.split("kWh")[0] ??
                              row.sumOfTodayEnergy}
                          </TableCell>
                          {/* <TableCell align="center">
                            {row.irradiation}
                          </TableCell> */}
                          <TableCell align="center">
                            {isNaN(row.cuf?.split("%")[0])
                              ? "-"
                              : row.cuf.split("%")[0] ?? row.cuf}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default SiteStatusTable;
