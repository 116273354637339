

import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import { Card, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLocation } from 'react-router-dom';
import { removeQuestionMark } from '../util/common/CommanFunction';
import { decryptPaddedQuery } from '../util/security/Cipher';
import { fetchPiehartGet, fetchPiehartGett } from '../../Api/PieChartDataApi';

Highcharts3D(Highcharts);

const PieChartData = (props) => {
  const { sitevalue ,filterstatus,filterData,statuscall,picharttooltip} = props;
  console.log(statuscall);
  console.log(picharttooltip);
  
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const isLight = colorMode === 'light';

  const [totalTickets, setTotalTickets] = useState(null);
  console.log(totalTickets);
  
  const [options, setOptions] = useState({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;

  const labelStyle = {
    fontSize: '12.5px',
    padding: '10px',
    margin: '0 0 0 0', 
  };
console.log(sitevalue);

  const fetchData = async (sitevalue) => {
  
    let id =sessionStorage.getItem('id')
let data;
  if(isFromSiteList===true ){
    data = await fetchPiehartGett(params.id);
  }else if(sitevalue>0) {
    data = await fetchPiehartGett(sitevalue);
  }else{
    data = await fetchPiehartGet(id);
  }

      

      if (data && data.totalTickets) {
        setTotalTickets(filterstatus===true?filterData.totalTickets:data.totalTickets);
        setOptions({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
            backgroundColor: isLight ? '#ffffff' : '#121212',
            height: '333px',
            events: {
              load: function () {
                this.series[0].points.forEach((point) => {
                  if (point.name === 'Open Tickets') {
                    point.slice(true);
                  }
                });
              },
            },
          },
          title: {
            text: 'Ticket Status',
            style: {
              fontSize: '15px',
              fontWeight: 'normal',
              color: isLight ? 'black' : 'white',
            },
            align: 'center',
          },
          tooltip: {
            formatter: function () {
              const tooltipValue = `${this.point.name}: ${this.y}`;
              console.log('Tooltip Value:', tooltipValue); // Log the tooltip value
              return tooltipValue; // Display the tooltip as usual
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: false,
                format: '{point.name}',
                connectorWidth: 0,
              },
              point: {
                events: {
                  mouseOver: function () {
                    this.series.chart.series[0].points.forEach((point) => {
                      if (point.sliced && point !== this) {
                        point.slice(false);
                      }
                    });
                    this.slice(true);
                  },
                  mouseOut: function () {
                    if (this.name !== 'Open Tickets') {
                      this.slice(false);
                      this.series.chart.series[0].points.forEach((point) => {
                        if (point.name === 'Open Tickets') {
                          point.slice(true);
                        }
                      });
                    }
                  },
                  click: function () {
                    const tooltipText = `${this.name}: ${this.y}`;
                    if (typeof picharttooltip === 'function') {
                      picharttooltip(tooltipText); 
                    } else {
                      console.error("picharttooltip is not a function");
                    }
                  }
                },
              },
            },
          },
          credits: {
            enabled: false, 
          },
          series: [
            {
              type: 'pie',
              name: 'Share',
              data: [
                {
                  name: 'Open Tickets',
                  y: data.totalTickets.openTicketCount,
                  color: '#434da0',
                  sliced: true,
                  selected: true,
                },
                {
                  name: 'Hold Tickets',
                  y: data.totalTickets.holdTicketCount,
                  color: '#00ff7f',
                },
                {
                  name: 'Closed Tickets',
                  y: data.totalTickets.closeTicketCount,
                  color: '#2196f3',
                },
                {
                  name: 'In Progress Tickets',
                  y: data.totalTickets.inProgressTicketCount,
                  color: '#4e7098',
                },
              ],
            },
          ],
          legend: {
            enabled: false,
          },
        });
      }
    };
    useEffect(() => {
      if (totalTickets) {
        setOptions({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
            backgroundColor: isLight ? '#ffffff' : '#121212',
            height: '333px',
            events: {
              load: function () {
                this.series[0].points.forEach((point) => {
                  if (point.name === 'Open Tickets') {
                    point.slice(true);
                  }
                });
              },
            },
          },
          title: {
            text: 'Ticket Status',
            style: {
              fontSize: '15px',
              fontWeight: 'normal',
              color: isLight ? 'black' : 'white',
            },
            align: 'center',
          },
          tooltip: {
            formatter: function () {
              const tooltipValue = `${this.point.name}: ${this.y}`;
              console.log('Tooltip Value:', tooltipValue); // Log the tooltip value
              return tooltipValue; // Display the tooltip as usual
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: false,
                format: '{point.name}',
                connectorWidth: 0,
              },
              point: {
                events: {
                  mouseOver: function () {
                    this.series.chart.series[0].points.forEach((point) => {
                      if (point.sliced && point !== this) {
                        point.slice(false);
                      }
                    });
                    this.slice(true);
                  },
                  mouseOut: function () {
                    if (this.name !== 'Open Tickets') {
                      this.slice(false);
                      this.series.chart.series[0].points.forEach((point) => {
                        if (point.name === 'Open Tickets') {
                          point.slice(true);
                        }
                      });
                    }
                  },
                  click: function () {
                    const tooltipText = `${this.name}: ${this.y}`;
                    if (typeof picharttooltip === 'function') {
                      picharttooltip(tooltipText);
                    } else {
                      console.error('picharttooltip is not a function');
                    }
                  },
                },
              },
            },
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              type: 'pie',
              name: 'Share',
              data: [
                {
                  name: 'Open Tickets',
                  y: totalTickets.openTicketCount,
                  color: '#434da0',
                  sliced: true,
                  selected: true,
                },
                {
                  name: 'Hold Tickets',
                  y: totalTickets.holdTicketCount,
                  color: '#00ff7f',
                },
                {
                  name: 'Closed Tickets',
                  y: totalTickets.closeTicketCount,
                  color: '#2196f3',
                },
                {
                  name: 'In Progress Tickets',
                  y: totalTickets.inProgressTicketCount,
                  color: '#4e7098',
                },
              ],
            },
          ],
          legend: {
            enabled: false,
          },
        });
      }
    }, [totalTickets, isLight, picharttooltip]);
    useEffect(() => {
      const data=filterstatus===true?filterData:sitevalue
      console.log(data);
      
      fetchData(sitevalue);
      console.log(sitevalue);
      
    }, [isLight, isFromSiteList, params.id, sitevalue,filterData,filterstatus]);
  return (
    <Card
      elevation={0}
      style={{
        borderRadius: '6px',
        height: isSmallScreen ?"400px":"auto",
        width: isSmallScreen ? '100%' : 'auto',
      }}
    >
      {totalTickets && (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <Grid container style={{ ...labelStyle }}>
            <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // marginTop:"2%"
                }}
              >
                <VerifiedUserIcon
                  style={{
                    color: '#434da0',
                    width: '13px',
                    marginRight: '2px',
                  }}
                />
                <Typography
                  style={{
                    color: isLight ? 'black' : 'white',
                    cursor: 'pointer',
                    fontSize: '0.8rem',
                    textDecoration: 'none',
                  }}
                >
                  Open : {totalTickets.openTicketCount}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PauseCircleOutlineIcon
                  style={{
                    color: '#00ff7f',
                    width: '13px',
                    marginRight: '2px',
                  }}
                />
                <Typography
                  style={{
                    color: isLight ? 'black' : 'white',
                    fontSize: '0.8rem',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fill: 'black',
                  }}
                >
                  Hold : {totalTickets.holdTicketCount}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CheckCircleOutlineIcon
                  style={{
                    color: '#2196f3',
                    width: '13px',
                    marginRight: '2px',
                  }}
                />
                <Typography
                  style={{
                    color: isLight ? 'black' : 'white',
                    cursor: 'pointer',
                    fontSize: '0.8rem',
                    textDecoration: 'none',
                    fill: 'black',
                  }}
                >
                  Close : {totalTickets.closeTicketCount}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TimelineIcon
                  style={{
                    color: '#4e7098',
                    width: '13px',
                    marginRight: '2px',
                  }}
                />
                <Typography
                  style={{
                    color: isLight ? 'black' : 'white',
                    cursor: 'pointer',
                    fontSize: '0.8rem',
                    textDecoration: 'none',
                    fill: 'black',
                  }}
                >
                  In Progress : {totalTickets.inProgressTicketCount}
                </Typography>
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // marginTop:"2%"
                }}
              >
                <VerifiedUserIcon
                  style={{
                    color: '#434da0',
                    width: '13px',
                    marginRight: '2px',
                  }}
                />
                <Typography
                  style={{
                    color: isLight ? 'black' : 'white',
                    cursor: 'pointer',
                    fontSize: '0.8rem',
                    textDecoration: 'none',
                  }}
                >
                  Open : {totalTickets.openTicketCount}
                </Typography>
              </div>
            </Grid> */}
          </Grid>
        </div>
      )}
    </Card>
 
  );
};

export default PieChartData;



