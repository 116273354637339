// import React, { useState, useEffect } from 'react';
// import './Alerts.css';
// import dayjs, { Dayjs } from 'dayjs';
// /*---------------------------Mui Components ----------------------------------*/
// import {
//   Button,
//   Stack,
//   Box,
//   Paper,
//   TextField,
//   Autocomplete,
//   Typography,
//   CardContent,
//   Card,
//   FormControl,
//   Modal,
//   Grid,
// } from '@mui/material';
// /*-------------------------------API----------------------------*/
// import {
//   fetchAlertsTable,
//   updateEditCall,
//   postAlertsSave,
// } from '../../../Api/AlertsComponentApi';
// import { Equipmentdropdown } from '../../../Api/EquipmentApi';
// import { fetchParameterListDetails } from '../../../Api/ExcelUploadAPi';
// import AddIcon from '@mui/icons-material/Add';
// import CloseIcon from '@mui/icons-material/Close';
// import SaveIcon from '@mui/icons-material/Save';
// import CancelIcon from '@mui/icons-material/Cancel';
// import '../../../Common.css';
// import { FadeLoader } from 'react-spinners';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// /*------------------------- External compoenents ---------------------------*/
// import Download from '../../../Template/Excel/Download';
// import TableTemplate from '../../../Template/TableTemplate';
// import CustomSnackbar from '../../util/components/CustomSnackbar';
// import { ErrorMessage, Field, Form, Formik } from 'formik';
// import { customizeAlerts } from '../../util/TextField';
// import errorMsg from '../../util/errorMessage.json';
// import { fetchSiteDetails } from '../../../Api/ReportApi';
// import { EquipmentlistDropdown } from '../../../Api/TicketApi';
// const stylemodal = {
//   margin: '20px 0px 0px 0px',
//   position: 'absolute',
//   top: '50%',
//   transform: 'translate(-50%, -50%)',
//   // left: '52%',
//   // width: "78%",
//   // height: "55vh",
//   // width: "90%",
//   width: '90%',
//   borderRadius: '15px',
//   left: '47%',
//   height: '69vh',
//   bgcolor: 'background.paper',
//   p: 4,
//   marginLeft: '5%',
// };
// const Status = [
//   { label: 'Active', value: 'active' },
//   { label: 'Inactive', value: 'inactive' },
// ];
// const typoStyle = {
//   textTransform: 'capitalize',
//   opacity: '0.8',
//   fontSize: '15px',
// };
// const Logic = [
//   { label: 'Less', value: 'Less', id: 1 },
//   { label: 'Greater', value: 'Greater', id: 2 },
//   { label: 'Equal', value: 'Equal', id: 3 },
// ];
// const CustomizeAlerts = ({ CustomizedAlert }) => {
//   const [siteTemp, setSiteTemp] = useState('');
//   const [loading, setloading] = useState(true);
//   const [tableValue, setTableValue] = useState([]);
//   const [editValue, setEditValue] = useState();
//   console.log(editValue, 'editValue before sending');
//   const [siteIdName, setSiteId] = useState([]);
//   const [equipments, setEquipments] = useState([]);
//   const [equipmentParameter, setParameterSite] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [editState, setEditState] = useState(false);
//   const [disabledSiteName, setDisabledSiteName] = useState(false);
//   const [disabledEquipment, setDisabledEquipment] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [siteTab, setSiteTab] = React.useState('');
//   const initialSnack = { open: false, severity: '', message: '' };
//   const [snack, setSnack] = useState(initialSnack);
//   const [email, setEmail] = useState(sessionStorage.getItem('email'));
//   const [equipmentParamter, setEquipmentParameter] = useState('');
//   const [activeFlag, setactiveFlag] = useState();
//   const [logic, setLogic] = useState('');
//   const [equipmentName, setEquipment] = useState('');
//   const [fieldValue, setFieldValue] = useState([]);
//   const [validEmail, setValidEmail] = useState(true);
//   console.log(fieldValue, 'equipmentName');
//   const [fromTime, setFromTime] = useState('');
//   const [toTime, setToTime] = useState('');
//   const earliestFromTime = dayjs().startOf('day').hour(5); // 5 AM
//   const latestToTime = dayjs().startOf('day').hour(19); // 7 PM
//   const ITEM_HEIGHT = 2;
//   const ITEM_PADDING_TOP = 2;
//   const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: ITEM_HEIGHT * 1.5 + ITEM_PADDING_TOP,
//         width: 250,
//       },
//     },
//   };
//   const handleSnackClose = () => {
//     setSnack(initialSnack);
//   };
//   //----------------User Table----------------//
//   const fetchData = async () => {
//     setloading(true);
//     try {
//       const data = await fetchAlertsTable();
//       setTableValue(data);
//       setloading(false);
//     } catch (e) {}
//   };
//   const isValidEmail = (email) => {
//     const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//     return emailPattern.test(email);
//   };
//   const handleEmailChange = (e) => {
//     const newEmail = e.target.value;
//     setEmail(newEmail);
//     setValidEmail(isValidEmail(newEmail));
//   };
//   const handleEmailChangevalue = (e) => {
//     const newEmail = e.target.value;
//     setEmail(newEmail);
//     setValidEmail(isValidEmail(newEmail));
//   };
//   const handleEquipmentParameter = (data, value) => {
//     if (value === null) {
//       setEquipmentParameter('');
//     } else setEquipmentParameter(value);
//   };
//   const handleEquipment = (data, value) => {
//     if (value == null) {
//       setEquipment('');
//     } else {
//       setEquipment(value);
//       // setEquipment(data)
//     }
//   };
//   const handleStatus = (event, newValue) => {
//     if (newValue) {
//       setEditValue((prev) => ({
//         ...prev,
//         activeFlag: newValue.value === 'active' ? 1 : 0,
//       }));
//     }
//   };
//   const handleChange = (e) => {
//     setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
//   };
//   const handleLogic = (data, event) => {
//     setLogic(event.value);
//   };
//   /*--------------------- Accept Numeric Only ---------------------*/
//   const handleInput = (event) => {
//     let input = event.target.value;
//     input = input.replace(/[^0-9]/g, '');
//     if (input.length > 15) {
//       input = input.slice(0, 15);
//     }
//     event.target.value = input;
//   };
//   const handleChangeFromTime = (event) => {
//     console.log(event, 'event');
//     const selectedFromTime = dayjs(event);
//     console.log(selectedFromTime, 'selectedFromTime');
//     console.log(earliestFromTime, 'earliestFromTime');
//     if (selectedFromTime.isBefore(earliestFromTime)) {
//       console.log(earliestFromTime, 'early');
//       setFromTime(earliestFromTime);
//     } else {
//       setFromTime(event);
//       console.log(event, 'elseevent');
//     }
//   };
//   const handleChangeToTime = (event) => {
//     console.log(event, 'event');
//     const selectedToTime = dayjs(event);
//     console.log(selectedToTime, 'selectedToTime');
//     console.log(latestToTime, 'earliestFromTime');
//     if (selectedToTime.isAfter(latestToTime)) {
//       console.log(latestToTime, 'early');
//       setToTime(latestToTime);
//     } else {
//       setToTime(event);
//       console.log(event, 'elseevent');
//     }
//   };
//   console.log(earliestFromTime, latestToTime, 'test');
//   /*----------------------Edit Call--------------------*/
//   const handleEditClick = async (alertId) => {
//     setAlertFields(alertId);
//     fetchEquipmentDetails(alertId.siteName);
//     fetchParams(alertId.siteid);
//     setEditValue(alertId);
//     setEditState(true);
//     setmodalOpen(true);
//     setDisabledSiteName(true);
//     setDisabledEquipment(true);
//     setEditMode(true);
//   };
//   /*----------------------------- update call -------------------------------*/
//   let initialState = {
//     siteName: '',
//     equipmentName: '',
//     parameter: '',
//     logic: '',
//     value: '',
//     emailId: '',
//     errorMessage: '',
//     status: '',
//   };
//   const [alertFields, setAlertFields] = useState([initialState]);
//   const [textField, setTextField] = useState([]);
//   useEffect(() => {
//     fetchData();
//     let data = customizeAlerts();
//     setTextField(data);
//   }, []);

//   const handleSave = async () => {
//     console.log(equipmentName, 'equipmentName');
//     let equipemntIds = equipments?.filter((data) => {
//       return data.displayName === equipmentName;
//     });
//     console.log(equipments, 'data test');
//     let siteIdFilter = siteIdName?.filter((data) => {
//       return data.siteName === siteTemp;
//     });
//     let valueDta = Number(fieldValue?.value);
//     console.log(fieldValue, 'fieldVlaue');
//     console.log(fromTime, toTime);
//     console.log(
//       dayjs(earliestFromTime).format('HH:mm'),
//       dayjs(latestToTime).format('HH:mm'),
//       'emailid test'
//     );
//     console.log(
//       dayjs(fromTime).format('HH:mm'),
//       dayjs(toTime).format('HH:mm'),
//       'apicall'
//     );
//     let data = [
//       {
//         userId: sessionStorage.getItem('id'),
//         equipmentId: equipemntIds[0]?.equipmentId,
//         siteId: siteIdFilter[0]?.siteId,
//         activeFlag: 1,
//         // emailId: fieldValue?.emailId,
//         emailId: email,
//         errorMessage: fieldValue?.errorMessage,
//         createdBy: sessionStorage.getItem('id'),
//         value: valueDta,
//         parameter: equipmentParamter,
//         logic: logic,
//         status: 1,
//         // fromTime: dayjs(fromTime).format('HH:mm'),  // Use raw fromTime value
//         // toTime: dayjs(toTime).format('HH:mm'),
//         fromTime:
//           fromTime !== ''
//             ? dayjs(fromTime).format('HH:mm')
//             : dayjs(earliestFromTime).format('HH:mm'),
//         toTime:
//           toTime !== ''
//             ? dayjs(toTime).format('HH:mm')
//             : dayjs(latestToTime).format('HH:mm'),
//       },
//     ];
//     console.log(data, 'data test');
//     try {
//       let responseData = await postAlertsSave(data);
//       console.log(responseData);
//       if (responseData.statusCode === 200) {
//         setFieldValue([]);
//         setLogic('');
//         setactiveFlag();
//         fetchData();
//         setFromTime('');
//         setToTime('');
//         setmodalOpen(false);
//         setSnack({
//           ...snack,
//           open: true,
//           severity: 'success',
//           message: responseData.statusDescription,
//         });
//       }
//     } catch (error) {
//       setSnack(errorMsg.failure);
//     }
//     // if(responseData === )
//     // console.log(responseData, 'response');
//     // setUpdateIconState(false)
//   };
//   const handleUpdate = async () => {
//     let customerNaming =
//       equipmentName === '' ? editValue.customerNaming : equipmentName;
//     console.log(customerNaming, 'custname');
//     let siteid = editValue.siteName;
//     let siteIdFilter = siteIdName?.filter((data) => {
//       return data.siteName === siteid;
//     });
//     let Equipmentid = editValue.customerNaming;
//     let equipemntIds = equipments?.filter((data) => {
//       return data.customernaming === Equipmentid;
//     });
//     console.log(equipments, 'eqpid');
//     let paramtr =
//       equipmentParamter !== '' ? equipmentParamter : editValue.parameter;
//     let logc = logic !== '' ? logic : editValue.logic;
//     let val =
//       fieldValue.value !== undefined ? fieldValue.value : editValue.value;
//     let email =
//       fieldValue.emailId !== undefined ? fieldValue.emailId : editValue.emailId;
//     let errormessage =
//       fieldValue.errorMessage !== undefined
//         ? fieldValue.errorMessage
//         : editValue.errorMessage;
//     // let activeFlagID = activeFlag === "inactive" ? 0 : editValue.activeFlag;
//     let activeFlagID =
//       editValue.activeFlag !== undefined ? editValue.activeFlag : 1;
//     // Format fromTime and toTime to "HH:mm" format
//     let fromtime =
//       fieldValue.fromTime !== undefined
//         ? dayjs(fieldValue.fromTime).format('HH:mm')
//         : editValue.fromTime;
//     console.log(fromtime, 'fromtime');
//     let totime =
//       fieldValue.toTime !== undefined
//         ? dayjs(fieldValue.toTime).format('HH:mm')
//         : editValue.toTime;
//     console.log(totime, 'active flag');
//     console.log(dayjs(fromTime).format('HH:mm'), 'fromTime', toTime);
//     console.log(fieldValue, 'editValue');
//     let valuedata = Number(val);
//     console.log(siteIdFilter[0].siteId, 'UpdateSiteId');
//     let equipemntid;
//     if (equipemntIds && equipemntIds.length > 0) {
//       equipemntid = equipemntIds[0].equipmentId;
//     } else {
//       console.log('No matching equipment found');
//     }
//     let obj = {
//       userId: sessionStorage.getItem('id'),
//       // equipmentId: equipemntIds[0].equipmentId,
//       equipmentId: equipemntid,
//       siteId: siteIdFilter[0].siteId,
//       parameter: paramtr,
//       logic: logc,
//       value: valuedata,
//       emailId: email,
//       errorMessage: errormessage,
//       activeFlag: activeFlagID,
//       lastUpdatedBy: Number(sessionStorage.getItem('id')),
//       status: activeFlagID,
//       fromTime:
//         fromTime !== ''
//           ? dayjs(fromTime).format('HH:mm')
//           : editValue.fromTime?.slice(0, 5),
//       toTime:
//         toTime !== ''
//           ? dayjs(toTime).format('HH:mm')
//           : editValue.toTime?.slice(0, 5),
//     };
//     console.log(obj, 'obj', editValue, 'editValue');
//     let res = await updateEditCall(obj, editValue.alertId);
//     setFieldValue([]);
//     setLogic('');
//     setactiveFlag();
//     fetchData();
//     setmodalOpen(false);
//     setmodalOpen(false);
//     console.log(res, 'dataaa');
//     setSnack({
//       open: true,
//       severity: 'success',
//       message: 'Data Saved Successfully!',
//     });
//     if (res.data === 'Updated') {
//       setOpen(false);
//       setmodalOpen(false);
//       setFromTime('');
//       setToTime('');
//       fetchData();
//     }
//   };
//   /*----------------------------Fields-------------------------------*/
//   const [clickCount, setClickCount] = useState(1);
//   const maxFields = 20;
//   const handleAddButtonClick = () => {
//     if (clickCount < maxFields) {
//       const newClickCount = clickCount + 1;
//       setClickCount(newClickCount);
//     }
//     setAlertFields(alertFields.concat(initialState));
//   };
//   const handleRemoveButtonClick = (indices) => {
//     if (clickCount > 1) {
//       setClickCount(clickCount - 1);
//     }
//     alertFields.splice(indices, 1);
//   };
//   /*--------------- Settings (Optional) Modal Components-----------------*/
//   const defaultLoadingStates = {
//     selectalert: true,
//     selectsite: true,
//     equipments: true,
//     parameter: true,
//     logic: true,
//     value: true,
//     errormessage: true,
//     status: true,
//     action: true,
//   };
//   useEffect(() => {
//     getSiteCall();
//   }, []);
//   /*----------------------------- Paramater Get call ------------------------------*/
//   const fetchParams = async (id) => {
//     try {
//       let data = await fetchParameterListDetails(id);
//       setParameterSite(data);
//     } catch (e) {
//       console.error(e);
//     }
//   };
//   /*----------------------------- Site Name API Call-----------------------*/
//   const handleSite = (data, val) => {
//     setSiteTemp(val);
//     // alertFields.forEach((obj) => {
//     //   obj.siteName = val;
//     // });
//     fetchEquipmentDetails(val);
//   };
//   const fetchEquipmentDetails = async (event) => {
//     let siteIdFilter = siteIdName?.filter((data) => {
//       return data.siteName === event;
//     });
//     console.log(siteIdFilter[0]?.siteId);
//     try {
//       let data = await EquipmentlistDropdown(siteIdFilter[0]?.siteId);
//       console.log(data, 'datasss');
//       setEquipments(data);
//       fetchParams(siteIdFilter[0]?.siteId);
//     } catch (e) {
//       console.error(e);
//     }
//   };
//   useEffect(() => {
//     // getSiteCall();
//   }, [equipments]);
//   /*-------------------------- Site Get ---------------------*/
//   const getSiteCall = async () => {
//     try {
//       // let id = sessionStorage.getItem('id');
//       let id = 263;
//       let data = await fetchSiteDetails();

//       if (data.length > 0) {
//         setSiteId([...new Set(data)]);
//         // setSite(data[0]?.siteName);
//       }
//     } catch (e) {
//       console.error(e);
//     }
//   };
//   /*-------------------Modal Component--------------------*/
//   const [modalopen, setmodalOpen] = React.useState(false);
//   const handleCrate = () => {
//     setDisabledEquipment(false);
//     setmodalOpen(true);
//     setEditState(false);
//     // setUpdateIconState(false)
//     setEditValue();
//     setEditMode(false);
//   };
//   const handlemodalClose = () => {
//     setmodalOpen(false);
//     setClickCount(1);
//     setAlertFields([initialState]);
//     setSiteTemp('');
//     setParameterSite([]);
//     setEquipments([]);
//     setDisabledEquipment(false);
//   };
//   const handleDropdownChanges = (event, value, name, indices) => {
//     if (name === 'logic') {
//       alertFields[indices][name] = value.value;
//     } else {
//       alertFields[indices][name] = value;
//     }
//     setAlertFields(alertFields);
//   };
//   const handleDropdownChange = (event, value, name) => {
//     if (name === 'logic') {
//       setAlertFields({ ...alertFields, [name]: value.value });
//     } else if (name === 'status' && typeof value.value === 'string') {
//       let modValue;
//       value.value === 'inactive' ? (modValue = 0) : (modValue = 1);
//       setAlertFields({ ...alertFields, [name]: modValue });
//     } else {
//       setAlertFields({ ...alertFields, [name]: value });
//     }
//   };
//   const handleTxtChanges = (e, indices) => {
//     const { name, value } = e.target;

//     let modName;
//     let prefix = 'alertFields';
//     if (name === prefix + '[' + [indices] + '][value]') {
//       modName = 'value';
//     } else if (name === prefix + '[' + [indices] + '].emailId') {
//       modName = 'emailId';
//     } else if (name === prefix + '[' + [indices] + '].errorMessage') {
//       modName = 'errorMessage';
//     }
//     if (!editMode) {
//       alertFields[indices][modName] = value;
//       setAlertFields(alertFields);
//     } else {
//       setAlertFields({ ...alertFields, [modName]: value });
//     }
//   };

//   /*---------------------Search Option ----------------*/
//   const handleDownloadTable = async (val) => {
//     const status = await Download(val, 'CustomizeAlert');
//     if (status === 0) {
//       setSnack(errorMsg.Download);
//     }
//   };
//   const headCells = [
//     {
//       label: 'Site Name',
//       id: 'siteName',
//       view: true,
//       default: true,
//     },
//     {
//       label: 'Equipments',
//       id: 'displayName',
//       view: true,
//       default: true,
//     },
//     {
//       label: 'Parameter',
//       id: 'parameter',
//       view: true,
//       default: true,
//     },
//     {
//       label: 'Logic',
//       id: 'logic',
//       view: true,
//     },
//     {
//       label: 'Email',
//       id: 'emailId',
//       view: true,
//     },
//     {
//       label: 'Error Message',
//       id: 'errorMessage',
//       view: true,
//       default: true,
//     },
//     {
//       label: 'From Time',
//       id: 'fromTime',
//       view: true,
//     },
//     {
//       label: 'To Time',
//       id: 'toTime',
//       view: true,
//     },
//     {
//       label: 'Status',
//       id: 'status',
//       view: true,
//     },
//     {
//       label: 'Action',
//       id: 'action',
//       view: true,
//       default: true,
//     },
//   ];
//   const tableValues = tableValue.filter((row) => {
//     if (siteTab !== '') {
//       return Number(row.status) === Number(siteTab);
//     } else {
//       return row;
//     }
//   });
//   const handleChangeTab = (newValue) => {
//     switch (newValue) {
//       case 'all':
//         setSiteTab(1);
//         break;
//       case 'Inactive':
//         setSiteTab(0);
//         break;
//       default:
//         setSiteTab('');
//     }
//   };
//   const Total = tableValue.length;
//   const Active = tableValue.filter((site) => site.status == 1).length;
//   const Inactive = tableValue.filter((site) => site.status == 0).length;

//   const tabsData = [
//     { name: 'Active Alerts', value: 'all', badgeCount: Active },
//     {
//       name: 'Inactive Alerts',
//       value: 'Inactive',
//       badgeCount: Inactive,
//     },
//   ];

//   function getOptions(name) {
//     let option;
//     switch (name) {
//       case 'equipmentName':
//         return (option = equipments?.map((option) => option?.displayName));
//       case 'parameter':
//         return equipmentParameter;
//       case 'logic':
//         return Logic;
//       case 'status':
//         return Status;
//       default:
//         break;
//     }
//     return option;
//   }

//   const paths = [
//     { label: 'Home', path: '/menu' },
//     { label: 'Customise Alert', path: 'customise-alerts' },
//   ];
//   return (
//     <div>
//       {loading ? (
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             marginTop: '20%',
//           }}
//         >
//           <FadeLoader color="#000440" loading={true} />
//         </div>
//       ) : (
//         <div className="">
//           <CustomSnackbar
//             open={snack.open}
//             onClose={handleSnackClose}
//             autoHideDuration={5000}
//             severity={snack.severity}
//             message={snack.message}
//           />
//           <div>
//             <div>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   flexWrap: 'wrap',
//                   '& > :not(style)': {
//                     mr: 0,
//                     mb: 2,
//                     mt: 2,
//                     width: '100%',
//                     minHeight: '7vh',
//                     maxHeight: 'max-content',
//                     m: 0,
//                     padding: '0',
//                   },
//                 }}
//               >
//                 <TableTemplate
//                   PageName={'Custom Alerts'}
//                   addButton={'Custom Alerts'}
//                   SearchLabel={'Search Alerts Here... '}
//                   header={headCells}
//                   rowsValue={tableValues}
//                   tabsData={tabsData}
//                   userRole={CustomizedAlert[0]}
//                   rawData={tableValue}
//                   handleChange={handleChangeTab}
//                   handleAddPopupOpen={(val) => handleCrate(val)}
//                   handleEditPopupOpen={(val) => handleEditClick(val)}
//                   handleDownloadExcel={(val) => {
//                     handleDownloadTable(val);
//                   }}
//                   paths={paths}
//                   fallbackText={'No custom alerts configured yet'}
//                 />
//               </Box>
//             </div>
//             <div>
//               <Modal
//                 open={modalopen}
//                 aria-labelledby="transition-modal-title"
//                 aria-describedby="transition-modal-description"
//                 sx={{ marginTop: '1.5%' }}
//               >
//                 <div
//                   className="monitoring-points"
//                   style={{ marginLeft: '2%', marginTop: '1%' }}
//                 >
//                   <Box sx={stylemodal}>
//                     <Paper elevation={0}>
//                       <Grid item xs={6} md={2}>
//                         <div className="analytics">
//                           <div style={{ margin: '0 0 0 15px' }}>
//                             <Typography
//                               style={{ fontSize: '1.5rem', fontWeight: 'bold' }}
//                             >
//                               {' '}
//                               Customize New Alert
//                             </Typography>
//                           </div>
//                           <div style={{ marginRight: '0.5%' }}>
//                             <Stack spacing={2} direction="row">
//                               {editValue !== undefined ? (
//                                 <Button
//                                   variant="contained"
//                                   style={{
//                                     borderRadius: '25px',
//                                     textTransform: 'capitalize',
//                                   }}
//                                   onClick={handleUpdate}
//                                   startIcon={<SaveIcon />}
//                                 >
//                                   Update
//                                 </Button>
//                               ) : (
//                                 <Button
//                                   variant="contained"
//                                   style={{
//                                     borderRadius: '25px',
//                                     textTransform: 'capitalize',
//                                   }}
//                                   onClick={handleSave}
//                                   startIcon={<SaveIcon />}
//                                 >
//                                   Save
//                                 </Button>
//                               )}
//                               <Button
//                                 variant="contained"
//                                 // style={buttonStyles}
//                                 style={{
//                                   borderRadius: '25px',
//                                   textTransform: 'capitalize',
//                                 }}
//                                 onClick={handlemodalClose}
//                                 startIcon={<CancelIcon />}
//                               >
//                                 Cancel
//                               </Button>
//                             </Stack>
//                           </div>
//                         </div>
//                         <div style={{ margin: '40px 0 0 15px' }}>
//                           <Grid container item xs={3} sm={3.2}>
//                             <Grid item xs={12} md={6}>
//                               <div style={{ minWidth: 200 }}>
//                                 <Typography style={typoStyle}>
//                                   {' '}
//                                   Site Name{' '}
//                                 </Typography>
//                                 <Autocomplete
//                                   size="small"
//                                   disablePortal
//                                   disableClearable
//                                   id="combo-box-demo"
//                                   defaultValue={
//                                     editMode
//                                       ? editValue
//                                         ? editValue.siteName
//                                         : null
//                                       : null
//                                   }
//                                   disabled={editMode ? true : false}
//                                   options={siteIdName.map(
//                                     (option) => option.siteName
//                                   )}
//                                   onChange={(data, event) =>
//                                     handleSite(data, event)
//                                   }
//                                   sx={{ width: '10vw' }}
//                                   ListboxProps={{
//                                     style: {
//                                       maxHeight: '100px',
//                                     },
//                                   }}
//                                   renderInput={(params) => (
//                                     <TextField
//                                       {...params}
//                                       placeholder="IFP"
//                                       disabled={disabledSiteName}
//                                     />
//                                   )}
//                                   classes={{ option: 'autocomplete' }}
//                                 />
//                               </div>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                               <div style={{ minWidth: 50 }}>
//                                 <Typography style={typoStyle}>Email</Typography>
//                                 {editValue ? (
//                                   <TextField
//                                     id="outlined-basic"
//                                     size="small"
//                                     placeholder="Email"
//                                     variant="outlined"
//                                     error={!validEmail}
//                                     helperText={
//                                       !validEmail ? 'Invalid email address' : ''
//                                     }
//                                     defaultValue={
//                                       editValue ? editValue.emailId : null
//                                     }
//                                     onChange={handleEmailChange}
//                                   />
//                                 ) : (
//                                   <TextField
//                                     id="outlined-basic"
//                                     size="small"
//                                     placeholder="Email"
//                                     variant="outlined"
//                                     sx={{ width: '10vw' }}
//                                     error={!validEmail}
//                                     helperText={
//                                       !validEmail ? 'Invalid email address' : ''
//                                     }
//                                     value={email}
//                                     onChange={handleEmailChangevalue}
//                                   />
//                                 )}
//                               </div>
//                             </Grid>
//                           </Grid>
//                           <div style={{ margin: '30px 0 0 0' }}>
//                             <Stack spacing={0} direction="row"></Stack>
//                           </div>
//                         </div>
//                         <Card
//                           sx={{
//                             width: '100%',
//                             height: '25vh',
//                             overflowY: 'scroll',
//                             border: 'none',
//                             boxShadow: 'none',
//                             // margin: "0 0 0 0",
//                           }}
//                         >
//                           <CardContent sx={{ width: '100%', height: '5%' }}>
//                             {[...Array(clickCount)].map((_, index) => (
//                               <div style={{ margin: '10px 0 0 0' }}>
//                                 <div
//                                   key={index}
//                                   style={{
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                   }}
//                                 >
//                                   <Grid container spacing={2} columns={27}>
//                                     <Grid
//                                       item
//                                       xs={3.5}
//                                       sm={3.7}
//                                       style={{ marginTop: '8.5px' }}
//                                     >
//                                       <div style={{ flex: 1, minWidth: 150 }}>
//                                         <Typography style={typoStyle}>
//                                           Equipment
//                                         </Typography>
//                                         <Autocomplete
//                                           disablePortal
//                                           disableClearable
//                                           id="combo-box-demo"
//                                           size="small"
//                                           defaultValue={
//                                             editMode
//                                               ? editValue
//                                                 ? editValue.displayName
//                                                 : null
//                                               : null
//                                           }
//                                           disabled={
//                                             !siteTemp || editMode ? true : false
//                                           }
//                                           options={equipments.map(
//                                             (option) => option.displayName
//                                           )}
//                                           onChange={(data, event) =>
//                                             handleEquipment(data, event)
//                                           }
//                                           sx={{ width: '10vw' }}
//                                           ListboxProps={{
//                                             style: {
//                                               maxHeight: '100px',
//                                             },
//                                           }}
//                                           renderInput={(params) => (
//                                             <TextField
//                                               {...params}
//                                               placeholder=" INV-01"
//                                               disabled={disabledEquipment}
//                                             />
//                                           )}
//                                           classes={{ option: 'autocomplete' }}
//                                         />
//                                       </div>
//                                     </Grid>
//                                     <Grid
//                                       item
//                                       xs={3.5}
//                                       sm={3.7}
//                                       style={{ marginTop: '8.5px' }}
//                                     >
//                                       <div style={{ flex: 1, minWidth: 150 }}>
//                                         <Typography style={typoStyle}>
//                                           Parameter
//                                         </Typography>
//                                         <Autocomplete
//                                           disablePortal
//                                           disableClearable
//                                           id="combo-box-demo"
//                                           size="small"
//                                           disabled={!siteTemp}
//                                           defaultValue={
//                                             editValue
//                                               ? editValue.parameter
//                                               : null
//                                           }
//                                           options={equipmentParameter.map(
//                                             (option) => option.stdParameterName
//                                           )}
//                                           onChange={(data, event) =>
//                                             handleEquipmentParameter(
//                                               data,
//                                               event
//                                             )
//                                           }
//                                           sx={{ width: '10vw' }}
//                                           ListboxProps={{
//                                             style: {
//                                               maxHeight: '100px',
//                                             },
//                                           }}
//                                           renderInput={(params) => (
//                                             <TextField
//                                               {...params}
//                                               placeholder=" Active Power"
//                                             />
//                                           )}
//                                           classes={{ option: 'autocomplete' }}
//                                         />
//                                       </div>
//                                     </Grid>
//                                     <Grid
//                                       item
//                                       xs={3.5}
//                                       sm={3.7}
//                                       style={{ marginTop: '8.5px' }}
//                                     >
//                                       <div style={{ flex: 1, minWidth: 150 }}>
//                                         <FormControl sx={{ width: '86%' }}>
//                                           <Typography style={typoStyle}>
//                                             Logic
//                                           </Typography>
//                                           <Autocomplete
//                                             disablePortal
//                                             disableClearable
//                                             id="combo-box-demo"
//                                             size="small"
//                                             defaultValue={
//                                               editValue ? editValue.logic : ''
//                                             }
//                                             onChange={(data, value) => {
//                                               handleLogic(data, value);
//                                             }}
//                                             options={Logic}
//                                             // sx={{ width: 300 }}
//                                             sx={{ width: '10vw' }}
//                                             renderInput={(params) => (
//                                               <TextField
//                                                 {...params}
//                                                 placeholder=" Less"
//                                                 // label="Trigger"
//                                               />
//                                             )}
//                                           />
//                                         </FormControl>
//                                       </div>
//                                     </Grid>
//                                     <Grid
//                                       item
//                                       xs={3.5}
//                                       sm={3.7}
//                                       style={{ marginTop: '8.5px' }}
//                                     >
//                                       <div style={{ flex: 1, minWidth: 150 }}>
//                                         <Typography style={typoStyle}>
//                                           Value
//                                         </Typography>
//                                         <TextField
//                                           disablePortal
//                                           id="combo-box-demo"
//                                           size="small"
//                                           name="value"
//                                           placeholder="1"
//                                           defaultValue={
//                                             editValue
//                                               ? editValue.value
//                                               : fieldValue.values
//                                           }
//                                           onChange={(event) => {
//                                             handleChange(event);
//                                             handleInput(event);
//                                           }}
//                                           // sx={{ width: "86%" }}
//                                           sx={{ width: '10vw' }}
//                                           renderInput={(params) => (
//                                             <TextField
//                                               {...params}
//                                               placeholder="Select  Site ..."
//                                             />
//                                           )}
//                                           classes={{ option: 'autocomplete' }}
//                                         />
//                                       </div>
//                                     </Grid>
//                                     <Grid
//                                       item
//                                       xs={3.5}
//                                       sm={3.7}
//                                       style={{ marginTop: '8.5px' }}
//                                     >
//                                       <div style={{ flex: 1, minWidth: 150 }}>
//                                         <Typography style={typoStyle}>
//                                           Error Message
//                                         </Typography>
//                                         <TextField
//                                           disablePortal
//                                           id="combo-box-demo"
//                                           size="small"
//                                           name="errorMessage"
//                                           placeholder="Grid Abnormal"
//                                           defaultValue={
//                                             editValue
//                                               ? editValue.errorMessage
//                                               : null
//                                           }
//                                           onChange={(event) =>
//                                             handleChange(event)
//                                           }
//                                           // sx={{ width: "86%" }}
//                                           sx={{ width: '10vw' }}
//                                           renderInput={(params) => (
//                                             <TextField
//                                               {...params}
//                                               placeholder="Select  Site ..."
//                                             />
//                                           )}
//                                           classes={{ option: 'autocomplete' }}
//                                         />
//                                       </div>
//                                     </Grid>
//                                     <Grid container item xs={3.3} sm={3.5}>
//                                       <Grid item xs={12} md={6}>
//                                         <LocalizationProvider
//                                           dateAdapter={AdapterDayjs}
//                                         >
//                                           <DemoContainer
//                                             components={['TimePicker']}
//                                           >
//                                             <div style={{ overflow: 'hidden' }}>
//                                               <Typography style={typoStyle}>
//                                                 From Time
//                                               </Typography>
//                                               <TimePicker
//                                                 ampm={false}
//                                                 defaultValue={
//                                                   editValue
//                                                     ? editValue.fromTime !==
//                                                       null
//                                                       ? dayjs(
//                                                           editValue.fromTime,
//                                                           'HH:mm:ss'
//                                                         )
//                                                       : earliestFromTime
//                                                     : earliestFromTime
//                                                 }
//                                                 onChange={(event) => {
//                                                   handleChangeFromTime(event);
//                                                 }}
//                                                 minTime={dayjs()
//                                                   .startOf('day')
//                                                   .hour(5)}
//                                                 // maxTime={latestToTime}
//                                                 maxTime={dayjs()
//                                                   .startOf('day')
//                                                   .hour(19)}
//                                                 slotProps={{
//                                                   textField: {
//                                                     size: 'small',
//                                                     InputProps: {
//                                                       style: {
//                                                         overflow: 'hidden',
//                                                         width: '10vw',
//                                                       },
//                                                     },
//                                                   },
//                                                 }}
//                                               />
//                                             </div>
//                                           </DemoContainer>
//                                         </LocalizationProvider>
//                                       </Grid>
//                                       <Grid item xs={12} md={6}>
//                                         <LocalizationProvider
//                                           dateAdapter={AdapterDayjs}
//                                         >
//                                           <DemoContainer
//                                             components={['TimePicker']}
//                                           >
//                                             <div style={{ overflow: 'hidden' }}>
//                                               <Typography style={typoStyle}>
//                                                 To Time
//                                               </Typography>
//                                               <TimePicker
//                                                 ampm={false}
//                                                 defaultValue={
//                                                   editValue
//                                                     ? editValue.toTime !== null
//                                                       ? dayjs(
//                                                           editValue.toTime,
//                                                           'HH:mm:ss'
//                                                         )
//                                                       : latestToTime
//                                                     : latestToTime
//                                                 }
//                                                 onChange={(event) => {
//                                                   handleChangeToTime(event);
//                                                 }}
//                                                 minTime={fromTime}
//                                                 maxTime={latestToTime}
//                                                 slotProps={{
//                                                   textField: {
//                                                     size: 'small',
//                                                     InputProps: {
//                                                       style: {
//                                                         width: '10vw', // Adjusted width for responsiveness
//                                                       },
//                                                     },
//                                                   },
//                                                 }}
//                                               />
//                                             </div>
//                                           </DemoContainer>
//                                         </LocalizationProvider>
//                                       </Grid>
//                                     </Grid>
//                                     {editState === true ? (
//                                       <Grid item xs={8}>
//                                         <div style={{ flex: 1, minWidth: 150 }}>
//                                           <FormControl sx={{ width: '78%' }}>
//                                             <Typography style={typoStyle}>
//                                               Status
//                                             </Typography>
//                                             <Autocomplete
//                                               // sx={{ width: "105%" }}
//                                               sx={{ width: '10vw' }}
//                                               size="small"
//                                               disablePortal
//                                               id="user-status-combo-box"
//                                               defaultValue={
//                                                 editValue &&
//                                                 editValue.activeFlag === 1
//                                                   ? Status[1] // Active
//                                                   : Status[0] // Inactive
//                                               }
//                                               options={Status}
//                                               onChange={handleStatus}
//                                               renderInput={(params) => (
//                                                 <TextField
//                                                   {...params}
//                                                   name="activeFlag"
//                                                 />
//                                               )}
//                                             />
//                                           </FormControl>
//                                         </div>
//                                       </Grid>
//                                     ) : (
//                                       ''
//                                     )}
//                                   </Grid>
//                                   {editState === false ? (
//                                     <div>
//                                       <Typography
//                                         style={{
//                                           color: '#000440',
//                                           margin: '24px 0 0 33px',
//                                           cursor: 'pointer',
//                                         }}
//                                       >
//                                         {index === 0 ? (
//                                           <>
//                                             <div
//                                               style={{
//                                                 display: 'flex',
//                                                 alignItems: 'center',
//                                                 margin: '0 0 0 0',
//                                               }}
//                                             >
//                                               <AddIcon
//                                                 onClick={handleAddButtonClick}
//                                               />
//                                             </div>
//                                           </>
//                                         ) : (
//                                           <CloseIcon
//                                             onClick={() =>
//                                               handleRemoveButtonClick(index)
//                                             }
//                                           />
//                                         )}
//                                       </Typography>
//                                     </div>
//                                   ) : (
//                                     ''
//                                   )}
//                                 </div>
//                               </div>
//                             ))}
//                           </CardContent>
//                         </Card>
//                         <div style={{ margin: '35px 0 0 0' }}>
//                           <Grid
//                             item
//                             xs={12}
//                             sm={6}
//                             style={{ margin: '35px 0 0 0' }}
//                           >
//                             <Stack
//                               sx={{
//                                 width: '100%', // Adjust width to fit the container
//                                 margin: '10px 0 0 0',
//                                 color: 'DodgerBlue',
//                                 contain: 'variant',
//                               }}
//                               spacing={2}
//                             >
//                               <Typography
//                                 style={{
//                                   whiteSpace: 'nowrap',
//                                   overflow: 'hidden',
//                                   textOverflow: 'ellipsis',
//                                 }}
//                               >
//                                 User configured Error Messages will be sent to
//                                 the email address*
//                               </Typography>
//                             </Stack>
//                           </Grid>
//                         </div>
//                       </Grid>
//                     </Paper>
//                   </Box>
//                 </div>
//               </Modal>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
// export default CustomizeAlerts;

import React, { useState, useEffect } from "react";
import "./Alerts.css";
import dayjs, { Dayjs } from "dayjs";
/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  FormControl,
  Modal,
  Grid,
} from "@mui/material";
/*-------------------------------API----------------------------*/
import {
  fetchAlertsTable,
  updateEditCall,
  postAlertsSave,
} from "../../../Api/AlertsComponentApi";
import { Equipmentdropdown } from "../../../Api/EquipmentApi";
import { fetchParameterListDetails } from "../../../Api/ExcelUploadAPi";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../../Common.css";
import { FadeLoader } from "react-spinners";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
/*------------------------- External compoenents ---------------------------*/
import Download from "../../../Template/Excel/Download";
import TableTemplate from "../../../Template/TableTemplate";
import CustomSnackbar from "../../util/components/CustomSnackbar";
import { customizeAlerts } from "../../util/TextField";
import errorMsg from "../../util/errorMessage.json";
import { fetchSiteDetails } from "../../../Api/ReportApi";
const stylemodal = {
  margin: "20px 0px 0px 0px",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "15px",
  left: "47%",
  height: "79vh",
  bgcolor: "background.paper",
  p: 4,
  marginLeft: "5%",
};
const Status = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];
const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  fontSize: "15px",
};
const Logic = [
  { label: "Less", value: "Less", id: 1 },
  { label: "Greater", value: "Greater", id: 2 },
  { label: "Equal", value: "Equal", id: 3 },
];

const ParamCondition = [
  { label: "AND", value: "AND", id: 1 },
  { label: "OR", value: "OR", id: 2 },
  { label: "NONE", value: "NONE", id: 3 },
];

const CustomizeAlerts = ({ CustomizedAlert }) => {
  const [siteTemp, setSiteTemp] = useState("");
  const [loading, setloading] = useState(false);
  const [tableValue, setTableValue] = useState([]);
  const [editValue, setEditValue] = useState();
  console.log(editValue, "editValue before sending");
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [open, setOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [disabledSiteName, setDisabledSiteName] = useState(false);
  const [disabledEquipment, setDisabledEquipment] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [siteTab, setSiteTab] = React.useState("");
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [email, setEmail] = useState(sessionStorage.getItem("email"));
  const [equipmentParamter, setEquipmentParameter] = useState("");
  const [activeFlag, setactiveFlag] = useState();
  const [logic, setLogic] = useState("");
  const [equipmentName, setEquipment] = useState("");
  const [fieldValue, setFieldValue] = useState([]);
  const [validEmail, setValidEmail] = useState(true);
  console.log(fieldValue, "equipmentName");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const earliestFromTime = dayjs().startOf("day").hour(5); // 5 AM
  const latestToTime = dayjs().startOf("day").hour(19); // 7 PM
  const [selectedCondition, setSelectedCondition] = useState("NONE");
  const [parameterSets, setParameterSets] = useState([
    { parameter: "", logic: "", value: "" },
    { parameter: "", logic: "", value: "" },
  ]);
  const ITEM_HEIGHT = 2;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 1.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  
  const fetchData = async () => {
    setloading(true);
    try {
      const [alertsData, sitesData] = await Promise.all([
        fetchAlertsTable(),
        fetchSiteDetails(),
      ]);

      const siteMap = new Map(
        sitesData.map((site) => [site.siteId, site.siteName])
      );

      const equipmentPromises = sitesData.map((site) =>
        Equipmentdropdown(site.siteId)
      );
      const allEquipmentData = await Promise.all(equipmentPromises);

      const equipmentMap = new Map();
      allEquipmentData.flat().forEach((equip) => {
        equipmentMap.set(equip.equipmentId, equip.displayName);
      });

      const updatedData = alertsData.map((alert) => {
        const parameterDetails = alert.parameters
          .filter((param) => param.parameter) // Filter out any empty parameters
          .map((param) => `${param.parameter} ${param.logic} ${param.value}`)
          .join(", ");

        return {
          ...alert,
          siteName: siteMap.get(alert.siteId) || "Unknown Site",
          equipmentName:
            equipmentMap.get(alert.equipmentId) || "Unknown Equipment",
          parameterDetails: parameterDetails,
          logic: alert.parameters
            .filter((param) => param.logic)
            .map((param) => param.logic)
            .join(", "),
          parameter: alert.parameters
            .filter((param) => param.parameter)
            .map((param) => param.parameter)
            .join(", "),
        };
      });

      console.log(updatedData, "Updated Fetched Data");
      setTableValue(updatedData);
      setEquipments(
        Array.from(equipmentMap.entries()).map(([id, name]) => ({
          equipmentId: id,
          displayName: name,
        }))
      );
    } catch (e) {
      console.error("Failed to fetch data:", e);
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteData = await fetchSiteDetails(); 
        setSiteId(siteData);
        const equipmentData = await Equipmentdropdown(); 
        setEquipments(equipmentData);
      } catch (e) {
        console.error("Failed to fetch site data:", e);
      }
    };

    fetchSiteData().then(() => fetchData());
  }, []);

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEmailChangevalue = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidEmail(isValidEmail(newEmail));
  };
  const handleEquipmentParameter = (data, value) => {
    if (value === null) {
      setEquipmentParameter("");
    } else setEquipmentParameter(value);
  };
  const handleEquipment = (data, value) => {
    if (value == null) {
      setEquipment("");
    } else {
      setEquipment(value);
      // setEquipment(data)
      if (editMode) {
        setEditValue((prev) => ({ ...prev, equipmentName: value }));
      }
    }
  };
  const handleStatus = (event, newValue) => {
    if (newValue) {
      setEditValue((prev) => ({
        ...prev,
        activeFlag: newValue.value === "active" ? 1 : 0,
      }));
    }
  };
  const handleChange = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };
  const handleLogic = (data, event) => {
    setLogic(event.value);
  };
  const handleConditionChange = (newValue) => {
    setSelectedCondition(newValue.value);
    if (newValue.value !== "NONE") {
      addParameterSet();
    }
  };
  const addParameterSet = () => {
    setParameterSets((prevSets) => [
      ...prevSets,
      { parameter: "", logic: "", value: "" },
    ]);
  };
  const handleParameterChange = (index, field, value) => {
    const newParameterSets = [...parameterSets];
    newParameterSets[index][field] = value;
    setParameterSets(newParameterSets);
  };
  /*--------------------- Accept Numeric Only ---------------------*/
  const handleInput = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9]/g, "");
    if (input.length > 15) {
      input = input.slice(0, 15);
    }
    event.target.value = input;
  };
  const handleChangeFromTime = (event) => {
    console.log(event, "event");
    const selectedFromTime = dayjs(event);
    console.log(selectedFromTime, "selectedFromTime");
    console.log(earliestFromTime, "earliestFromTime");
    if (selectedFromTime.isBefore(earliestFromTime)) {
      console.log(earliestFromTime, "early");
      setFromTime(earliestFromTime);
    } else {
      setFromTime(event);
      console.log(event, "elseevent");
    }
  };
  const handleChangeToTime = (event) => {
    console.log(event, "event");
    const selectedToTime = dayjs(event);
    console.log(selectedToTime, "selectedToTime");
    console.log(latestToTime, "earliestFromTime");
    if (selectedToTime.isAfter(latestToTime)) {
      console.log(latestToTime, "early");
      setToTime(latestToTime);
    } else {
      setToTime(event);
      console.log(event, "elseevent");
    }
  };
  console.log(earliestFromTime, latestToTime, "test");
  /*----------------------Edit Call--------------------*/
  const handleEditClick = async (id) => {
    setAlertFields(id);
    fetchEquipmentDetails(id.siteName);
    fetchParams(id.siteid);
    setEditValue(id);
    setEditState(true);
    setmodalOpen(true);
    setDisabledSiteName(true);
    setDisabledEquipment(true);
    setEditMode(true);
    setEquipment(id.equipmentName);
    setSiteTemp(id.siteName);
    setEmail(id.emailId);
    setFieldValue({
      errorMessage: id.errorMessage,
    });
    setFromTime(dayjs(id.fromTime, "HH:mm:ss"));
    setToTime(dayjs(id.toTime, "HH:mm:ss"));
    const savedParameters = id.parameters || [];
    const newParameterSets = savedParameters.map((param) => ({
      parameter: param.parameter,
      logic: Logic.find((l) => l.value === param.logic) || null,
      value: param.value.toString(),
    }));
    setParameterSets(newParameterSets);
    if (savedParameters.length > 1) {
      setSelectedCondition(id.conditions.includes("&&") ? "AND" : "OR");
    } else {
      setSelectedCondition("NONE");
    }
  };
  /*----------------------------- update call -------------------------------*/
  let initialState = {
    siteName: "",
    equipmentName: "",
    parameter: "",
    logic: "",
    value: "",
    emailId: "",
    errorMessage: "",
    parameterSets: "",
    status: "",
  };
  const [alertFields, setAlertFields] = useState([initialState]);
  const [textField, setTextField] = useState([]);
  useEffect(() => {
    fetchData();
    let data = customizeAlerts();
    setTextField(data);
  }, []);

  const handleSave = async () => {
    let equipmentIds = equipments?.filter((data) => {
      return data.displayName === equipmentName;
    });

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteTemp;
    });

    let validParameters = parameterSets.filter(
      (set) => set.parameter && set.logic && set.value
    );
    let parameters = parameterSets.map((set) => ({
      parameter: set.parameter,
      logic: set.logic.value, 
      value: Number(set.value),
    }));

    let conditions = validParameters
      .map((set, index) => {
        if (index === validParameters.length - 1) return set.parameter;
        return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
      })
      .join("");

    let data = {
      siteId: siteIdFilter[0]?.siteId,
      equipmentId: equipmentIds[0]?.equipmentId,
      userId: Number(sessionStorage.getItem("id")),
      parameters: parameters,
      conditions: conditions,
      errorMessage: fieldValue?.errorMessage,
      status: 1,
      emailId: email,
      smsEnabled: "NO",
      createdBy: Number(sessionStorage.getItem("id")),
      fromTime:
        fromTime !== ""
          ? dayjs(fromTime).format("HH:mm:ss")
          : dayjs(earliestFromTime).format("HH:mm:ss"),
      toTime:
        toTime !== ""
          ? dayjs(toTime).format("HH:mm:ss")
          : dayjs(latestToTime).format("HH:mm:ss"),
    };
    console.log(data, "data to be sent");
    try {
      let responseData = await postAlertsSave(data);
      console.log(responseData, "Save response");
      if (responseData && responseData.id) {
        setFieldValue([]);
        setLogic("");
        setactiveFlag();
        await fetchData();
        setFromTime("");
        setToTime("");
        resetFormState(); 
        setmodalOpen(false);
        setSnack({
          open: true,
          severity: "success",
          message: "Alert saved successfully!",
        });
      } else {
        setSnack({
          open: true,
          severity: "error",
          message: "Failed to save alert. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setSnack({
        open: true,
        severity: "error",
        message: "Error saving data. Please try again.",
      });
    }
  };
  const handleUpdate = async () => {
    let customerNaming =
      equipmentName === "" ? editValue.customerNaming : equipmentName;
    let siteid = editValue.siteName;
    let siteIdFilter = siteIdName?.filter((data) => data.siteName === siteid);
    let Equipmentid = editValue.customerNaming;
    let equipemntIds = equipments?.filter(
      (data) => data.customernaming === Equipmentid
    );
  
    let equipemntid =
      equipemntIds && equipemntIds.length > 0
        ? equipemntIds[0].equipmentId
        : null;
    if (!equipemntid) {
      console.log("No matching equipment found");
      return;
    }
    // let parameters = parameterSets.map((set) => ({
    //   parameter: set.parameter,
    //   logic: set.logic.value, 
    //   value: Number(set.value),
    // }));
  
    let parameters = parameterSets.map((set) => ({
      parameter: set.parameter || '',
      logic: set.logic?.value || '', 
      value: Number(set.value) || 0,
    }));
    let conditions = parameterSets
      .map((set, index) => {
        if (index === parameterSets.length - 1) return set.parameter;
        return index === 0 ? `${set.parameter} && ` : `${set.parameter} || `;
      })
      .join("");
  
    let obj = {
      userId: Number(sessionStorage.getItem("id")),
      equipmentId: equipemntid,
      siteId: siteIdFilter[0].siteId,
      parameters: parameters,
      conditions: conditions,
      // emailId:
      //   fieldValue.emailId !== undefined
      //     ? fieldValue.emailId
      //     : editValue.emailId,
      emailId: email,
      errorMessage:
        fieldValue.errorMessage !== undefined
          ? fieldValue.errorMessage
          : editValue.errorMessage,
      status: editValue.activeFlag !== undefined ? editValue.activeFlag : 1,
      lastUpdatedBy: Number(sessionStorage.getItem("id")),
      smsEnabled: "NO",
      createdBy: Number(sessionStorage.getItem("id")),
      fromTime:
        fromTime !== ""
          ? dayjs(fromTime).format("HH:mm:ss")
          : editValue.fromTime?.slice(0, 5),
      toTime:
        toTime !== ""
          ? dayjs(toTime).format("HH:mm:ss")
          : editValue.toTime?.slice(0, 5),
    };
  
    console.log(obj, "obj to be updated");
  
    try {
      const response = await updateEditCall(obj, editValue.id);
      console.log("Full API response:", response);
      const responseData = response.data;
      console.log("Response data:", responseData);
      if (responseData && responseData.userId) {
        setFieldValue([]);
        setLogic("");
        setactiveFlag();
        await fetchData();
        setOpen(false);
        resetFormState(); 
        setmodalOpen(false);
        setFromTime("");
        setToTime("");
        
        setSnack({
          open: true,
          severity: "success",
          message: "Alert updated successfully!",
        });
      } else {
        console.warn("Unexpected response format:", responseData);
        setSnack({
          open: true,
          severity: "success",
          message: "Alert updated successfully, but response format was unexpected.",
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        console.error("Error response data:", error.response.data);
      }
      
      setSnack({
        open: true,
        severity: "error",
        message: "Error updating data. Please try again.",
      });
    }
  };
  /*----------------------------Fields-------------------------------*/
  const [clickCount, setClickCount] = useState(1);
  const maxFields = 20;
  const handleAddButtonClick = () => {
    if (clickCount < maxFields) {
      const newClickCount = clickCount + 1;
      setClickCount(newClickCount);
    }
    setAlertFields(alertFields.concat(initialState));
  };
  const handleRemoveButtonClick = (indices) => {
    if (clickCount > 1) {
      setClickCount(clickCount - 1);
    }
    alertFields.splice(indices, 1);
  };
  /*--------------- Settings (Optional) Modal Components-----------------*/
  const defaultLoadingStates = {
    selectalert: true,
    selectsite: true,
    equipments: true,
    parameter: true,
    logic: true,
    value: true,
    errormessage: true,
    status: true,
    action: true,
  };
  useEffect(() => {
    getSiteCall();
  }, []);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      setParameterSite(data);
    } catch (e) {
      console.error(e);
    }
  };
  /*----------------------------- Site Name API Call-----------------------*/
  const handleSite = (data, val) => {
    setSiteTemp(val);
    fetchEquipmentDetails(val);
  };
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    console.log(siteIdFilter[0]?.siteId);
    try {
      let data = await Equipmentdropdown(siteIdFilter[0]?.siteId);
      console.log(data, "datasss");
      setEquipments(data);
      fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    // getSiteCall();
  }, [equipments]);
  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      // let id = sessionStorage.getItem('id');
      let id = 263;
      let data = await fetchSiteDetails();

      if (data.length > 0) {
        setSiteId([...new Set(data)]);
        // setSite(data[0]?.siteName);
      }
    } catch (e) {
      console.error(e);
    }
  };
  /*-------------------Modal Component--------------------*/
  const [modalopen, setmodalOpen] = React.useState(false);
  const handleCrate = () => {
    setDisabledEquipment(false);
    setmodalOpen(true);
    setEditState(false);
    // setUpdateIconState(false)
    setEditValue();
    setEditMode(false);
  };

  const resetFormState = () => {
    setEditValue(undefined);
    setEditState(false);
    setDisabledSiteName(false);
    setDisabledEquipment(false);
    setEditMode(false);
    setEquipment("");
    setSiteTemp("");
    setEmail("");
    setFieldValue({});
    setFromTime("");
    setToTime("");
    setParameterSets([
      { parameter: "", logic: "", value: "" },
      { parameter: "", logic: "", value: "" },
    ]);
    setSelectedCondition("NONE");
  };

  const handlemodalClose = () => {
    setmodalOpen(false);
    setClickCount(1);
    setAlertFields([initialState]);
    setSiteTemp("");
    setParameterSite([]);
    setEquipments([]);
    setDisabledEquipment(false);
    resetFormState()
  };
  /*---------------------Search Option ----------------*/
  const handleDownloadTable = async (val) => {
    const status = await Download(val, "CustomizeAlert");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const headCells = [
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Equipments",
      id: "equipmentName",
      view: true,
      default: true,
    },
    {
      label: "Parameter",
      id: "parameter",
      view: true,
      default: true,
    },
    {
      label: "Logic",
      id: "logic",
      view: true,
    },
    {
      label: "Email",
      id: "emailId",
      view: true,
    },
    {
      label: "Error Message",
      id: "errorMessage",
      view: true,
      default: true,
    },
    {
      label: "From Time",
      id: "fromTime",
      view: true,
    },
    {
      label: "To Time",
      id: "toTime",
      view: true,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  const tableValues = tableValue.filter((row) => {
    if (siteTab !== "") {
      return Number(row.status) === Number(siteTab);
    } else {
      return row;
    }
  });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = tableValue.length;
  const Active = tableValue.filter((site) => site.status == 1).length;
  const Inactive = tableValue.filter((site) => site.status == 0).length;

  const tabsData = [
   
    { name: 'Active Report', value: 'all', badgeCount: Active },
    {
      name: 'Inactive Report',
      value: 'Inactive',
      badgeCount: Inactive,
    },
  ];

  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Customise Alert", path: "customise-alerts" },
  ];
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div className="">
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />
          <div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    mr: 0,
                    mb: 2,
                    mt: 2,
                    width: "100%",
                    minHeight: "7vh",
                    maxHeight: "max-content",
                    m: 0,
                    padding: "0",
                  },
                }}
              >
                <TableTemplate
                  PageName={"Custom Alerts"}
                  addButton={"Custom Alerts"}
                  SearchLabel={"Search Alerts Here... "}
                  header={headCells}
                  tabsData={tabsData}
                  rowsValue={tableValues}
                  userRole={CustomizedAlert[0]}
                  rawData={tableValue}
                  handleChange={handleChangeTab}
                  handleAddPopupOpen={(val) => handleCrate(val)}
                  handleEditPopupOpen={(val) => handleEditClick(val)}
                  handleDownloadExcel={(val) => {
                    handleDownloadTable(val);
                  }}
                  paths={paths}
                  fallbackText={"No custom alerts configured yet"}
                />
              </Box>
            </div>
            <div>
              <Modal
                open={modalopen}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={{ marginTop: "1.5%" }}
              >
                <div
                  className="monitoring-points"
                  style={{ marginLeft: "2%", marginTop: "1%" }}
                >
                  <Box sx={stylemodal}>
                    <Paper elevation={0}>
                      <Grid item xs={6} md={2}>
                        <div className="analytics">
                          <div style={{ margin: "0 0 0 15px" }}>
                            <Typography
                              style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              {" "}
                              Customize New Alert
                            </Typography>
                          </div>
                          <div style={{ marginRight: "0.5%" }}>
                            <Stack spacing={2} direction="row">
                              {editValue !== undefined ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={handleUpdate}
                                  startIcon={<SaveIcon />}
                                >
                                  Update
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={handleSave}
                                  startIcon={<SaveIcon />}
                                >
                                  Save
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={handlemodalClose}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          </div>
                        </div>
                        <div style={{ margin: "30px 0 0 15px" }}>
                          <Grid container item xs={3} sm={3.2}>
                            <Grid item xs={12} md={6}>
                              <div style={{ minWidth: 200 }}>
                                <Typography style={typoStyle}>
                                  {" "}
                                  Site Name{" "}
                                </Typography>
                                <Autocomplete
                                  size="small"
                                  disablePortal
                                  disableClearable
                                  id="combo-box-demo"
                                  defaultValue={
                                    editMode
                                      ? editValue
                                        ? editValue.siteName
                                        : null
                                      : null
                                  }
                                  disabled={editMode ? true : false}
                                  options={siteIdName.map(
                                    (option) => option.siteName
                                  )}
                                  onChange={(data, event) =>
                                    handleSite(data, event)
                                  }
                                  sx={{ width: "11vw" }}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "100px",
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="IFP"
                                      disabled={disabledSiteName}
                                    />
                                  )}
                                  classes={{ option: "autocomplete" }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <div style={{ minWidth: 50, marginLeft: "40px" }}>
                                <Typography style={typoStyle}>Email</Typography>
                                {editValue ? (
                                  <TextField
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Email"
                                    variant="outlined"
                                    error={!validEmail}
                                    helperText={
                                      !validEmail ? "Invalid email address" : ""
                                    }
                                    value={email}
                                    // defaultValue={
                                    //   editValue ? editValue.emailId : null
                                    // }
                                    onChange={handleEmailChange}
                                  />
                                ) : (
                                  <TextField
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Email"
                                    variant="outlined"
                                    sx={{ width: "11vw" }}
                                    error={!validEmail}
                                    helperText={
                                      !validEmail ? "Invalid email address" : ""
                                    }
                                    value={email}
                                    onChange={handleEmailChangevalue}
                                  />
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          <div style={{ margin: "30px 0 0 0" }}>
                            <Stack spacing={0} direction="row"></Stack>
                          </div>
                        </div>
                        <Card
                          sx={{
                            width: "100%",
                            height: "45vh",
                            overflowY: "scroll",
                            marginTop: "-30px",
                          }}
                        >
                          <CardContent sx={{ width: "100%" }}>
                            <div>
                              <Grid container spacing={6} columns={27}>
                                <Grid
                                  item
                                  xs={4}
                                  sm={4}
                                  style={{ marginTop: "8.5px" }}
                                >
                                  <div style={{ flex: 1, minWidth: 150 }}>
                                    <Typography style={typoStyle}>
                                      Equipment
                                    </Typography>
                                   
                                    <Autocomplete
                                      disablePortal
                                      disableClearable
                                      id="combo-box-demo"
                                      size="small"
                                      value={
                                        editMode
                                          ? editValue?.equipmentName
                                          : equipmentName
                                      }
                                      disabled={!siteTemp || editMode}
                                      options={equipments.map(
                                        (option) => option.displayName
                                      )}
                                      onChange={(data, event) =>
                                        handleEquipment(data, event)
                                      }
                                      sx={{ width: "11vw" }}
                                      ListboxProps={{
                                        style: {
                                          maxHeight: "100px",
                                        },
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder=" INV-01"
                                          disabled={disabledEquipment}
                                        />
                                      )}
                                      classes={{ option: "autocomplete" }}
                                    />
                                  </div>
                                </Grid>

                                <Grid
                                  item
                                  xs={4.5}
                                  sm={4.5}
                                  style={{ marginTop: "8.5px" }}
                                >
                                  <div style={{ flex: 1, minWidth: 150, marginLeft: "20px" }}>
                                    <Typography style={typoStyle}>
                                      Error Message
                                    </Typography>
                                    <TextField
                                      disablePortal
                                      id="combo-box-demo"
                                      size="small"
                                      name="errorMessage"
                                      placeholder="Grid Abnormal"
                                      defaultValue={
                                        editValue
                                          ? editValue.errorMessage
                                          : null
                                      }
                                      onChange={(event) => handleChange(event)}
                                      sx={{ width: "11vw" }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Select  Site ..."
                                        />
                                      )}
                                      classes={{ option: "autocomplete" }}
                                    />
                                  </div>
                                </Grid>
                                <Grid container item xs={4.5} sm={4.5}>
                                  <Grid item xs={12} md={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["TimePicker"]}
                                      >
                                        <div style={{ overflow: "hidden" }}>
                                          <Typography style={typoStyle}>
                                            From Time
                                          </Typography>
                                          <TimePicker
                                            ampm={false}
                                            defaultValue={
                                              editValue
                                                ? editValue.fromTime !== null
                                                  ? dayjs(
                                                      editValue.fromTime,
                                                      "HH:mm:ss"
                                                    )
                                                  : earliestFromTime
                                                : earliestFromTime
                                            }
                                            onChange={(event) => {
                                              handleChangeFromTime(event);
                                            }}
                                            minTime={dayjs()
                                              .startOf("day")
                                              .hour(5)}
                                            maxTime={dayjs()
                                              .startOf("day")
                                              .hour(19)}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                InputProps: {
                                                  style: {
                                                    overflow: "hidden",
                                                    width: "11vw",
                                                  },
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={["TimePicker"]}
                                      >
                                        <div style={{ overflow: "hidden" }}>
                                          <Typography style={typoStyle}>
                                            To Time
                                          </Typography>
                                          <TimePicker
                                            ampm={false}
                                            defaultValue={
                                              editValue
                                                ? editValue.toTime !== null
                                                  ? dayjs(
                                                      editValue.toTime,
                                                      "HH:mm:ss"
                                                    )
                                                  : latestToTime
                                                : latestToTime
                                            }
                                            onChange={(event) => {
                                              handleChangeToTime(event);
                                            }}
                                            minTime={fromTime}
                                            maxTime={latestToTime}
                                            slotProps={{
                                              textField: {
                                                size: "small",
                                                InputProps: {
                                                  style: {
                                                    width: "11vw", 
                                                  },
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                                {editState === true ? (
                                  <Grid item xs={8}>
                                    <div style={{ flex: 1, minWidth: 150 }}>
                                      <FormControl sx={{ width: "78%" }}>
                                        <Typography style={typoStyle}>
                                          Status
                                        </Typography>
                                        <Autocomplete
                                          sx={{ width: "10vw" }}
                                          size="small"
                                          disablePortal
                                          id="user-status-combo-box"
                                          defaultValue={
                                            editValue &&
                                            editValue.activeFlag === 1
                                              ? Status[1] // Active
                                              : Status[0] // Inactive
                                          }
                                          options={Status}
                                          onChange={handleStatus}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              name="activeFlag"
                                            />
                                          )}
                                        />
                                      </FormControl>
                                    </div>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>
                          
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "flex-start",
                                  marginTop: "20px",
                                }}
                              >
                                {parameterSets.slice(0, 3).map((set, index) => (
                                  <React.Fragment key={index}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end",
                                        marginRight: "20px",
                                        marginBottom: "20px",
                                        width: index === 0 ? "100%" : "auto",
                                        flexBasis:
                                          index === 2
                                            ? "100%"
                                            : "calc(50% - 20px)",
                                      }}
                                    >
                                      <div style={{ marginRight: "25px" }}>
                                        <Typography style={typoStyle}>
                                          Parameter
                                        </Typography>
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id={`parameter-combo-box-${index}`}
                                          size="small"
                                          disabled={!siteTemp}
                                          value={set.parameter}
                                          options={equipmentParameter.map(
                                            (option) => option.stdParameterName
                                          )}
                                          onChange={(event, newValue) =>
                                            handleParameterChange(
                                              index,
                                              "parameter",
                                              newValue
                                            )
                                          }
                                          sx={{ width: "11vw" }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Active Power"
                                            />
                                          )}
                                        />
                                      </div>
                                      <div style={{ marginRight: "25px" }}>
                                        <Typography style={typoStyle}>
                                          Logic
                                        </Typography>
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id={`logic-combo-box-${index}`}
                                          size="small"
                                          value={set.logic}
                                          onChange={(event, newValue) =>
                                            handleParameterChange(
                                              index,
                                              "logic",
                                              newValue
                                            )
                                          }
                                          options={Logic}
                                          sx={{ width: "11vw" }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Less"
                                            />
                                          )}
                                        />
                                      </div>
                                      <div style={{ marginRight: "10px" }}>
                                        <Typography style={typoStyle}>
                                          Value
                                        </Typography>
                                        <TextField
                                          size="small"
                                          name="value"
                                          placeholder="1"
                                          value={set.value}
                                          onChange={(event) => {
                                            handleParameterChange(
                                              index,
                                              "value",
                                              event.target.value
                                            );
                                            handleInput(event);
                                          }}
                                          sx={{ width: "11vw" }}
                                        />
                                      </div>
                                    </div>
                                    {index < 2 && (
                                      <div
                                        style={{
                                          marginRight: "20px",
                                          marginBottom: "20px",
                                          width: "auto",
                                        }}
                                      >
                                        <Typography style={typoStyle}>
                                          Condition
                                        </Typography>
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="condition-combo-box"
                                          size="small"
                                          options={ParamCondition}
                                          sx={{ width: "11vw" }}
                                          value={
                                            ParamCondition.find(
                                              (cond) =>
                                                cond.value === selectedCondition
                                            ) || null
                                          }
                                          onChange={(event, newValue) =>
                                            handleConditionChange(newValue)
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Select condition"
                                            />
                                          )}
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                             
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Paper>
                  </Box>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomizeAlerts;