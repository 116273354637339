import { useMemo } from "react";

const useGenerateSeries = (data, params, siteList = []) => {
  return useMemo(() => {
    if (!data || !Array.isArray(data) || !params || params.length === 0) {
      return [];
    }

    // Group data by siteId
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.siteId]) {
        acc[item.siteId] = {};
        params.forEach(({ key }) => {
          acc[item.siteId][key] = [];
        });
      }

      params.forEach(({ key }) => {
        acc[item.siteId][key].push([item.timestamp, item[key]]);
      });

      return acc;
    }, {});

    // Build series dynamically
    const series = [];
    for (const siteId in groupedData) {
      const siteName =
        siteList.find((site) => site.siteId === Number(siteId))?.siteName ||
        `Site ${siteId}`;

      params.forEach(({ key, displayName, type, axis }, index) => {
        series.push({
          name: `${siteName} - ${displayName}`,
          type: type, // Alternate type for variety
          data: groupedData[siteId][key],
          yAxis: axis !== undefined ? axis : index, // Use user-defined yAxis or fallback to index
        });
      });
    }

    return series;
  }, [data, params, siteList]);
};

export default useGenerateSeries;
