import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import {
  Paper,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import CellTowerIcon from "@mui/icons-material/CellTower";
import TimelineIcon from "@mui/icons-material/Timeline";
import { GiHeartBottle } from "react-icons/gi";
import { useNavigate } from "react-router";
import { paddedEncryptQuery } from "../../util/security/Cipher";
import { CustomIcons } from "../../util/components/customSvgIcon";

const PieChart2 = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  function navigateEncryptedURL(URL, payload) {
    let passSite = payload;
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }

  function handleNavigate(payload) {
    navigateEncryptedURL("/menu/sitelist", payload);
  }

  const BarCell = ({ value, max, color }) => {
    const percentage = (value / max) * 100;
    return (
      <div
        style={{
          width: "50px",
          backgroundColor: "#e0e0e0",
          borderRadius: "5px",
          margin: "0px 5px",
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            backgroundColor: color,
            height: "10px",
            borderRadius: "5px",
          }}
        />
      </div>
    );
  };

  const series = props.data;

  const max = useMemo(() => {
    return series[0] + series[1] + series[2] + series[3];
  }, [series]);

  const options = {
    chart: {
      width: isSmallScreen ? "100%" : "55%",
      type: "donut",
    },
    colors: ["#71DD37", "#FF1913", "#98A3B2", "#FFB10E"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <Paper
      elevation={0}
      className="total-status"
      style={{
        paddingRight: "15px",
        padding: " 2px 10px 2px 10px",
        borderRadius: "20px",
        height: "90%",

        width: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            style={{
              textTransform: "capitalize",
              lineHeight: "14.52px !important",
              color: "rgba(0, 0, 0, 0.87);",
              fontSize: "1.2rem",
              fontWeight: "500",
            }}
          >
            Site Status
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "10px" }}>
          <CustomIcons icon={"signalIcon"} style={{ fontSize: "30px" }} />
          {/* <GiHeartBottle
            style={{
              fontSize: "35px",
            }}
          /> */}
        </Grid>
      </Grid>
      {/* {series[0] !== 0 ? ( */}
      <div style={{ marginTop: "10px" }}>
        {/* <Chart
          options={options}
          series={series}
          type="donut"
          //   height="60%"
        /> */}
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: isSmallScreen ? "10px 0" : "0 0 0 0px",
            }}
          >
            <div
              style={{
                textTransform: "capitalize",
                lineHeight: "14.52px !important",
                color: "rgba(0, 0, 0, 0.87);",
                fontSize: "1rem",
                cursor: "pointer",
                fontWeight: 400,
              }}
              onClick={() => handleNavigate({ status: "active" })}
            >
              <VerifiedUserIcon
                style={{
                  color: "#228B22",
                  verticalAlign: "bottom",
                }}
              />{" "}
              Active{" "}
            </div>
            <div
              style={{ display: "flex", width: "5vw", alignItems: "center" }}
            >
              <div>
                <BarCell value={series[0]} max={max} color="#228B22" />
              </div>
              <Typography
                fontWeight="500"
                color="#10A710"
                sx={{ width: "1vw" }}
              >
                {series[0]}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: isSmallScreen ? "10px 0" : "0 0 0 0px",
            }}
            onClick={() => handleNavigate({ status: "down" })}
          >
            <div
              style={{
                textTransform: "capitalize",
                lineHeight: "14.52px !important",
                color: "rgba(0, 0, 0, 0.87);",
                fontSize: "1rem",
                cursor: "pointer",
                fontWeight: "400",
              }}
            >
              <CellTowerIcon
                style={{
                  color: "rgb(232, 58, 42)",
                  verticalAlign: "bottom",
                }}
              />{" "}
              Down{" "}
            </div>
            <div
              style={{ display: "flex", width: "5vw", alignItems: "center" }}
            >
              <div>
                <BarCell value={series[1]} max={max} color="rgb(232, 58, 42)" />
              </div>
              <Typography
                fontWeight="500"
                color="rgb(232, 58, 42)"
                sx={{ width: "1vw" }}
              >
                {series[1]}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: isSmallScreen ? "10px 0" : "0 0 0 0px",
            }}
            onClick={() => handleNavigate({ status: "warning" })}
          >
            <div
              style={{
                textTransform: "capitalize",
                lineHeight: "14.52px !important",
                color: "rgba(0, 0, 0, 0.87);",
                fontSize: "1rem",
                cursor: "pointer",
                fontWeight: "400",
              }}
            >
              <TimelineIcon
                style={{
                  color: "#FFB10E",
                  verticalAlign: "bottom",
                }}
              />{" "}
              Warning
            </div>
            <div
              style={{ display: "flex", width: "5vw", alignItems: "center" }}
            >
              <div>
                <BarCell value={series[3]} max={max} color="#FFB10E" />
              </div>
              <Typography
                fontWeight="500"
                color="#FFB10E"
                sx={{ width: "1vw" }}
              >
                {series[3]}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: isSmallScreen ? "10px 0" : "0 0 0 0px",
            }}
            onClick={() => handleNavigate({ status: "offline" })}
          >
            <div
              style={{
                textTransform: "capitalize",
                lineHeight: "14.52px !important",
                color: "rgba(0, 0, 0, 0.87);",
                fontSize: "1rem",
                cursor: "pointer",
                fontWeight: "400",
              }}
            >
              <SportsSoccerIcon
                style={{
                  color: "#98A3B2",
                  verticalAlign: "bottom",
                }}
              />{" "}
              Offline{" "}
            </div>
            <div
              style={{ display: "flex", width: "5vw", alignItems: "center" }}
            >
              <div>
                <BarCell value={series[2]} max={max} color="#98A3B2" />
              </div>
              <Typography
                fontWeight="500"
                color="#98A3B2"
                sx={{ width: "1vw" }}
              >
                {series[2]}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default PieChart2;
