import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";

import "../Style.css";
import PieChart2 from "./PieChart2";

const Content1 = (props) => {
  const siteStats = props.SiteStatistics;
  const [pieChart, setPieChart] = useState([0, 0, 0, 0]);
  useEffect(() => {
    setPieChart([
      siteStats.activeCount,
      siteStats.downCount,
      siteStats.offlineCount,
      siteStats.warningCount,
    ]);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{
              width: "16vw",
              // height: '38vh',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 5px 10px 10px !important",
              }}
            >
              <PieChart2 data={pieChart} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Content1;
