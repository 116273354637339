import React, { useEffect, useState } from "react";
import TotalCard from "./HeaderCard/TotalCard";

import {
  ContactAnalytics,
  ContactAnalytics3,
  ContactAnalytics4,
  ContactAnalytics5,
  ContactAnalytics6,
  ContactAnalytics7,
} from "./ContentCard/ContactAnalytics";
import Content2 from "./ContentCard/Content2";
import Content1 from "./ContentCard/Content1";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import "./Style.css";
import { FadeLoader } from "react-spinners";
import { getDashboardValues } from "../../Api/DashboardAllAPI";
import CachedIcon from "@mui/icons-material/Cached";
import axios from "axios";
import {
  Autocomplete,
  Button,
  Card,
  IconButton,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { fetchSiteDropdownByUser } from "../../Api/CommonApi";
import { EnergyPerfTable } from "./EnergyPerfTable";
import { CustomChartIcon } from "../util/common/CustomChartIcon";
import { paddedEncryptQuery } from "../util/security/Cipher";
import { useNavigate } from "react-router";
import { CheckForLight } from "../util/common/CommanFunction";
import TotalCard2 from "./HeaderCard/ToatlCard2";
import { TotalActivePower } from "./TotalActivePower";
import SiteStatusTable from "./SiteStatusTable";

export const SaasDashboardVertical = () => {
  const [EventStatistcs, setEventStatistcs] = useState();
  const [SiteStatistics, setSiteStatistics] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [isReferesh, setRefresh] = useState(false);
  const [site, setSite] = useState("");
  const [siteIdName, setSiteId] = useState([]);
  const [responseData, setResponseData] = useState();
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("DG PV Grid Management");
  const [autoRefresh, setAutoRefresh] = useState(
    sessionStorage.getItem("refresh") === "true"
  );

  const navigate = useNavigate();

  useEffect(() => {
    getAPiDashboardCall();
    sessionStorage.setItem("refresh", true);
  }, []);

  function navigateEncryptedURL(URL) {
    const siteId = siteIdName.filter((data) => data.siteName === site)[0]
      .siteId;
    let passSite = { id: siteId, name: site };
    let passParam = new URLSearchParams(passSite).toString();
    let encryptedParams = paddedEncryptQuery(passParam);
    let url = `${URL}?${encryptedParams}`;
    navigate(url);
  }
  const handleAnalytics = () => {
    navigateEncryptedURL("/menu/view-analyst");
  };

  const chartList = [
    { label: "DG PV Grid Management", value: "dgpvGrid" },
    // { label: "System Interactivity", value: "energyPerformance" },
    { label: "Performance Ratio", value: "performanceRatio" },
    { label: "Actual Vs Expected", value: "actualVsExpected" },
    { label: "Active Power", value: "activePower" },
    // { label: "Actual ", value: "actual" },
  ];

  const GroupedData = (data) => ({
    dashboardDetails: {
      siteCount: data.siteCount,
      utilityCount: data.utilityCount,
      roofTopCount: data.roofTopCount,
      alarmTotalCount: data.alarmTotalCount,
      alarmTodayCount: data.alarmTodayCount,
      totalTodayEnergy: data.totalTodayEnergy,
      sumOfTotalEnergy: data.sumOfTotalEnergy,
      co2: data.co2,
      totalOpenTickets: data.totalOpenTickets,
      totalHoldTickets: data.totalHoldTickets,
      totalCloseTickets: data.totalCloseTickets,
    },
    siteDetails: [...data.siteDetails],
    SiteStatistics: {
      activeCount: data.activeCount,
      warningCount: data.warningCount,
      offlineCount: data.offlineCount,
      downCount: data.downCount,
    },
  });

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      setSite(data[0].siteName);
      setSiteId([...new Set(data)]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      setSite(val);
    }
  };

  const getAPiDashboardCall = async () => {
    const token = sessionStorage.getItem("jwtToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["allowCredentials"] = true;
    try {
      setLoading(true);
      // let responseData = await SiteDetails();
      let id = sessionStorage.getItem("id");
      let response = await getDashboardValues(id);
      await getSiteCall();
      const data = GroupedData(response);
      setDashboardDetails(data.dashboardDetails);
      setEventStatistcs(data.siteDetails);
      setSiteStatistics(data.SiteStatistics);
      setResponseData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefresh = () => {
    setRefresh(true);
    const RefreshTimeout = setTimeout(() => {
      setRefresh(false);
    }, 1000);

    return () => clearTimeout(RefreshTimeout);
  };

  const handleChangeChart = (data, event) => {
    console.log(data, event);
    setChartName(event);
  };

  const handleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
    sessionStorage.setItem("refresh", !autoRefresh);
  };

  useEffect(() => {
    let interval;
    if (autoRefresh) {
      console.log("Auto-refresh enabled");
      interval = setInterval(handleRefresh, 5 * 60 * 1000);
      console.log("Interval set for 5 minutes");
    } else {
      clearInterval(interval);
      console.log("Auto-refresh disabled");
    }
    return () => clearInterval(interval);
  }, [autoRefresh]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="card-main"
            style={{ flexDirection: "column", width: "20%" }}
          >
            <div style={{ width: "95%", marginBottom: "4%" }}>
              <Content1 SiteStatistics={SiteStatistics} />
            </div>
            <div style={{ width: "95%" }}>
              <TotalCard2 dashboardDetails={dashboardDetails} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="card-main-card"
              style={{
                flexDirection: "column",
                width: "100%",
                height: "350px",
                overflowY: "auto",
                marginLeft: "10px",
              }}
            >
              <SiteStatusTable />
            </div>
            <div
              className="card-main-card"
              style={{
                flexDirection: "column",
                width: "100%",
                height: "410px",
                marginLeft: "10px",
              }}
            >
              <div style={{ width: "75vw" }}>
                <Card
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  elevation={0}
                >
                  <div>
                    <Tooltip title={site}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        disableClearable
                        value={site}
                        options={siteIdName.map((option) => option.siteName)}
                        onChange={(data, event) => handleSite(data, event)}
                        sx={{ width: "16vw", marginRight: "8px" }} // Reduced width and added margin
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: "0.9rem" },
                            }}
                            placeholder="Select Site ..."
                          />
                        )}
                        classes={{ option: "autocomplete" }}
                      />
                    </Tooltip>
                  </div>

                  {/* chart list dropdown */}
                  <div style={{ display: "flex" }}>
                    <Tooltip title={chartName}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        disableClearable
                        value={chartName}
                        options={chartList.map((option) => option.label)}
                        onChange={(data, event) =>
                          handleChangeChart(data, event)
                        }
                        sx={{ width: "16vw", marginRight: "8px" }} // Reduced width and added margin
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: "0.9rem" },
                            }}
                            placeholder="Select Chart Name ..."
                          />
                        )}
                        classes={{ option: "autocomplete" }}
                      />
                    </Tooltip>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoRefresh}
                            onChange={(e) =>
                              handleAutoRefresh(e.target.checked)
                            }
                          />
                        }
                        label="Auto Refresh (5 min)"
                      />
                    </div>
                    <div>
                      <Tooltip title="Refresh">
                        <IconButton
                          onClick={handleRefresh}
                          disabled={isReferesh}
                        >
                          <CachedIcon
                            sx={{
                              color: CheckForLight() ? "black" : "white",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div style={{ marginTop: "1px" }}>
                      <Tooltip title="Analytics">
                        <IconButton onClick={handleAnalytics}>
                          <CustomChartIcon
                            sx={{ height: "20px", width: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Card>
              </div>
              <div style={{ overflowY: "auto", marginTop: "5px" }}>
                <div className="card-main-card">
                  <div
                    style={{
                      width: "50%",
                      height: "fit-content",
                      marginBottom: "10px",
                    }}
                  >
                    <ContactAnalytics
                      name={"daily generation"}
                      siteName={site}
                      siteDetails={
                        siteIdName.filter((item) => item.siteName === site)[0]
                      }
                      refresh={isReferesh}
                    />
                  </div>
                  <div
                    style={{
                      width: "50%",
                      height: "fit-content",
                      marginBottom: "10px",
                    }}
                  >
                    <TotalActivePower
                      name={"total active power"}
                      siteName={site}
                      siteDetails={
                        siteIdName.filter((item) => item.siteName === site)[0]
                      }
                      refresh={isReferesh}
                    />
                  </div>
                </div>
                <div className="card-main-card">
                  <div style={{ width: "50%", height: "fit-content" }}>
                    <EnergyPerfTable
                      name={"energy performance"}
                      siteName={site}
                      siteDetails={
                        siteIdName.filter((item) => item.siteName === site)[0]
                      }
                      refresh={isReferesh}
                    />
                  </div>
                  <div style={{ width: "50%", height: "fit-content" }}>
                    {chartName === "Performance Ratio" ? (
                      <ContactAnalytics7
                        name={"performance ratio"}
                        siteName={site}
                        siteDetails={
                          siteIdName.filter((item) => item.siteName === site)[0]
                        }
                        refresh={isReferesh}
                      />
                    ) : chartName === "Actual Vs Expected" ? (
                      <ContactAnalytics4
                        name={"actual Vs expected"}
                        siteName={site}
                        siteDetails={
                          siteIdName.filter((item) => item.siteName === site)[0]
                        }
                        refresh={isReferesh}
                      />
                    ) : chartName === "Active Power" ? (
                      <ContactAnalytics3
                        name={"active power"}
                        siteName={site}
                        siteDetails={
                          siteIdName.filter((item) => item.siteName === site)[0]
                        }
                        refresh={isReferesh}
                      />
                    ) : (
                      <ContactAnalytics6
                        name={"dg pv grid management"}
                        siteName={site}
                        siteDetails={
                          siteIdName.filter((item) => item.siteName === site)[0]
                        }
                        refresh={isReferesh}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
