import React, { useEffect } from "react";
import { Tabs, Tab, useTheme } from "@mui/material";
import { removeQuestionMark } from "../common/CommanFunction";
import { useLocation } from "react-router";
import { decryptPaddedQuery } from "../security/Cipher";

export const TabsComponent = ({ isLogger, loggerState, tabs, onTabChange }) => {
  const [value, setValue] = React.useState("all");
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    if (params.status) {
      setValue(params.status);
    }
  }, [params.status]);

  useEffect(() => {
    if (isLogger) {
      setValue(loggerState);
    }
  }, [loggerState]);
  const tabStyle = (val) => {
    return {
      marginRight: 8,
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Roboto",
      fontWeight: "400",
      textTransform: "capitalize",
      color:
        value === val
          ? colorMode === "light"
            ? "#252525"
            : "#DBDBEB"
          : "rgba(181, 181, 181, 1)",
    };
  };

  const badge = {
    borderRadius: "10px 10px 10px 10px",
    backgroundColor: "#1976d2",
    color: "white",
    fontSize: "11px",
    lineHeight: "18px",
    width: "30px",
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs
      value={value}
      style={{ display: "flex" }}
      onChange={handleChange}
      TabIndicatorProps={{ style: { height: "5px" } }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={tabStyle(tab.value)}>{tab.name}</div>
              {!isLogger ? <div style={badge}>{tab.badgeCount}</div> : null}
            </div>
          }
          value={tab.value}
        />
      ))}
    </Tabs>
  );
};
