import React, { useState, useEffect } from "react";
import "./AnalyticStyle.css";
import moment from "moment";
import {
  getBLockList,
  GetSitesDailyGen,
  GetSitesPR,
  GetSitesSpecificYield,
} from "../../../Api/DataAnalystApi";
import FadeLoader from "react-spinners/FadeLoader";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Menu,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Typography,
  CardContent,
  Card,
  Divider,
  MenuItem,
  ListItemText,
  Checkbox,
  Grid,
  IconButton,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  Radio,
  Tooltip,
  Popover,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import dayjs from "dayjs";
/*------------------------- External compoenents ---------------------------*/
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import { removeQuestionMark } from "../../util/common/CommanFunction";
import {
  adjustDateRange,
  convertLast7DaysFlagToDateRange,
  convertMonthFlag,
  convertWeekFlagToDateRange,
  getCumYearFlag,
  getLast7DaysFlag,
  getMonthFlag,
  getWeekOfMonth,
  getYearFlag,
  setCumYearlyRange,
  setYearlyRange,
} from "../../util/CustomDateRange";
import {
  decryptPaddedQuery,
  paddedEncryptQuery,
} from "../../util/security/Cipher";
import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CheckForLight } from "../../util/common/CommanFunction";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DateRange } from "react-date-range";
import StackedBarChartOutlinedIcon from "@mui/icons-material/StackedBarChartOutlined";
import { BlockSiteChart } from "../../Charts/BlockSiteChart";

const timer = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  // { label: 'Today', value: 'Today' },
  // { label: 'Yesterday', value: 'Yesterday' },
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" },
  { label: "This Month", value: "This Month" },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
  { label: "Custom range", value: "custom" },
];

const DeviationOption = [
  { label: "This Month", value: "This Month" },
  { label: "Yearly", value: "Yearly" },
];

const dailychartType = [
  { label: "Line Chart", value: "daily" },
  { label: "Bar Chart", value: "custom" },
];
const chartType = [
  { label: "Daily Generation", value: "daily_generation" },
  { label: "Specific Yield", value: "specific_yield" },
  { label: "Performance Ratio", value: "performance_ratio" },
  // { label: "Revenue Generation", value: "revenue_generation" },
];

const typoStyle = {
  textTransform: "capitalize",
  opacity: "0.8",
  lineHeight: "14.52px !important",
  color: " rgb(113, 118, 111)",
};

export const BlockAnalytics = () => {
  const [site, setSite] = React.useState("");
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setEquipmentParamName] = React.useState([]);
  const [equipmentParamwithUnits, setParameter] = useState([]);
  const [axisData, setAxisData] = useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [chartName, setChartName] = useState("");
  const [FromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [ToDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [range, setRange] = useState("");
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [chartFromDate, setChartFromDate] = useState("");
  const [chartToDate, setChartToDate] = useState("");
  const [value, setValue] = useState(0);
  const [customView, setCustomView] = useState();
  const [timevalue, setTimeValue] = useState("Daily");
  const [chartSite, setChartSite] = useState();
  const [dataModel, setDataModel] = useState();
  const [fromApply, setApply] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [sdaysFlag, setSdaysFlag] = useState(getLast7DaysFlag(new Date()));
  const [WeeklyFlag, setWeeklyFlag] = useState(getWeekOfMonth(new Date()));
  const [MonthlyFlag, setMonthlyFlag] = useState(getMonthFlag(new Date()));
  const [YearlyFlag, setYearlyFlag] = useState(getYearFlag(new Date()));
  const [cumYearFlag, setCumYearFlag] = useState(getCumYearFlag(new Date()));
  const [loading, setLoading] = useState(true);
  const [chartDropdown, setChartDropdown] = useState(true);
  const [fromTabSwitch, setFromTabSwitch] = useState(true);
  const [componentLoading, setComponentLoading] = useState(false);
  const [equpParamMap, setEqupParamMapValue] = useState();
  const [menuOpen, setOpenMenu] = useState(false);
  const [menuOpenn, setOpennMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [timerValue, setTimerValue] = useState(5);
  const [timerOptions, setTimerOptions] = useState(timer);
  const [optionalCharts, setOptionalCharts] = useState(false);
  const [calenderAnchor, setCalenderAnchor] = useState(null);
  const [rangeLevel, setRangeLevel] = useState("today");
  const [RangeState, setRangeState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [chartAnchor, setChartAnchor] = useState(null);
  const [dailyChartType, setDailyChartType] = useState("daily");
  const [customChart, setCustomChart] = useState(false);
  const [isStacked, setIsStacked] = useState(false);
  const [basedOn, setBasedOn] = useState("Site");
  const [blockList, setBlockList] = useState([]);

  function GetBlockEndpoint(type) {
    switch (type) {
      case "Daily Generation":
        return GetSitesDailyGen;
      case "Specific Yield":
        return GetSitesSpecificYield;
      case "Performance Ratio":
        return GetSitesPR;
      default:
        break;
    }
  }

  const optionToUse =
    chartName === "Expected Deviation" ? DeviationOption : optionTime;

  const stackedIcon = isStacked ? (
    <StackedBarChartOutlinedIcon
      sx={{
        color: CheckForLight() ? "black" : "white",
        opacity: 1,
        pointerEvents: "auto",
      }}
    />
  ) : (
    <BarChartOutlinedIcon
      sx={{
        color: CheckForLight() ? "black" : "white",
        opacity: 1,
        pointerEvents: "auto",
      }}
    />
  );

  function openCalender(event, from) {
    // console.log(event, from);

    if (from === "fromCalender") {
      setCalenderAnchor(event.currentTarget);
    } else {
      setCalenderAnchor(anchorEl);
      setOpenMenu(false);
      setAnchorEl(null);
    }
  }

  function closeCalender() {
    setCalenderAnchor(null);
  }

  const isCalendarOpen = Boolean(calenderAnchor);
  const calenderId = isCalendarOpen
    ? "simple-popover-date-range-picker"
    : undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;
  const paths = isFromSiteList
    ? [
        { label: "Home", path: "/menu" },
        { label: "Site List", path: -1 },
        { label: "Block Analytics", path: "block-analyst" },
      ]
    : [
        { label: "Home", path: "/menu" },
        { label: "Block Analytics", path: "block-analyst" },
      ];
  function ResetDateRange() {
    setTimerValue("5");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  }
  //Components list
  // State to manage which inv and params are checked
  const [checkedItems, setCheckedItems] = useState({});

  // State to manage open/close state for both categories and equipment
  const [openCategoryLists, setOpenCategoryLists] = useState({});
  const [openEquipmentLists, setOpenEquipmentLists] = useState({});

  // Function to toggle the open/close state of a category list
  const toggleCategoryListOpen = (category) => {
    setOpenCategoryLists((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  // Function to toggle the open/close state of an equipment list within a category
  const toggleEquipmentListOpen = (equ) => {
    setOpenEquipmentLists((prevState) => ({
      ...prevState,
      [equ]: !prevState[equ],
    }));
  };

  //chart Type list
  const [selectedOptions, setSelectedOptions] = useState("Daily Generation");
  //Equipment list
  const [selectedEquipments, setSelectedEquipments] = useState([]);

  const handleChartCheckboxChange = (event, option) => {
    const Predefined = [
      "Daily Generation",
      "Energy Performance",
      "Specific Yield",
      "Specific Yield - Heatmap",
      "DG PV Grid Management",
      "Deemed Generation",
      "Set Point",
    ];
    if (event.target.checked) {
      // setBasedOn('Site');
      setSelectedOptions(option);
      ResetDateRange();
      setChartName(option);
      setSelectedOption(option);
      setFromTabSwitch(false);
      setChartDropdown(false);
      setCustomChart(false);
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setCumYearFlag(getCumYearFlag(new Date()));
      setTimeValue(option !== "Expected Deviation" ? "Daily" : "This Month");
      setDailyChartType(
        chartName === "Specific Yield" ||
          option === "Specific Yield Vs Irradiation"
          ? "custom"
          : "daily"
      );
      setSelectedEquipments([]);
      setOptionalCharts(
        !Predefined?.includes(option) && option !== "Active Power"
      );
      let customData;
      if (option === "Energy Performance") {
        let displayName = equipments
          .filter((data) => {
            return data.equipmentCategory.includes("Inverter");
          })
          .map((datav) => {
            return datav.displayName;
          })
          .sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });
        setEquipmentData(displayName);
        setSelectedEquipments(displayName);
      } else if (!Predefined?.includes(option)) {
        if (option === "String Current") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName[0]);
        } else if (option === "Active Power") {
          let displayName = equipments
            .filter((data) => {
              return data.equipmentCategory.includes("Inverter");
            })
            .map((datav) => {
              return datav.displayName;
            })
            .sort((a, b) => {
              if (a?.includes("-") && b?.includes("-")) {
                return +a.split("-").pop() - +b.split("-").pop();
              } else if (a?.includes("_") && b?.includes("_")) {
                return +a.split("_").pop() - +b.split("_").pop();
              }
              return a.localeCompare(b);
            });
          setEquipmentData(displayName);
          setSelectedEquipments(displayName);
        }
      }
      setDatalogValue([]);
      setTableData([]);
      setValue();
      handleApplyChanges(
        option !== "Expected Deviation" ? "Daily" : "This Month",
        false,
        "timeValue",
        option
      );
    } else {
      setDate(moment().format("YYYY-MM-DD"));
      setSdaysFlag(getLast7DaysFlag(new Date()));
      setMonthlyFlag(getMonthFlag(new Date()));
      setWeeklyFlag(getWeekOfMonth(new Date()));
      setYearlyFlag(getYearFlag(new Date()));
      setCumYearFlag(getCumYearFlag(new Date()));
      setTimeValue("Daily");
      setSelectedOptions("Daily Generation");
      setChartName("Daily Generation");
      setSelectedOption("Daily Generation");
      handleApplyChanges("Daily", false, "timeValue", "Daily Generation");
    }
  };

  function customCharts(event) {
    if (event === "String Current" || event === "Active Power") {
      console.log(groupByCategory(equpAndParams), equpAndParams);
      // Retrieve the keys from grouped data
      const categories = Object.keys(groupByCategory(equpAndParams));

      let SortedInvNames = [];
      categories.forEach((key) => {
        // Check if the key contains 'inverter'

        if (
          key.toLocaleLowerCase()?.includes("inverter") ||
          key.toLocaleLowerCase()?.includes("inv")
        ) {
          // Get the list of inverters in this category
          const invList = groupByCategory(equpAndParams)[key];
          const InvNames = [];

          invList.forEach((inv) => {
            InvNames.push(inv.equ);
          });

          // Sort the inverter names
          const sortedNames = InvNames.sort((a, b) => {
            if (a?.includes("-") && b?.includes("-")) {
              return +a.split("-").pop() - +b.split("-").pop();
            } else if (a?.includes("_") && b?.includes("_")) {
              return +a.split("_").pop() - +b.split("_").pop();
            }
            return a.localeCompare(b);
          });

          // Accumulate the sorted names
          SortedInvNames = SortedInvNames.concat(sortedNames);
        }
      });

      // Set the equipment data with the sorted inverter names
      setEquipmentData(SortedInvNames);

      return SortedInvNames;
    }
  }

  const [panelIndex, setPanelIndex] = useState("1");
  const [equpAndParams, setEqupAndParams] = useState([]);

  const handleChangePanel = (event, newValue) => {
    if (newValue === "1") {
      setChartName("Daily Generation");
      setSelectedOptions("Daily Generation");
      setSelectedOption("Daily Generation");
      handleTabChange(site, "Daily", "daily", "Daily Generation");
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      ResetDateRange();
    } else {
      setChartName("Parameter Comparision");
      setDate(moment().format("YYYY-MM-DD"));
      setTimeValue("Daily");
      setSelectedOptions("Parameter Comparision");
      setSelectedOption("Parameter Comparision");
      setDatalogValue([]);
      ResetDateRange();
    }
    setPanelIndex(newValue);
  };

  const handleCheckboxChange = (equ) => (event) => {
    setCheckedItems((prevState) => {
      // Check if the equipment already exists in the state
      if (!prevState[equ]) {
        return {
          ...prevState,
          [equ]: {
            checked: false, // Initialize the checkbox as unchecked
            params: [], // Initialize params as an empty array
          },
        };
      }

      // If it already exists, return the previous state without making any changes
      return prevState;
    });
  };

  // Function to handle toggling of params
  const handleParamToggle = (equ, param) => () => {
    const selectedParams = checkedItems[equ]?.params || [];
    const currentIndex = selectedParams.indexOf(param);
    const newChecked = [...selectedParams];

    if (currentIndex === -1) {
      newChecked.push(param);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems((prevState) => ({
      ...prevState,
      [equ]: {
        checked: newChecked.length > 0, // Checkbox is checked if at least one param is selected
        params: newChecked,
      },
    }));
  };

  function groupByCategory(arr) {
    return arr.reduce((acc, item) => {
      let { category, equ, params } = item;

      // Normalize the category name to lower case
      let cat = category.toLowerCase();

      // Group by common equipment types
      if (cat?.includes("meter")) {
        category = "Meters";
      } else if (cat?.includes("transformer")) {
        category = "Transformers";
      } else if (cat?.includes("inverter")) {
        category = "Inverters";
      } else if (cat?.includes("panel")) {
        category = "Panels";
      } else if (cat?.includes("alarm") || category?.includes("fire")) {
        category = "Alarm Systems";
      } else if (cat?.includes("ups")) {
        category = "UPS";
      }

      // Initialize category array if it doesn't exist
      if (!acc[category]) {
        acc[category] = [];
      }

      // Add the equipment to the appropriate category
      acc[category].push({ equ, params });

      return acc;
    }, {});
  }

  useEffect(() => {
    getSiteCall();
  }, []);

  useEffect(() => {
    if (site) {
      handleTabChange(site, timevalue, range, chartSite);
    }
  }, [site]);

  async function createEquipmentParamsArray(siteId) {
    const id = params.id ? params.id : siteId;
    try {
      setComponentLoading(true);
      // Fetch the equipment dropdown data for the site
      const equip = await fetchEquipDropdownBySite(id);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (dataLoggerId === null || dataLoggerId === undefined) {
          return map;
        }
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultArray = [];

      // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        // Fetch parameters for the current dataLoggerId
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });

        // For each equipment, create an object with `equ` and `params` keys
        equipments.forEach((equipment) => {
          resultArray.push({
            equ: equipment.displayName,
            params: params
              .filter((param) => param.stdParameterName !== "Dummy")
              .map((param) => `${param.stdParameterName}(${param.stdUom})`),
            category: equipment.equipmentCategory,
          });
        });
      }

      console.log("resultArray", resultArray);

      // Update the state with the resulting array
      setEqupAndParams(resultArray);
      setComponentLoading(false);
      return resultArray;
    } catch (e) {
      console.error(e);
      setEqupAndParams([]);
      setComponentLoading(false);
    }
  }

  useEffect(() => {
    if (!params.id || !params.name) {
      if (blockList.length > 0) {
        const siteNameFromSession = params.name;

        const name = siteNameFromSession
          ? siteNameFromSession
          : blockList[0].blockName;
        setSite(name);

        let date = new Date();
        let fromDate = moment(date).format("YYYY-MM-DD");
        const EiraDataModel = {
          fromDate: fromDate,
          GraphType: "Daily Generation",
          siteIds: blockList[0].siteMasterDTOs.flatMap((item) => item.siteId),
          toDate: fromDate,
          range: "custom",
          timeperiod: "Daily",
        };
        setCustomView(EiraDataModel);

        setChartSite(EiraDataModel.GraphType);

        fetchApplyChange();
      }
    } else {
      setSite(params.name);
      let date = new Date();
      let fromDate = moment(date).format("YYYY-MM-DD");
      const EiraDataModel = {
        fromDate: fromDate,
        GraphType: "Daily Generation",
        siteIds: blockList
          .find((item) => item.blockName === params.name)
          .siteMasterDTOs.flatMap((item) => item.siteId),
        toDate: fromDate,
        range: "custom",
        timeperiod: "Daily",
      };

      setCustomView(EiraDataModel);
      setChartSite(EiraDataModel.GraphType);
      fetchApplyChange();
    }
  }, [siteIdName]);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParamsDropdownByLogger(id);
      let ParamsName = data
        .filter((item) => item.stdParameterName !== "Dummy")
        .map((item) => item.stdParameterName);
      setParameter(data);
      setParameterSite(ParamsName);
      return data;
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = async (data, val) => {
    if (val === null) {
      setSite("");
      ResetDateRange();
    } else {
      setSite(val);
      const params = new URLSearchParams();
      params.set("siteName", val);
      setSelectedOptions("Daily Generation");
      setRangeLevel("daily");
      setTimeValue("Daily");
      ResetDateRange();
      setEquipmentName([]);
      setPanelIndex("1");
      setEquipmentData([]);
      setSelectedEquipments([]);
      setCheckedItems({});
      setOpenCategoryLists({});
      setOpenEquipmentLists({});
      const encryptedParams = paddedEncryptQuery(params.toString());
      // console.log(encryptedParams.toString());
      navigate(`?${encryptedParams.toString()}`);
      setSelectedOption("");
    }
  };
  function checkArray(arr) {
    // Function to check if all values in an object are null
    function allValuesNull(obj) {
      return Object.values(obj).every((value) => value === null);
    }

    // Check each object in the array
    for (let obj of arr) {
      if (!allValuesNull(obj)) {
        return arr; // Return the original array if any object has non-null values
      }
    }

    return []; // Return an empty array if all objects have all null values
  }

  //--------------------range control functions starts -------------------------------//
  function UpdateFlag(data, flag) {
    let result;
    switch (data) {
      case "Last 7 days":
        result = adjustDateRange(data, sdaysFlag, flag);
        setSdaysFlag(result);
        break;
      case "Weekly":
        result = adjustDateRange(data, WeeklyFlag, flag);
        setWeeklyFlag(result);
        break;
      case "This Month":
        result = adjustDateRange(data, MonthlyFlag, flag);
        setMonthlyFlag(result);
        break;
      case "Monthly":
        result = adjustDateRange(data, YearlyFlag, flag);
        setYearlyFlag(result);
        break;
      case "Yearly":
        result = adjustDateRange(data, cumYearFlag, flag);
        setCumYearFlag(result);
        break;
      default:
        break;
    }
    return result;
  }

  const handleChangeDate = async (data) => {
    let newDate;
    let result;
    setTableData([]);
    if (!chartName) {
      setSelectedOption(chartSite);
      setChartName(chartSite);
    }
    const currentDate = moment(date);
    // console.log(timevalue);

    if (data === "left") {
      result = UpdateFlag(timevalue, "left");
      newDate = currentDate.subtract(1, "days");
    } else if (data === "right") {
      result = UpdateFlag(timevalue, "right");
      newDate = currentDate.add(1, "days");
    } else {
      return;
    }

    const today = moment().startOf("day");

    if (newDate.isAfter(today)) {
      newDate = today;
    }

    const formattedDateTime = newDate.format("YYYY-MM-DD");
    setDate(formattedDateTime);
    // console.log(formattedDateTime);
    await handleApplyChanges(
      {
        date: formattedDateTime,
        ref: result,
        chartName: chartSite,
      },
      "ref"
    );
  };

  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const block = await getBLockList();
      setBlockList(block);
      const dataFrequency = data[0].siteFrequency ?? 5;
      setTimerValue(dataFrequency);
      setTimerOptions(timer.filter((data) => +data.value >= +dataFrequency));
      setSiteId([...new Set(data)]);
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleTimeChange = async (item) => {
    const { startDate, endDate } = item.selection;
    // Handle the change only if startDate and endDate are selected
    if (
      dayjs(startDate) &&
      dayjs(endDate) &&
      (dayjs(startDate).format("YYYY-MM-DD") !==
        dayjs(endDate).format("YYYY-MM-DD") ||
        dayjs(endDate).format("YYYY-MM-DD") ===
          dayjs(RangeState[0].endDate).format("YYYY-MM-DD"))
    ) {
      // console.log('Selected Date Range:', {
      //   startDate: dayjs(startDate).format('YYYY-MM-DD'),
      //   endDate: dayjs(endDate).format('YYYY-MM-DD'),
      //   condition:
      //     dayjs(startDate).format('YYYY-MM-DD') !==
      //     dayjs(endDate).format('YYYY-MM-DD'),
      // });

      handleChangeFromDate(dayjs(startDate));
      handleChangeToDate(dayjs(endDate));
      setRangeState([item.selection]);
      closeCalender();
    } else {
      setRangeState([item.selection]); // Update range without closing the calendar
    }
  };

  /*--------------handle----------- Custom range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setFromDate(formattedDateTime);
    }
  };
  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format("YYYY-MM-DD");
      setToDate(formattedDateTime);
    }
  };

  /*--------------------- Time range ---------------------*/

  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = "";
    let toDate = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Today":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Last 7 days":
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setFromDate(responseDate);
        setToDate(toDate);
        setRange("custom");
        setRangeLevel("last7days");
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange("custom");
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange("custom");
        break;
      case "custom":
        setRange("custom");
        break;
      default:
        break;
    }
  }, [timevalue]);

  /*------------------------------------------  Apply changes ----------------------*/

  const formatDate = (inputDate) => {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = inputDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const calculateDateRange = (timevalue, refVal) => {
    let date = new Date();
    let fromDate = "";
    let toDate = "";
    let range = "";

    switch (timevalue) {
      case "Today":
        fromDate = toDate = formatDate(date);
        range = "today";
        setRange("daily");
        setRangeLevel("today");
        break;
      case "Daily":
        fromDate = toDate = formatDate(date);
        range = "daily";
        setRange("daily");
        setRangeLevel("daily");
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        fromDate = toDate = formatDate(date);
        range = "yesterday";
        setRange("daily");
        setRangeLevel("yesterday");
        break;

      case "Last 7 days":
        const SRef = convertLast7DaysFlagToDateRange(refVal || sdaysFlag, 2024);
        // console.log(sdaysFlag);

        if (SRef) {
          const [startDay, endDay] = SRef.split(" - ").map(
            (part) => new Date(`${part} ${date.getFullYear()}`)
          );

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
        } else {
          const pastDate = new Date(date);
          pastDate.setDate(date.getDate() - 6);
          fromDate = formatDate(pastDate);
          toDate = formatDate(date);
        }
        range = "last7days";
        setRange("custom");
        setRangeLevel("last7days");
        break;

      case "This Month":
        const Mref = convertMonthFlag(refVal || MonthlyFlag);
        if (Mref) {
          const [monthName, year] = Mref.split(" ");
          const monthMap = {
            January: 0,
            February: 1,
            March: 2,
            April: 3,
            May: 4,
            June: 5,
            July: 6,
            August: 7,
            September: 8,
            October: 9,
            November: 10,
            December: 11,
          };
          const month = monthMap[monthName];
          const startDate = new Date(year, month, 1);
          const endDate = new Date(year, month + 1, 0);
          fromDate = formatDate(startDate);
          toDate = formatDate(endDate);
          range = "monthly";
          setRange("custom");
          setRangeLevel("monthly");
        }
        break;

      case "Weekly":
        const Wref = convertWeekFlagToDateRange(refVal || WeeklyFlag, 2024);
        if (Wref) {
          const [startDay, endDay] = Wref.split(" - ").map(
            (part) => new Date(`${part} ${date.getFullYear()}`)
          );

          fromDate = formatDate(startDay);
          toDate = formatDate(endDay);
          range = "weekly";
          setRange("custom");
          setRangeLevel("weekly");
        }
        break;

      case "Monthly":
        const Yref = refVal || YearlyFlag;
        const { from, to } = setYearlyRange(Yref);
        fromDate = formatDate(from);
        toDate = formatDate(to);
        range = "yearly";
        setRange("custom");
        setRangeLevel("yearly");
        break;

      case "Yearly":
        const CYref = refVal || cumYearFlag;
        const { fromC, toC } = setCumYearlyRange(CYref);
        fromDate = formatDate(fromC);
        toDate = formatDate(toC);
        range = "yearlyCum";
        setRange("custom");
        setRangeLevel("yearlyCum");
        break;

      case "custom":
        range = "custom";
        setRange("custom");
        setRangeLevel("custom");
        return [];

      default:
        return [];
    }

    if (new Date(toDate) > new Date()) {
      toDate = formatDate(new Date());
    }

    setFromDate(fromDate);
    setToDate(toDate);
    return [fromDate, toDate, range];
  };

  const handleApplyChanges = async (
    value,
    isTimeCall = true,
    flag,
    chart,
    invName,
    timer,
    daily_gen_type,
    based
  ) => {
    setLoading(true);
    setApply(true);
    setFromTabSwitch(false);
    setCustomView();
    setDatalogValue([site, equipment, equipmentParam]);
    const [fromDate, toDate, range] = calculateDateRange(
      flag === "timeValue" ? value : timevalue,
      value?.ref
    );
    console.log(value);

    const GraphName = chart
      ? chart
      : selectedOption
      ? selectedOption
      : chartSite;

    const fromDataRanger = value !== null && typeof value === "object";
    if (
      flag !== "disableReset" &&
      !fromDataRanger &&
      flag !== "timer" &&
      flag !== "dailyGenChartType"
    ) {
      setDate(moment().format("YYYY-MM-DD"));
    }

    // console.log(timevalue);

    const from = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : fromDate
      : isTimeCall || flag === "disableReset"
      ? FromDate
      : fromDate;

    const to = fromDataRanger
      ? timevalue === "Daily"
        ? value.date
        : toDate
      : isTimeCall || flag === "disableReset"
      ? ToDate
      : toDate;
    console.log({
      FromDate,
      ToDate,
      fromDate,
      toDate,
      from,
      to,
    });

    const rangeValue =
      (fromDate === toDate && !isTimeCall) ||
      (timevalue === "custom" && FromDate === ToDate && isTimeCall) ||
      (FromDate === ToDate && isTimeCall)
        ? "daily"
        : "custom";

    let EiraDataModel = {
      fromDate: from,
      range: fromDataRanger && timevalue === "Daily" ? "daily" : rangeValue,
      timeperiod: flag === "timeValue" ? value : timevalue,
      siteIds: blockList
        .find((item) => item.blockName === site)
        .siteMasterDTOs.flatMap((item) => item.siteId),
      toDate: to,
      GraphType: GraphName,
    };

    EiraDataModel.range =
      EiraDataModel.timeperiod === "daily"
        ? "custom"
        : EiraDataModel.timeperiod === "Monthly"
        ? "yearly"
        : EiraDataModel.timeperiod === "Yearly"
        ? "yearlyCum"
        : "custom";
    console.log(EiraDataModel, "EiraDataModel");
    const endpoint = GetBlockEndpoint(EiraDataModel?.GraphType);
    try {
      const data = await endpoint(EiraDataModel);
      setTableData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  /*------------------------------KEY Metrics Moniter  ----------------*/
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const getEquipCap = async (id) => {
    let data = await fetchEquipDropdownBySite(id);
    setEquipments(data);
    return data;
  };

  const handleTabChange = async (site, timevalue, range, GraphType) => {
    setChartSite(GraphType);
    setRange(range);
    setDate(moment().format("YYYY-MM-DD"));
    setChartDropdown(true);
    setTimeValue(timevalue);
    setChartName("");
    setSelectedOption("");
    setEquipmentName([]);
    setEquipmentParamName([]);
    setTableData([]);
    setFromTabSwitch(true);
    setLoading(true);
    let date = new Date();
    let fromDate = new Date();
    let responseDate,
      toDate,
      ApplyFromDate,
      ApplyToDate,
      rangeValue,
      timeApiValue = "";
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, "0");
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case "Daily":
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Daily";
        break;
      case "Yesterday":
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "daily";
        timeApiValue = "Yesterday";
        break;
      case "Last 7 days":
        fromDate.setDate(fromDate.getDate() - 6);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD  ");
        setRange("custom");
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = "custom";
        timeApiValue = "Last 7 days";
        break;
      case "This Month":
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        ApplyFromDate = formatDate(startDate);
        ApplyToDate = formatDate(endDate);
        rangeValue = "custom";
        timeApiValue = "This Month";
        break;
      case "Last Month":
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format("YYYY-MM-DD");
        toDate = moment(date).format("YYYY-MM-DD");
        let dataValue = formatDate(firstDayOfLastMonth);
        let dataValueEnd = formatDate(lastDayOfLastMonth);
        ApplyFromDate = dataValue;
        ApplyToDate = dataValueEnd;
        rangeValue = "custom";
        timeApiValue = "Last Month";
        break;
      default:
        break;
    }

    let rangeData =
      GraphType === "Specific Yield"
        ? range
        : (GraphType === "Daily Generation" && rangeValue === "daily") ||
          (GraphType === "Deemed Generation" && rangeValue === "daily")
        ? "daily"
        : "custom";

    console.clear();
    console.log(
      blockList.find((item) => item.blockName === site),
      "site"
    );

    let EiraDataModel = {
      fromDate: ApplyFromDate,
      GraphType: GraphType,
      range: rangeData,
      siteIds: blockList
        .find((item) => item.blockName === site)
        .siteMasterDTOs.flatMap((item) => item.siteId),
      toDate: ApplyToDate,
      timeperiod: timeApiValue,
    };
    EiraDataModel.range =
      EiraDataModel.range === "daily" ? "custom" : EiraDataModel.range;
    fetchApplyChange(EiraDataModel);
    setDataModel(EiraDataModel);
    setChartName(EiraDataModel?.GraphType);
    setCustomView(EiraDataModel);
  };

  /*------------------------------------- Handle Key Metrics Monitor ------------------------------ */
  const fetchApplyChange = async (EiraDataModel) => {
    if (EiraDataModel !== undefined) {
      setLoading(true);
      setApply(false);
      setChartFromDate(EiraDataModel?.fromDate);
      setChartToDate(EiraDataModel?.toDate);
      console.log(EiraDataModel);

      const endpoint = GetBlockEndpoint(EiraDataModel?.GraphType);
      try {
        const data = await endpoint(EiraDataModel);
        console.log(data);

        setTableData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  const handleClickMenuItem = (event, flag) => {
    if (flag === "Time") {
      setOpenMenu(true);
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorTimerEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = async (value, flag) => {
    if (flag === "Time") {
      setTimeValue(value);
      if (value !== "custom") {
        if (value === "Daily") {
          setDate(moment().format("YYYY-MM-DD"));
        }
        setCustomChart(false);
        setRangeState([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        handleApplyChanges(
          value,
          false,
          "timeValue",
          null,
          null,
          value === "Daily" ? "daily" : dailyChartType
        );
        handleCloseMenu();
      }
      if (value === "custom") {
        openCalender();
        setTableData([]);
      }
    } else if (flag === "dailychart") {
      setDailyChartType(value);
      setCustomChart(true);
      handleCloseeMenu();
      if (chartName !== "Specific Yield Vs Irradiation") {
        await handleApplyChanges(
          null,
          true,
          "dailyGenChartType",
          chartName,
          null,
          timerValue,
          value
        );
      }
    } else {
      setTimerValue(Number(value));
      await handleApplyChanges(
        null,
        true,
        "timer",
        chartName,
        null,
        Number(value)
      );
      handleCloseTimer();
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const handleCloseeMenu = () => {
    setOpennMenu(false);
    setChartAnchor(null);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };

  const RangeText =
    timevalue === "custom"
      ? "Custom Range"
      : timevalue === "Last 7 days"
      ? convertLast7DaysFlagToDateRange(sdaysFlag, 2024)
      : timevalue === "Weekly"
      ? convertWeekFlagToDateRange(WeeklyFlag, 2024)
      : timevalue === "This Month"
      ? convertMonthFlag(MonthlyFlag)
      : timevalue === "Monthly"
      ? YearlyFlag
      : timevalue === "Yearly"
      ? cumYearFlag.split("-")[0]
      : moment(date).format("DD/MM/YYYY");

  const analyticsMetrics = () => {
    return (
      <>
        <div
          style={{
            marginLeft: "2%",
            marginRight: "5%",
            marginTop: "5%",
          }}
        >
          {/* <Typography
            style={{
              fontSize: '1rem',
              '@media (max-width: 600px)': {
                fontSize: '0.875rem',
              },
            }}
          >
            Metric Points
          </Typography> */}
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "23vh",
              padding: "8px",
              borderRadius: "5px",
              "@media (max-width: 600px)": {
                width: "100%",
                marginLeft: "0%",
              },
            }}
          >
            <List
              style={{
                paddingTop: 0,
                "@media (max-width: 600px)": {
                  paddingTop: "5px",
                },
              }}
            >
              {chartType.map((option) => (
                <ListItem
                  key={option.label}
                  disablePadding
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "@media (min-width: 600px)": {
                      flexDirection: "row",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions === option.label}
                        onChange={(event) =>
                          handleChartCheckboxChange(event, option.label)
                        }
                        disabled={!site}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 14 },
                          cursor: "default",
                          visibility: "hidden",
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          "@media (max-width: 600px)": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "12rem",
                            marginRight: 35,
                            cursor: "pointer",
                            fontSize: "14px",
                            color:
                              selectedOptions === option.label
                                ? "#1976d2"
                                : "inherit",
                            "@media (max-width: 600px)": {
                              width: "100%",
                              marginRight: "0",
                              textAlign: "center",
                            },
                          }}
                        >
                          {option.label}
                        </span>
                      </Box>
                    }
                    sx={{
                      fontSize: 15,
                      "@media (max-width: 600px)": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      </>
    );
  };

  const analyticsEquipment = () => {
    // Group the equipment by category

    const groupedByCategory = groupByCategory(equpAndParams);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          padding: 10,
        }}
      >
        <div style={{ width: "95%", textAlign: "left" }}>
          {/* <Typography>Components</Typography> */}
        </div>
        <div
          style={{
            // border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: "5px",
            width: "95%",
            marginTop: "2%",
          }}
        >
          <div
            style={{
              // marginLeft: '6%',
              marginTop: "5%",
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              height: "48vh",
              flexDirection: "column",
            }}
          >
            {componentLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100%",
                }}
              >
                <FadeLoader color="#000440" loading={true} />
              </div>
            ) : equpAndParams.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "100%",
                }}
              >
                <typography>No configuration found</typography>
              </div>
            ) : (
              Object.entries(groupedByCategory)
                .sort()
                .map(([category, equipmentList]) => (
                  <div key={category} style={{ marginBottom: "10px" }}>
                    {/* Add marginBottom for spacing */}
                    <div
                      onClick={() => toggleCategoryListOpen(category)}
                      className="categoryItem"
                    >
                      {openCategoryLists[category] ? (
                        <BookmarkOutlinedIcon sx={{ fontSize: 18 }} />
                      ) : (
                        <BookmarkBorderIcon
                          sx={{ color: "rgb(113, 118, 111)", fontSize: 18 }}
                        />
                      )}
                      <Typography
                        style={{
                          marginRight: "8px",
                          width: "12rem",
                          cursor: "pointer",
                          fontSize: "14px",
                          "@media (max-width: 600px)": {
                            width: "100%",
                            marginRight: "0",
                            textAlign: "center",
                          },
                          userSelect: "none",
                        }}
                      >
                        {category}
                      </Typography>
                    </div>
                    {openCategoryLists[category] && (
                      <List
                        sx={{
                          marginLeft: "4px",
                          borderLeft: "1px solid gray",
                          paddingTop: "0px",
                        }}
                      >
                        {equipmentList
                          .sort((a, b) => {
                            if (a.equ?.includes("-") && b.equ?.includes("-")) {
                              return (
                                +a.equ.split("-").pop() -
                                +b.equ.split("-").pop()
                              );
                            } else if (
                              a.equ?.includes("_") &&
                              b.equ?.includes("_")
                            ) {
                              return (
                                +a.equ.split("_").pop() -
                                +b.equ.split("_").pop()
                              );
                            }
                            return a.equ.localeCompare(b.equ);
                          })
                          .map(({ equ, params }) => (
                            <div key={equ} className="equipmentItem">
                              <FormControlLabel
                                className="equipmentLabel"
                                control={
                                  <Checkbox
                                    checked={
                                      checkedItems[equ]?.checked || false
                                    }
                                    onChange={handleCheckboxChange(equ)}
                                    onClick={() => toggleEquipmentListOpen(equ)}
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 14 },
                                      cursor: "default",
                                      visibility: "hidden",
                                      marginLeft: "-8%",
                                    }}
                                  />
                                }
                                label={
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    {openEquipmentLists[equ] ? (
                                      <BookmarkOutlinedIcon
                                        sx={{ fontSize: 18 }}
                                      />
                                    ) : (
                                      <BookmarkBorderIcon
                                        sx={{
                                          color: "rgb(113, 118, 111)",
                                          fontSize: 18,
                                        }}
                                      />
                                    )}
                                    <span
                                      style={{
                                        marginRight: "8px",
                                        width: "12rem",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        color: checkedItems[equ]?.checked
                                          ? "#1976d2"
                                          : "inherit",
                                        "@media (max-width: 600px)": {
                                          width: "100%",
                                          marginRight: "0",
                                          textAlign: "center",
                                        },
                                      }}
                                    >
                                      {equ}
                                    </span>
                                  </Box>
                                }
                                sx={{ fontSize: 14 }}
                              />

                              {openEquipmentLists[equ] && (
                                <List
                                  sx={{
                                    marginLeft: "4px",
                                    borderLeft: "1px solid gray",
                                  }}
                                >
                                  {params.map((param) => (
                                    <ListItemButton
                                      key={param}
                                      onClick={handleParamToggle(equ, param)}
                                      sx={{
                                        fontSize: 11,
                                        padding: "0 0 0 8px",
                                        "& .MuiListItemText-root": {
                                          fontSize: "12px",
                                        },
                                      }}
                                    >
                                      <Radio
                                        edge="start"
                                        checked={
                                          checkedItems[equ]?.params?.indexOf(
                                            param
                                          ) !== -1
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 12,
                                          },
                                        }}
                                      />
                                      <ListItemText
                                        primary={
                                          <div style={{ fontSize: "12px" }}>
                                            {param}
                                          </div>
                                        }
                                      />
                                    </ListItemButton>
                                  ))}
                                </List>
                              )}
                            </div>
                          ))}
                      </List>
                    )}
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Paper
          elevation={0}
          style={{
            width: "99%",
            borderRadius: "2px 2px 0px 0px",
            userSelect: "none",
            height: "5vh",
            display: "flex",
            alignItems: "center",
            // marginLeft: '2.5%',
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <CustomBreadcrumbs
                paths={paths || []}
                separatorSize="18px"
                fontSize="14px"
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <div className="monitoring-graph" style={{ flexDirection: "row" }}>
        <div style={{ margin: "0px 0px 10px 0px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              marginTop: "10px",
              backgroundColor: "background.paper",
              "& > :not(style)": {
                m: 1,
                width: "20vw",
                minHeight: "70vh",
                height: "fit-content",
              },
              // overflowY: "scroll",
            }}
          >
            <Paper elevation={0} height="100%">
              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // marginLeft: '7%',
                    // marginRight: '5%',
                    marginBottom: "5%",
                  }}
                >
                  <Typography>Block Name</Typography>
                  <div>
                    <Tooltip
                      title={site}
                      disableFocusListener
                      enterDelay={200}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                      arrow
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        value={site}
                        disabled={params.name}
                        options={blockList.map((option) => option.blockName)}
                        onChange={(data, event) => handleSite(data, event)}
                        sx={{ width: "18vw" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Site ..."
                          />
                        )}
                        classes={{ option: "autocomplete" }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Tabs value={panelIndex} onChange={handleChangePanel}>
                  <Tab
                    label="Comparison Metrics"
                    key={1}
                    value="1"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      //   width: "20vw",
                      backgroundColor:
                        panelIndex === "1" ? "#1976d229" : "transparent",
                      transition: "background-color 0.3s ease-in-out", // Added transition effect
                    }}
                  />
                </Tabs>
              </Box>

              {panelIndex === "1" ? (
                <div>{analyticsMetrics()}</div>
              ) : (
                <div
                  style={{
                    overflowY: "auto",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {analyticsEquipment()}
                </div>
              )}
            </Paper>
          </Box>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ marginLeft: "1%", width: "70vw" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "13vh",
                },
              }}
            >
              <Paper elevation={0} style={{ height: "100%" }}>
                <div style={{ width: "auto", padding: "10px" }}>
                  <div>
                    <div className="graph">
                      <div>
                        <div style={{ marginTop: 10 }}>
                          <Stack spacing={1} direction="row">
                            <div className="monitor-text">
                              <Typography
                                style={{
                                  ...typoStyle,
                                  color: CheckForLight() ? "black" : "white",
                                }}
                              >
                                Range
                              </Typography>
                              <div>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  borderRadius="4px"
                                  sx={{ width: "28vw" }}
                                  justifyContent="space-between"
                                  p="0"
                                  style={{
                                    border: CheckForLight()
                                      ? "1px solid #c4c4c4"
                                      : "1px solid #494949",
                                  }}
                                >
                                  <Tooltip title="Previous">
                                    <IconButton
                                      disabled={timevalue === "custom"}
                                      onClick={() => handleChangeDate("left")}
                                    >
                                      <ChevronLeftIcon
                                        sx={{
                                          color:
                                            timevalue === "custom"
                                              ? "rgb(113, 118, 111)"
                                              : CheckForLight()
                                              ? "black"
                                              : "white",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Divider orientation="vertical" flexItem />
                                  <Stack
                                    direction="row"
                                    style={{
                                      cursor: "pointer",
                                      flex: "1",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      mt="1px"
                                      ml="2.5px"
                                      style={{
                                        color: CheckForLight()
                                          ? "black"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                      }}
                                    >
                                      {RangeText}
                                    </Typography>
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    disabled={
                                      moment(date).isSame(
                                        moment().startOf("day")
                                      ) || timevalue === "custom"
                                    }
                                    onClick={() => handleChangeDate("right")}
                                  >
                                    <ChevronRightIcon
                                      sx={{
                                        color:
                                          moment(date).isSame(
                                            moment().startOf("day")
                                          ) || timevalue === "custom"
                                            ? "rgb(113, 118, 111)"
                                            : CheckForLight()
                                            ? "black"
                                            : "white",
                                      }}
                                    />
                                  </IconButton>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      setIsStacked(!isStacked)
                                    }
                                  >
                                    {stackedIcon}
                                  </IconButton>
                                  <Menu
                                    anchorEl={chartAnchor}
                                    open={menuOpenn}
                                    onClose={handleCloseeMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {dailychartType.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "dailychart"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                  <Divider orientation="vertical" flexItem />
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickMenuItem(event, "Time")
                                    }
                                  >
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        color: CheckForLight()
                                          ? "black"
                                          : "white",
                                        opacity: 1,
                                        pointerEvents: "auto",
                                      }}
                                    />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    {optionToUse.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={(event) =>
                                          handleMenuItemClick(
                                            option.value,
                                            "Time"
                                          )
                                        }
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </Box>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "fit-content",
                                marginTop: "23px",
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                borderRadius="4px"
                                sx={{ width: "fit-content", height: "43px" }}
                                justifyContent="space-between"
                                p="0"
                                style={{
                                  border: CheckForLight()
                                    ? "1px solid #c4c4c4"
                                    : "1px solid #494949",
                                }}
                              >
                                <IconButton
                                  disabled={timevalue !== "custom"}
                                  onClick={(e) =>
                                    openCalender(e, "fromCalender")
                                  }
                                >
                                  <CalendarMonthOutlinedIcon
                                    sx={{
                                      color:
                                        timevalue !== "custom"
                                          ? "rgb(113, 118, 111)"
                                          : CheckForLight()
                                          ? "black"
                                          : "white",
                                    }}
                                  />
                                </IconButton>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    From:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(FromDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                                <Divider orientation="vertical" flexItem />
                                <div
                                  style={{
                                    display: "flex",
                                    // width: '12vw',
                                    marginRight: "10px",
                                    cursor: "default",
                                  }}
                                >
                                  <Typography sx={{ padding: "10px" }}>
                                    To:
                                  </Typography>
                                  <Typography
                                    sx={{ padding: "10px", paddingLeft: "0px" }}
                                  >
                                    {dayjs(ToDate).format("DD/MM/YYYY")}
                                  </Typography>
                                </div>
                              </Box>
                              <div>
                                <Popover
                                  id={calenderId}
                                  open={isCalendarOpen}
                                  anchorEl={calenderAnchor}
                                  onClose={closeCalender}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                  sx={{ mt: 1 }}
                                >
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) => handleTimeChange(item)}
                                    months={2}
                                    ranges={RangeState}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    dateDisplayFormat="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    direction="horizontal"
                                  />
                                </Popover>
                              </div>
                            </div>

                            <div
                              style={{
                                marginTop: "2%",
                                marginLeft: "1%",
                                marginRight: "1%",
                                display: "flex",
                                // justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                  marginTop: "4%",
                                  fontSize: "1rem",
                                }}
                                onClick={() => {
                                  handleApplyChanges(
                                    null,
                                    timevalue === "custom" ? true : false,
                                    "disableReset"
                                  );
                                }}
                                startIcon={<DownloadDoneOutlinedIcon />}
                                disabled={
                                  (chartName === "Energy Performance" &&
                                    site !== "" &&
                                    selectedEquipments.length > 0) ||
                                  (chartName === "Parameter Comparision" &&
                                    site !== "" &&
                                    !optionalCharts &&
                                    ((Object.keys(checkedItems).length > 0 &&
                                      Object.values(checkedItems).some(
                                        (x) => x.checked
                                      )) ||
                                      selectedEquipments !== "")) ||
                                  ((chartName === "Daily Generation" ||
                                    chartName === "Specific Yield" ||
                                    chartName === "Specific Yield - Heatmap" ||
                                    chartName === "Actual Vs Expected" ||
                                    chartName === "DG PV Grid Management" ||
                                    chartName === "Deemed Generation" ||
                                    chartName === "Set Point" ||
                                    optionalCharts) &&
                                    site !== "" &&
                                    timevalue === "custom")
                                    ? false
                                    : true
                                }
                              >
                                Apply
                              </Button>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div>
                    <div>
                      <div>
                        {blockList.length === 0 ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "60vh",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "50vh",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "1.5rem",
                                  textTransform: "capitalize",
                                  lineHeight: "14.52px !important",
                                  color: " rgb(113, 118, 111)",
                                }}
                              >
                                No Block has been added
                              </Typography>
                            </CardContent>
                          </Card>
                        ) : loading ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "60vh",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                height: "60vh",
                                overflowX: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  //  marginTop: "15%",
                                }}
                              >
                                <FadeLoader color="#000440" loading={true} />
                              </div>
                            </CardContent>
                          </Card>
                        ) : tableData?.length >= 0 ? (
                          <Card
                            sx={{
                              width: "100%",
                              height: "10%",
                              overflowX: "scroll",
                            }}
                            elevation={0}
                          >
                            <CardContent
                              sx={{
                                padding: "1rem",
                                paddingBottom: "1rem !important",
                                width: "100%",
                                overflowX: "auto",
                              }}
                            >
                              <BlockSiteChart
                                tableData={tableData}
                                chartName={chartName}
                                customView={dataModel}
                                barchartdata={barchartdata}
                                widgetName={`${site}-${chartName}`}
                                fallBack={fallBack}
                                isStacked={isStacked}
                                siteList={siteIdName}
                                from={"Vanalytics"}
                              />
                            </CardContent>
                          </Card>
                        ) : (
                          fallBack
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};
