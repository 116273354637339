
import axios from 'axios';
export const GetCustomerdropdowndata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
          process.env.REACT_APP_USERCONFIG_CUSTOMERLIST
        }/${sessionStorage.getItem('companyId')}`
      );
      console.log(response.data, 'API Response');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  export const fetchSiteTableById = async (Id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}${
        
             process.env.REACT_APP_SITELISTT
        }/${
    Id
        }`
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };
  export const fetchsiteblockTable = async (Id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/${
        
             process.env.REACT_APP_SITE_BLOCK_MAP_TABLE
        }/${sessionStorage.getItem('id')}`
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };
  export const postsiteblockTable = async (object) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/${
        
             process.env.REACT_APP_SITE_BLOCK_MAP_SAVE

        }`,object
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };
  export const putsiteblockTable = async (Id,object) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_PATH}/${
        
             process.env.REACT_APP_SITE_BLOCK_MAP_UPDATE

        }/${Id}`,object
      );
      // console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };