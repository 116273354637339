import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useSearchParams } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import {
  fetchEachEquipHistory,
  fetchEquipTickets,
  fetchEventTable,
} from '../../Api/EquipmenReplaceApi';
import { TabsComponent } from '../util/components/Tabs';
import ColumnChart from '../Charts/DoubleBarChart';
import { useTheme } from '@mui/material/styles';
import CustomBreadcrumbs from '../util/components/CustomBread';
import {
  CheckForLight,
  removeQuestionMark,
} from '../util/common/CommanFunction';
import { queryDecrypt } from '../util/security/Cipher';
const EquipmentOverview = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rawResponse, setRawResponse] = useState([]);
  const [Event, setEvent] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [tabVal, setTabVal] = useState(0);
  const [loading, setloading] = useState(false);
  const [getDate, setGetDate] = useState([]);
  const [getEvent, setGetEvent] = useState([]);
  const [getTicket, setGetTicket] = useState([]);
  // const [dateVal, setDateVal] = useState({
  //   getDate: [],
  //   getEvent: [],
  //   getTicket: []
  // });
  const theme = useTheme();

  const location = useLocation();
  let rm_QM = removeQuestionMark(location.search);
  let decryptedParams = queryDecrypt(rm_QM);
  let queryParam = new URLSearchParams(`?${decryptedParams}`);
  let params = {};
  for (const [key, value] of queryParam) {
    params[key] = value;
  }
  let equipId = params.id;
equipId = parseInt(equipId); // Removes decimal
console.log(equipId);
  
  let equipName = params.name;
  // const { state } = location;
  // const { siteEquip } = state || {};
  // console.log(siteEquip, "siteEquips?.eventList");
  const paths = [
    { label: 'Home', path: '/menu' },
    { label: 'Site List', path: '/menu/sitelist' },
    { label: 'Equipment Details', path: -1 },
    {
      label: equipName,
      path: '/menu/equipmentdetails/equipmentOverview',
    },
  ];

  const labelStyle = {
    lineHeight: '16px',
    // fontSize: '1rem',
    // fontWeight: '405',
    fontSize: '0.80rem',
    fontWeight: '410',
    marginBottom: '1rem',
    // marginLeft: '2%',
    width: '50%', // Adjust the width to accommodate the longest label
  };
  const colonStyle = {
    lineHeight: '16px',
    // fontSize: '1rem',
    // fontWeight: '405',
    marginBottom: '1rem',
    width: '20px',
    textAlign: 'center',
  };
  const valueStyle = {
    lineHeight: '16px',
    fontSize: '0.75rem',
    // fontWeight: '400',
    marginBottom: '1rem',
    width: '45%',
  };
  const TypoCompo = (...pairs) => (
    <div
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}
    >
      {pairs.map(([label, value], index) => {
        let values =
          typeof value === 'string' && value.includes(' ')
            ? value.split(' ')
            : [value];
        let val = values
          .map((v) =>
            v !== 'undefined' && v !== undefined && v !== null && v[0] !== 0
              ? v
              : ''
          )
          .join(' ');
        return (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={labelStyle}>{label}</Typography>
            <Typography style={colonStyle}>:</Typography>
            <Typography key={index} style={valueStyle}>
              {val}
            </Typography>
          </div>
        );
      })}
    </div>
  );

  const replaceHeadCell = [
    {
      id: 'equipmentType',
      label: 'Equipment Type',
    },
    {
      id: 'withAcUnits',
      label: 'Ac Capacity',
    },
    {
      id: 'withDcUnits',
      label: 'Dc Capacity',
    },
    {
      id: 'serialNo',
      label: 'Serial No',
    },
    {
      id: 'equipmentCategory',
      label: 'Category',
    },
    {
      id: 'lastUpdatedDate',
      label: 'Last Replaced On',
    },
    {
      id: 'userName',
      label: 'Created By',
    },
  ];
  const eventHeadCell = [
    {
      id: 'parameter',
      label: 'Parameter',
    },
    {
      id: 'emailId',
      label: 'EmailId',
    },
    {
      id: 'logic',
      label: 'Logic',
    },
    {
      id: 'value',
      label: 'Value',
    },
    {
      id: 'errorMessage',
      label: 'Error Message',
    },
    {
      id: 'lastUpdatedDate',
      label: 'Last Updated Date',
    },
    {
      id: 'userName',
      label: 'Created By',
    },
    // {
    //   id: "errorDescription",
    //   label: "Event Description",
    // },
    // {
    //   id: 'errorMessage',
    //   label: 'Error Message'
    // },
    // {
    //   id: 'errorCode',
    //   label: 'Error Code'
    // }, {
    //   id: 'eventTimestampText',
    //   label: 'Last Updated'
    // }
  ];
  const ticketHeadCell = [
    {
      id: 'ticketCode',
      label: 'Ticket No',
    },
    {
      id: 'categoryName',
      label: 'Ticket Category',
    },
    {
      id: 'assignedBy',
      label: 'Assigned To',
    },
    {
      id: 'statusName',
      label: 'Status',
    },
    {
      id: 'subject',
      label: 'Subject',
    },
    {
      id: 'ticketTypeName',
      label: 'Ticket Type',
    },
    {
      id: 'lastUpdatedDate',
      label: 'Last Updated Date',
    },
    {
      id: 'userName',
      label: 'Created By',
    },
  ];
  const overviewHeadcells = [
    {
      id: 'displayName',
      label: 'Equipment Name',
    },
    {
      id: 'equipmentType',
      label: 'Equipment Type',
    },
    {
      id: 'serialNo',
      label: 'Serial No',
    },
    {
      id: 'isPrimaryEquipment',
      label: 'Primary Equipment',
    },
    {
      id: 'equipmentCategory',
      label: 'Category',
    },
    {
      id: 'noOfComponents',
      label: 'No.of components',
    },
    {
      id: 'acCapacity',
      label: 'Ac Capacity',
    },
    {
      id: 'dcCapacity',
      label: 'Dc Capacity',
    },
    {
      id: 'latitude',
      label: 'Latitude',
    },
    {
      id: 'longitude',
      label: 'Longitude',
    },
    {
      id: 'remarks',
      label: 'Remarks',
    },
    {
      id: 'userName',
      label: 'Created By',
    },
    {
      id: '',
      label: 'Installation Date',
    },
    {
      id: '',
      label: 'Warranty Date',
    },
    {
      id: 'replaceCount',
      label: 'Replace Count',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];
  const labelValuePairs = overviewHeadcells.map((cell) => {
    return [
      cell.label,
      cell.id === 'acCapacity'
        ? rawResponse[cell.id]
          ? rawResponse[cell.id] + ' ' + rawResponse['acUnits']
          : 'N/A'
        : cell.id === 'dcCapacity'
        ? rawResponse[cell.id]
          ? rawResponse[cell.id] + ' ' + rawResponse['dcUnits']
          : 'N/A'
        : cell.id === 'isPrimaryEquipment'
        ? rawResponse[cell.id] === 1
          ? 'Yes'
          : 'No'
        : cell.id === 'replaceCount'
        ? rawResponse.equipReplacementhistory?.length !== 0
          ? rawResponse.equipReplacementhistory?.length
          : 'N/A'
        : cell.id === 'status'
        ? rawResponse[cell.id] === 1
          ? 'Active'
          : 'Inactive'
        : rawResponse[cell.id]
        ? rawResponse[cell.id]
        : 'N/A',
    ];
  });
  if (
    rawResponse.equipmentadditionalfield &&
    rawResponse.equipmentadditionalfield.length > 0
  ) {
    rawResponse.equipmentadditionalfield.forEach((field) => {
      labelValuePairs.push([field.fieldName, field.value]);
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  let replaceCount = rawResponse?.equipReplacementhistory?.length || 0;
  let eventsLength = Event.length;
  let ticketLength = tickets.length;
  const tabsData = [
    { name: 'Replace', value: 'all', badgeCount: replaceCount },
    { name: 'Events', value: 'Events', badgeCount: eventsLength },
    { name: 'Tickets', value: 'tickets', badgeCount: ticketLength },
  ];
  function handleTabChange(val) {
    setPage(0);
    handleChange(val);
  }
  const handleChange = (val) => {
    switch (val) {
      case 'all':
        setTabVal(0);
        break;
      case 'Events':
        setTabVal(1);
        break;
      case 'tickets':
        setTabVal(2);
        break;
      default:
        setTabVal(0);
    }
  };
  const filteredEventData = (
    tabVal == 0
      ? rawResponse?.equipReplacementhistory
      : tabVal == 1
      ? Event
      : tickets
  )?.map((row) => {
    let newRow = { ...row };
    if (row.acCapacity) {
      newRow.withAcUnits = row.acCapacity + ' ' + row.acUnits;
    }

    if (row.dcCapacity) {
      newRow.withDcUnits = row.dcCapacity + ' ' + row.dcUnits;
    }
    return newRow;
  });
  console.log(Event, 'filteredEventData');
  useEffect(() => {
    fetchData();
    fetchTicket();
    fetchEquipHistory();
  }, []);

  const fetchEquipHistory = async () => {
    setloading(true);
    try {
      const data = await fetchEachEquipHistory(equipId);
      setRawResponse(data);
      setloading(false);
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };
  // console.log(rawResponse.equipmentadditionalfield[0]?.value, 'rawResponse');
  const fetchData = async () => {
    setloading(true);
    try {
      // console.log(siteEquip, 'siteEquip');
      const data = await fetchEventTable(equipId);
      setEvent(data);
      // await setEvent(siteEquip);
      setloading(false);
    } catch (e) {
      console.error(e);
      setloading(false);
    }
  };
  const fetchTicket = async () => {
    setloading(true);
    try {
      const data = await fetchEquipTickets(equipId);
      setTickets(data);
      setloading(false);
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  function getLastWeekDates() {
    const dates = [];
    const today = new Date();

    for (let i = 6; i >= 0; i--) {
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - i);
      dates.push(formatDate(pastDate));
    }

    return dates;
  }

  function getEventByDate(receive) {
    // Step 1: Get today's date and the date 7 days ago
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 6); // Includes today as the 7th day

    // Step 2: Map event dates to an array of strings in "MM/DD/YYYY" format
    let dates = receive.map((event) => {
      let newDate = new Date(event?.lastUpdatedDate);
      return newDate.toLocaleDateString();
    });

    // Step 3: Sort the date strings
    let sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));

    // Step 4: Initialize an array to hold the counts
    let dateCounts = [];

    // Step 5: Helper function to format dates as "MM/DD/YYYY"
    const formatDate = (date) => {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    // Step 6: Iterate through each of the last 7 days, filling in gaps with zero counts
    for (
      let date = new Date(sevenDaysAgo);
      date <= today;
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = formatDate(date);
      const count = dates.filter((d) => d === formattedDate).length;
      dateCounts.push(count);
    }

    return dateCounts;
  }
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 6); // Adjust to get seven days including today

  // Step 2: Initialize an array to store counts for each day
  const countByDay = [];

  // Step 3: Loop through each day in the last seven days
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(sevenDaysAgo);
    currentDate.setDate(sevenDaysAgo.getDate() + i);

    // Filter events for the current day
    const filteredEvents = Event.filter((event) => {
      const eventTimestamp = new Date(event.eventTimestamp);
      return eventTimestamp.toDateString() === currentDate.toDateString();
    });

    // Count number of events for the current day
    const count = filteredEvents.length;

    // Push count to the array
    countByDay.push(count);
  }
  console.log(countByDay, 'tisketsh');
  useEffect(() => {
    setloading(true);
    const getDates = async () => {
      const getDate = await getLastWeekDates();
      setGetDate(getDate);
      const getEvent = await getEventByDate(Event);
      console.log(getEvent, 'getEvent');
      setGetEvent(getEvent);
      const getTicket = await getEventByDate(tickets);
      console.log(getTicket, 'getDatezz');
      setGetTicket(getTicket);
      setloading(false);
    };
    getDates();
  }, [Event, tickets]);
  console.log(getTicket, 'getDate');
  console.log(countByDay, 'getDates');
  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Paper
              elevation={0}
              style={{
                width: '101%',
                borderRadius: '2px 2px 0px 0px',
                userSelect: 'none',
                height: '5vh',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid
                container
                spacing={3}
                justifyContent="space-between"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CustomBreadcrumbs
                    paths={paths || []}
                    separatorSize="18px"
                    fontSize="14px"
                  />
                </Grid>
                <Grid item xs="auto">
                  {tabsData ? (
                    <TabsComponent
                      tabs={tabsData}
                      onTabChange={(val) => handleTabChange(val)}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <div style={{ display: 'flex' }}>
            <Card
              sx={{
                marginTop: '20px',
                width: '49%',
                height: '38vh',
                marginRight: '1%',
                overflowY: 'scroll',
              }}
              elevation="0"
            >
              <CardHeader
                title="Equipment Details"
                style={{
                  padding: 0,
                  paddingTop: 10,
                  paddingBottom: 10,
                  position: 'sticky',
                  top: 0,
                  backgroundColor: CheckForLight() ? '#ffffff' : '#121212',
                  zIndex: 1,
                }}
                titleTypographyProps={{
                  style: {
                    padding: 0,
                    marginLeft: '1%',
                  },
                }}
              />
              <CardContent style={{ padding: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    height: '40vh',
                    marginLeft: '1vw',
                    padding: '5px',
                  }}
                >
                  {TypoCompo(...labelValuePairs)}
                </div>
              </CardContent>
            </Card>
            {Event.length !== 0 || tickets.length !== 0 ? (
              <Card
                sx={{
                  marginTop: '20px',
                  height: '38vh',
                  width: '49%',
                  marginLeft: '1%',
                }}
                elevation="0"
              >
                <ColumnChart
                  date={getDate}
                  chart={'Equipment'}
                  firstBar={getEvent}
                  secondBar={getTicket}
                />
              </Card>
            ) : (
              <Card
                sx={{
                  marginTop: '20px',
                  height: '38vh',
                  width: '49%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '1%',
                }}
                elevation="0"
              >
                <CardContent sx={{ Height: 650 }}>
                  No events and tickets were found
                </CardContent>
              </Card>
            )}
          </div>
          <div>
            {(tabVal === 0 &&
              rawResponse?.equipReplacementhistory?.length === 0) ||
            (tabVal === 1 && Event.length === 0) ||
            (tabVal === 2 && tickets.length === 0) ? (
              <Card
                sx={{
                  marginTop: '20px',
                  width: '100%',
                  height: '38vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                elevation="0"
              >
                <CardContent>
                  <div>
                    <Typography style={{ marginTop: '5%' }}>
                      {/* There are currently no{" "} */}
                      {tabVal === 0
                        ? 'No equipment has been replaced'
                        : tabVal === 1
                        ? 'No Event is configured yet'
                        : 'No Ticket  is configured yet'}{' '}
                      {/* to display */}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <div>
                <Box style={{ marginTop: '20px', height: '30px' }}>
                  <Paper elevation="0">
                    <Typography
                      style={{
                        fontWeight: '405',
                        marginLeft: '2%',
                        marginTop: '1%',
                        paddingBottom: '2%',
                      }}
                    >
                      {`Equipment
                      ${
                        tabVal == 0
                          ? 'Replacement'
                          : tabVal == 1
                          ? 'Event'
                          : 'Ticket'
                      }
                      :`}
                    </Typography>
                  </Paper>
                </Box>
                <Card sx={{ height: '32.4vh' }} elevation="0">
                  <TableContainer
                    style={{
                      maxHeight: '100%',
                      overflow: 'auto',
                      marginTop: '-0.5%',
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {(tabVal === 0
                            ? replaceHeadCell
                            : tabVal === 1
                            ? eventHeadCell
                            : ticketHeadCell
                          ).map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align="center"
                              style={{ position: 'sticky', top: 0 }}
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredEventData
                          ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={row.userid}>
                              {(tabVal === 0
                                ? replaceHeadCell
                                : tabVal === 1
                                ? eventHeadCell
                                : ticketHeadCell
                              ).map((headCell) => {
                                const newDate = new Date(row.lastUpdatedDate);
                                const decodedDate =
                                  newDate.toLocaleDateString();
                                return (
                                  <TableCell align="center">
                                    {headCell.id === 'lastUpdatedDate'
                                      ? decodedDate
                                      : row[headCell.id]?.length === 0 ||
                                        row[headCell.id] === null ||
                                        row[headCell.id] === undefined
                                      ? '-'
                                      : row[headCell.id]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
                <div
                  style={{
                    backgroundColor:
                      theme.palette.mode === 'light' ? 'white' : '#121212',
                    marginTop: '-0.5%',
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredEventData?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            )}
            <div>
              <div>
                <Box style={{ width: '100%' }}>
                  <Card style={{ width: '100%' }} elevation={0}></Card>
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EquipmentOverview;
